import { Injectable } from '@angular/core'
import { APIService } from '../../API.service'
import { Question, Test } from '../types'
import { pick } from 'lodash'

@Injectable({
  providedIn: 'root'
})
export class TestApiService {
  constructor(private apiService: APIService) {}

  public async addTest(test: Test): Promise<Test> {
    const request = { object: this.mapToTest(test) }
    const { data } = await this.apiService.AddTest({
      data: JSON.stringify(request)
    })
    const response = JSON.parse(data)
    return this.mapFromTest(response)
  }

  public async deleteTest(id: string): Promise<Test> {
    const request = { id: id }
    const { data } = await this.apiService.DeleteTest({
      data: JSON.stringify(request)
    })
    const response = JSON.parse(data)
    return this.mapFromTest(response)
  }

  public async getTest(id: string): Promise<Test> {
    const request = { id: id }
    const { data } = await this.apiService.GetTest({
      data: JSON.stringify(request)
    })
    const response = JSON.parse(data)
    return this.mapFromTest(response)
  }

  public async updateTest(id: string, partial: any): Promise<Test> {
    const request = { id: id, object: this.mapToTest(partial) }
    const { data } = await this.apiService.UpdateTest({
      data: JSON.stringify(request)
    })
    const response = JSON.parse(data)
    return this.mapFromTest(response)
  }

  public async listTests(
    query: any,
    options?: any
  ): Promise<{ meta: any; objects: Array<Test> }> {
    const request = { query: query, options }
    const { data } = await this.apiService.ListTests({
      data: JSON.stringify(request)
    })
    const response = JSON.parse(data)
    return {
      meta: response.meta,
      objects: response.objects.map(o => {
        return this.mapFromTest(o)
      })
    }
  }

  /**
   * Map Test Objects
   * From Server to Client
   * @private
   */
  private mapFromTest(json: any): Test {
    return {
      id: json._id,
      accountId: json.accountId,
      code: json.code,
      title: json.title,
      description: json.description,
      items: json.items,
      instructions: json.instructions,
      type: json.type,
      ownerId: json.type,
      scoring: json.scoring,
      options: json.options,
      logic: json.logic
    }
  }

  /**
   * Map Test Objects
   * From Server to Client
   * @private
   */
  private mapToTest(json: any): Test {
    // Remove ID since we are forwarding to server,
    // ID should be specified separately
    return pick(json, [
      'accountId',
      'ownerId',
      'title',
      'code',
      'type',
      'items',
      'description',
      'instructions',
      'scoring',
      'logic',
      'options'
    ])
  }
}
