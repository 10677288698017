export const WsActions = {
  /**
   * Action to invoke when Patient Submits a Pretest
   */
  PretestSubmission: 'PretestSubmission',
  /**
   * Action to invoke an admin approves Pretest
   */
  PretestApproval: 'PretestApproval'
}
