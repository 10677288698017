import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Question, QuestionMeta, Section } from '../../../../../types'
import { FormArray, FormControl, FormGroup } from '@angular/forms'
import { TestService } from '../../../../../services/test.service'
import { isBoolean } from 'lodash'

@Component({
  selector: 'app-multiple-choice-question',
  templateUrl: './multiple-choice-question.component.html',
  styleUrls: ['./multiple-choice-question.component.scss']
})
export class MultipleChoiceQuestionComponent implements OnInit {
  /**
   * The Section object
   */
  @Input('section') section: Section
  /**
   * Question Meta that is 'selected' for this Question
   */
  @Input('questionMeta') questionMeta: QuestionMeta
  /**
   * The Question object
   */
  @Input('question') question: Question
  /**
   * Emitter that tells whether this question type component has reached an answered state
   */
  @Output() answered = new EventEmitter<boolean>()
  /**
   * Input model map
   */
  public inputs: { [key: string]: boolean }

  constructor(private testService: TestService) {}

  ngOnInit(): void {
    // Instantiate the form group and control
    const values = this.testService.getAnswerValue(this.question.id)

    // Load the values from answer if it exists
    this.inputs = this.questionMeta.items.reduce((acc, cur) => {
      // If there is no value assigned to the field, use false
      if (acc[cur.value] == undefined) {
        acc[cur.value] = false
      }
      return acc
    }, values || {})
    // Always check upon init
    this.checkIfAnswered()
  }

  onChange(value, name) {
    // Update the answer value
    this.testService.updateAnswerValue(
      this.section,
      this.question,
      this.questionMeta,
      // name would refer to the choice value
      // value is either true/false based on checkbox value
      { [name]: value }
    )
    this.checkIfAnswered()
  }

  private checkIfAnswered() {
    // Map the inputs to their value
    const hasAnswered =
      Object.keys(this.inputs)
        .map(k => this.inputs[k])
        // Check tha all values have length
        .find(value => {
          return value === true
        }) != null
    this.answered.emit(hasAnswered)
  }
}
