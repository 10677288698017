import { Section } from './section'

export type Test = {
  /************************* REQUIRED FIELDS *******************************/
  /**
   * Main Identifier
   */
  id?: string
  /**
   * Account this object belongs to
   */
  accountId: string
  /**
   * Identity (userId) that has created this object
   */
  ownerId: string
  /**
   * Refers to the visible title of the Section
   */
  title: string
  /**
   * Refers to the test code that will be unique to the test
   */
  code: string
  /**
   * Main subtype of this object
   */
  type: TestType
  /**
   * Refers to the different questions in this section
   */
  items: Section[]
  /************************* OPTIONAL FIELDS *******************************/
  /**
   * Refers to the description that will be visible to NON-patients
   */
  description?: string
  /**
   * Refers to instructions that will be visible to patient
   */
  instructions?: string
  /**
   * Contains information on how this question will be scored
   */
  scoring?: TestScoring
  /**
   * Contains information when this question should be displayed/hidden/required
   */
  logic?: TestLogic
  /**
   * Contains the options specific to the question's type, different settings
   */
  options?: TestOptions
}

export enum TestType {
  PRETEST = 'PRETEST',
  ASSESSMENT = 'ASSESSMENT'
}

export type TestScoring = {
  maximumScore: number
  guide?: string
}

export type TestLogic = {}

export type TestOptions = {}
