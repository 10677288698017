import { Injectable } from '@angular/core'
import { miniCogTest, TestApiService, tymTest } from '../testing'
import { modGPCOGTest } from '../testing/types/test-examples/mod-gpcog.test'
import { APIService } from '../API.service'
import { cesdrTest } from '../testing/types/test-examples/cesdr.test'
import { phq9Test } from '../testing/types/test-examples/phq9.test'
import { harmTest } from '../testing/types/test-examples/harm.test'

@Injectable({
  providedIn: 'root'
})
export class SeedService {
  public testId: string
  constructor(
    private testApiService: TestApiService,
    private apiService: APIService
  ) {}

  public async up() {
    // console.log('Looking for tests..')
    // const listTests = await this.testApiService.listTests({})
    // console.log('Found Tests', listTests)
    //const addedTest1 = await this.testApiService.addTest(pretestTest)
    //console.log('Added', addedTest1)
    // if (listTests.objects.length > 0) {
    //   console.log('Found tests so deleting them', listTests)
    //   const deleted = await Promise.all(
    //     listTests.objects.map(item => {
    //       return this.testApiService.deleteTest(item.id)
    //     })
    //   )
    //   console.log('Deleted tests', deleted)
    // }
    //
    // console.log('Adding the following tests', miniCogTest)
    // const addedTest1 = await this.testApiService.addTest(miniCogTest)
    // const addedTest2 = await this.testApiService.addTest(tymTest)
    // console.log('Created Seed Tests', addedTest1, addedTest2)
    // this.testId = addedTest2.id
  }

  public async test() {
    console.log('Create Test...')
    const addTest = await this.testApiService.addTest(miniCogTest)
    console.log('Created Test', addTest)

    console.log('Getting Test...')
    const getTest = await this.testApiService.getTest(addTest.id)
    console.log('Got Test', getTest)

    console.log('Updating Test...')
    const updateTest = await this.testApiService.updateTest(addTest.id, {
      description: 'Hello World'
    })
    console.log('Updated Test', updateTest)

    console.log('List Tests...')
    const listTests = await this.testApiService.listTests({ code: 'TYM' })
    console.log('Listed Tests', listTests)

    console.log('Deleting Test...')
    const deletedTest = await this.testApiService.deleteTest(addTest.id)
    console.log('Deleted Test', deletedTest)
  }

  public async down() {
    console.log('Deleting Seed Test')
    const deletedTest = await this.testApiService.deleteTest(this.testId)
    console.log('Deleted Seed Test', deletedTest)
    this.testId = null
  }
}
