import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core'
import {
  Question,
  QuestionMeta,
  QuestionOptions,
  Section
} from '../../../../../types'
import { TestService } from '../../../../../services/test.service'

@Component({
  selector: 'app-drawing-question',
  templateUrl: './drawing-question.component.html',
  styleUrls: ['./drawing-question.component.scss']
})
export class DrawingQuestionComponent
  implements OnInit, AfterViewInit, OnDestroy {
  /**
   * The Section object
   */
  @Input('section') section: Section
  /**
   * Question Meta that is 'selected' for this Question
   */
  @Input('questionMeta') questionMeta: QuestionMeta
  /**
   * The Question object
   */
  @Input('question') question: Question
  /**
   * Emitter that tells whether this question type component has reached an answered state
   */
  @Output() answered = new EventEmitter<boolean>()
  /**
   * The canvas where the drawing is rendered and user can draw
   */
  @ViewChild('canvasElement') canvas: ElementRef<HTMLCanvasElement>

  /**
   * Context2D to render stuff in canvas
   */
  public canvasContext: CanvasRenderingContext2D

  /**
   * Question options that was merged with defaults
   */
  private options: QuestionOptions

  /**
   * This is a flag to check if the canvas has been touched
   * @private
   */
  public dirty: boolean = false

  constructor(private testService: TestService) {}

  ngOnInit(): void {
    const options = this.question.options
    // Load default options
    this.options = Object.assign(
      {},
      {
        // Assign defaults
        strokeWidth: 30,
        strokeAlpha: 0.17,
        imageAlpha: 1
      },
      options
    )
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    // Need to load these in AfterView because that is the only valid time
    // for canvas to be reachable
    const canvas = this.canvas.nativeElement
    const context = canvas.getContext('2d')
    this.canvasContext = context

    // Load config from options
    const { strokeAlpha, strokeWidth } = this.options

    let isIdle = true

    // Will trigger when drawing would have started
    const drawStart = event => {
      context.beginPath()
      context.moveTo(
        event.pageX - canvas.offsetLeft,
        event.pageY - canvas.offsetTop
      )
      isIdle = false
    }

    // Will trigger when drawing is being made
    const drawMove = event => {
      if (isIdle) return
      // Use default kendo colors
      // Use the props from options
      context.globalAlpha = strokeAlpha
      context.strokeStyle = `rgba(255, 99, 87, ${strokeAlpha})`
      context.lineWidth = strokeWidth
      context.lineTo(
        event.pageX - canvas.offsetLeft,
        event.pageY - canvas.offsetTop
      )
      context.stroke()
    }

    // Will trigger when drawing has ended
    const drawEnd = event => {
      if (isIdle) return
      drawMove(event)
      isIdle = true
      this.dirty = true
      this.saveAnswer()
    }

    // Will only follow single touch, upon starting to draw
    const touchstart = event => {
      drawStart(event.touches[0])
    }

    // Will only follow single touch, while drawing
    const touchmove = event => {
      drawMove(event.touches[0])
      event.preventDefault()
    }

    // Will only follow single touch, after finished drawing
    const touchend = event => {
      drawEnd(event.changedTouches[0])
    }

    // Add the canvas listeners
    canvas.addEventListener('touchstart', touchstart, false)
    canvas.addEventListener('touchmove', touchmove, false)
    canvas.addEventListener('touchend', touchend, false)
    canvas.addEventListener('mousedown', drawStart, false)
    canvas.addEventListener('mousemove', drawMove, false)
    canvas.addEventListener('mouseup', drawEnd, false)

    const data = this.testService.getAnswerValue(this.question.id)
    if (data) {
      // Upon setup, draw the base image
      this.drawBaseImage()
      this.drawAnswerImage(data)
      this.dirty = true
    } else {
      // Upon setup, draw the base image
      this.drawBaseImage(true)
    }
  }

  /**
   * Will clear the canvas
   */
  clearCanvas(): void {
    this.canvasContext.clearRect(
      0,
      0,
      this.canvas.nativeElement.width,
      this.canvas.nativeElement.height
    )
  }

  /**
   * Reset state to like when the component initially loads
   */
  resetCanvas() {
    this.dirty = false
    this.clearCanvas()
    this.drawBaseImage()
    this.saveAnswer()
  }

  /**
   * Draws the base image
   */
  drawBaseImage(save?: boolean): void {
    const canvas = this.canvas.nativeElement
    const { imageAlpha } = this.options
    const img = new Image()
    const ctx = this.canvasContext
    img.crossOrigin = 'anonymous'
    img.onload = () => {
      // Load imageAlpha which comes from the options object
      ctx.globalAlpha = imageAlpha
      ctx.drawImage(
        img,
        0,
        0,
        img.width,
        img.height,
        0,
        0,
        canvas.width,
        canvas.height
      ) // Or at whatever offset you like
      if (save) {
        this.saveAnswer()
      }
    }

    // Image URL is loaded from meta.data
    img.src = this.questionMeta.data
  }

  /**
   * Draws the base image
   */
  drawAnswerImage(data): void {
    const canvas = this.canvas.nativeElement
    const img = new Image()
    const ctx = this.canvasContext
    img.onload = function () {
      // Load imageAlpha which comes from the options object
      ctx.globalAlpha = 1
      ctx.drawImage(
        img,
        0,
        0,
        img.width,
        img.height,
        0,
        0,
        canvas.width,
        canvas.height
      ) // Or at whatever offset you like
    }

    // Image URL is loaded from meta.data
    img.src = data
  }

  saveAnswer() {
    // It will save answer when drawing
    const data = this.canvas.nativeElement.toDataURL()
    this.testService.updateAnswerValue(
      this.section,
      this.question,
      this.questionMeta,
      data
    )

    // If the canvas has been drawn, it is dirty
    // thus assume that it is in answered state
    this.answered.emit(this.dirty)
  }
}
