import {
  Test,
  TestType,
  Question,
  QuestionType,
  Section,
  SectionType
} from '../test'

const accountId = 'b8600834-815b-49ad-bafa-b4d3338a0d2c'
const ownerId = '46040efb-36b8-4249-a724-db1d6bfd370b'

const question1_1: Question = {
  id: 'section1-1', // questionId
  type: QuestionType.INSTRUCTION,
  meta: [
    { text: '- Banana\n' + '- Sunrise\n' + '- Chair' },
    { text: '- Leader\n' + '- Season\n' + '- Table' },
    { text: '- Village\n' + '- Kitchen\n' + '- Baby' },
    { text: '- River\n' + '- Nation\n' + '- Finger' },
    { text: '- Captain\n' + '- Garden\n' + '- Picture' },
    { text: '- Daughter\n' + '- Heaven\n' + '- Mountain' }
  ]
}

const section1: Section = {
  type: SectionType.BREAK,
  title: 'Three Word Registration',
  instructions:
    'Remember the following words, and repeat them (to the instructor)',
  items: [question1_1]
}

const question2_1: Question = {
  id: 'section2-1', // questionId
  type: QuestionType.DRAWING,
  scoring: { maximumScore: 2 }, // scoring rules on the Question level
  logic: {}, // show/hide logic of the Question
  options: {
    strokeWidth: 6,
    strokeAlpha: 0.5,
    imageAlpha: 1
  },
  meta: [
    {
      scoring: {
        guide:
          'Normal clock = 2 points. A normal clock has all numbers placed in the correct\n' +
          'sequence and approximately correct position (e.g., 12, 3, 6 and 9 are in anchor\n' +
          'positions) with no missing or duplicate numbers. Hands are pointing to the 11\n' +
          'and 2 (11:10). Hand length is not scored.\n' +
          'Inability or refusal to draw a clock (abnormal) = 0 points.'
      },
      text:
        'Please draw in a clock face, put in all the numbers where they go and place the hands to 10 past 11',
      data: 'https://i.imgur.com/ZNMc1pK.png'
    }
  ]
}

const section2: Section = {
  type: SectionType.BOX,
  title: 'Clock Drawing',
  items: [question2_1]
}

const question3_1: Question = {
  id: 'section3-1', // questionId
  type: QuestionType.ENUMERATION,
  scoring: { isAutomatic: true, maximumScore: 3 }, // scoring rules on the Question level
  meta: [
    {
      scoring: {
        guide: 'Banana,Sunrise,Chair',
        scorer: {
          name: 'EnumerationScorer',
          inputs: {
            enumeration: 'Banana,Sunrise,Chair'
          }
        }
      },
      options: { enumerationCount: 3 }
    },
    {
      scoring: {
        guide: 'Leader,Season,Table',
        scorer: {
          name: 'EnumerationScorer',
          inputs: {
            enumeration: 'Leader,Season,Table'
          }
        }
      },
      options: { enumerationCount: 3 }
    },
    {
      scoring: {
        guide: 'Village,Kitchen,Baby',
        scorer: {
          name: 'EnumerationScorer',
          inputs: {
            enumeration: 'Village,Kitchen,Baby'
          }
        }
      },
      options: { enumerationCount: 3 }
    },
    {
      scoring: {
        guide: 'River,Nation,Finger',
        scorer: {
          name: 'EnumerationScorer',
          inputs: {
            enumeration: 'River,Nation,Finger'
          }
        }
      },
      options: { enumerationCount: 3 }
    },
    {
      scoring: {
        guide: 'Captain,Garden,Picture',
        scorer: {
          name: 'EnumerationScorer',
          inputs: {
            enumeration: 'Captain,Garden,Picture'
          }
        }
      },
      options: { enumerationCount: 3 }
    },
    {
      scoring: {
        guide: 'Daughter,Heaven,Mountain',
        scorer: {
          name: 'EnumerationScorer',
          inputs: {
            enumeration: 'Daughter,Heaven,Mountain'
          }
        }
      },
      options: { enumerationCount: 3 }
    }
  ]
}

const section3: Section = {
  type: SectionType.BOX,
  title: 'Three Word Recall',
  instructions: 'Recall the three words that were asked of you to remember',
  items: [question3_1]
}

export const miniCogTest: Test = {
  id: '5ff09a4281eaf60008dfbf23',
  accountId,
  ownerId,
  type: TestType.ASSESSMENT, // Type of test
  title: 'Mini Cognitive Test', // Title of the test for display
  code: 'MINICOG', // Short code of the test
  description: '',
  instructions:
    '## Mini COG Test\n' +
    '\n' +
    'The Mini-Cog test is a 3-minute instrument to screen for cognitive impairment in older\n' +
    'adults in the primary care setting\n' +
    '\n' +
    'You may press `Start` when you are ready to start the test.',
  items: [
    // section0,
    section1,
    section2,
    section3
  ], // (aka Box) refers to separation of questions
  scoring: {
    maximumScore: 5
  }, // scoring rules on the test level
  logic: {}, // show/hide logic of the test
  options: {} // options for the test
}
