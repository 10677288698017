import { Injectable, OnDestroy, OnInit } from '@angular/core'
import { BehaviorSubject, combineLatest, Subject, Subscription } from 'rxjs'
import { APIService, GetAccountQuery, GetLocationQuery } from '../API.service'
import { map } from 'rxjs/operators'
import { SessionService } from './session.service'

@Injectable({
  providedIn: 'root'
})
export class OfficeFilterService implements OnDestroy {
  private accountSub: Subscription
  private locationSub: Subscription
  private userTypeSub: Subscription

  selectedLocation$: BehaviorSubject<GetLocationQuery>
  selectedAccount$: BehaviorSubject<GetAccountQuery>
  locationSelection$: BehaviorSubject<Array<GetLocationQuery>>
  accountSelection$: BehaviorSubject<Array<GetAccountQuery>>

  // These are unsubscribable subjects that is used to force refresh the list
  private accountsTicker$: BehaviorSubject<number>
  private locationsTicker$: BehaviorSubject<number>
  // These are the active selections used by components
  private activeSelectedAccount$: BehaviorSubject<GetAccountQuery>
  private activeSelectedLocation$: BehaviorSubject<GetLocationQuery>
  constructor(
    private apiService: APIService,
    private sessionService: SessionService
  ) {
    this.activeSelectedAccount$ = new BehaviorSubject<GetAccountQuery>(null)
    this.selectedAccount$ = new BehaviorSubject<GetAccountQuery>(null)
    this.activeSelectedLocation$ = new BehaviorSubject<GetLocationQuery>(null)
    this.selectedLocation$ = new BehaviorSubject<GetLocationQuery>(null)
    this.locationSelection$ = new BehaviorSubject<Array<GetLocationQuery>>(null)
    this.accountSelection$ = new BehaviorSubject<Array<GetAccountQuery>>(null)
    // Tickets
    this.accountsTicker$ = new BehaviorSubject<number>(null)
    this.locationsTicker$ = new BehaviorSubject<number>(null)

    this.accountSub = combineLatest([
      this.sessionService.currentUser$,
      this.sessionService.userType$,
      this.activeSelectedAccount$,
      this.locationsTicker$
    ])
      .pipe(
        map(([currentUser, userType, account, ticker]) => {
          if (userType && userType === 'Super') {
            // Only allow super type to select a different account
            return account
          } else if (currentUser) {
            // By default use the current account
            return currentUser.account
          } else {
            return null
          }
        })
      )
      .subscribe(account => {
        this.selectedAccount$.next(account)
        // If the value of the selected account changes
        // We need to update the value of selectable locations
        if (account) {
          this.apiService
            .ListLocations({ accountId: { eq: account.id } })
            .then(({ items }) => {
              // @ts-ignore
              this.locationSelection$.next(items)
              // @ts-ignore
              this.activeSelectedLocation$.next(items.length ? items[0] : null)
            })
        } else {
          this.locationSelection$.next(null)
        }
      })

    // Create a subscription that will watch for changes in multiple streams
    // To ultimately figure out what is the selected Office to use
    // @ts-ignore
    this.locationSub = combineLatest([
      this.sessionService.currentUser$,
      this.sessionService.userType$,
      this.activeSelectedLocation$
    ])
      .pipe(
        map(([currentUser, userType, location]) => {
          return location
        })
      )
      .subscribe(location => {
        // @ts-ignore
        this.selectedLocation$.next(location)
      })

    // Watch for the userType subject
    this.userTypeSub = combineLatest([
      this.sessionService.currentUser$,
      this.sessionService.userType$,
      this.accountsTicker$
    ]).subscribe(([currentUser, userType, ticker]) => {
      if (userType === 'Super') {
        // if the user is a super, then probably we need to load the accounts
        // So that the user can pick
        this.apiService.ListAccounts().then(({ items }) => {
          this.accountSelection$.next(items)
          // By default pick the first one when reloading
          this.activeSelectedAccount$.next(items.length ? items[0] : null)
        })
      } else {
        // Otherwise, you shouldn't be able to switch accounts
        this.accountSelection$.next(null)
      }
    })
  }

  ngOnDestroy() {
    this.accountSub.unsubscribe()
    this.locationSub.unsubscribe()
    this.userTypeSub.unsubscribe()
  }

  selectLocation(location: GetLocationQuery) {
    this.activeSelectedLocation$.next(location)
  }

  selectAccount(account: GetAccountQuery) {
    this.activeSelectedAccount$.next(account)
  }

  refreshLocations() {
    this.locationsTicker$.next(Date.now())
  }

  refreshAccounts() {
    this.accountsTicker$.next(Date.now())
  }
}
