import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Question, QuestionMeta, Section } from '../../../../../types'
import { TestService } from '../../../../../services/test.service'

@Component({
  selector: 'app-enumeration-question',
  templateUrl: './enumeration-question.component.html',
  styleUrls: ['./enumeration-question.component.scss']
})
export class EnumerationQuestionComponent implements OnInit {
  /**
   * The Section object
   */
  @Input('section') section: Section
  /**
   * Question Meta that is 'selected' for this Question
   */
  @Input('questionMeta') questionMeta: QuestionMeta
  /**
   * The Question object
   */
  @Input('question') question: Question
  /**
   * Emitter that tells whether this question type component has reached an answered state
   */
  @Output() answered = new EventEmitter<boolean>()
  /**
   * The numbering that will appear on every blank field
   */
  public blanks: number[]
  /**
   * Inputs model where inputs will be stored/loaded
   */
  public inputs: { [key: string]: string }
  /**
   * String array of answers
   */
  public answers: string[]
  constructor(private testService: TestService) {}

  ngOnInit(): void {
    // Load the required options
    let { enumerationCount } = this.questionMeta.options || {}

    // Double check if the question has valid options for this component
    if (!enumerationCount) {
      console.error('Missing Option/s: enumerationCount, enumerationData')
      enumerationCount = 0
    }

    // Load answer value if it exists
    const inputs = this.testService.getAnswerValue(this.question.id)

    // Setup the data structures needed for rendering
    this.blanks = Array(enumerationCount)
      .fill(0)
      .map((x, i) => i + 1)

    // Prepare the blank inputs model based on the numbering
    this.inputs = this.blanks.reduce((acc, cur) => {
      if (!acc[cur]) {
        acc[cur] = ''
      }
      return acc
    }, inputs || {})

    // Always check upon init
    this.checkIfAnswered()
  }

  onChange(value, name) {
    // Update answer value upon changes
    this.testService.updateAnswerValue(
      this.section,
      this.question,
      this.questionMeta,
      // Use map form, name would be the item#
      // Value would be the field value
      { [name]: value }
    )
    // When there are changes to the answer
    // check if all fields were answered and
    // use answer emitter
    this.checkIfAnswered()
  }

  private checkIfAnswered() {
    // Map the inputs to their value
    const allAnswered = Object.keys(this.inputs)
      .map(k => this.inputs[k])
      // Check tha all values have length
      .every(value => {
        return value && value.length > 0
      })
    this.answered.emit(allAnswered)
  }
}
