import { Component, Input, OnInit } from '@angular/core'
import { AnswerSheetItem } from '../../../../../types'
import { TestService } from '../../../../../services/test.service'
import { ScoreService } from '../../../../../services/score.service'

@Component({
  selector: 'app-score-multiple-choice',
  templateUrl: './score-multiple-choice.component.html',
  styleUrls: ['./score-multiple-choice.component.scss']
})
export class ScoreMultipleChoiceComponent implements OnInit {
  /**
   * The Section object
   */
  @Input('answer') answer: AnswerSheetItem
  public inputs: { [key: string]: boolean }

  constructor(private scoreService: ScoreService) {}

  ngOnInit(): void {
    // Instantiate the form group and control

    // Load the values from answer if it exists
    this.inputs = this.answer.answerValue || {}
  }
}
