import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core'
import { Question, QuestionMeta, Section } from '../../../../../types'
import { TestService } from '../../../../../services/test.service'

@Component({
  selector: 'app-completion-question',
  templateUrl: './completion-question.component.html',
  styleUrls: ['./completion-question.component.scss']
})
export class CompletionQuestionComponent implements OnInit {
  /**
   * The Section object
   */
  @Input('section') section: Section
  /**
   * Question Meta that is 'selected' for this Question
   */
  @Input('questionMeta') questionMeta: QuestionMeta
  /**
   * The Question object
   */
  @Input('question') question: Question
  /**
   * Emitter that tells whether this question type component has reached an answered state
   */
  @Output() answered = new EventEmitter<boolean>()

  /**
   * These are the parts of a completion question, they
   * are rendered as text or input fields
   */
  public parts: CompletionQuestionPart[]

  /**
   * These are the input fields for this component
   */
  public inputs: { [key: string]: string } = {}

  constructor(private testService: TestService) {
    // Start with empty
    this.parts = []
  }

  ngOnInit(): void {
    const inputs = this.testService.getAnswerValue(this.question.id)

    // Upon initialization, load the dynamic template, parse it into parts
    const parts = this.parse(this.questionMeta.text)

    // Save the parts to this component
    this.parts = parts

    // Each part will determine the inputs model
    this.inputs = parts.reduce((acc, cur) => {
      if (cur.type === 'input' && !acc[cur.data]) {
        acc[cur.data] = ''
      }
      return acc
    }, inputs || {})
    // Always check upon init
    this.checkIfAnswered()
  }

  /**
   * This is the main porser for the dynamic templates
   * @param text
   */
  parse(text: string): CompletionQuestionPart[] {
    let parts: CompletionQuestionPart[] = []
    let temp = ''

    // Process each character
    for (let i = 0; i < text.length; i++) {
      let ch = text.charAt(i)

      if (ch === '\n') {
        // When it is a newline
        if (temp.trim().length) {
          // if the temp var has contents, save it as text
          parts.push({ type: 'text', data: temp.trim() })
          temp = ''
        }
        parts.push({ type: 'break' })
      } else if (ch === '{') {
        // When it is an opening {
        if (temp.trim().length) {
          // if the temp var has contents, save it as text
          parts.push({ type: 'text', data: temp.trim() })
          temp = ''
        }
      } else if (ch === '}') {
        // When it is a closing }
        if (temp.trim().length) {
          const inputToken = temp.trim()
          let [data, placeholder, width] = inputToken
            .split(':')
            .map(t => t.trim())
          if (!width) width = '100px'
          if (!placeholder) placeholder = data
          // if the temp var has contents, probably an input token
          parts.push({ type: 'input', data, width, placeholder })
          temp = ''
        }
      } else if (i === text.length - 1) {
        // When it is the last character
        // Just add it to temp, and save it as text regardless
        temp += ch
        parts.push({ type: 'text', data: temp.trim() })
        temp = ''
      } else {
        // In most cases, its just a string so just add it to temp
        temp += ch
      }
    }
    return parts
  }

  onChange(value, name) {
    // Update the answer object upon changes
    this.testService.updateAnswerValue(
      this.section,
      this.question,
      this.questionMeta,
      // Use a named map where map is the variable name based on the dynamic template
      { [name]: value }
    )

    // When there are changes to the answer
    // check if all fields were answered and
    // use answer emitter
    this.checkIfAnswered()
  }

  private checkIfAnswered() {
    // Map the inputs to their value
    const allAnswered = Object.keys(this.inputs)
      .map(k => this.inputs[k])
      // Check tha all values have length
      .every(value => {
        return value && value.length > 0
      })
    this.answered.emit(allAnswered)
  }
}

export type CompletionQuestionPart = {
  type: 'text' | 'input' | 'break'
  data?: string
  width?: string
  placeholder?: string
}
