import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { LoginComponent } from './login/login.component'
import { HeaderComponent } from './nav/header/header.component'
import { FooterComponent } from './nav/footer/footer.component'
import { AppBarModule } from '@progress/kendo-angular-navigation'
import { IconModule } from '@progress/kendo-angular-icons'
import {
  CheckBoxModule,
  RadioButtonModule,
  TextBoxModule
} from '@progress/kendo-angular-inputs'
import { LoaderModule } from '@progress/kendo-angular-indicators'
import { FlexModule } from '@angular/flex-layout'
import { PageNotFoundComponent } from './page-not-found/page-not-found.component'
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular'
import { DashboardComponent } from './dashboard/dashboard.component'
import { ContentComponent } from './nav/content/content.component'
import { ButtonModule } from '@progress/kendo-angular-buttons'
import { MarkdownModule } from 'ngx-markdown'
import { StepperModule } from '@progress/kendo-angular-layout'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import {
  CompletionQuestionComponent,
  CopySentenceQuestionComponent,
  DrawingQuestionComponent,
  EnumerationQuestionComponent,
  InstructionQuestionComponent,
  LabelingQuestionComponent,
  MultipleChoiceQuestionComponent,
  QuestionComponent,
  ScoreAnswerComponent,
  ScoreCompletionComponent,
  ScoreComponent,
  ScoreCopySentenceComponent,
  ScoreDrawingComponent,
  ScoreEnumerationComponent,
  ScoreLabelingComponent,
  ScoreMultipleChoiceComponent,
  ScoreSectionComponent,
  ScoreShortEssayComponent,
  ScoreSingleChoiceComponent,
  ShortEssayQuestionComponent,
  SingleChoiceQuestionComponent,
  TestComponent,
  TestSectionComponent,
  ScoreInstructionComponent,
  YesNoQuestionComponent
} from './testing'
import { AnswerSheetsComponent } from './answer-sheets/answer-sheets.component'
import { GridModule } from '@progress/kendo-angular-grid'
import { DialogModule } from '@progress/kendo-angular-dialog'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { TooltipComponent } from '@angular/material/tooltip'
import { TooltipModule } from '@progress/kendo-angular-tooltip'
import { DropDownListModule } from '@progress/kendo-angular-dropdowns'
import { PageLockComponent } from './page-lock/page-lock.component'
import { CodeInputModule } from 'angular-code-input'
import { DatePickerModule } from '@progress/kendo-angular-dateinputs'

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    PageNotFoundComponent,
    DashboardComponent,
    ContentComponent,
    TestComponent,
    QuestionComponent,
    TestSectionComponent,
    SingleChoiceQuestionComponent,
    MultipleChoiceQuestionComponent,
    CompletionQuestionComponent,
    CopySentenceQuestionComponent,
    EnumerationQuestionComponent,
    ShortEssayQuestionComponent,
    LabelingQuestionComponent,
    DrawingQuestionComponent,
    ScoreComponent,
    ScoreSectionComponent,
    ScoreAnswerComponent,
    ScoreCompletionComponent,
    ScoreCopySentenceComponent,
    ScoreDrawingComponent,
    ScoreEnumerationComponent,
    ScoreLabelingComponent,
    ScoreMultipleChoiceComponent,
    ScoreShortEssayComponent,
    ScoreSingleChoiceComponent,
    AnswerSheetsComponent,
    TooltipComponent,
    InstructionQuestionComponent,
    ScoreInstructionComponent,
    YesNoQuestionComponent,
    PageLockComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    AppBarModule,
    IconModule,
    TextBoxModule,
    LoaderModule,
    FlexModule,
    AmplifyUIAngularModule,
    ButtonModule,
    MarkdownModule.forRoot(),
    StepperModule,
    FormsModule,
    RadioButtonModule,
    ReactiveFormsModule,
    CheckBoxModule,
    GridModule,
    DialogModule,
    TooltipModule,
    DropDownListModule,
    CodeInputModule,
    DatePickerModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
