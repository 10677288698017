import { Injectable } from '@angular/core'
import { WsConnectionService } from './ws-connection.service'

@Injectable({
  providedIn: 'root'
})
export class WsMessagingService {
  private handlers: {
    [key: string]: (message: WSMessageReceived) => Promise<void>
  }

  constructor(private wsConnectionService: WsConnectionService) {
    this.handlers = {}
  }

  handle(action, handler: (message: WSMessageReceived) => Promise<void>) {
    if (this.handlers[action]) {
      console.warn('Replacing WSMessaging Handler', name)
    }
    this.handlers[action] = handler
  }

  async processMessage(message: WSMessageReceived) {
    console.log('WsMessagingService processMessage', message)
    if (message) {
      const registeredHandler = this.handlers[message.action]
      if (registeredHandler) {
        await registeredHandler(message)
      }
    }
  }

  send(message: WSMessageToSend) {
    this.wsConnectionService.send(
      message.target,
      message.action,
      message.message
    )
  }
}

export type WSMessageReceived = {
  action: string
  from: string
  message: { [key: string]: any }
}

export type WSMessageToSend = {
  action: string
  target: string
  message: { [key: string]: any }
}
