import { Injectable } from '@angular/core'
import { APIService } from '../../API.service'
import { AnswerSheet, Test } from '../types'
import { pick } from 'lodash'

@Injectable({
  providedIn: 'root'
})
export class AnswerSheetApiService {
  constructor(private apiService: APIService) {}

  public async addAnswerSheet(answerSheet: AnswerSheet): Promise<AnswerSheet> {
    const request = { object: this.mapToAnswerSheet(answerSheet) }
    const { data } = await this.apiService.AddAnswerSheet({
      data: JSON.stringify(request)
    })
    const response = JSON.parse(data)
    return this.mapFromAnswerSheet(response)
  }

  public async deleteAnswerSheet(id: string): Promise<AnswerSheet> {
    const request = { id: id }
    const { data } = await this.apiService.DeleteAnswerSheet({
      data: JSON.stringify(request)
    })
    const response = JSON.parse(data)
    return this.mapFromAnswerSheet(response)
  }

  public async getAnswerSheet(id: string): Promise<AnswerSheet> {
    const request = { id: id }
    const { data } = await this.apiService.GetAnswerSheet({
      data: JSON.stringify(request)
    })
    const response = JSON.parse(data)
    return this.mapFromAnswerSheet(response)
  }

  public async updateAnswerSheet(
    id: string,
    partial: any
  ): Promise<AnswerSheet> {
    const request = { id: id, object: this.mapToAnswerSheet(partial) }
    const { data } = await this.apiService.UpdateAnswerSheet({
      data: JSON.stringify(request)
    })
    const response = JSON.parse(data)
    return this.mapFromAnswerSheet(response)
  }

  public async listAnswerSheets(
    query: any,
    options?: any
  ): Promise<{ meta: any; objects: Array<AnswerSheet> }> {
    const request = { query: query, options }
    const { data } = await this.apiService.ListAnswerSheets({
      data: JSON.stringify(request)
    })
    const response = JSON.parse(data)
    console.log(response)
    return {
      meta: response.meta,
      objects: response.objects.map(o => {
        return this.mapFromAnswerSheet(o)
      })
    }
  }

  /**
   * Map AnswerSheet Objects
   * From Server to Client
   * @private
   */
  private mapFromAnswerSheet(json: any): AnswerSheet {
    return {
      id: json._id,
      startedAt: json.startedAt,
      endedAt: json.endedAt,
      accountId: json.accountId,
      testRef: json.testRef,
      meta: json.meta,
      patientId: json.patientId,
      status: json.status,
      items: json.items,
      scorerId: json.scorerId,
      totalScore: json.totalScore
    }
  }

  /**
   * Map AnswerSheet Objects
   * From Client to Server
   * @private
   */
  private mapToAnswerSheet(json: any): AnswerSheet {
    // Remove ID since we are forwarding to server,
    // ID should be specified separately
    return pick(json, [
      'startedAt',
      'endedAt',
      'accountId',
      'meta',
      'patientId',
      'status',
      'items',
      'scorerId',
      'testRef',
      'totalScore'
    ])
  }
}
