import { Injectable, OnDestroy, OnInit } from '@angular/core'
import { Auth as auth } from 'aws-amplify'
import { UserGroup } from '../shared/types/user-group.types'
import { SessionService } from './session.service'

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit, OnDestroy {
  // MOCK FOR NOW

  constructor(private sessionService: SessionService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  async getAccessGroups(): Promise<Array<UserGroup>> {
    try {
      const session = await auth.currentSession()
      const payload = session.getAccessToken().decodePayload()
      return payload['cognito:groups']
    } catch (err) {
      // @ts-ignore
      return []
    }
  }

  async isLoggedIn(): Promise<boolean> {
    try {
      const session = await auth.currentSession()
      return session.isValid()
    } catch (err) {
      return false
    }
  }

  logout(): Promise<any> {
    return auth.signOut().then(() => {
      return this.sessionService.loadSession()
    })
  }
}
