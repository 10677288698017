export enum UserGroup {
  Super = 'Super',
  CCM = 'CCM',
  Providers = 'Providers',
  Administrators = 'Administrators',
  Billing = 'Billing'
}

export const mapGroupNameToUserType = (groupName: string) => {
  return {
    Administrators: 'Administrator',
    Billing: 'Biller',
    CCM: 'CCM',
    Nurses: 'Nurse',
    Providers: 'Physician',
    Representative: 'Representative',
    Super: 'Super'
  }[groupName]
}
