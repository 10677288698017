import { Injectable } from '@angular/core'
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router'

@Injectable({ providedIn: 'root' })
export class TestGuard implements CanActivate {
  constructor(private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const params = route.queryParams
    // Always require assessmentId
    if (params.assessmentId) {
      // params have surveyId and employeeId
      // So proceed
      return true
    } else {
      await this.router.navigate(['/404'], {
        queryParams: {
          statusCode: 404,
          error: 'Missing or Invalid Test ID'
        }
      })
      return false
    }
  }
}
