import { Component, OnInit } from '@angular/core'
import { APIService } from '../API.service'
import { SessionService } from '../services/session.service'
import { Router } from '@angular/router'
import { AnswerSheet, AnswerSheetApiService } from '../testing'

@Component({
  selector: 'app-answer-sheets',
  templateUrl: './answer-sheets.component.html',
  styleUrls: ['./answer-sheets.component.scss']
})
export class AnswerSheetsComponent implements OnInit {
  answerSheets: SheetMapping[] = []
  patients: any[] = []
  users: any[] = []

  constructor(
    private answerSheetApiService: AnswerSheetApiService,
    private sessionService: SessionService,
    private router: Router,
    private apiService: APIService
  ) {}

  async ngOnInit(): Promise<void> {
    // Refresh patient and user data
    await Promise.all([this.refreshPatients(), this.refreshUsers()])
    // Load answer sheets
    await this.refreshAnswerSheets()
  }

  async refreshAnswerSheets() {
    const accountId = this.sessionService.currentUser$.getValue()['accountId']
    const { objects } = await this.answerSheetApiService.listAnswerSheets(
      {
        accountId
      },
      {
        select: [
          'startedAt',
          'endedAt',
          'accountId',
          'meta',
          'patientId',
          'status',
          'totalScore',
          'scorerId',
          'testRef'
        ]
      }
    )

    // TODO: Need a backend solution that does this;
    //  Maybe consider mongo population OR graphql connections
    this.answerSheets = objects.map(sheet => {
      const patientId = sheet.patientId
      const scorerId = sheet.scorerId

      // Look for the patient and admin users
      const patient = this.patients.find(p => p.id === patientId)
      const scorer = this.users.find(p => p.id === scorerId)
      let patientName = ''
      let scorerName = ''

      // Save their names here
      if (patient) {
        patientName = `${patient.givenName} ${patient.familyName}`
      }
      if (scorer) {
        scorerName = `${scorer.firstName} ${scorer.lastName}`
      }

      return {
        ...sheet,
        patientName,
        scorerName
      }
    })
  }

  async refreshPatients() {
    const accountId = this.sessionService.currentUser$.getValue()['accountId']
    const { items } = await this.apiService.ListPatients({
      accountId: { eq: accountId }
    })
    this.patients = items
  }

  async refreshUsers() {
    const accountId = this.sessionService.currentUser$.getValue()['accountId']
    const { items } = await this.apiService.ListUsers({
      accountId: { eq: accountId }
    })
    this.users = items
  }

  async scoreAnswerSheet(answerSheet) {
    await this.router.navigate(['score'], {
      queryParams: { answerSheetId: answerSheet.id }
    })
  }
}

export type SheetMapping = {
  patientName: string
  scorerName: string
} & AnswerSheet
