import { Component, OnInit } from '@angular/core'
import { ScoreService } from '../../services/score.service'

@Component({
  selector: 'app-score-section',
  templateUrl: './score-section.component.html',
  styleUrls: ['./score-section.component.scss']
})
export class ScoreSectionComponent implements OnInit {
  constructor(public scoreService: ScoreService) {}

  ngOnInit(): void {}
}
