import { Component, Input, OnInit } from '@angular/core'
import { AnswerSheetItem } from '../../../../../types'
import { TestService } from '../../../../../services/test.service'

@Component({
  selector: 'app-score-short-essay',
  templateUrl: './score-short-essay.component.html',
  styleUrls: ['./score-short-essay.component.scss']
})
export class ScoreShortEssayComponent implements OnInit {
  /**
   * The Section object
   */
  @Input('answer') answer: AnswerSheetItem
  /**
   * The input model for the essay answer
   */
  public input: string

  constructor() {}

  ngOnInit(): void {
    // Load answer if it exists
    this.input = this.answer.answerValue || ''
  }
}
