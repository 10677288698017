import { Component, Input, OnInit } from '@angular/core'
import { AnswerSheetItem } from '../../../../../types'

@Component({
  selector: 'app-score-enumeration',
  templateUrl: './score-enumeration.component.html',
  styleUrls: ['./score-enumeration.component.scss']
})
export class ScoreEnumerationComponent implements OnInit {
  /**
   * The Section object
   */
  @Input('answer') answer: AnswerSheetItem
  /**
   * The numbering that will appear on every blank field
   */
  public blanks: number[]
  /**
   * Inputs model where inputs will be stored/loaded
   */
  public inputs: { [key: string]: string }
  /**
   * String array of answers
   */
  public answers: string[]
  constructor() {}

  ngOnInit(): void {
    // Load the required options
    let { enumerationCount } = this.answer.questionRef.options || {}

    // Double check if the question has valid options for this component
    if (!enumerationCount) {
      console.error('Missing Option/s: enumerationCount, enumerationData')
      enumerationCount = 0
    }

    // Load answer value if it exists
    const inputs = this.answer.answerValue

    // Setup the data structures needed for rendering
    this.blanks = Array(enumerationCount)
      .fill(0)
      .map((x, i) => i + 1)

    // Prepare the blank inputs model based on the numbering
    this.inputs = this.blanks.reduce((acc, cur) => {
      if (!acc[cur]) {
        acc[cur] = ''
      }
      return acc
    }, inputs || {})
  }
}
