import { Component, OnDestroy, OnInit } from '@angular/core'
import { SessionService } from '../services/session.service'
import {
  APIService,
  AssessmentStatus,
  AssessmentType,
  Gender,
  GetPatientQuery,
  Salutation,
  Status,
  UserType
} from '../API.service'
import { Observable, Subscription } from 'rxjs'
import { map } from 'rxjs/operators'
import { Router } from '@angular/router'
import { TestApiService } from '../testing'
import { PatientSessionService } from '../testing/services/patient-session.service'
import { PageLockService } from '../services/page-lock.service'
import { OfficeFilterService } from '../services/office-filter.service'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  states: Array<{ name: string; group: string; code: string }> = [
    { name: 'Alabama', group: '4a', code: 'AL' },
    { name: 'Alaska', group: '4a', code: 'AK' },
    { name: 'Arizona', group: '4a', code: 'AZ' },
    { name: 'Arkansas', group: '3', code: 'AR' },
    { name: 'California', group: '4a', code: 'CA' },
    { name: 'Colorado', group: '3', code: 'CO' },
    { name: 'Connecticut', group: '2', code: 'CT' },
    { name: 'Delaware', group: '3', code: 'DE' },
    { name: 'Florida', group: '1', code: 'FL' },
    { name: 'Georgia', group: '4a', code: 'GA' },
    { name: 'Hawaii', group: '4a', code: 'HI' },
    { name: 'Idaho', group: '4a', code: 'ID' },
    { name: 'Illinois', group: '2', code: 'IL' },
    { name: 'Indiana', group: '5', code: 'IN' },
    { name: 'Iowa', group: '5', code: 'IA' },
    { name: 'Kansas', group: '5', code: 'KS' },
    { name: 'Kentucky', group: '4b', code: 'KY' },
    { name: 'Louisiana', group: '3', code: 'LA' },
    { name: 'Maine', group: '2', code: 'ME' },
    { name: 'Maryland', group: '3', code: 'MD' },
    { name: 'Massachusetts', group: '2', code: 'MA' },
    { name: 'Michigan', group: '5', code: 'MI' },
    { name: 'Minnesota', group: '2', code: 'MN' },
    { name: 'Mississippi', group: '3', code: 'MS' },
    { name: 'Missouri', group: '5', code: 'MO' },
    { name: 'Montana', group: '4a', code: 'MT' },
    { name: 'Nebraska', group: '5', code: 'NE' },
    { name: 'Nevada', group: '4a', code: 'NV' },
    { name: 'New Hampshire', group: '2', code: 'NH' },
    { name: 'New Jersey', group: '3', code: 'NJ' },
    { name: 'New Mexico', group: '3', code: 'NM' },
    { name: 'New York', group: '2', code: 'NY' },
    { name: 'North Carolina', group: '4a', code: 'NC' },
    { name: 'North Dakota', group: '4a', code: 'ND' },
    { name: 'Ohio', group: '4b', code: 'OH' },
    { name: 'Oklahoma', group: '3', code: 'OK' },
    { name: 'Oregon', group: '4a', code: 'OR' },
    { name: 'Pennsylvania', group: '3', code: 'PA' },
    { name: 'Puerto Rico', group: '1', code: 'PR' }, // Not a state?
    { name: 'Rhode Island', group: '2', code: 'RI' },
    { name: 'South Carolina', group: '4a', code: 'SC' },
    { name: 'South Dakota', group: '4a', code: 'SD' },
    { name: 'Tennessee', group: '4a', code: 'TN' },
    { name: 'Texas', group: '3', code: 'TX' },
    { name: 'Utah', group: '4a', code: 'UT' },
    { name: 'Vermont', group: '2', code: 'VT' },
    { name: 'Virginia', group: '4a', code: 'VA' },
    { name: 'Virgin Islands', group: '1', code: 'VI' }, // Not a state?
    { name: 'Washington', group: '4a', code: 'WA' },
    { name: 'West Virginia', group: '4a', code: 'WV' },
    { name: 'Wisconsin', group: '2', code: 'WI' },
    { name: 'Wyoming', group: '4a', code: 'WY' }
  ]
  defaultAccount = { name: 'All', id: undefined }
  accounts = []
  locations = []
  patients: Array<any> = []
  genders = [Gender.MALE, Gender.FEMALE]
  salutations = [
    Salutation.MR,
    Salutation.MS,
    Salutation.MRS,
    Salutation.JR,
    Salutation.SR
  ]

  // Set in the form
  emailInput: string
  passwordInput: string
  mobileInput: string
  givenNameInput: string
  middleNameInput: string
  familyNameInput: string
  addressLine1Input: string
  addressLine2Input: string
  regionInput: string
  countryInput: string
  postalInput: string
  dobInput: Date
  salutationInput: Salutation
  genderInput: Gender

  allowLocationSelection: boolean
  registrationDialogOpen = false
  accountFilter = undefined
  startAssessmentDialogOpen = false
  selectedPatient: GetPatientQuery
  selectedPatientAssessmentCount: number

  officeFilterSub: Subscription

  constructor(
    private apiService: APIService,
    private sessionService: SessionService,
    private router: Router,
    private testApiService: TestApiService,
    private patientSessionService: PatientSessionService,
    private pageLockService: PageLockService,
    private officeFilterService: OfficeFilterService
  ) {
    this.clearInputFields()
  }

  async ngOnInit(): Promise<void> {
    const currentUser = this.sessionService.currentUser$.getValue()

    if (currentUser) {
      // Allow location selection if its from admin
      this.allowLocationSelection =
        currentUser['userType'] === UserType.ADMINISTRATION
    }

    this.officeFilterSub = this.officeFilterService.selectedLocation$.subscribe(
      async selectedLocation => {
        if (selectedLocation) {
          await this.refreshPatients()
        }
      }
    )
  }

  ngOnDestroy(): void {
    if (this.officeFilterSub) {
      this.officeFilterSub.unsubscribe()
    }
  }

  openRegistration() {
    this.registrationDialogOpen = true
  }

  closeRegistration() {
    this.registrationDialogOpen = false
  }

  async refreshPatients() {
    const accountId = this.officeFilterService.selectedAccount$.getValue().id
    const locationId = this.officeFilterService.selectedLocation$.getValue().id

    console.log('Listing Patients..', accountId, locationId)

    const { items: patients } = await this.apiService.GetPatientsList({
      locationId: { eq: locationId },
      accountId: { eq: accountId }
    })

    console.log('Patients', accountId, patients)

    // @ts-ignore
    this.patients = patients.map(patient => {
      // @ts-ignore
      return {
        // @ts-ignore
        assessmentsCount: (patient.assessments || []).length,
        ...patient,
        // @ts-ignore
        providerNames: patient.providers.items
          .map(item => {
            return item.provider.firstName + ' ' + item.provider.lastName
          })
          .join(', ')
      }
    })
  }

  /**
   * Clear Input fields
   */
  clearInputFields() {
    this.emailInput = 'jason.wong+patient0@qwantegy.com'
    this.mobileInput = '+642041857381'
    this.givenNameInput = 'John'
    this.middleNameInput = 'Johnson'
    this.familyNameInput = 'Johnsons'
    this.postalInput = '123'
    this.regionInput = 'AL'
    this.addressLine1Input = '123 ABC Village, Greenhills, San Juan City'
    this.addressLine2Input = 'Unit 321'
    this.countryInput = 'U.S.'
    this.salutationInput = Salutation.MR
    this.genderInput = Gender.MALE
    this.passwordInput = 'Password12345'
    this.dobInput = new Date(1954, 3, 21)
  }

  /**
   * This happens when register is clicked
   */
  async registerPatientAndPretest() {
    const accountId = this.officeFilterService.selectedAccount$.getValue().id
    const locationId = this.officeFilterService.selectedLocation$.getValue().id
    const managerUserId = this.sessionService.userId$.getValue()
    // First, add the patient account (for login)
    const patient = await this.apiService.AddPatientAccount({
      email: this.emailInput,
      password: this.passwordInput,
      mobile: this.mobileInput,
      accountId: accountId,
      givenName: this.givenNameInput,
      familyName: this.familyNameInput,
      locationId: locationId
    })

    console.log('Registered Patient', patient)

    // Update the patient profile
    const updatePatient = await this.apiService.UpdatePatient({
      id: patient.id,
      salutation: this.salutationInput,
      middleName: this.middleNameInput,
      status: Status.ACTIVE,
      gender: this.genderInput,
      patientManagerId: managerUserId,
      dob: this.dobInput.toISOString(),
      address: {
        postal: this.postalInput,
        region: this.regionInput,
        line1: this.addressLine1Input,
        line2: this.addressLine2Input,
        country: this.countryInput
      }
    })

    console.log('Updated Patient', updatePatient)

    this.clearInputFields()

    // Fetch the pretest data
    const { objects: pretests } = await this.testApiService.listTests({
      code: 'PRETEST'
    })

    console.log('Listing PRETEST', pretests)

    if (pretests.length === 0) {
      throw new Error('No Pretests Available to use for this account')
    }

    const pretest = pretests[0]

    // Then generate a (pretest) assessment for the newly registered patient
    const pretestAssessment = await this.apiService.CreateAssessment({
      accountId: accountId,
      locationId: locationId,
      patientId: patient.id,
      managerId: managerUserId,
      scheduledDate: new Date().toISOString(),
      status: AssessmentStatus.SCHEDULED,
      meta: 0,
      type: AssessmentType.PRETEST,
      title: 'Pretest',
      testId: pretest.id
      // The following would be nulls
      // providers, // Refers to the list of providers who can access the assessment
      // result, // Refers to the result of the pretest in text
      // score, // Score of the pretest which will be irrelevant
      // assessed, // Date when the pretest has been assessed
      // answerSheetId, // Answer sheet used for the pretest
      // encounterAssessmentsId, // Encounter where the pretest would belong
    })

    this.patientSessionService.initialize(
      patient.id,
      pretestAssessment.id,
      [],
      true
    )

    // Before navigating, setup page lock so that the patient cannot move to other routes
    this.pageLockService.initializeOnPatientTest(patient.id)

    await this.patientSessionService.navigateToCurrentAssessment()

    this.closeRegistration()
  }

  refreshRandomPassword() {
    this.passwordInput = this.nextRandomPassword()
  }

  nextRandomPassword(): string {
    const adjectives = [
      'Amazing',
      'Awesome',
      'Playful',
      'Excellent',
      'Fabulous',
      'Fantastic',
      'Favorable',
      'Curious',
      'Gorgeous',
      'Incredible',
      'Tricky',
      'Exciting',
      'Virtuous',
      'Perfect',
      'Faithful',
      'Remarkable',
      'Rousing',
      'Spectacular',
      'Splendid',
      'Stellar',
      'Stupendous',
      'Superb',
      'Upbeat',
      'Stunning',
      'Wondrous'
    ]
    const animals = [
      'Shark',
      'Bird',
      'Bear',
      'Fish',
      'Chicken',
      'Duck',
      'Horse',
      'Cat',
      'Dog',
      'Monkey',
      'Whale',
      'Dolphin',
      'Rabbit',
      'Snake',
      'Rabbit',
      'Spider',
      'Lobster'
    ]
    const getRandom = array => {
      return array[Math.floor(Math.random() * array.length)]
    }

    return getRandom(adjectives) + getRandom(animals)
  }

  async onPatientSelected(item) {
    const patient = item.dataItem
    this.selectedPatient = patient
    await this.patientSessionService.loadPatientSessionFromRemote(
      this.selectedPatient.id
    )
    this.selectedPatientAssessmentCount = this.patientSessionService.assessmentsLeft()
    this.startAssessmentDialogOpen = true
    console.log('OnPatientSelected', item)
  }

  async startAssessments() {
    this.pageLockService.initializeOnPatientTest(this.selectedPatient.id)
    // Upon starting the assessments, load first the bulk of the assessments
    await this.patientSessionService.navigateToCurrentAssessment()
    this.closeStartAssessmentDialog()
  }

  closeStartAssessmentDialog() {
    this.startAssessmentDialogOpen = false
  }

  cancelStartingAssessments() {
    // When cancelled is selected, invalidate the loaded session
    this.patientSessionService.invalidateSession()
    this.closeStartAssessmentDialog()
  }
}
