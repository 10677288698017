import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Question, QuestionMeta, Section } from '../../../../../types'
import { TestService } from '../../../../../services/test.service'

@Component({
  selector: 'app-short-essay-question',
  templateUrl: './short-essay-question.component.html',
  styleUrls: ['./short-essay-question.component.scss']
})
export class ShortEssayQuestionComponent implements OnInit {
  /**
   * The Section object
   */
  @Input('section') section: Section
  /**
   * Question Meta that is 'selected' for this Question
   */
  @Input('questionMeta') questionMeta: QuestionMeta
  /**
   * The Question object
   */
  @Input('question') question: Question
  /**
   * Emitter that tells whether this question type component has reached an answered state
   */
  @Output() answered = new EventEmitter<boolean>()
  /**
   * The input model for the essay answer
   */
  public input: string

  constructor(private testService: TestService) {}

  ngOnInit(): void {
    // Load answer if it exists
    this.input = this.testService.getAnswerValue(this.question.id) || ''
    // Always check upon init
    this.checkIfAnswered()
  }

  onChange(value) {
    // Update the answer as text
    this.testService.updateAnswerValue(
      this.section,
      this.question,
      this.questionMeta,
      value
    )
    this.checkIfAnswered()
  }

  private checkIfAnswered() {
    const value = this.input || ''
    this.answered.emit(value.length > 0)
  }
}
