import { Question } from './question'

export type Section = {
  /************************* REQUIRED FIELDS *******************************/
  /**
   * Refers to the visible title of the Section
   */
  title: string
  /**
   * Main subtype of this object
   */
  type: SectionType
  /**
   * Refers to the different questions in this section
   */
  items: Question[]
  /************************* OPTIONAL FIELDS *******************************/
  /**
   * Refers to the description that will be visible to NON-patients
   */
  description?: string
  /**
   * Refers to instructions that will be visible to patient
   */
  instructions?: string
  /**
   * Contains information on how this question will be scored
   */
  scoring?: SectionScoring
  /**
   * Contains information when this question should be displayed/hidden/required
   */
  logic?: SectionLogic
  /**
   * Contains the options specific to the question's type, different settings
   */
  options?: SectionOptions
}

export enum SectionType {
  BREAK = 'BREAK',
  BOX = 'BOX'
}
export type SectionScoring = {
  maximumScore: number
  guide?: string
}
export type SectionLogic = {}
export type SectionOptions = {
  timeToAnswer: number
}
