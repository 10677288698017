/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { Observable } from "zen-observable-ts";

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type AddUserAccountInput = {
  email: string;
  password: string;
  accountId: string;
  userType: UserType;
  locations?: Array<string | null> | null;
  phone?: string | null;
  firstName?: string | null;
  lastName?: string | null;
};

export enum UserType {
  PROVIDER = "PROVIDER",
  NURSE = "NURSE",
  ADMINISTRATION = "ADMINISTRATION",
  BILLING = "BILLING",
  CCM = "CCM",
  PATIENT = "PATIENT",
  GUEST = "GUEST"
}

export type AddUserAccountOutput = {
  __typename: "AddUserAccountOutput";
  id?: string | null;
};

export type AddPatientAccountInput = {
  email: string;
  password: string;
  accountId: string;
  locationId: string;
  givenName: string;
  familyName: string;
  mobile?: string | null;
};

export type AddPatientAccountOutput = {
  __typename: "AddPatientAccountOutput";
  id?: string | null;
};

export type RequestTelehealthAccessInput = {
  telehealthSessionId: string;
};

export type RequestTelehealthAccessOutput = {
  __typename: "RequestTelehealthAccessOutput";
  accessToken?: string;
  roomSid?: string;
};

export type InitTwilioRoomInput = {
  roomName: string;
  recordSession?: boolean | null;
};

export type InitTwilioRoomOutput = {
  __typename: "InitTwilioRoomOutput";
  roomSid?: string;
};

export type DestroyTwilioRoomInput = {
  roomSid: string;
};

export type DestroyTwilioRoomOutput = {
  __typename: "DestroyTwilioRoomOutput";
  roomSid?: string;
};

export type RequestChatAccessInput = {
  userId?: string | null;
  patientId?: string | null;
};

export type RequestChatAccessOutput = {
  __typename: "RequestChatAccessOutput";
  accessToken?: string;
  identity?: string;
};

export type RequestVoiceAccessInput = {
  identity?: string | null;
};

export type RequestVoiceAccessOutput = {
  __typename: "RequestVoiceAccessOutput";
  accessToken?: string;
  identity?: string;
};

export type GetAssessmentEligibilityInput = {
  patientId?: string | null;
  psychological?: boolean | null;
  neuropsychological?: boolean | null;
};

export type GetAssessmentEligibilityOutput = {
  __typename: "GetAssessmentEligibilityOutput";
  eligibleTests?: Array<EligibleTest | null> | null;
};

export type EligibleTest = {
  __typename: "EligibleTest";
  title?: string | null;
  testId?: string | null;
  code?: string | null;
  recommended?: boolean | null;
  required?: boolean | null;
};

export type ProcessPretestApprovalInput = {
  soapCodes?: Array<string | null> | null;
  pretestAssessmentId?: string | null;
  selectedEligibleTests?: Array<EligibleTestInput | null> | null;
  approverId?: string | null;
  isApproved?: boolean | null;
};

export type EligibleTestInput = {
  title?: string | null;
  testId?: string | null;
  code?: string | null;
  recommended?: boolean | null;
  required?: boolean | null;
};

export type ProcessPretestApprovalOutput = {
  __typename: "ProcessPretestApprovalOutput";
  encounterId?: string | null;
  pretestAssessmentId?: string | null;
  assessmentIds?: Array<string | null> | null;
  wasApproved?: boolean | null;
  comment?: string | null;
};

export type JSONInput = {
  data?: string | null;
};

export type JSONOutput = {
  __typename: "JSONOutput";
  data?: string;
};

export type GenerateEncounterQuestionnairesOutput = {
  __typename: "GenerateEncounterQuestionnairesOutput";
  ids?: Array<string | null> | null;
};

export type GenerateEncounterImpressionsOutput = {
  __typename: "GenerateEncounterImpressionsOutput";
  ids?: Array<string | null> | null;
};

export type GetPatientTestingProfileInput = {
  patientId?: string | null;
};

export type GetPatientTestingProfileOutput = {
  __typename: "GetPatientTestingProfileOutput";
  patientId?: string | null;
  groupQualifier?: string | null;
};

export type StripePaymentMethodInput = {
  action?: string | null;
  id?: string | null;
  cardNumber?: string | null;
  expiryMonth?: string | null;
  expiryYear?: string | null;
  cvv?: string | null;
  accountId?: string | null;
  description?: string | null;
  paymentMethodId?: string | null;
};

export type StripePaymentMethodOutput = {
  __typename: "StripePaymentMethodOutput";
  error?: string | null;
  items?: Array<StripePaymentMethodItem | null> | null;
};

export type StripePaymentMethodItem = {
  __typename: "StripePaymentMethodItem";
  paymentMethodId?: string | null;
  expiryMonth?: string | null;
  expiryYear?: string | null;
  brand?: string | null;
  last4Digits?: string | null;
};

export type GetRecentEligibleAssessmentsInput = {
  patientId?: string | null;
};

export type GetRecentEligibleAssessmentsOutput = {
  __typename: "GetRecentEligibleAssessmentsOutput";
  patientId?: string | null;
  recentAssessments?: Array<RecentEligibleAssessment | null> | null;
};

export type RecentEligibleAssessment = {
  __typename: "RecentEligibleAssessment";
  id?: string | null;
  testCode?: string | null;
  assessed?: string | null;
  scoreGauge?: ScoreGauge | null;
  score?: number | null;
};

export enum ScoreGauge {
  UNSCORED = "UNSCORED",
  GREEN = "GREEN",
  YELLOW = "YELLOW",
  ORANGE = "ORANGE",
  RED = "RED",
  DARKRED = "DARKRED"
}

export type CreatePatientInput = {
  accountId: string;
  address?: AddressInput | null;
  connectionId?: string | null;
  contacts?: Array<ContactInput | null> | null;
  dob?: string | null;
  eligibleRPM?: boolean | null;
  email?: string | null;
  familyName: string;
  gender?: Gender | null;
  givenName: string;
  id?: string | null;
  insurance?: Array<InsuranceInput | null> | null;
  interests?: Array<string | null> | null;
  locationId: string;
  middleName?: string | null;
  mobile?: string | null;
  owner: string;
  patientLastAssessmentId?: string | null;
  patientManagerId?: string | null;
  providers?: Array<string | null> | null;
  salutation?: Salutation | null;
  status?: Status | null;
  ssn?: string | null;
  patientLastEncounterId?: string | null;
  patientTreatmentPlanId?: string | null;
};

export type AddressInput = {
  coordinates?: CoordinatesInput | null;
  country?: string | null;
  line1?: string | null;
  line2?: string | null;
  postal?: string | null;
  region?: string | null;
};

export type CoordinatesInput = {
  lat: number;
  lon: number;
};

export type ContactInput = {
  contactType?: ContactType | null;
  email?: string | null;
  familyName: string;
  givenName: string;
  id?: string | null;
  mobile?: string | null;
  salutation?: Salutation | null;
};

export enum ContactType {
  EMERGENCY = "EMERGENCY",
  BUSINESS = "BUSINESS"
}

export enum Salutation {
  MR = "MR",
  MS = "MS",
  MRS = "MRS",
  SR = "SR",
  JR = "JR"
}

export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE"
}

export type InsuranceInput = {
  carrierId?: string | null;
  groupId?: string | null;
  id?: string | null;
  insuranceType?: InsuranceType | null;
  memberId: string;
};

export enum InsuranceType {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY"
}

export enum Status {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED"
}

export type ModelPatientConditionInput = {
  accountId?: ModelIDInput | null;
  and?: Array<ModelPatientConditionInput | null> | null;
  connectionId?: ModelStringInput | null;
  dob?: ModelStringInput | null;
  eligibleRPM?: ModelBooleanInput | null;
  email?: ModelStringInput | null;
  familyName?: ModelStringInput | null;
  gender?: ModelGenderInput | null;
  givenName?: ModelStringInput | null;
  interests?: ModelStringInput | null;
  locationId?: ModelIDInput | null;
  middleName?: ModelStringInput | null;
  mobile?: ModelStringInput | null;
  not?: ModelPatientConditionInput | null;
  or?: Array<ModelPatientConditionInput | null> | null;
  patientManagerId?: ModelIDInput | null;
  salutation?: ModelSalutationInput | null;
  status?: ModelStatusInput | null;
};

export type ModelIDInput = {
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  beginsWith?: string | null;
  between?: Array<string | null> | null;
  contains?: string | null;
  eq?: string | null;
  ge?: string | null;
  gt?: string | null;
  le?: string | null;
  lt?: string | null;
  ne?: string | null;
  notContains?: string | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  _null = "_null",
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet"
}

export type ModelSizeInput = {
  between?: Array<number | null> | null;
  eq?: number | null;
  ge?: number | null;
  gt?: number | null;
  le?: number | null;
  lt?: number | null;
  ne?: number | null;
};

export type ModelStringInput = {
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  beginsWith?: string | null;
  between?: Array<string | null> | null;
  contains?: string | null;
  eq?: string | null;
  ge?: string | null;
  gt?: string | null;
  le?: string | null;
  lt?: string | null;
  ne?: string | null;
  notContains?: string | null;
  size?: ModelSizeInput | null;
};

export type ModelBooleanInput = {
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  eq?: boolean | null;
  ne?: boolean | null;
};

export type ModelGenderInput = {
  eq?: Gender | null;
  ne?: Gender | null;
};

export type ModelSalutationInput = {
  eq?: Salutation | null;
  ne?: Salutation | null;
};

export type ModelStatusInput = {
  eq?: Status | null;
  ne?: Status | null;
};

export type Patient = {
  __typename: "Patient";
  id?: string;
  accountId?: string;
  locationId?: string;
  patientManagerId?: string | null;
  owner?: string;
  salutation?: string | null;
  givenName?: string;
  middleName?: string | null;
  familyName?: string;
  address?: Address;
  ssn?: string | null;
  dob?: string | null;
  gender?: Gender | null;
  mobile?: string | null;
  email?: string | null;
  interests?: Array<string | null> | null;
  providerIds?: Array<string | null> | null;
  status?: Status | null;
  eligibleRPM?: boolean | null;
  contacts?: Array<Contact | null> | null;
  insurance?: Array<Insurance | null> | null;
  qualifiedTests?: Array<string | null> | null;
  connectionId?: string | null;
  createdAt?: string;
  updatedAt?: string;
  providers?: ModelPatientProviderConnectionConnection;
  programs?: ModelPatientProgramConnectionConnection;
  eligibleTests?: ModelEligibilityConnectionConnection;
  manager?: User;
  account?: Account;
  location?: Location;
  lastAssessment?: Assessment;
  assessments?: ModelAssessmentConnection;
  treatmentPlan?: TreatmentPlan;
  lastEncounter?: Encounter;
  diagnoses?: ModelDiagnosticCodeConnection;
};

export type Address = {
  __typename: "Address";
  line1?: string | null;
  line2?: string | null;
  region?: string | null;
  postal?: string | null;
  country?: string | null;
  coordinates?: Coordinates;
};

export type Coordinates = {
  __typename: "Coordinates";
  lat?: number;
  lon?: number;
};

export type Contact = {
  __typename: "Contact";
  id?: string | null;
  salutation?: Salutation | null;
  givenName?: string;
  familyName?: string;
  mobile?: string | null;
  email?: string | null;
  contactType?: ContactType | null;
};

export type Insurance = {
  __typename: "Insurance";
  id?: string | null;
  memberId?: string;
  groupId?: string | null;
  carrierId?: string | null;
  insuranceType?: InsuranceType | null;
};

export type ModelPatientProviderConnectionConnection = {
  __typename: "ModelPatientProviderConnectionConnection";
  items?: Array<PatientProviderConnection | null> | null;
  nextToken?: string | null;
};

export type PatientProviderConnection = {
  __typename: "PatientProviderConnection";
  id?: string;
  accountId?: string;
  patientId?: string;
  providerId?: string;
  type?: ProviderType | null;
  createdAt?: string;
  updatedAt?: string;
  patient?: Patient;
  provider?: User;
  account?: Account;
};

export enum ProviderType {
  PRIMARY = "PRIMARY",
  SPECIALIST = "SPECIALIST"
}

export type User = {
  __typename: "User";
  id?: string;
  accountId?: string;
  owner?: string | null;
  email?: string;
  phone?: string | null;
  online?: boolean | null;
  firstName?: string | null;
  lastName?: string | null;
  titles?: Array<string | null> | null;
  npi?: string | null;
  image?: string | null;
  userType?: UserType | null;
  connections?: Array<WSConnection | null> | null;
  onlineStatus?: UserOnlineStatus | null;
  lastLogin?: LoginEvent;
  pinCode?: string | null;
  permissions?: Array<AccessPermission | null> | null;
  createdAt?: string;
  updatedAt?: string;
  locations?: ModelUserLocationConnectionConnection;
  account?: Account;
};

export type WSConnection = {
  __typename: "WSConnection";
  connectionId?: string | null;
  startedAt?: string | null;
};

export enum UserOnlineStatus {
  AVAILABLE = "AVAILABLE",
  BUSY = "BUSY"
}

export type LoginEvent = {
  __typename: "LoginEvent";
  ip?: string | null;
  date?: string;
};

export enum AccessPermission {
  AddEvents = "AddEvents",
  ViewCCM = "ViewCCM",
  ViewBilling = "ViewBilling",
  ApproveWork = "ApproveWork",
  ViewPatientAssessments = "ViewPatientAssessments",
  AccessPatientNotes = "AccessPatientNotes",
  CreateAssessment = "CreateAssessment",
  TestingReminders = "TestingReminders",
  ScheduleTests = "ScheduleTests",
  AccessCallRecordings = "AccessCallRecordings",
  AllOfficeAccess = "AllOfficeAccess",
  OfficeCalendarView = "OfficeCalendarView",
  ChangeCreditCard = "ChangeCreditCard",
  OfficeLogs = "OfficeLogs",
  MessengerEmails = "MessengerEmails",
  TreatmentActionPlans = "TreatmentActionPlans",
  ViewResources = "ViewResources",
  OfficeAccountDocuments = "OfficeAccountDocuments",
  PDFReports = "PDFReports"
}

export type ModelUserLocationConnectionConnection = {
  __typename: "ModelUserLocationConnectionConnection";
  items?: Array<UserLocationConnection | null> | null;
  nextToken?: string | null;
};

export type UserLocationConnection = {
  __typename: "UserLocationConnection";
  id?: string;
  accountId?: string;
  userId?: string;
  locationId?: string;
  createdAt?: string;
  updatedAt?: string;
  user?: User;
  account?: Account;
  location?: Location;
};

export type Account = {
  __typename: "Account";
  id?: string;
  name?: string;
  owner?: string | null;
  stripeCustomerId?: string | null;
  createdAt?: string;
  updatedAt?: string;
};

export type Location = {
  __typename: "Location";
  id?: string;
  owner?: string | null;
  accountId?: string;
  name?: string;
  address?: Address;
  callerId?: string | null;
  testsCount?: number | null;
  usersCount?: number | null;
  physicianCount?: number | null;
  frontDesk?: Contact;
  createdAt?: string;
  updatedAt?: string;
  lastLoginUser?: User;
  users?: ModelUserLocationConnectionConnection;
  account?: Account;
  lastAssessment?: Assessment;
  lastPayment?: Payment;
};

export type Assessment = {
  __typename: "Assessment";
  id?: string;
  accountId?: string;
  locationId?: string;
  patientId?: string;
  providers?: Array<string | null> | null;
  managerId?: string;
  submitterId?: string | null;
  result?: string | null;
  testId?: string | null;
  testCode?: string | null;
  meta?: number | null;
  answerSheetId?: string | null;
  title?: string | null;
  type?: AssessmentType | null;
  assessed?: string | null;
  scheduledDate?: string | null;
  score?: number | null;
  scoreGauge?: ScoreGauge | null;
  status?: AssessmentStatus | null;
  assessmentEncounterId?: string | null;
  createdAt?: string;
  updatedAt?: string;
  patient?: Patient;
  manager?: User;
  account?: Account;
  location?: Location;
  billedTime?: BilledTime;
};

export enum AssessmentType {
  PRETEST = "PRETEST",
  ASSESSMENT = "ASSESSMENT"
}

export enum AssessmentStatus {
  SCHEDULED = "SCHEDULED",
  PENDING = "PENDING",
  SCORED = "SCORED",
  COMPLETED = "COMPLETED",
  QUALIFIED = "QUALIFIED",
  UNQUALIFIED = "UNQUALIFIED"
}

export type BilledTime = {
  __typename: "BilledTime";
  id?: string;
  minutes?: number | null;
  type?: BilledTimeType | null;
  date?: string | null;
  userId?: string;
  createdAt?: string;
  updatedAt?: string;
  user?: User;
};

export enum BilledTimeType {
  SCORING = "SCORING",
  ADMINISTRATIVE = "ADMINISTRATIVE"
}

export type Payment = {
  __typename: "Payment";
  id?: string;
  accountId?: string;
  locationId?: string;
  userId?: string;
  stripePaymentMethodId?: string;
  paymentDate?: string;
  createdAt?: string;
  updatedAt?: string;
  invoice?: Invoice;
};

export type Invoice = {
  __typename: "Invoice";
  id?: string;
  accountId?: string;
  locationId?: string;
  dueDate?: string;
  billDate?: string;
  billingPeriodStartDate?: string;
  billingPeriodEndDate?: string;
  hours?: number | null;
  totalBill?: number | null;
  status?: InvoiceStatus | null;
  createdAt?: string;
  updatedAt?: string;
  billingItems?: ModelBillingItemConnection;
};

export enum InvoiceStatus {
  DUE = "DUE",
  PAID = "PAID"
}

export type ModelBillingItemConnection = {
  __typename: "ModelBillingItemConnection";
  items?: Array<BillingItem | null> | null;
  nextToken?: string | null;
};

export type BillingItem = {
  __typename: "BillingItem";
  id?: string;
  accountId?: string;
  locationId?: string | null;
  patientId?: string;
  codeId?: string;
  amount?: number;
  type?: BillingType | null;
  currency?: string | null;
  created?: string | null;
  serviced?: string | null;
  status?: BillingItemStatus | null;
  createdAt?: string;
  updatedAt?: string;
  patient?: Patient;
  billedTime?: ModelBilledTimeConnection;
  code?: Code;
};

export enum BillingType {
  SERVICE = "SERVICE",
  CREDIT = "CREDIT"
}

export enum BillingItemStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED"
}

export type ModelBilledTimeConnection = {
  __typename: "ModelBilledTimeConnection";
  items?: Array<BilledTime | null> | null;
  nextToken?: string | null;
};

export type Code = {
  __typename: "Code";
  id?: string;
  type?: CodeType;
  name?: string;
  description?: string | null;
  rate?: number | null;
  createdAt?: string;
  updatedAt?: string;
};

export enum CodeType {
  ICD10 = "ICD10",
  CPT = "CPT"
}

export type ModelPatientProgramConnectionConnection = {
  __typename: "ModelPatientProgramConnectionConnection";
  items?: Array<PatientProgramConnection | null> | null;
  nextToken?: string | null;
};

export type PatientProgramConnection = {
  __typename: "PatientProgramConnection";
  id?: string | null;
  accountId?: string;
  patientId?: string;
  programId?: string;
  date?: string | null;
  createdAt?: string;
  updatedAt?: string;
  patient?: Patient;
  program?: Program;
  account?: Account;
};

export type Program = {
  __typename: "Program";
  id?: string;
  questionnaireId?: string;
  name?: string | null;
  createdAt?: string;
  updatedAt?: string;
  tests?: ModelProgramTestConnection;
  questionnaire?: Questionnaire;
};

export type ModelProgramTestConnection = {
  __typename: "ModelProgramTestConnection";
  items?: Array<ProgramTest | null> | null;
  nextToken?: string | null;
};

export type ProgramTest = {
  __typename: "ProgramTest";
  id?: string;
  name?: string | null;
  referenceTestId?: string | null;
  createdAt?: string;
  updatedAt?: string;
};

export type Questionnaire = {
  __typename: "Questionnaire";
  id?: string;
  created?: string | null;
  name?: string | null;
  title?: string | null;
  template?: string | null;
  createdAt?: string;
  updatedAt?: string;
};

export type ModelEligibilityConnectionConnection = {
  __typename: "ModelEligibilityConnectionConnection";
  items?: Array<EligibilityConnection | null> | null;
  nextToken?: string | null;
};

export type EligibilityConnection = {
  __typename: "EligibilityConnection";
  id?: string | null;
  patientId?: string;
  programTestId?: string;
  required?: boolean | null;
  date?: string | null;
  createdAt?: string;
  updatedAt?: string;
  patient?: Patient;
  programTest?: ProgramTest;
};

export type ModelAssessmentConnection = {
  __typename: "ModelAssessmentConnection";
  items?: Array<Assessment | null> | null;
  nextToken?: string | null;
};

export type TreatmentPlan = {
  __typename: "TreatmentPlan";
  id?: string;
  accountId?: string;
  locationId?: string;
  patientId?: string;
  providers?: Array<string | null> | null;
  treatmentPlanManagerId?: string | null;
  created?: string;
  createdAt?: string;
  updatedAt?: string;
  patient?: Account;
  account?: Account;
  location?: Location;
  resourceEvents?: ModelPatientResourceScheduledEventConnectionConnection;
};

export type ModelPatientResourceScheduledEventConnectionConnection = {
  __typename: "ModelPatientResourceScheduledEventConnectionConnection";
  items?: Array<PatientResourceScheduledEventConnection | null> | null;
  nextToken?: string | null;
};

export type PatientResourceScheduledEventConnection = {
  __typename: "PatientResourceScheduledEventConnection";
  id?: string;
  accountId?: string;
  patientId?: string;
  encounterId?: string;
  resourceScheduledEventId?: string;
  created?: string | null;
  createdAt?: string;
  updatedAt?: string;
  patient?: Patient;
  account?: Account;
  resourceScheduledEvent?: ResourceScheduledEvent;
  encounter?: Encounter;
};

export type ResourceScheduledEvent = {
  __typename: "ResourceScheduledEvent";
  id?: string;
  accountId?: string;
  ownerId?: string;
  locationId?: string;
  title?: string;
  start?: string;
  end?: string;
  startTimezone?: string | null;
  endTimezone?: string | null;
  isAllDay?: boolean | null;
  description?: string | null;
  recurrenceRule?: string | null;
  recurrenceExceptions?: string | null;
  recurrenceId?: string | null;
  resourceId?: string;
  category?: ResourceCategory;
  cost?: number | null;
  ageGroup?: AgeGroup | null;
  createdAt?: string;
  updatedAt?: string;
  resource?: EventResource;
};

export enum ResourceCategory {
  SOCIAL = "SOCIAL",
  RELIGIOUS = "RELIGIOUS",
  PHYSICAL = "PHYSICAL",
  VOLUNTEER = "VOLUNTEER",
  SUPPORT = "SUPPORT"
}

export enum AgeGroup {
  AGES_18_PLUS = "AGES_18_PLUS",
  AGES_18_TO_50 = "AGES_18_TO_50",
  AGES_65_PLUS = "AGES_65_PLUS"
}

export type EventResource = {
  __typename: "EventResource";
  id?: string;
  ownerId?: string | null;
  accountId?: string | null;
  locationId?: string | null;
  name?: string | null;
  type?: ResourceType | null;
  classification?: EventResourceClassification | null;
  website?: string | null;
  contact?: Contact;
  address?: Address;
  businessHours?: string | null;
  billedTime?: number | null;
  createdAt?: string;
  updatedAt?: string;
};

export enum ResourceType {
  Physical = "Physical",
  Virtual = "Virtual"
}

export enum EventResourceClassification {
  PSYCHOLOGICAL = "PSYCHOLOGICAL",
  NEUROPSYCHOLOGICAL = "NEUROPSYCHOLOGICAL"
}

export type Encounter = {
  __typename: "Encounter";
  id?: string;
  accountId?: string;
  locationId?: string;
  managerId?: string;
  patientId?: string;
  ownerId?: string;
  status?: EncounterStatus | null;
  date?: string;
  soapItems?: Array<SoapItem | null> | null;
  createdAt?: string;
  updatedAt?: string;
  patient?: Patient;
  manager?: User;
  owner?: User;
  account?: Account;
  location?: Location;
  billedTimes?: ModelEncounterBilledTimeConnectionConnection;
  pretest?: Assessment;
  assessments?: ModelAssessmentConnection;
  questionnaireResponses?: ModelQuestionnaireResponseConnection;
  clinicalImpressions?: ModelClinicalImpressionConnection;
  providers?: ModelEncounterProviderConnectionConnection;
  notes?: ModelEncounterNoteConnectionConnection;
};

export enum EncounterStatus {
  INPROGRESS = "INPROGRESS",
  PENDING = "PENDING",
  SCORED = "SCORED",
  REVIEWED = "REVIEWED",
  APPROVED = "APPROVED",
  COMPLETED = "COMPLETED"
}

export type SoapItem = {
  __typename: "SoapItem";
  type?: SoapItemType | null;
  text?: string | null;
  code?: string | null;
  selected?: boolean | null;
};

export enum SoapItemType {
  DIAGNOSED = "DIAGNOSED",
  OBSERVED = "OBSERVED",
  REPORTED = "REPORTED"
}

export type ModelEncounterBilledTimeConnectionConnection = {
  __typename: "ModelEncounterBilledTimeConnectionConnection";
  items?: Array<EncounterBilledTimeConnection | null> | null;
  nextToken?: string | null;
};

export type EncounterBilledTimeConnection = {
  __typename: "EncounterBilledTimeConnection";
  id?: string | null;
  accountId?: string;
  encounterId?: string;
  billedTimeId?: string;
  createdAt?: string;
  updatedAt?: string;
  account?: Account;
  billedTime?: BilledTime;
  encounter?: Encounter;
};

export type ModelQuestionnaireResponseConnection = {
  __typename: "ModelQuestionnaireResponseConnection";
  items?: Array<QuestionnaireResponse | null> | null;
  nextToken?: string | null;
};

export type QuestionnaireResponse = {
  __typename: "QuestionnaireResponse";
  id?: string;
  accountId?: string;
  patientId?: string;
  encounterId?: string;
  contributors?: string | null;
  created?: string | null;
  name?: string | null;
  title?: string | null;
  status?: QuestionnaireStatus | null;
  json?: string | null;
  createdAt?: string;
  updatedAt?: string;
  patient?: Patient;
  account?: Account;
  authors?: ModelQuestionnaireResponseAuthorConnection;
  encounter?: Encounter;
};

export enum QuestionnaireStatus {
  PENDING = "PENDING",
  REVIEWED = "REVIEWED",
  APPROVED = "APPROVED"
}

export type ModelQuestionnaireResponseAuthorConnection = {
  __typename: "ModelQuestionnaireResponseAuthorConnection";
  items?: Array<QuestionnaireResponseAuthor | null> | null;
  nextToken?: string | null;
};

export type QuestionnaireResponseAuthor = {
  __typename: "QuestionnaireResponseAuthor";
  id?: string;
  accountId?: string;
  responseId?: string;
  authorId?: string;
  createdAt?: string;
  updatedAt?: string;
  author?: User;
  account?: Account;
  questionnaireResponse?: QuestionnaireResponse;
};

export type ModelClinicalImpressionConnection = {
  __typename: "ModelClinicalImpressionConnection";
  items?: Array<ClinicalImpression | null> | null;
  nextToken?: string | null;
};

export type ClinicalImpression = {
  __typename: "ClinicalImpression";
  id?: string | null;
  accountId?: string;
  patientId?: string;
  encounterId?: string;
  authorId?: string | null;
  created?: string | null;
  title?: string | null;
  status?: ClinicalImpressionStatus | null;
  summary?: string | null;
  name?: string | null;
  signature?: string | null;
  approved?: string | null;
  createdAt?: string;
  updatedAt?: string;
  patient?: Patient;
  account?: Account;
  questionnaireResponse?: QuestionnaireResponse;
  encounter?: Encounter;
};

export enum ClinicalImpressionStatus {
  PENDING = "PENDING",
  REVIEWED = "REVIEWED",
  APPROVED = "APPROVED"
}

export type ModelEncounterProviderConnectionConnection = {
  __typename: "ModelEncounterProviderConnectionConnection";
  items?: Array<EncounterProviderConnection | null> | null;
  nextToken?: string | null;
};

export type EncounterProviderConnection = {
  __typename: "EncounterProviderConnection";
  id?: string;
  accountId?: string;
  encounterId?: string;
  providerId?: string;
  createdAt?: string;
  updatedAt?: string;
  provider?: User;
  account?: Account;
  encounter?: Encounter;
};

export type ModelEncounterNoteConnectionConnection = {
  __typename: "ModelEncounterNoteConnectionConnection";
  items?: Array<EncounterNoteConnection | null> | null;
  nextToken?: string | null;
};

export type EncounterNoteConnection = {
  __typename: "EncounterNoteConnection";
  id?: string;
  accountId?: string;
  encounterId?: string;
  noteId?: string;
  createdAt?: string;
  updatedAt?: string;
  account?: Account;
  encounter?: Encounter;
  note?: Note;
};

export type Note = {
  __typename: "Note";
  id?: string;
  author?: AuthorType;
  userId?: string;
  patientId?: string;
  type?: NoteType | null;
  title?: string | null;
  content?: string | null;
  createdAt?: string;
  updatedAt?: string;
  patient?: Patient;
  user?: User;
};

export enum AuthorType {
  PATIENT = "PATIENT",
  USER = "USER"
}

export enum NoteType {
  SOAP = "SOAP",
  INTERNAL = "INTERNAL",
  GENERAL = "GENERAL"
}

export type ModelDiagnosticCodeConnection = {
  __typename: "ModelDiagnosticCodeConnection";
  items?: Array<DiagnosticCode | null> | null;
  nextToken?: string | null;
};

export type DiagnosticCode = {
  __typename: "DiagnosticCode";
  id?: string;
  accountId?: string;
  codeId?: string;
  patientId?: string;
  diagnosed?: string | null;
  createdAt?: string;
  updatedAt?: string;
  patient?: Patient;
  account?: Account;
  code?: Code;
};

export type UpdatePatientInput = {
  accountId?: string | null;
  address?: AddressInput | null;
  connectionId?: string | null;
  contacts?: Array<ContactInput | null> | null;
  dob?: string | null;
  eligibleRPM?: boolean | null;
  email?: string | null;
  familyName?: string | null;
  gender?: Gender | null;
  givenName?: string | null;
  id: string;
  insurance?: Array<InsuranceInput | null> | null;
  interests?: Array<string | null> | null;
  locationId?: string | null;
  middleName?: string | null;
  mobile?: string | null;
  owner?: string | null;
  patientLastAssessmentId?: string | null;
  patientManagerId?: string | null;
  providers?: Array<string | null> | null;
  salutation?: Salutation | null;
  status?: Status | null;
  ssn?: string | null;
  patientLastEncounterId?: string | null;
  patientTreatmentPlanId?: string | null;
};

export type DeletePatientInput = {
  id?: string | null;
};

export type CreatePatientProviderConnectionInput = {
  id?: string | null;
  accountId: string;
  patientId: string;
  providerId: string;
  type?: ProviderType | null;
};

export type ModelPatientProviderConnectionConditionInput = {
  accountId?: ModelIDInput | null;
  patientId?: ModelIDInput | null;
  providerId?: ModelIDInput | null;
  type?: ModelProviderTypeInput | null;
  and?: Array<ModelPatientProviderConnectionConditionInput | null> | null;
  or?: Array<ModelPatientProviderConnectionConditionInput | null> | null;
  not?: ModelPatientProviderConnectionConditionInput | null;
};

export type ModelProviderTypeInput = {
  eq?: ProviderType | null;
  ne?: ProviderType | null;
};

export type UpdatePatientProviderConnectionInput = {
  id: string;
  accountId?: string | null;
  patientId?: string | null;
  providerId?: string | null;
  type?: ProviderType | null;
};

export type DeletePatientProviderConnectionInput = {
  id?: string | null;
};

export type CreatePatientProgramConnectionInput = {
  id?: string | null;
  accountId: string;
  patientId: string;
  programId: string;
  date?: string | null;
};

export type ModelPatientProgramConnectionConditionInput = {
  accountId?: ModelIDInput | null;
  patientId?: ModelIDInput | null;
  programId?: ModelIDInput | null;
  date?: ModelStringInput | null;
  and?: Array<ModelPatientProgramConnectionConditionInput | null> | null;
  or?: Array<ModelPatientProgramConnectionConditionInput | null> | null;
  not?: ModelPatientProgramConnectionConditionInput | null;
};

export type UpdatePatientProgramConnectionInput = {
  id: string;
  accountId?: string | null;
  patientId?: string | null;
  programId?: string | null;
  date?: string | null;
};

export type DeletePatientProgramConnectionInput = {
  id?: string | null;
};

export type CreateProgramInput = {
  id?: string | null;
  questionnaireId: string;
  name?: string | null;
};

export type ModelProgramConditionInput = {
  questionnaireId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  and?: Array<ModelProgramConditionInput | null> | null;
  or?: Array<ModelProgramConditionInput | null> | null;
  not?: ModelProgramConditionInput | null;
};

export type UpdateProgramInput = {
  id: string;
  questionnaireId?: string | null;
  name?: string | null;
};

export type DeleteProgramInput = {
  id?: string | null;
};

export type CreateProgramTestInput = {
  id?: string | null;
  name?: string | null;
  referenceTestId?: string | null;
  programTestsId?: string | null;
};

export type ModelProgramTestConditionInput = {
  name?: ModelStringInput | null;
  referenceTestId?: ModelStringInput | null;
  and?: Array<ModelProgramTestConditionInput | null> | null;
  or?: Array<ModelProgramTestConditionInput | null> | null;
  not?: ModelProgramTestConditionInput | null;
};

export type UpdateProgramTestInput = {
  id: string;
  name?: string | null;
  referenceTestId?: string | null;
  programTestsId?: string | null;
};

export type DeleteProgramTestInput = {
  id?: string | null;
};

export type CreateEligibilityConnectionInput = {
  id?: string | null;
  patientId: string;
  programTestId: string;
  required?: boolean | null;
  date?: string | null;
};

export type ModelEligibilityConnectionConditionInput = {
  patientId?: ModelIDInput | null;
  programTestId?: ModelIDInput | null;
  required?: ModelBooleanInput | null;
  date?: ModelStringInput | null;
  and?: Array<ModelEligibilityConnectionConditionInput | null> | null;
  or?: Array<ModelEligibilityConnectionConditionInput | null> | null;
  not?: ModelEligibilityConnectionConditionInput | null;
};

export type UpdateEligibilityConnectionInput = {
  id: string;
  patientId?: string | null;
  programTestId?: string | null;
  required?: boolean | null;
  date?: string | null;
};

export type DeleteEligibilityConnectionInput = {
  id?: string | null;
};

export type CreateUserInput = {
  id?: string | null;
  accountId: string;
  owner?: string | null;
  email: string;
  phone?: string | null;
  online?: boolean | null;
  firstName?: string | null;
  lastName?: string | null;
  titles?: Array<string | null> | null;
  npi?: string | null;
  image?: string | null;
  userType?: UserType | null;
  connections?: Array<WSConnectionInput | null> | null;
  onlineStatus?: UserOnlineStatus | null;
  lastLogin?: LoginEventInput | null;
  pinCode?: string | null;
  permissions?: Array<AccessPermission | null> | null;
};

export type WSConnectionInput = {
  connectionId?: string | null;
  startedAt?: string | null;
};

export type LoginEventInput = {
  ip?: string | null;
  date: string;
};

export type ModelUserConditionInput = {
  accountId?: ModelIDInput | null;
  email?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  online?: ModelBooleanInput | null;
  firstName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  titles?: ModelStringInput | null;
  npi?: ModelStringInput | null;
  image?: ModelStringInput | null;
  userType?: ModelUserTypeInput | null;
  onlineStatus?: ModelUserOnlineStatusInput | null;
  pinCode?: ModelStringInput | null;
  permissions?: ModelAccessPermissionListInput | null;
  and?: Array<ModelUserConditionInput | null> | null;
  or?: Array<ModelUserConditionInput | null> | null;
  not?: ModelUserConditionInput | null;
};

export type ModelUserTypeInput = {
  eq?: UserType | null;
  ne?: UserType | null;
};

export type ModelUserOnlineStatusInput = {
  eq?: UserOnlineStatus | null;
  ne?: UserOnlineStatus | null;
};

export type ModelAccessPermissionListInput = {
  eq?: Array<AccessPermission | null> | null;
  ne?: Array<AccessPermission | null> | null;
  contains?: AccessPermission | null;
  notContains?: AccessPermission | null;
};

export type UpdateUserInput = {
  id: string;
  accountId?: string | null;
  owner?: string | null;
  email?: string | null;
  phone?: string | null;
  online?: boolean | null;
  firstName?: string | null;
  lastName?: string | null;
  titles?: Array<string | null> | null;
  npi?: string | null;
  image?: string | null;
  userType?: UserType | null;
  connections?: Array<WSConnectionInput | null> | null;
  onlineStatus?: UserOnlineStatus | null;
  lastLogin?: LoginEventInput | null;
  pinCode?: string | null;
  permissions?: Array<AccessPermission | null> | null;
};

export type DeleteUserInput = {
  id?: string | null;
};

export type CreateUserLocationConnectionInput = {
  id?: string | null;
  accountId: string;
  userId: string;
  locationId: string;
};

export type ModelUserLocationConnectionConditionInput = {
  accountId?: ModelIDInput | null;
  locationId?: ModelIDInput | null;
  and?: Array<ModelUserLocationConnectionConditionInput | null> | null;
  or?: Array<ModelUserLocationConnectionConditionInput | null> | null;
  not?: ModelUserLocationConnectionConditionInput | null;
};

export type UpdateUserLocationConnectionInput = {
  id: string;
  accountId?: string | null;
  userId?: string | null;
  locationId?: string | null;
};

export type DeleteUserLocationConnectionInput = {
  id?: string | null;
};

export type CreateAccountInput = {
  id?: string | null;
  name: string;
  owner?: string | null;
  stripeCustomerId?: string | null;
};

export type ModelAccountConditionInput = {
  name?: ModelStringInput | null;
  stripeCustomerId?: ModelStringInput | null;
  and?: Array<ModelAccountConditionInput | null> | null;
  or?: Array<ModelAccountConditionInput | null> | null;
  not?: ModelAccountConditionInput | null;
};

export type UpdateAccountInput = {
  id: string;
  name?: string | null;
  owner?: string | null;
  stripeCustomerId?: string | null;
};

export type DeleteAccountInput = {
  id?: string | null;
};

export type CreateLocationInput = {
  id?: string | null;
  owner?: string | null;
  accountId: string;
  name: string;
  address?: AddressInput | null;
  callerId?: string | null;
  testsCount?: number | null;
  usersCount?: number | null;
  physicianCount?: number | null;
  frontDesk?: ContactInput | null;
  locationLastPaymentId?: string | null;
  locationLastAssessmentId?: string | null;
  locationLastLoginUserId?: string | null;
};

export type ModelLocationConditionInput = {
  accountId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  callerId?: ModelStringInput | null;
  testsCount?: ModelIntInput | null;
  usersCount?: ModelIntInput | null;
  physicianCount?: ModelIntInput | null;
  and?: Array<ModelLocationConditionInput | null> | null;
  or?: Array<ModelLocationConditionInput | null> | null;
  not?: ModelLocationConditionInput | null;
};

export type ModelIntInput = {
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  between?: Array<number | null> | null;
  eq?: number | null;
  ge?: number | null;
  gt?: number | null;
  le?: number | null;
  lt?: number | null;
  ne?: number | null;
};

export type UpdateLocationInput = {
  id: string;
  owner?: string | null;
  accountId?: string | null;
  name?: string | null;
  address?: AddressInput | null;
  callerId?: string | null;
  testsCount?: number | null;
  usersCount?: number | null;
  physicianCount?: number | null;
  frontDesk?: ContactInput | null;
  locationLastPaymentId?: string | null;
  locationLastAssessmentId?: string | null;
  locationLastLoginUserId?: string | null;
};

export type DeleteLocationInput = {
  id?: string | null;
};

export type CreateBilledTimeInput = {
  id?: string | null;
  minutes?: number | null;
  type?: BilledTimeType | null;
  date?: string | null;
  userId: string;
  billingItemBilledTimeId?: string | null;
};

export type ModelBilledTimeConditionInput = {
  minutes?: ModelIntInput | null;
  type?: ModelBilledTimeTypeInput | null;
  date?: ModelStringInput | null;
  userId?: ModelIDInput | null;
  and?: Array<ModelBilledTimeConditionInput | null> | null;
  or?: Array<ModelBilledTimeConditionInput | null> | null;
  not?: ModelBilledTimeConditionInput | null;
};

export type ModelBilledTimeTypeInput = {
  eq?: BilledTimeType | null;
  ne?: BilledTimeType | null;
};

export type UpdateBilledTimeInput = {
  id: string;
  minutes?: number | null;
  type?: BilledTimeType | null;
  date?: string | null;
  userId?: string | null;
  billingItemBilledTimeId?: string | null;
};

export type DeleteBilledTimeInput = {
  id?: string | null;
};

export type CreateEncounterBilledTimeConnectionInput = {
  id?: string | null;
  accountId: string;
  encounterId: string;
  billedTimeId: string;
};

export type ModelEncounterBilledTimeConnectionConditionInput = {
  accountId?: ModelIDInput | null;
  encounterId?: ModelIDInput | null;
  billedTimeId?: ModelIDInput | null;
  and?: Array<ModelEncounterBilledTimeConnectionConditionInput | null> | null;
  or?: Array<ModelEncounterBilledTimeConnectionConditionInput | null> | null;
  not?: ModelEncounterBilledTimeConnectionConditionInput | null;
};

export type UpdateEncounterBilledTimeConnectionInput = {
  id: string;
  accountId?: string | null;
  encounterId?: string | null;
  billedTimeId?: string | null;
};

export type DeleteEncounterBilledTimeConnectionInput = {
  id?: string | null;
};

export type CreateAssessmentBilledTimeConnectionInput = {
  id?: string | null;
  accountId: string;
  assessmentId: string;
  billedTimeId: string;
};

export type ModelAssessmentBilledTimeConnectionConditionInput = {
  accountId?: ModelIDInput | null;
  assessmentId?: ModelIDInput | null;
  billedTimeId?: ModelIDInput | null;
  and?: Array<ModelAssessmentBilledTimeConnectionConditionInput | null> | null;
  or?: Array<ModelAssessmentBilledTimeConnectionConditionInput | null> | null;
  not?: ModelAssessmentBilledTimeConnectionConditionInput | null;
};

export type AssessmentBilledTimeConnection = {
  __typename: "AssessmentBilledTimeConnection";
  id?: string;
  accountId?: string;
  assessmentId?: string;
  billedTimeId?: string;
  createdAt?: string;
  updatedAt?: string;
  account?: Account;
  billedTime?: BilledTime;
  assessment?: Assessment;
};

export type UpdateAssessmentBilledTimeConnectionInput = {
  id: string;
  accountId?: string | null;
  assessmentId?: string | null;
  billedTimeId?: string | null;
};

export type DeleteAssessmentBilledTimeConnectionInput = {
  id?: string | null;
};

export type CreateAssessmentInput = {
  id?: string | null;
  accountId: string;
  locationId: string;
  patientId: string;
  providers?: Array<string | null> | null;
  managerId: string;
  submitterId?: string | null;
  result?: string | null;
  testId?: string | null;
  testCode?: string | null;
  meta?: number | null;
  answerSheetId?: string | null;
  title?: string | null;
  type?: AssessmentType | null;
  assessed?: string | null;
  scheduledDate?: string | null;
  score?: number | null;
  scoreGauge?: ScoreGauge | null;
  status?: AssessmentStatus | null;
  assessmentEncounterId?: string | null;
  assessmentBilledTimeId?: string | null;
  encounterAssessmentsId?: string | null;
};

export type ModelAssessmentConditionInput = {
  accountId?: ModelIDInput | null;
  locationId?: ModelIDInput | null;
  managerId?: ModelIDInput | null;
  submitterId?: ModelIDInput | null;
  result?: ModelStringInput | null;
  testId?: ModelStringInput | null;
  testCode?: ModelStringInput | null;
  meta?: ModelIntInput | null;
  answerSheetId?: ModelStringInput | null;
  title?: ModelStringInput | null;
  type?: ModelAssessmentTypeInput | null;
  assessed?: ModelStringInput | null;
  scheduledDate?: ModelStringInput | null;
  score?: ModelIntInput | null;
  scoreGauge?: ModelScoreGaugeInput | null;
  status?: ModelAssessmentStatusInput | null;
  assessmentEncounterId?: ModelStringInput | null;
  and?: Array<ModelAssessmentConditionInput | null> | null;
  or?: Array<ModelAssessmentConditionInput | null> | null;
  not?: ModelAssessmentConditionInput | null;
};

export type ModelAssessmentTypeInput = {
  eq?: AssessmentType | null;
  ne?: AssessmentType | null;
};

export type ModelScoreGaugeInput = {
  eq?: ScoreGauge | null;
  ne?: ScoreGauge | null;
};

export type ModelAssessmentStatusInput = {
  eq?: AssessmentStatus | null;
  ne?: AssessmentStatus | null;
};

export type UpdateAssessmentInput = {
  id: string;
  accountId?: string | null;
  locationId?: string | null;
  patientId?: string | null;
  providers?: Array<string | null> | null;
  managerId?: string | null;
  submitterId?: string | null;
  result?: string | null;
  testId?: string | null;
  testCode?: string | null;
  meta?: number | null;
  answerSheetId?: string | null;
  title?: string | null;
  type?: AssessmentType | null;
  assessed?: string | null;
  scheduledDate?: string | null;
  score?: number | null;
  scoreGauge?: ScoreGauge | null;
  status?: AssessmentStatus | null;
  assessmentEncounterId?: string | null;
  assessmentBilledTimeId?: string | null;
  encounterAssessmentsId?: string | null;
};

export type DeleteAssessmentInput = {
  id?: string | null;
};

export type CreateTreatmentPlanInput = {
  accountId: string;
  created: string;
  id?: string | null;
  locationId: string;
  patientId: string;
  providers?: Array<string | null> | null;
  treatmentPlanManagerId?: string | null;
};

export type ModelTreatmentPlanConditionInput = {
  accountId?: ModelIDInput | null;
  and?: Array<ModelTreatmentPlanConditionInput | null> | null;
  created?: ModelStringInput | null;
  locationId?: ModelIDInput | null;
  not?: ModelTreatmentPlanConditionInput | null;
  or?: Array<ModelTreatmentPlanConditionInput | null> | null;
  treatmentPlanManagerId?: ModelIDInput | null;
};

export type UpdateTreatmentPlanInput = {
  accountId?: string | null;
  created?: string | null;
  id: string;
  locationId?: string | null;
  patientId?: string | null;
  providers?: Array<string | null> | null;
  treatmentPlanManagerId?: string | null;
};

export type DeleteTreatmentPlanInput = {
  id?: string | null;
};

export type CreatePatientResourceScheduledEventConnectionInput = {
  id?: string | null;
  accountId: string;
  patientId: string;
  encounterId: string;
  resourceScheduledEventId: string;
  created?: string | null;
  treatmentPlanResourceEventsId?: string | null;
};

export type ModelPatientResourceScheduledEventConnectionConditionInput = {
  accountId?: ModelIDInput | null;
  patientId?: ModelIDInput | null;
  encounterId?: ModelIDInput | null;
  resourceScheduledEventId?: ModelIDInput | null;
  created?: ModelStringInput | null;
  and?: Array<ModelPatientResourceScheduledEventConnectionConditionInput | null> | null;
  or?: Array<ModelPatientResourceScheduledEventConnectionConditionInput | null> | null;
  not?: ModelPatientResourceScheduledEventConnectionConditionInput | null;
};

export type UpdatePatientResourceScheduledEventConnectionInput = {
  id: string;
  accountId?: string | null;
  patientId?: string | null;
  encounterId?: string | null;
  resourceScheduledEventId?: string | null;
  created?: string | null;
  treatmentPlanResourceEventsId?: string | null;
};

export type DeletePatientResourceScheduledEventConnectionInput = {
  id?: string | null;
};

export type CreateTelehealthSessionInput = {
  id?: string | null;
  title: string;
  accountId: string;
  participantIds: Array<string | null>;
  participants: Array<TelehealthSessionParticipantInput | null>;
  status?: TelehealthSessionStatus | null;
  roomType?: RoomType | null;
  startedAt?: string | null;
  endedAt?: string | null;
};

export type TelehealthSessionParticipantInput = {
  name: string;
  type?: UserType | null;
  identity: string;
};

export enum TelehealthSessionStatus {
  PENDING = "PENDING",
  ONGOING = "ONGOING",
  INACTIVE = "INACTIVE"
}

export enum RoomType {
  AUDIO = "AUDIO",
  VIDEO = "VIDEO"
}

export type ModelTelehealthSessionConditionInput = {
  title?: ModelStringInput | null;
  accountId?: ModelIDInput | null;
  status?: ModelTelehealthSessionStatusInput | null;
  roomType?: ModelRoomTypeInput | null;
  startedAt?: ModelStringInput | null;
  endedAt?: ModelStringInput | null;
  and?: Array<ModelTelehealthSessionConditionInput | null> | null;
  or?: Array<ModelTelehealthSessionConditionInput | null> | null;
  not?: ModelTelehealthSessionConditionInput | null;
};

export type ModelTelehealthSessionStatusInput = {
  eq?: TelehealthSessionStatus | null;
  ne?: TelehealthSessionStatus | null;
};

export type ModelRoomTypeInput = {
  eq?: RoomType | null;
  ne?: RoomType | null;
};

export type TelehealthSession = {
  __typename: "TelehealthSession";
  id?: string;
  title?: string;
  accountId?: string;
  participantIds?: Array<string | null>;
  participants?: Array<TelehealthSessionParticipant | null>;
  status?: TelehealthSessionStatus | null;
  roomType?: RoomType | null;
  startedAt?: string | null;
  endedAt?: string | null;
  createdAt?: string;
  updatedAt?: string;
};

export type TelehealthSessionParticipant = {
  __typename: "TelehealthSessionParticipant";
  name?: string;
  type?: UserType | null;
  identity?: string;
};

export type UpdateTelehealthSessionInput = {
  id: string;
  title?: string | null;
  accountId?: string | null;
  participantIds?: Array<string | null> | null;
  participants?: Array<TelehealthSessionParticipantInput | null> | null;
  status?: TelehealthSessionStatus | null;
  roomType?: RoomType | null;
  startedAt?: string | null;
  endedAt?: string | null;
};

export type DeleteTelehealthSessionInput = {
  id?: string | null;
};

export type CreateScheduledEventInput = {
  id?: string | null;
  accountId: string;
  ownerId: string;
  invitees: Array<ScheduledEventInviteeInput | null>;
  participantIds: Array<string | null>;
  title: string;
  start: string;
  end: string;
  type: ScheduledEventType;
  assessments?: Array<ScheduledEventAssessmentInput | null> | null;
  startTimezone?: string | null;
  endTimezone?: string | null;
  isAllDay?: boolean | null;
  description?: string | null;
  recurrenceRule?: string | null;
  recurrenceExceptions?: string | null;
  recurrenceId?: string | null;
  scheduledEventResourceId?: string | null;
};

export type ScheduledEventInviteeInput = {
  name: string;
  type?: UserType | null;
  id?: string | null;
};

export enum ScheduledEventType {
  GENERAL = "GENERAL",
  MEETING = "MEETING",
  TELEHEALTH = "TELEHEALTH"
}

export type ScheduledEventAssessmentInput = {
  id?: string | null;
  name?: string | null;
};

export type ModelScheduledEventConditionInput = {
  accountId?: ModelIDInput | null;
  title?: ModelStringInput | null;
  start?: ModelStringInput | null;
  end?: ModelStringInput | null;
  type?: ModelScheduledEventTypeInput | null;
  startTimezone?: ModelStringInput | null;
  endTimezone?: ModelStringInput | null;
  isAllDay?: ModelBooleanInput | null;
  description?: ModelStringInput | null;
  recurrenceRule?: ModelStringInput | null;
  recurrenceExceptions?: ModelStringInput | null;
  recurrenceId?: ModelStringInput | null;
  and?: Array<ModelScheduledEventConditionInput | null> | null;
  or?: Array<ModelScheduledEventConditionInput | null> | null;
  not?: ModelScheduledEventConditionInput | null;
};

export type ModelScheduledEventTypeInput = {
  eq?: ScheduledEventType | null;
  ne?: ScheduledEventType | null;
};

export type ScheduledEvent = {
  __typename: "ScheduledEvent";
  id?: string;
  accountId?: string;
  ownerId?: string;
  invitees?: Array<ScheduledEventInvitee | null>;
  participantIds?: Array<string | null>;
  title?: string;
  start?: string;
  end?: string;
  type?: ScheduledEventType;
  assessments?: Array<ScheduledEventAssessment | null> | null;
  startTimezone?: string | null;
  endTimezone?: string | null;
  isAllDay?: boolean | null;
  description?: string | null;
  recurrenceRule?: string | null;
  recurrenceExceptions?: string | null;
  recurrenceId?: string | null;
  createdAt?: string;
  updatedAt?: string;
  resource?: ResourceScheduledEvent;
};

export type ScheduledEventInvitee = {
  __typename: "ScheduledEventInvitee";
  name?: string;
  type?: UserType | null;
  id?: string | null;
};

export type ScheduledEventAssessment = {
  __typename: "ScheduledEventAssessment";
  id?: string | null;
  name?: string | null;
};

export type UpdateScheduledEventInput = {
  id: string;
  accountId?: string | null;
  ownerId?: string | null;
  invitees?: Array<ScheduledEventInviteeInput | null> | null;
  participantIds?: Array<string | null> | null;
  title?: string | null;
  start?: string | null;
  end?: string | null;
  type?: ScheduledEventType | null;
  assessments?: Array<ScheduledEventAssessmentInput | null> | null;
  startTimezone?: string | null;
  endTimezone?: string | null;
  isAllDay?: boolean | null;
  description?: string | null;
  recurrenceRule?: string | null;
  recurrenceExceptions?: string | null;
  recurrenceId?: string | null;
  scheduledEventResourceId?: string | null;
};

export type DeleteScheduledEventInput = {
  id?: string | null;
};

export type CreateResourceScheduledEventInput = {
  id?: string | null;
  accountId: string;
  ownerId: string;
  locationId: string;
  title: string;
  start: string;
  end: string;
  startTimezone?: string | null;
  endTimezone?: string | null;
  isAllDay?: boolean | null;
  description?: string | null;
  recurrenceRule?: string | null;
  recurrenceExceptions?: string | null;
  recurrenceId?: string | null;
  resourceId: string;
  category: ResourceCategory;
  cost?: number | null;
  ageGroup?: AgeGroup | null;
};

export type ModelResourceScheduledEventConditionInput = {
  accountId?: ModelIDInput | null;
  locationId?: ModelIDInput | null;
  title?: ModelStringInput | null;
  start?: ModelStringInput | null;
  end?: ModelStringInput | null;
  startTimezone?: ModelStringInput | null;
  endTimezone?: ModelStringInput | null;
  isAllDay?: ModelBooleanInput | null;
  description?: ModelStringInput | null;
  recurrenceRule?: ModelStringInput | null;
  recurrenceExceptions?: ModelStringInput | null;
  recurrenceId?: ModelStringInput | null;
  resourceId?: ModelIDInput | null;
  category?: ModelResourceCategoryInput | null;
  cost?: ModelFloatInput | null;
  ageGroup?: ModelAgeGroupInput | null;
  and?: Array<ModelResourceScheduledEventConditionInput | null> | null;
  or?: Array<ModelResourceScheduledEventConditionInput | null> | null;
  not?: ModelResourceScheduledEventConditionInput | null;
};

export type ModelResourceCategoryInput = {
  eq?: ResourceCategory | null;
  ne?: ResourceCategory | null;
};

export type ModelFloatInput = {
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  between?: Array<number | null> | null;
  eq?: number | null;
  ge?: number | null;
  gt?: number | null;
  le?: number | null;
  lt?: number | null;
  ne?: number | null;
};

export type ModelAgeGroupInput = {
  eq?: AgeGroup | null;
  ne?: AgeGroup | null;
};

export type UpdateResourceScheduledEventInput = {
  id: string;
  accountId?: string | null;
  ownerId?: string | null;
  locationId?: string | null;
  title?: string | null;
  start?: string | null;
  end?: string | null;
  startTimezone?: string | null;
  endTimezone?: string | null;
  isAllDay?: boolean | null;
  description?: string | null;
  recurrenceRule?: string | null;
  recurrenceExceptions?: string | null;
  recurrenceId?: string | null;
  resourceId?: string | null;
  category?: ResourceCategory | null;
  cost?: number | null;
  ageGroup?: AgeGroup | null;
};

export type DeleteResourceScheduledEventInput = {
  id?: string | null;
};

export type CreateSessionRecordingInput = {
  id?: string | null;
  codec?: string | null;
  container?: string | null;
  offset?: string | null;
  sourceSid?: string | null;
  twilioMediaUri?: string | null;
  roomSid?: string | null;
  eventId?: string | null;
  ownerId: string;
  sessionId: string;
  s3Url?: string | null;
};

export type ModelSessionRecordingConditionInput = {
  codec?: ModelStringInput | null;
  container?: ModelStringInput | null;
  offset?: ModelStringInput | null;
  sourceSid?: ModelStringInput | null;
  twilioMediaUri?: ModelStringInput | null;
  roomSid?: ModelStringInput | null;
  eventId?: ModelStringInput | null;
  sessionId?: ModelIDInput | null;
  s3Url?: ModelStringInput | null;
  and?: Array<ModelSessionRecordingConditionInput | null> | null;
  or?: Array<ModelSessionRecordingConditionInput | null> | null;
  not?: ModelSessionRecordingConditionInput | null;
};

export type SessionRecording = {
  __typename: "SessionRecording";
  id?: string | null;
  codec?: string | null;
  container?: string | null;
  offset?: string | null;
  sourceSid?: string | null;
  twilioMediaUri?: string | null;
  roomSid?: string | null;
  eventId?: string | null;
  ownerId?: string;
  sessionId?: string;
  s3Url?: string | null;
  createdAt?: string;
  updatedAt?: string;
};

export type UpdateSessionRecordingInput = {
  id: string;
  codec?: string | null;
  container?: string | null;
  offset?: string | null;
  sourceSid?: string | null;
  twilioMediaUri?: string | null;
  roomSid?: string | null;
  eventId?: string | null;
  ownerId?: string | null;
  sessionId?: string | null;
  s3Url?: string | null;
};

export type DeleteSessionRecordingInput = {
  id?: string | null;
};

export type CreateEventResourceInput = {
  id?: string | null;
  ownerId?: string | null;
  accountId?: string | null;
  locationId?: string | null;
  name?: string | null;
  type?: ResourceType | null;
  classification?: EventResourceClassification | null;
  website?: string | null;
  contact?: ContactInput | null;
  address?: AddressInput | null;
  businessHours?: string | null;
  billedTime?: number | null;
};

export type ModelEventResourceConditionInput = {
  accountId?: ModelIDInput | null;
  locationId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  type?: ModelResourceTypeInput | null;
  classification?: ModelEventResourceClassificationInput | null;
  website?: ModelStringInput | null;
  businessHours?: ModelStringInput | null;
  billedTime?: ModelIntInput | null;
  and?: Array<ModelEventResourceConditionInput | null> | null;
  or?: Array<ModelEventResourceConditionInput | null> | null;
  not?: ModelEventResourceConditionInput | null;
};

export type ModelResourceTypeInput = {
  eq?: ResourceType | null;
  ne?: ResourceType | null;
};

export type ModelEventResourceClassificationInput = {
  eq?: EventResourceClassification | null;
  ne?: EventResourceClassification | null;
};

export type UpdateEventResourceInput = {
  id: string;
  ownerId?: string | null;
  accountId?: string | null;
  locationId?: string | null;
  name?: string | null;
  type?: ResourceType | null;
  classification?: EventResourceClassification | null;
  website?: string | null;
  contact?: ContactInput | null;
  address?: AddressInput | null;
  businessHours?: string | null;
  billedTime?: number | null;
};

export type DeleteEventResourceInput = {
  id?: string | null;
};

export type CreateQuestionnaireInput = {
  id?: string | null;
  created?: string | null;
  name?: string | null;
  title?: string | null;
  template?: string | null;
};

export type ModelQuestionnaireConditionInput = {
  created?: ModelStringInput | null;
  name?: ModelStringInput | null;
  title?: ModelStringInput | null;
  template?: ModelStringInput | null;
  and?: Array<ModelQuestionnaireConditionInput | null> | null;
  or?: Array<ModelQuestionnaireConditionInput | null> | null;
  not?: ModelQuestionnaireConditionInput | null;
};

export type UpdateQuestionnaireInput = {
  id: string;
  created?: string | null;
  name?: string | null;
  title?: string | null;
  template?: string | null;
};

export type DeleteQuestionnaireInput = {
  id?: string | null;
};

export type CreateQuestionnaireResponseAuthorInput = {
  id?: string | null;
  accountId: string;
  responseId: string;
  authorId: string;
};

export type ModelQuestionnaireResponseAuthorConditionInput = {
  accountId?: ModelIDInput | null;
  responseId?: ModelIDInput | null;
  and?: Array<ModelQuestionnaireResponseAuthorConditionInput | null> | null;
  or?: Array<ModelQuestionnaireResponseAuthorConditionInput | null> | null;
  not?: ModelQuestionnaireResponseAuthorConditionInput | null;
};

export type UpdateQuestionnaireResponseAuthorInput = {
  id: string;
  accountId?: string | null;
  responseId?: string | null;
  authorId?: string | null;
};

export type DeleteQuestionnaireResponseAuthorInput = {
  id?: string | null;
};

export type CreateQuestionnaireResponseInput = {
  id?: string | null;
  accountId: string;
  patientId: string;
  encounterId: string;
  contributors?: string | null;
  created?: string | null;
  name?: string | null;
  title?: string | null;
  status?: QuestionnaireStatus | null;
  json?: string | null;
};

export type ModelQuestionnaireResponseConditionInput = {
  accountId?: ModelIDInput | null;
  patientId?: ModelIDInput | null;
  encounterId?: ModelIDInput | null;
  created?: ModelStringInput | null;
  name?: ModelStringInput | null;
  title?: ModelStringInput | null;
  status?: ModelQuestionnaireStatusInput | null;
  json?: ModelStringInput | null;
  and?: Array<ModelQuestionnaireResponseConditionInput | null> | null;
  or?: Array<ModelQuestionnaireResponseConditionInput | null> | null;
  not?: ModelQuestionnaireResponseConditionInput | null;
};

export type ModelQuestionnaireStatusInput = {
  eq?: QuestionnaireStatus | null;
  ne?: QuestionnaireStatus | null;
};

export type UpdateQuestionnaireResponseInput = {
  id: string;
  accountId?: string | null;
  patientId?: string | null;
  encounterId?: string | null;
  contributors?: string | null;
  created?: string | null;
  name?: string | null;
  title?: string | null;
  status?: QuestionnaireStatus | null;
  json?: string | null;
};

export type DeleteQuestionnaireResponseInput = {
  id?: string | null;
};

export type CreateClinicalImpressionInput = {
  id?: string | null;
  accountId: string;
  patientId: string;
  encounterId: string;
  authorId?: string | null;
  created?: string | null;
  title?: string | null;
  status?: ClinicalImpressionStatus | null;
  summary?: string | null;
  name?: string | null;
  signature?: string | null;
  approved?: string | null;
  clinicalImpressionQuestionnaireResponseId?: string | null;
};

export type ModelClinicalImpressionConditionInput = {
  accountId?: ModelIDInput | null;
  patientId?: ModelIDInput | null;
  encounterId?: ModelIDInput | null;
  created?: ModelStringInput | null;
  title?: ModelStringInput | null;
  status?: ModelClinicalImpressionStatusInput | null;
  summary?: ModelStringInput | null;
  name?: ModelStringInput | null;
  signature?: ModelStringInput | null;
  approved?: ModelStringInput | null;
  and?: Array<ModelClinicalImpressionConditionInput | null> | null;
  or?: Array<ModelClinicalImpressionConditionInput | null> | null;
  not?: ModelClinicalImpressionConditionInput | null;
};

export type ModelClinicalImpressionStatusInput = {
  eq?: ClinicalImpressionStatus | null;
  ne?: ClinicalImpressionStatus | null;
};

export type UpdateClinicalImpressionInput = {
  id: string;
  accountId?: string | null;
  patientId?: string | null;
  encounterId?: string | null;
  authorId?: string | null;
  created?: string | null;
  title?: string | null;
  status?: ClinicalImpressionStatus | null;
  summary?: string | null;
  name?: string | null;
  signature?: string | null;
  approved?: string | null;
  clinicalImpressionQuestionnaireResponseId?: string | null;
};

export type DeleteClinicalImpressionInput = {
  id?: string | null;
};

export type CreateEncounterInput = {
  id?: string | null;
  accountId: string;
  locationId: string;
  managerId: string;
  patientId: string;
  ownerId: string;
  status?: EncounterStatus | null;
  date: string;
  soapItems?: Array<SoapItemInput | null> | null;
  encounterPretestId?: string | null;
};

export type SoapItemInput = {
  type?: SoapItemType | null;
  text?: string | null;
  code?: string | null;
  selected?: boolean | null;
};

export type ModelEncounterConditionInput = {
  accountId?: ModelIDInput | null;
  locationId?: ModelIDInput | null;
  managerId?: ModelIDInput | null;
  patientId?: ModelIDInput | null;
  status?: ModelEncounterStatusInput | null;
  date?: ModelStringInput | null;
  and?: Array<ModelEncounterConditionInput | null> | null;
  or?: Array<ModelEncounterConditionInput | null> | null;
  not?: ModelEncounterConditionInput | null;
};

export type ModelEncounterStatusInput = {
  eq?: EncounterStatus | null;
  ne?: EncounterStatus | null;
};

export type UpdateEncounterInput = {
  id: string;
  accountId?: string | null;
  locationId?: string | null;
  managerId?: string | null;
  patientId?: string | null;
  ownerId?: string | null;
  status?: EncounterStatus | null;
  date?: string | null;
  soapItems?: Array<SoapItemInput | null> | null;
  encounterPretestId?: string | null;
};

export type DeleteEncounterInput = {
  id?: string | null;
};

export type CreateEncounterProviderConnectionInput = {
  id?: string | null;
  accountId: string;
  encounterId: string;
  providerId: string;
};

export type ModelEncounterProviderConnectionConditionInput = {
  accountId?: ModelIDInput | null;
  encounterId?: ModelIDInput | null;
  providerId?: ModelIDInput | null;
  and?: Array<ModelEncounterProviderConnectionConditionInput | null> | null;
  or?: Array<ModelEncounterProviderConnectionConditionInput | null> | null;
  not?: ModelEncounterProviderConnectionConditionInput | null;
};

export type UpdateEncounterProviderConnectionInput = {
  id: string;
  accountId?: string | null;
  encounterId?: string | null;
  providerId?: string | null;
};

export type DeleteEncounterProviderConnectionInput = {
  id?: string | null;
};

export type CreateEncounterNoteConnectionInput = {
  id?: string | null;
  accountId: string;
  encounterId: string;
  noteId: string;
};

export type ModelEncounterNoteConnectionConditionInput = {
  accountId?: ModelIDInput | null;
  encounterId?: ModelIDInput | null;
  noteId?: ModelIDInput | null;
  and?: Array<ModelEncounterNoteConnectionConditionInput | null> | null;
  or?: Array<ModelEncounterNoteConnectionConditionInput | null> | null;
  not?: ModelEncounterNoteConnectionConditionInput | null;
};

export type UpdateEncounterNoteConnectionInput = {
  id: string;
  accountId?: string | null;
  encounterId?: string | null;
  noteId?: string | null;
};

export type DeleteEncounterNoteConnectionInput = {
  id?: string | null;
};

export type CreateNoteInput = {
  id?: string | null;
  author: AuthorType;
  userId: string;
  patientId: string;
  type?: NoteType | null;
  title?: string | null;
  content?: string | null;
};

export type ModelNoteConditionInput = {
  author?: ModelAuthorTypeInput | null;
  userId?: ModelIDInput | null;
  patientId?: ModelIDInput | null;
  type?: ModelNoteTypeInput | null;
  title?: ModelStringInput | null;
  content?: ModelStringInput | null;
  and?: Array<ModelNoteConditionInput | null> | null;
  or?: Array<ModelNoteConditionInput | null> | null;
  not?: ModelNoteConditionInput | null;
};

export type ModelAuthorTypeInput = {
  eq?: AuthorType | null;
  ne?: AuthorType | null;
};

export type ModelNoteTypeInput = {
  eq?: NoteType | null;
  ne?: NoteType | null;
};

export type UpdateNoteInput = {
  id: string;
  author?: AuthorType | null;
  userId?: string | null;
  patientId?: string | null;
  type?: NoteType | null;
  title?: string | null;
  content?: string | null;
};

export type DeleteNoteInput = {
  id?: string | null;
};

export type CreateDiagnosticCodeInput = {
  id?: string | null;
  accountId: string;
  codeId: string;
  patientId: string;
  diagnosed?: string | null;
};

export type ModelDiagnosticCodeConditionInput = {
  accountId?: ModelIDInput | null;
  codeId?: ModelIDInput | null;
  patientId?: ModelIDInput | null;
  diagnosed?: ModelStringInput | null;
  and?: Array<ModelDiagnosticCodeConditionInput | null> | null;
  or?: Array<ModelDiagnosticCodeConditionInput | null> | null;
  not?: ModelDiagnosticCodeConditionInput | null;
};

export type UpdateDiagnosticCodeInput = {
  id: string;
  accountId?: string | null;
  codeId?: string | null;
  patientId?: string | null;
  diagnosed?: string | null;
};

export type DeleteDiagnosticCodeInput = {
  id?: string | null;
};

export type CreateBillingItemInput = {
  id?: string | null;
  accountId: string;
  locationId?: string | null;
  patientId: string;
  codeId: string;
  amount: number;
  type?: BillingType | null;
  currency?: string | null;
  created?: string | null;
  serviced?: string | null;
  status?: BillingItemStatus | null;
  invoiceBillingItemsId?: string | null;
};

export type ModelBillingItemConditionInput = {
  accountId?: ModelIDInput | null;
  locationId?: ModelIDInput | null;
  patientId?: ModelIDInput | null;
  codeId?: ModelIDInput | null;
  amount?: ModelFloatInput | null;
  type?: ModelBillingTypeInput | null;
  currency?: ModelStringInput | null;
  created?: ModelStringInput | null;
  serviced?: ModelStringInput | null;
  status?: ModelBillingItemStatusInput | null;
  and?: Array<ModelBillingItemConditionInput | null> | null;
  or?: Array<ModelBillingItemConditionInput | null> | null;
  not?: ModelBillingItemConditionInput | null;
};

export type ModelBillingTypeInput = {
  eq?: BillingType | null;
  ne?: BillingType | null;
};

export type ModelBillingItemStatusInput = {
  eq?: BillingItemStatus | null;
  ne?: BillingItemStatus | null;
};

export type UpdateBillingItemInput = {
  id: string;
  accountId?: string | null;
  locationId?: string | null;
  patientId?: string | null;
  codeId?: string | null;
  amount?: number | null;
  type?: BillingType | null;
  currency?: string | null;
  created?: string | null;
  serviced?: string | null;
  status?: BillingItemStatus | null;
  invoiceBillingItemsId?: string | null;
};

export type DeleteBillingItemInput = {
  id?: string | null;
};

export type CreateInvoiceInput = {
  id?: string | null;
  accountId: string;
  locationId: string;
  dueDate: string;
  billDate: string;
  billingPeriodStartDate: string;
  billingPeriodEndDate: string;
  hours?: number | null;
  totalBill?: number | null;
  status?: InvoiceStatus | null;
};

export type ModelInvoiceConditionInput = {
  accountId?: ModelIDInput | null;
  locationId?: ModelIDInput | null;
  dueDate?: ModelStringInput | null;
  billDate?: ModelStringInput | null;
  billingPeriodStartDate?: ModelStringInput | null;
  billingPeriodEndDate?: ModelStringInput | null;
  hours?: ModelIntInput | null;
  totalBill?: ModelFloatInput | null;
  status?: ModelInvoiceStatusInput | null;
  and?: Array<ModelInvoiceConditionInput | null> | null;
  or?: Array<ModelInvoiceConditionInput | null> | null;
  not?: ModelInvoiceConditionInput | null;
};

export type ModelInvoiceStatusInput = {
  eq?: InvoiceStatus | null;
  ne?: InvoiceStatus | null;
};

export type UpdateInvoiceInput = {
  id: string;
  accountId?: string | null;
  locationId?: string | null;
  dueDate?: string | null;
  billDate?: string | null;
  billingPeriodStartDate?: string | null;
  billingPeriodEndDate?: string | null;
  hours?: number | null;
  totalBill?: number | null;
  status?: InvoiceStatus | null;
};

export type DeleteInvoiceInput = {
  id?: string | null;
};

export type CreatePaymentInput = {
  id?: string | null;
  accountId: string;
  locationId: string;
  userId: string;
  stripePaymentMethodId: string;
  paymentDate: string;
  paymentInvoiceId?: string | null;
};

export type ModelPaymentConditionInput = {
  accountId?: ModelIDInput | null;
  locationId?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  stripePaymentMethodId?: ModelStringInput | null;
  paymentDate?: ModelStringInput | null;
  and?: Array<ModelPaymentConditionInput | null> | null;
  or?: Array<ModelPaymentConditionInput | null> | null;
  not?: ModelPaymentConditionInput | null;
};

export type UpdatePaymentInput = {
  id: string;
  accountId?: string | null;
  locationId?: string | null;
  userId?: string | null;
  stripePaymentMethodId?: string | null;
  paymentDate?: string | null;
  paymentInvoiceId?: string | null;
};

export type DeletePaymentInput = {
  id?: string | null;
};

export type CreateCodeInput = {
  id?: string | null;
  type: CodeType;
  name: string;
  description?: string | null;
  rate?: number | null;
};

export type ModelCodeConditionInput = {
  type?: ModelCodeTypeInput | null;
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  rate?: ModelFloatInput | null;
  and?: Array<ModelCodeConditionInput | null> | null;
  or?: Array<ModelCodeConditionInput | null> | null;
  not?: ModelCodeConditionInput | null;
};

export type ModelCodeTypeInput = {
  eq?: CodeType | null;
  ne?: CodeType | null;
};

export type UpdateCodeInput = {
  id: string;
  type?: CodeType | null;
  name?: string | null;
  description?: string | null;
  rate?: number | null;
};

export type DeleteCodeInput = {
  id?: string | null;
};

export type ModelPatientFilterInput = {
  accountId?: ModelIDInput | null;
  and?: Array<ModelPatientFilterInput | null> | null;
  connectionId?: ModelStringInput | null;
  dob?: ModelStringInput | null;
  eligibleRPM?: ModelBooleanInput | null;
  email?: ModelStringInput | null;
  familyName?: ModelStringInput | null;
  gender?: ModelGenderInput | null;
  givenName?: ModelStringInput | null;
  id?: ModelIDInput | null;
  interests?: ModelStringInput | null;
  locationId?: ModelIDInput | null;
  middleName?: ModelStringInput | null;
  mobile?: ModelStringInput | null;
  not?: ModelPatientFilterInput | null;
  or?: Array<ModelPatientFilterInput | null> | null;
  owner?: ModelStringInput | null;
  patientManagerId?: ModelIDInput | null;
  providers?: ModelStringInput | null;
  salutation?: ModelSalutationInput | null;
  status?: ModelStatusInput | null;
};

export type ModelPatientConnection = {
  __typename: "ModelPatientConnection";
  items?: Array<Patient | null> | null;
  nextToken?: string | null;
};

export type ModelPatientProviderConnectionFilterInput = {
  id?: ModelIDInput | null;
  accountId?: ModelIDInput | null;
  patientId?: ModelIDInput | null;
  providerId?: ModelIDInput | null;
  type?: ModelProviderTypeInput | null;
  and?: Array<ModelPatientProviderConnectionFilterInput | null> | null;
  or?: Array<ModelPatientProviderConnectionFilterInput | null> | null;
  not?: ModelPatientProviderConnectionFilterInput | null;
};

export type ModelPatientProgramConnectionFilterInput = {
  id?: ModelIDInput | null;
  accountId?: ModelIDInput | null;
  patientId?: ModelIDInput | null;
  programId?: ModelIDInput | null;
  date?: ModelStringInput | null;
  and?: Array<ModelPatientProgramConnectionFilterInput | null> | null;
  or?: Array<ModelPatientProgramConnectionFilterInput | null> | null;
  not?: ModelPatientProgramConnectionFilterInput | null;
};

export type ModelProgramFilterInput = {
  id?: ModelIDInput | null;
  questionnaireId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  and?: Array<ModelProgramFilterInput | null> | null;
  or?: Array<ModelProgramFilterInput | null> | null;
  not?: ModelProgramFilterInput | null;
};

export type ModelProgramConnection = {
  __typename: "ModelProgramConnection";
  items?: Array<Program | null> | null;
  nextToken?: string | null;
};

export type ModelProgramTestFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  referenceTestId?: ModelStringInput | null;
  and?: Array<ModelProgramTestFilterInput | null> | null;
  or?: Array<ModelProgramTestFilterInput | null> | null;
  not?: ModelProgramTestFilterInput | null;
};

export type ModelEligibilityConnectionFilterInput = {
  id?: ModelIDInput | null;
  patientId?: ModelIDInput | null;
  programTestId?: ModelIDInput | null;
  required?: ModelBooleanInput | null;
  date?: ModelStringInput | null;
  and?: Array<ModelEligibilityConnectionFilterInput | null> | null;
  or?: Array<ModelEligibilityConnectionFilterInput | null> | null;
  not?: ModelEligibilityConnectionFilterInput | null;
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null;
  accountId?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  email?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  online?: ModelBooleanInput | null;
  firstName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  titles?: ModelStringInput | null;
  npi?: ModelStringInput | null;
  image?: ModelStringInput | null;
  userType?: ModelUserTypeInput | null;
  onlineStatus?: ModelUserOnlineStatusInput | null;
  pinCode?: ModelStringInput | null;
  permissions?: ModelAccessPermissionListInput | null;
  and?: Array<ModelUserFilterInput | null> | null;
  or?: Array<ModelUserFilterInput | null> | null;
  not?: ModelUserFilterInput | null;
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection";
  items?: Array<User | null> | null;
  nextToken?: string | null;
};

export type SearchableUserFilterInput = {
  id?: SearchableIDFilterInput | null;
  accountId?: SearchableIDFilterInput | null;
  owner?: SearchableStringFilterInput | null;
  email?: SearchableStringFilterInput | null;
  phone?: SearchableStringFilterInput | null;
  online?: SearchableBooleanFilterInput | null;
  firstName?: SearchableStringFilterInput | null;
  lastName?: SearchableStringFilterInput | null;
  titles?: SearchableStringFilterInput | null;
  npi?: SearchableStringFilterInput | null;
  image?: SearchableStringFilterInput | null;
  pinCode?: SearchableStringFilterInput | null;
  and?: Array<SearchableUserFilterInput | null> | null;
  or?: Array<SearchableUserFilterInput | null> | null;
  not?: SearchableUserFilterInput | null;
};

export type SearchableIDFilterInput = {
  ne?: string | null;
  gt?: string | null;
  lt?: string | null;
  gte?: string | null;
  lte?: string | null;
  eq?: string | null;
  match?: string | null;
  matchPhrase?: string | null;
  matchPhrasePrefix?: string | null;
  multiMatch?: string | null;
  exists?: boolean | null;
  wildcard?: string | null;
  regexp?: string | null;
  range?: Array<string | null> | null;
};

export type SearchableStringFilterInput = {
  ne?: string | null;
  gt?: string | null;
  lt?: string | null;
  gte?: string | null;
  lte?: string | null;
  eq?: string | null;
  match?: string | null;
  matchPhrase?: string | null;
  matchPhrasePrefix?: string | null;
  multiMatch?: string | null;
  exists?: boolean | null;
  wildcard?: string | null;
  regexp?: string | null;
  range?: Array<string | null> | null;
};

export type SearchableBooleanFilterInput = {
  eq?: boolean | null;
  ne?: boolean | null;
};

export type SearchableUserSortInput = {
  field?: SearchableUserSortableFields | null;
  direction?: SearchableSortDirection | null;
};

export enum SearchableUserSortableFields {
  id = "id",
  accountId = "accountId",
  owner = "owner",
  email = "email",
  phone = "phone",
  online = "online",
  firstName = "firstName",
  lastName = "lastName",
  titles = "titles",
  npi = "npi",
  image = "image",
  pinCode = "pinCode"
}

export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc"
}

export type SearchableUserConnection = {
  __typename: "SearchableUserConnection";
  items?: Array<User | null> | null;
  nextToken?: string | null;
  total?: number | null;
};

export type ModelUserLocationConnectionFilterInput = {
  id?: ModelIDInput | null;
  accountId?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  locationId?: ModelIDInput | null;
  and?: Array<ModelUserLocationConnectionFilterInput | null> | null;
  or?: Array<ModelUserLocationConnectionFilterInput | null> | null;
  not?: ModelUserLocationConnectionFilterInput | null;
};

export type ModelAccountFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  owner?: ModelStringInput | null;
  stripeCustomerId?: ModelStringInput | null;
  and?: Array<ModelAccountFilterInput | null> | null;
  or?: Array<ModelAccountFilterInput | null> | null;
  not?: ModelAccountFilterInput | null;
};

export type ModelAccountConnection = {
  __typename: "ModelAccountConnection";
  items?: Array<Account | null> | null;
  nextToken?: string | null;
};

export type ModelLocationFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  accountId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  callerId?: ModelStringInput | null;
  testsCount?: ModelIntInput | null;
  usersCount?: ModelIntInput | null;
  physicianCount?: ModelIntInput | null;
  and?: Array<ModelLocationFilterInput | null> | null;
  or?: Array<ModelLocationFilterInput | null> | null;
  not?: ModelLocationFilterInput | null;
};

export type ModelLocationConnection = {
  __typename: "ModelLocationConnection";
  items?: Array<Location | null> | null;
  nextToken?: string | null;
};

export type SearchableLocationFilterInput = {
  id?: SearchableIDFilterInput | null;
  owner?: SearchableStringFilterInput | null;
  accountId?: SearchableIDFilterInput | null;
  name?: SearchableStringFilterInput | null;
  callerId?: SearchableStringFilterInput | null;
  testsCount?: SearchableIntFilterInput | null;
  usersCount?: SearchableIntFilterInput | null;
  physicianCount?: SearchableIntFilterInput | null;
  and?: Array<SearchableLocationFilterInput | null> | null;
  or?: Array<SearchableLocationFilterInput | null> | null;
  not?: SearchableLocationFilterInput | null;
};

export type SearchableIntFilterInput = {
  ne?: number | null;
  gt?: number | null;
  lt?: number | null;
  gte?: number | null;
  lte?: number | null;
  eq?: number | null;
  range?: Array<number | null> | null;
};

export type SearchableLocationSortInput = {
  field?: SearchableLocationSortableFields | null;
  direction?: SearchableSortDirection | null;
};

export enum SearchableLocationSortableFields {
  id = "id",
  owner = "owner",
  accountId = "accountId",
  name = "name",
  callerId = "callerId",
  testsCount = "testsCount",
  usersCount = "usersCount",
  physicianCount = "physicianCount"
}

export type SearchableLocationConnection = {
  __typename: "SearchableLocationConnection";
  items?: Array<Location | null> | null;
  nextToken?: string | null;
  total?: number | null;
};

export type ModelBilledTimeFilterInput = {
  id?: ModelIDInput | null;
  minutes?: ModelIntInput | null;
  type?: ModelBilledTimeTypeInput | null;
  date?: ModelStringInput | null;
  userId?: ModelIDInput | null;
  and?: Array<ModelBilledTimeFilterInput | null> | null;
  or?: Array<ModelBilledTimeFilterInput | null> | null;
  not?: ModelBilledTimeFilterInput | null;
};

export type ModelEncounterBilledTimeConnectionFilterInput = {
  id?: ModelIDInput | null;
  accountId?: ModelIDInput | null;
  encounterId?: ModelIDInput | null;
  billedTimeId?: ModelIDInput | null;
  and?: Array<ModelEncounterBilledTimeConnectionFilterInput | null> | null;
  or?: Array<ModelEncounterBilledTimeConnectionFilterInput | null> | null;
  not?: ModelEncounterBilledTimeConnectionFilterInput | null;
};

export type ModelAssessmentBilledTimeConnectionFilterInput = {
  id?: ModelIDInput | null;
  accountId?: ModelIDInput | null;
  assessmentId?: ModelIDInput | null;
  billedTimeId?: ModelIDInput | null;
  and?: Array<ModelAssessmentBilledTimeConnectionFilterInput | null> | null;
  or?: Array<ModelAssessmentBilledTimeConnectionFilterInput | null> | null;
  not?: ModelAssessmentBilledTimeConnectionFilterInput | null;
};

export type ModelAssessmentBilledTimeConnectionConnection = {
  __typename: "ModelAssessmentBilledTimeConnectionConnection";
  items?: Array<AssessmentBilledTimeConnection | null> | null;
  nextToken?: string | null;
};

export type ModelAssessmentFilterInput = {
  id?: ModelIDInput | null;
  accountId?: ModelIDInput | null;
  locationId?: ModelIDInput | null;
  patientId?: ModelIDInput | null;
  providers?: ModelStringInput | null;
  managerId?: ModelIDInput | null;
  submitterId?: ModelIDInput | null;
  result?: ModelStringInput | null;
  testId?: ModelStringInput | null;
  testCode?: ModelStringInput | null;
  meta?: ModelIntInput | null;
  answerSheetId?: ModelStringInput | null;
  title?: ModelStringInput | null;
  type?: ModelAssessmentTypeInput | null;
  assessed?: ModelStringInput | null;
  scheduledDate?: ModelStringInput | null;
  score?: ModelIntInput | null;
  scoreGauge?: ModelScoreGaugeInput | null;
  status?: ModelAssessmentStatusInput | null;
  assessmentEncounterId?: ModelStringInput | null;
  and?: Array<ModelAssessmentFilterInput | null> | null;
  or?: Array<ModelAssessmentFilterInput | null> | null;
  not?: ModelAssessmentFilterInput | null;
};

export type ModelTreatmentPlanFilterInput = {
  accountId?: ModelIDInput | null;
  and?: Array<ModelTreatmentPlanFilterInput | null> | null;
  created?: ModelStringInput | null;
  id?: ModelIDInput | null;
  locationId?: ModelIDInput | null;
  not?: ModelTreatmentPlanFilterInput | null;
  or?: Array<ModelTreatmentPlanFilterInput | null> | null;
  patientId?: ModelIDInput | null;
  providers?: ModelStringInput | null;
  treatmentPlanManagerId?: ModelIDInput | null;
};

export type ModelTreatmentPlanConnection = {
  __typename: "ModelTreatmentPlanConnection";
  items?: Array<TreatmentPlan | null> | null;
  nextToken?: string | null;
};

export type ModelPatientResourceScheduledEventConnectionFilterInput = {
  id?: ModelIDInput | null;
  accountId?: ModelIDInput | null;
  patientId?: ModelIDInput | null;
  encounterId?: ModelIDInput | null;
  resourceScheduledEventId?: ModelIDInput | null;
  created?: ModelStringInput | null;
  and?: Array<ModelPatientResourceScheduledEventConnectionFilterInput | null> | null;
  or?: Array<ModelPatientResourceScheduledEventConnectionFilterInput | null> | null;
  not?: ModelPatientResourceScheduledEventConnectionFilterInput | null;
};

export type ModelTelehealthSessionFilterInput = {
  id?: ModelIDInput | null;
  title?: ModelStringInput | null;
  accountId?: ModelIDInput | null;
  participantIds?: ModelStringInput | null;
  status?: ModelTelehealthSessionStatusInput | null;
  roomType?: ModelRoomTypeInput | null;
  startedAt?: ModelStringInput | null;
  endedAt?: ModelStringInput | null;
  and?: Array<ModelTelehealthSessionFilterInput | null> | null;
  or?: Array<ModelTelehealthSessionFilterInput | null> | null;
  not?: ModelTelehealthSessionFilterInput | null;
};

export type ModelTelehealthSessionConnection = {
  __typename: "ModelTelehealthSessionConnection";
  items?: Array<TelehealthSession | null> | null;
  nextToken?: string | null;
};

export type ModelScheduledEventFilterInput = {
  id?: ModelIDInput | null;
  accountId?: ModelIDInput | null;
  ownerId?: ModelIDInput | null;
  participantIds?: ModelStringInput | null;
  title?: ModelStringInput | null;
  start?: ModelStringInput | null;
  end?: ModelStringInput | null;
  type?: ModelScheduledEventTypeInput | null;
  startTimezone?: ModelStringInput | null;
  endTimezone?: ModelStringInput | null;
  isAllDay?: ModelBooleanInput | null;
  description?: ModelStringInput | null;
  recurrenceRule?: ModelStringInput | null;
  recurrenceExceptions?: ModelStringInput | null;
  recurrenceId?: ModelStringInput | null;
  and?: Array<ModelScheduledEventFilterInput | null> | null;
  or?: Array<ModelScheduledEventFilterInput | null> | null;
  not?: ModelScheduledEventFilterInput | null;
};

export type ModelScheduledEventConnection = {
  __typename: "ModelScheduledEventConnection";
  items?: Array<ScheduledEvent | null> | null;
  nextToken?: string | null;
};

export type ModelResourceScheduledEventFilterInput = {
  id?: ModelIDInput | null;
  accountId?: ModelIDInput | null;
  ownerId?: ModelIDInput | null;
  locationId?: ModelIDInput | null;
  title?: ModelStringInput | null;
  start?: ModelStringInput | null;
  end?: ModelStringInput | null;
  startTimezone?: ModelStringInput | null;
  endTimezone?: ModelStringInput | null;
  isAllDay?: ModelBooleanInput | null;
  description?: ModelStringInput | null;
  recurrenceRule?: ModelStringInput | null;
  recurrenceExceptions?: ModelStringInput | null;
  recurrenceId?: ModelStringInput | null;
  resourceId?: ModelIDInput | null;
  category?: ModelResourceCategoryInput | null;
  cost?: ModelFloatInput | null;
  ageGroup?: ModelAgeGroupInput | null;
  and?: Array<ModelResourceScheduledEventFilterInput | null> | null;
  or?: Array<ModelResourceScheduledEventFilterInput | null> | null;
  not?: ModelResourceScheduledEventFilterInput | null;
};

export type ModelResourceScheduledEventConnection = {
  __typename: "ModelResourceScheduledEventConnection";
  items?: Array<ResourceScheduledEvent | null> | null;
  nextToken?: string | null;
};

export type ModelSessionRecordingFilterInput = {
  id?: ModelIDInput | null;
  codec?: ModelStringInput | null;
  container?: ModelStringInput | null;
  offset?: ModelStringInput | null;
  sourceSid?: ModelStringInput | null;
  twilioMediaUri?: ModelStringInput | null;
  roomSid?: ModelStringInput | null;
  eventId?: ModelStringInput | null;
  ownerId?: ModelIDInput | null;
  sessionId?: ModelIDInput | null;
  s3Url?: ModelStringInput | null;
  and?: Array<ModelSessionRecordingFilterInput | null> | null;
  or?: Array<ModelSessionRecordingFilterInput | null> | null;
  not?: ModelSessionRecordingFilterInput | null;
};

export type ModelSessionRecordingConnection = {
  __typename: "ModelSessionRecordingConnection";
  items?: Array<SessionRecording | null> | null;
  nextToken?: string | null;
};

export type ModelEventResourceFilterInput = {
  id?: ModelIDInput | null;
  ownerId?: ModelIDInput | null;
  accountId?: ModelIDInput | null;
  locationId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  type?: ModelResourceTypeInput | null;
  classification?: ModelEventResourceClassificationInput | null;
  website?: ModelStringInput | null;
  businessHours?: ModelStringInput | null;
  billedTime?: ModelIntInput | null;
  and?: Array<ModelEventResourceFilterInput | null> | null;
  or?: Array<ModelEventResourceFilterInput | null> | null;
  not?: ModelEventResourceFilterInput | null;
};

export type ModelEventResourceConnection = {
  __typename: "ModelEventResourceConnection";
  items?: Array<EventResource | null> | null;
  nextToken?: string | null;
};

export type ModelQuestionnaireFilterInput = {
  id?: ModelIDInput | null;
  created?: ModelStringInput | null;
  name?: ModelStringInput | null;
  title?: ModelStringInput | null;
  template?: ModelStringInput | null;
  and?: Array<ModelQuestionnaireFilterInput | null> | null;
  or?: Array<ModelQuestionnaireFilterInput | null> | null;
  not?: ModelQuestionnaireFilterInput | null;
};

export type ModelQuestionnaireConnection = {
  __typename: "ModelQuestionnaireConnection";
  items?: Array<Questionnaire | null> | null;
  nextToken?: string | null;
};

export type ModelQuestionnaireResponseAuthorFilterInput = {
  id?: ModelIDInput | null;
  accountId?: ModelIDInput | null;
  responseId?: ModelIDInput | null;
  authorId?: ModelIDInput | null;
  and?: Array<ModelQuestionnaireResponseAuthorFilterInput | null> | null;
  or?: Array<ModelQuestionnaireResponseAuthorFilterInput | null> | null;
  not?: ModelQuestionnaireResponseAuthorFilterInput | null;
};

export type ModelQuestionnaireResponseFilterInput = {
  id?: ModelIDInput | null;
  accountId?: ModelIDInput | null;
  patientId?: ModelIDInput | null;
  encounterId?: ModelIDInput | null;
  contributors?: ModelStringInput | null;
  created?: ModelStringInput | null;
  name?: ModelStringInput | null;
  title?: ModelStringInput | null;
  status?: ModelQuestionnaireStatusInput | null;
  json?: ModelStringInput | null;
  and?: Array<ModelQuestionnaireResponseFilterInput | null> | null;
  or?: Array<ModelQuestionnaireResponseFilterInput | null> | null;
  not?: ModelQuestionnaireResponseFilterInput | null;
};

export type ModelClinicalImpressionFilterInput = {
  id?: ModelIDInput | null;
  accountId?: ModelIDInput | null;
  patientId?: ModelIDInput | null;
  encounterId?: ModelIDInput | null;
  authorId?: ModelStringInput | null;
  created?: ModelStringInput | null;
  title?: ModelStringInput | null;
  status?: ModelClinicalImpressionStatusInput | null;
  summary?: ModelStringInput | null;
  name?: ModelStringInput | null;
  signature?: ModelStringInput | null;
  approved?: ModelStringInput | null;
  and?: Array<ModelClinicalImpressionFilterInput | null> | null;
  or?: Array<ModelClinicalImpressionFilterInput | null> | null;
  not?: ModelClinicalImpressionFilterInput | null;
};

export type ModelEncounterFilterInput = {
  id?: ModelIDInput | null;
  accountId?: ModelIDInput | null;
  locationId?: ModelIDInput | null;
  managerId?: ModelIDInput | null;
  patientId?: ModelIDInput | null;
  ownerId?: ModelIDInput | null;
  status?: ModelEncounterStatusInput | null;
  date?: ModelStringInput | null;
  and?: Array<ModelEncounterFilterInput | null> | null;
  or?: Array<ModelEncounterFilterInput | null> | null;
  not?: ModelEncounterFilterInput | null;
};

export type ModelEncounterConnection = {
  __typename: "ModelEncounterConnection";
  items?: Array<Encounter | null> | null;
  nextToken?: string | null;
};

export type ModelEncounterProviderConnectionFilterInput = {
  id?: ModelIDInput | null;
  accountId?: ModelIDInput | null;
  encounterId?: ModelIDInput | null;
  providerId?: ModelIDInput | null;
  and?: Array<ModelEncounterProviderConnectionFilterInput | null> | null;
  or?: Array<ModelEncounterProviderConnectionFilterInput | null> | null;
  not?: ModelEncounterProviderConnectionFilterInput | null;
};

export type ModelEncounterNoteConnectionFilterInput = {
  id?: ModelIDInput | null;
  accountId?: ModelIDInput | null;
  encounterId?: ModelIDInput | null;
  noteId?: ModelIDInput | null;
  and?: Array<ModelEncounterNoteConnectionFilterInput | null> | null;
  or?: Array<ModelEncounterNoteConnectionFilterInput | null> | null;
  not?: ModelEncounterNoteConnectionFilterInput | null;
};

export type ModelNoteFilterInput = {
  id?: ModelIDInput | null;
  author?: ModelAuthorTypeInput | null;
  userId?: ModelIDInput | null;
  patientId?: ModelIDInput | null;
  type?: ModelNoteTypeInput | null;
  title?: ModelStringInput | null;
  content?: ModelStringInput | null;
  and?: Array<ModelNoteFilterInput | null> | null;
  or?: Array<ModelNoteFilterInput | null> | null;
  not?: ModelNoteFilterInput | null;
};

export type ModelNoteConnection = {
  __typename: "ModelNoteConnection";
  items?: Array<Note | null> | null;
  nextToken?: string | null;
};

export type ModelDiagnosticCodeFilterInput = {
  id?: ModelIDInput | null;
  accountId?: ModelIDInput | null;
  codeId?: ModelIDInput | null;
  patientId?: ModelIDInput | null;
  diagnosed?: ModelStringInput | null;
  and?: Array<ModelDiagnosticCodeFilterInput | null> | null;
  or?: Array<ModelDiagnosticCodeFilterInput | null> | null;
  not?: ModelDiagnosticCodeFilterInput | null;
};

export type ModelBillingItemFilterInput = {
  id?: ModelIDInput | null;
  accountId?: ModelIDInput | null;
  locationId?: ModelIDInput | null;
  patientId?: ModelIDInput | null;
  codeId?: ModelIDInput | null;
  amount?: ModelFloatInput | null;
  type?: ModelBillingTypeInput | null;
  currency?: ModelStringInput | null;
  created?: ModelStringInput | null;
  serviced?: ModelStringInput | null;
  status?: ModelBillingItemStatusInput | null;
  and?: Array<ModelBillingItemFilterInput | null> | null;
  or?: Array<ModelBillingItemFilterInput | null> | null;
  not?: ModelBillingItemFilterInput | null;
};

export type ModelInvoiceFilterInput = {
  id?: ModelIDInput | null;
  accountId?: ModelIDInput | null;
  locationId?: ModelIDInput | null;
  dueDate?: ModelStringInput | null;
  billDate?: ModelStringInput | null;
  billingPeriodStartDate?: ModelStringInput | null;
  billingPeriodEndDate?: ModelStringInput | null;
  hours?: ModelIntInput | null;
  totalBill?: ModelFloatInput | null;
  status?: ModelInvoiceStatusInput | null;
  and?: Array<ModelInvoiceFilterInput | null> | null;
  or?: Array<ModelInvoiceFilterInput | null> | null;
  not?: ModelInvoiceFilterInput | null;
};

export type ModelInvoiceConnection = {
  __typename: "ModelInvoiceConnection";
  items?: Array<Invoice | null> | null;
  nextToken?: string | null;
};

export type ModelPaymentFilterInput = {
  id?: ModelIDInput | null;
  accountId?: ModelIDInput | null;
  locationId?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  stripePaymentMethodId?: ModelStringInput | null;
  paymentDate?: ModelStringInput | null;
  and?: Array<ModelPaymentFilterInput | null> | null;
  or?: Array<ModelPaymentFilterInput | null> | null;
  not?: ModelPaymentFilterInput | null;
};

export type ModelPaymentConnection = {
  __typename: "ModelPaymentConnection";
  items?: Array<Payment | null> | null;
  nextToken?: string | null;
};

export type ModelCodeFilterInput = {
  id?: ModelIDInput | null;
  type?: ModelCodeTypeInput | null;
  name?: ModelStringInput | null;
  description?: ModelStringInput | null;
  rate?: ModelFloatInput | null;
  and?: Array<ModelCodeFilterInput | null> | null;
  or?: Array<ModelCodeFilterInput | null> | null;
  not?: ModelCodeFilterInput | null;
};

export type ModelCodeConnection = {
  __typename: "ModelCodeConnection";
  items?: Array<Code | null> | null;
  nextToken?: string | null;
};

export type AddUserAccountMutation = {
  __typename: "AddUserAccountOutput";
  id?: string | null;
};

export type AddPatientAccountMutation = {
  __typename: "AddPatientAccountOutput";
  id?: string | null;
};

export type RequestTelehealthAccessMutation = {
  __typename: "RequestTelehealthAccessOutput";
  accessToken: string;
  roomSid: string;
};

export type InitTwilioRoomMutation = {
  __typename: "InitTwilioRoomOutput";
  roomSid: string;
};

export type DestroyTwilioRoomMutation = {
  __typename: "DestroyTwilioRoomOutput";
  roomSid: string;
};

export type RequestChatAccessMutation = {
  __typename: "RequestChatAccessOutput";
  accessToken: string;
  identity: string;
};

export type RequestVoiceAccessMutation = {
  __typename: "RequestVoiceAccessOutput";
  accessToken: string;
  identity: string;
};

export type GetAssessmentEligibilityMutation = {
  __typename: "GetAssessmentEligibilityOutput";
  eligibleTests?: Array<{
    __typename: "EligibleTest";
    title?: string | null;
    testId?: string | null;
    code?: string | null;
    recommended?: boolean | null;
    required?: boolean | null;
  } | null> | null;
};

export type ProcessPretestApprovalMutation = {
  __typename: "ProcessPretestApprovalOutput";
  encounterId?: string | null;
  pretestAssessmentId?: string | null;
  assessmentIds?: Array<string | null> | null;
  wasApproved?: boolean | null;
  comment?: string | null;
};

export type AddTestMutation = {
  __typename: "JSONOutput";
  data: string;
};

export type GetTestMutation = {
  __typename: "JSONOutput";
  data: string;
};

export type ListTestsMutation = {
  __typename: "JSONOutput";
  data: string;
};

export type UpdateTestMutation = {
  __typename: "JSONOutput";
  data: string;
};

export type DeleteTestMutation = {
  __typename: "JSONOutput";
  data: string;
};

export type AddAnswerSheetMutation = {
  __typename: "JSONOutput";
  data: string;
};

export type GetAnswerSheetMutation = {
  __typename: "JSONOutput";
  data: string;
};

export type ListAnswerSheetsMutation = {
  __typename: "JSONOutput";
  data: string;
};

export type UpdateAnswerSheetMutation = {
  __typename: "JSONOutput";
  data: string;
};

export type DeleteAnswerSheetMutation = {
  __typename: "JSONOutput";
  data: string;
};

export type GenerateEncounterQuestionnairesMutation = {
  __typename: "GenerateEncounterQuestionnairesOutput";
  ids?: Array<string | null> | null;
};

export type GenerateEncounterImpressionsMutation = {
  __typename: "GenerateEncounterImpressionsOutput";
  ids?: Array<string | null> | null;
};

export type GetPatientTestingProfileMutation = {
  __typename: "GetPatientTestingProfileOutput";
  patientId?: string | null;
  groupQualifier?: string | null;
};

export type StripePaymentMethodMutation = {
  __typename: "StripePaymentMethodOutput";
  error?: string | null;
  items?: Array<{
    __typename: "StripePaymentMethodItem";
    paymentMethodId?: string | null;
    expiryMonth?: string | null;
    expiryYear?: string | null;
    brand?: string | null;
    last4Digits?: string | null;
  } | null> | null;
};

export type GetRecentEligibleAssessmentsMutation = {
  __typename: "GetRecentEligibleAssessmentsOutput";
  patientId?: string | null;
  recentAssessments?: Array<{
    __typename: "RecentEligibleAssessment";
    id?: string | null;
    testCode?: string | null;
    assessed?: string | null;
    scoreGauge?: ScoreGauge | null;
    score?: number | null;
  } | null> | null;
};

export type CreatePatientMutation = {
  __typename: "Patient";
  id: string;
  accountId: string;
  locationId: string;
  patientManagerId?: string | null;
  owner: string;
  salutation?: string | null;
  givenName: string;
  middleName?: string | null;
  familyName: string;
  address?: {
    __typename: "Address";
    line1?: string | null;
    line2?: string | null;
    region?: string | null;
    postal?: string | null;
    country?: string | null;
  } | null;
  ssn?: string | null;
  dob?: string | null;
  gender?: Gender | null;
  mobile?: string | null;
  email?: string | null;
  interests?: Array<string | null> | null;
  providerIds?: Array<string | null> | null;
  status?: Status | null;
  eligibleRPM?: boolean | null;
  contacts?: Array<{
    __typename: "Contact";
    id?: string | null;
    salutation?: Salutation | null;
    givenName: string;
    familyName: string;
    mobile?: string | null;
    email?: string | null;
    contactType?: ContactType | null;
  } | null> | null;
  insurance?: Array<{
    __typename: "Insurance";
    id?: string | null;
    memberId: string;
    groupId?: string | null;
    carrierId?: string | null;
    insuranceType?: InsuranceType | null;
  } | null> | null;
  qualifiedTests?: Array<string | null> | null;
  connectionId?: string | null;
  createdAt: string;
  updatedAt: string;
  providers?: {
    __typename: "ModelPatientProviderConnectionConnection";
    nextToken?: string | null;
  } | null;
  programs?: {
    __typename: "ModelPatientProgramConnectionConnection";
    nextToken?: string | null;
  } | null;
  eligibleTests?: {
    __typename: "ModelEligibilityConnectionConnection";
    nextToken?: string | null;
  } | null;
  manager?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  location?: {
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
    callerId?: string | null;
    testsCount?: number | null;
    usersCount?: number | null;
    physicianCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  lastAssessment?: {
    __typename: "Assessment";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    managerId: string;
    submitterId?: string | null;
    result?: string | null;
    testId?: string | null;
    testCode?: string | null;
    meta?: number | null;
    answerSheetId?: string | null;
    title?: string | null;
    type?: AssessmentType | null;
    assessed?: string | null;
    scheduledDate?: string | null;
    score?: number | null;
    scoreGauge?: ScoreGauge | null;
    status?: AssessmentStatus | null;
    assessmentEncounterId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  assessments?: {
    __typename: "ModelAssessmentConnection";
    nextToken?: string | null;
  } | null;
  treatmentPlan?: {
    __typename: "TreatmentPlan";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    treatmentPlanManagerId?: string | null;
    created: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  lastEncounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  diagnoses?: {
    __typename: "ModelDiagnosticCodeConnection";
    nextToken?: string | null;
  } | null;
};

export type UpdatePatientMutation = {
  __typename: "Patient";
  id: string;
  accountId: string;
  locationId: string;
  patientManagerId?: string | null;
  owner: string;
  salutation?: string | null;
  givenName: string;
  middleName?: string | null;
  familyName: string;
  address?: {
    __typename: "Address";
    line1?: string | null;
    line2?: string | null;
    region?: string | null;
    postal?: string | null;
    country?: string | null;
  } | null;
  ssn?: string | null;
  dob?: string | null;
  gender?: Gender | null;
  mobile?: string | null;
  email?: string | null;
  interests?: Array<string | null> | null;
  providerIds?: Array<string | null> | null;
  status?: Status | null;
  eligibleRPM?: boolean | null;
  contacts?: Array<{
    __typename: "Contact";
    id?: string | null;
    salutation?: Salutation | null;
    givenName: string;
    familyName: string;
    mobile?: string | null;
    email?: string | null;
    contactType?: ContactType | null;
  } | null> | null;
  insurance?: Array<{
    __typename: "Insurance";
    id?: string | null;
    memberId: string;
    groupId?: string | null;
    carrierId?: string | null;
    insuranceType?: InsuranceType | null;
  } | null> | null;
  qualifiedTests?: Array<string | null> | null;
  connectionId?: string | null;
  createdAt: string;
  updatedAt: string;
  providers?: {
    __typename: "ModelPatientProviderConnectionConnection";
    nextToken?: string | null;
  } | null;
  programs?: {
    __typename: "ModelPatientProgramConnectionConnection";
    nextToken?: string | null;
  } | null;
  eligibleTests?: {
    __typename: "ModelEligibilityConnectionConnection";
    nextToken?: string | null;
  } | null;
  manager?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  location?: {
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
    callerId?: string | null;
    testsCount?: number | null;
    usersCount?: number | null;
    physicianCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  lastAssessment?: {
    __typename: "Assessment";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    managerId: string;
    submitterId?: string | null;
    result?: string | null;
    testId?: string | null;
    testCode?: string | null;
    meta?: number | null;
    answerSheetId?: string | null;
    title?: string | null;
    type?: AssessmentType | null;
    assessed?: string | null;
    scheduledDate?: string | null;
    score?: number | null;
    scoreGauge?: ScoreGauge | null;
    status?: AssessmentStatus | null;
    assessmentEncounterId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  assessments?: {
    __typename: "ModelAssessmentConnection";
    nextToken?: string | null;
  } | null;
  treatmentPlan?: {
    __typename: "TreatmentPlan";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    treatmentPlanManagerId?: string | null;
    created: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  lastEncounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  diagnoses?: {
    __typename: "ModelDiagnosticCodeConnection";
    nextToken?: string | null;
  } | null;
};

export type DeletePatientMutation = {
  __typename: "Patient";
  id: string;
  accountId: string;
  locationId: string;
  patientManagerId?: string | null;
  owner: string;
  salutation?: string | null;
  givenName: string;
  middleName?: string | null;
  familyName: string;
  address?: {
    __typename: "Address";
    line1?: string | null;
    line2?: string | null;
    region?: string | null;
    postal?: string | null;
    country?: string | null;
  } | null;
  ssn?: string | null;
  dob?: string | null;
  gender?: Gender | null;
  mobile?: string | null;
  email?: string | null;
  interests?: Array<string | null> | null;
  providerIds?: Array<string | null> | null;
  status?: Status | null;
  eligibleRPM?: boolean | null;
  contacts?: Array<{
    __typename: "Contact";
    id?: string | null;
    salutation?: Salutation | null;
    givenName: string;
    familyName: string;
    mobile?: string | null;
    email?: string | null;
    contactType?: ContactType | null;
  } | null> | null;
  insurance?: Array<{
    __typename: "Insurance";
    id?: string | null;
    memberId: string;
    groupId?: string | null;
    carrierId?: string | null;
    insuranceType?: InsuranceType | null;
  } | null> | null;
  qualifiedTests?: Array<string | null> | null;
  connectionId?: string | null;
  createdAt: string;
  updatedAt: string;
  providers?: {
    __typename: "ModelPatientProviderConnectionConnection";
    nextToken?: string | null;
  } | null;
  programs?: {
    __typename: "ModelPatientProgramConnectionConnection";
    nextToken?: string | null;
  } | null;
  eligibleTests?: {
    __typename: "ModelEligibilityConnectionConnection";
    nextToken?: string | null;
  } | null;
  manager?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  location?: {
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
    callerId?: string | null;
    testsCount?: number | null;
    usersCount?: number | null;
    physicianCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  lastAssessment?: {
    __typename: "Assessment";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    managerId: string;
    submitterId?: string | null;
    result?: string | null;
    testId?: string | null;
    testCode?: string | null;
    meta?: number | null;
    answerSheetId?: string | null;
    title?: string | null;
    type?: AssessmentType | null;
    assessed?: string | null;
    scheduledDate?: string | null;
    score?: number | null;
    scoreGauge?: ScoreGauge | null;
    status?: AssessmentStatus | null;
    assessmentEncounterId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  assessments?: {
    __typename: "ModelAssessmentConnection";
    nextToken?: string | null;
  } | null;
  treatmentPlan?: {
    __typename: "TreatmentPlan";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    treatmentPlanManagerId?: string | null;
    created: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  lastEncounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  diagnoses?: {
    __typename: "ModelDiagnosticCodeConnection";
    nextToken?: string | null;
  } | null;
};

export type CreatePatientProviderConnectionMutation = {
  __typename: "PatientProviderConnection";
  id: string;
  accountId: string;
  patientId: string;
  providerId: string;
  type?: ProviderType | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  provider?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdatePatientProviderConnectionMutation = {
  __typename: "PatientProviderConnection";
  id: string;
  accountId: string;
  patientId: string;
  providerId: string;
  type?: ProviderType | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  provider?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeletePatientProviderConnectionMutation = {
  __typename: "PatientProviderConnection";
  id: string;
  accountId: string;
  patientId: string;
  providerId: string;
  type?: ProviderType | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  provider?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreatePatientProgramConnectionMutation = {
  __typename: "PatientProgramConnection";
  id?: string | null;
  accountId: string;
  patientId: string;
  programId: string;
  date?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  program?: {
    __typename: "Program";
    id: string;
    questionnaireId: string;
    name?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdatePatientProgramConnectionMutation = {
  __typename: "PatientProgramConnection";
  id?: string | null;
  accountId: string;
  patientId: string;
  programId: string;
  date?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  program?: {
    __typename: "Program";
    id: string;
    questionnaireId: string;
    name?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeletePatientProgramConnectionMutation = {
  __typename: "PatientProgramConnection";
  id?: string | null;
  accountId: string;
  patientId: string;
  programId: string;
  date?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  program?: {
    __typename: "Program";
    id: string;
    questionnaireId: string;
    name?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateProgramMutation = {
  __typename: "Program";
  id: string;
  questionnaireId: string;
  name?: string | null;
  createdAt: string;
  updatedAt: string;
  tests?: {
    __typename: "ModelProgramTestConnection";
    nextToken?: string | null;
  } | null;
  questionnaire?: {
    __typename: "Questionnaire";
    id: string;
    created?: string | null;
    name?: string | null;
    title?: string | null;
    template?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateProgramMutation = {
  __typename: "Program";
  id: string;
  questionnaireId: string;
  name?: string | null;
  createdAt: string;
  updatedAt: string;
  tests?: {
    __typename: "ModelProgramTestConnection";
    nextToken?: string | null;
  } | null;
  questionnaire?: {
    __typename: "Questionnaire";
    id: string;
    created?: string | null;
    name?: string | null;
    title?: string | null;
    template?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteProgramMutation = {
  __typename: "Program";
  id: string;
  questionnaireId: string;
  name?: string | null;
  createdAt: string;
  updatedAt: string;
  tests?: {
    __typename: "ModelProgramTestConnection";
    nextToken?: string | null;
  } | null;
  questionnaire?: {
    __typename: "Questionnaire";
    id: string;
    created?: string | null;
    name?: string | null;
    title?: string | null;
    template?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateProgramTestMutation = {
  __typename: "ProgramTest";
  id: string;
  name?: string | null;
  referenceTestId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateProgramTestMutation = {
  __typename: "ProgramTest";
  id: string;
  name?: string | null;
  referenceTestId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteProgramTestMutation = {
  __typename: "ProgramTest";
  id: string;
  name?: string | null;
  referenceTestId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateEligibilityConnectionMutation = {
  __typename: "EligibilityConnection";
  id?: string | null;
  patientId: string;
  programTestId: string;
  required?: boolean | null;
  date?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  programTest?: {
    __typename: "ProgramTest";
    id: string;
    name?: string | null;
    referenceTestId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateEligibilityConnectionMutation = {
  __typename: "EligibilityConnection";
  id?: string | null;
  patientId: string;
  programTestId: string;
  required?: boolean | null;
  date?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  programTest?: {
    __typename: "ProgramTest";
    id: string;
    name?: string | null;
    referenceTestId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteEligibilityConnectionMutation = {
  __typename: "EligibilityConnection";
  id?: string | null;
  patientId: string;
  programTestId: string;
  required?: boolean | null;
  date?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  programTest?: {
    __typename: "ProgramTest";
    id: string;
    name?: string | null;
    referenceTestId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateUserMutation = {
  __typename: "User";
  id: string;
  accountId: string;
  owner?: string | null;
  email: string;
  phone?: string | null;
  online?: boolean | null;
  firstName?: string | null;
  lastName?: string | null;
  titles?: Array<string | null> | null;
  npi?: string | null;
  image?: string | null;
  userType?: UserType | null;
  connections?: Array<{
    __typename: "WSConnection";
    connectionId?: string | null;
    startedAt?: string | null;
  } | null> | null;
  onlineStatus?: UserOnlineStatus | null;
  lastLogin?: {
    __typename: "LoginEvent";
    ip?: string | null;
    date: string;
  } | null;
  pinCode?: string | null;
  permissions?: Array<AccessPermission | null> | null;
  createdAt: string;
  updatedAt: string;
  locations?: {
    __typename: "ModelUserLocationConnectionConnection";
    nextToken?: string | null;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateUserMutation = {
  __typename: "User";
  id: string;
  accountId: string;
  owner?: string | null;
  email: string;
  phone?: string | null;
  online?: boolean | null;
  firstName?: string | null;
  lastName?: string | null;
  titles?: Array<string | null> | null;
  npi?: string | null;
  image?: string | null;
  userType?: UserType | null;
  connections?: Array<{
    __typename: "WSConnection";
    connectionId?: string | null;
    startedAt?: string | null;
  } | null> | null;
  onlineStatus?: UserOnlineStatus | null;
  lastLogin?: {
    __typename: "LoginEvent";
    ip?: string | null;
    date: string;
  } | null;
  pinCode?: string | null;
  permissions?: Array<AccessPermission | null> | null;
  createdAt: string;
  updatedAt: string;
  locations?: {
    __typename: "ModelUserLocationConnectionConnection";
    nextToken?: string | null;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteUserMutation = {
  __typename: "User";
  id: string;
  accountId: string;
  owner?: string | null;
  email: string;
  phone?: string | null;
  online?: boolean | null;
  firstName?: string | null;
  lastName?: string | null;
  titles?: Array<string | null> | null;
  npi?: string | null;
  image?: string | null;
  userType?: UserType | null;
  connections?: Array<{
    __typename: "WSConnection";
    connectionId?: string | null;
    startedAt?: string | null;
  } | null> | null;
  onlineStatus?: UserOnlineStatus | null;
  lastLogin?: {
    __typename: "LoginEvent";
    ip?: string | null;
    date: string;
  } | null;
  pinCode?: string | null;
  permissions?: Array<AccessPermission | null> | null;
  createdAt: string;
  updatedAt: string;
  locations?: {
    __typename: "ModelUserLocationConnectionConnection";
    nextToken?: string | null;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateUserLocationConnectionMutation = {
  __typename: "UserLocationConnection";
  id: string;
  accountId: string;
  userId: string;
  locationId: string;
  createdAt: string;
  updatedAt: string;
  user?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  location?: {
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
    callerId?: string | null;
    testsCount?: number | null;
    usersCount?: number | null;
    physicianCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateUserLocationConnectionMutation = {
  __typename: "UserLocationConnection";
  id: string;
  accountId: string;
  userId: string;
  locationId: string;
  createdAt: string;
  updatedAt: string;
  user?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  location?: {
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
    callerId?: string | null;
    testsCount?: number | null;
    usersCount?: number | null;
    physicianCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteUserLocationConnectionMutation = {
  __typename: "UserLocationConnection";
  id: string;
  accountId: string;
  userId: string;
  locationId: string;
  createdAt: string;
  updatedAt: string;
  user?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  location?: {
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
    callerId?: string | null;
    testsCount?: number | null;
    usersCount?: number | null;
    physicianCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateAccountMutation = {
  __typename: "Account";
  id: string;
  name: string;
  owner?: string | null;
  stripeCustomerId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateAccountMutation = {
  __typename: "Account";
  id: string;
  name: string;
  owner?: string | null;
  stripeCustomerId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteAccountMutation = {
  __typename: "Account";
  id: string;
  name: string;
  owner?: string | null;
  stripeCustomerId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateLocationMutation = {
  __typename: "Location";
  id: string;
  owner?: string | null;
  accountId: string;
  name: string;
  address?: {
    __typename: "Address";
    line1?: string | null;
    line2?: string | null;
    region?: string | null;
    postal?: string | null;
    country?: string | null;
  } | null;
  callerId?: string | null;
  testsCount?: number | null;
  usersCount?: number | null;
  physicianCount?: number | null;
  frontDesk?: {
    __typename: "Contact";
    id?: string | null;
    salutation?: Salutation | null;
    givenName: string;
    familyName: string;
    mobile?: string | null;
    email?: string | null;
    contactType?: ContactType | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  lastLoginUser?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  users?: {
    __typename: "ModelUserLocationConnectionConnection";
    nextToken?: string | null;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  lastAssessment?: {
    __typename: "Assessment";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    managerId: string;
    submitterId?: string | null;
    result?: string | null;
    testId?: string | null;
    testCode?: string | null;
    meta?: number | null;
    answerSheetId?: string | null;
    title?: string | null;
    type?: AssessmentType | null;
    assessed?: string | null;
    scheduledDate?: string | null;
    score?: number | null;
    scoreGauge?: ScoreGauge | null;
    status?: AssessmentStatus | null;
    assessmentEncounterId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  lastPayment?: {
    __typename: "Payment";
    id: string;
    accountId: string;
    locationId: string;
    userId: string;
    stripePaymentMethodId: string;
    paymentDate: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateLocationMutation = {
  __typename: "Location";
  id: string;
  owner?: string | null;
  accountId: string;
  name: string;
  address?: {
    __typename: "Address";
    line1?: string | null;
    line2?: string | null;
    region?: string | null;
    postal?: string | null;
    country?: string | null;
  } | null;
  callerId?: string | null;
  testsCount?: number | null;
  usersCount?: number | null;
  physicianCount?: number | null;
  frontDesk?: {
    __typename: "Contact";
    id?: string | null;
    salutation?: Salutation | null;
    givenName: string;
    familyName: string;
    mobile?: string | null;
    email?: string | null;
    contactType?: ContactType | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  lastLoginUser?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  users?: {
    __typename: "ModelUserLocationConnectionConnection";
    nextToken?: string | null;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  lastAssessment?: {
    __typename: "Assessment";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    managerId: string;
    submitterId?: string | null;
    result?: string | null;
    testId?: string | null;
    testCode?: string | null;
    meta?: number | null;
    answerSheetId?: string | null;
    title?: string | null;
    type?: AssessmentType | null;
    assessed?: string | null;
    scheduledDate?: string | null;
    score?: number | null;
    scoreGauge?: ScoreGauge | null;
    status?: AssessmentStatus | null;
    assessmentEncounterId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  lastPayment?: {
    __typename: "Payment";
    id: string;
    accountId: string;
    locationId: string;
    userId: string;
    stripePaymentMethodId: string;
    paymentDate: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteLocationMutation = {
  __typename: "Location";
  id: string;
  owner?: string | null;
  accountId: string;
  name: string;
  address?: {
    __typename: "Address";
    line1?: string | null;
    line2?: string | null;
    region?: string | null;
    postal?: string | null;
    country?: string | null;
  } | null;
  callerId?: string | null;
  testsCount?: number | null;
  usersCount?: number | null;
  physicianCount?: number | null;
  frontDesk?: {
    __typename: "Contact";
    id?: string | null;
    salutation?: Salutation | null;
    givenName: string;
    familyName: string;
    mobile?: string | null;
    email?: string | null;
    contactType?: ContactType | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  lastLoginUser?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  users?: {
    __typename: "ModelUserLocationConnectionConnection";
    nextToken?: string | null;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  lastAssessment?: {
    __typename: "Assessment";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    managerId: string;
    submitterId?: string | null;
    result?: string | null;
    testId?: string | null;
    testCode?: string | null;
    meta?: number | null;
    answerSheetId?: string | null;
    title?: string | null;
    type?: AssessmentType | null;
    assessed?: string | null;
    scheduledDate?: string | null;
    score?: number | null;
    scoreGauge?: ScoreGauge | null;
    status?: AssessmentStatus | null;
    assessmentEncounterId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  lastPayment?: {
    __typename: "Payment";
    id: string;
    accountId: string;
    locationId: string;
    userId: string;
    stripePaymentMethodId: string;
    paymentDate: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateBilledTimeMutation = {
  __typename: "BilledTime";
  id: string;
  minutes?: number | null;
  type?: BilledTimeType | null;
  date?: string | null;
  userId: string;
  createdAt: string;
  updatedAt: string;
  user?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateBilledTimeMutation = {
  __typename: "BilledTime";
  id: string;
  minutes?: number | null;
  type?: BilledTimeType | null;
  date?: string | null;
  userId: string;
  createdAt: string;
  updatedAt: string;
  user?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteBilledTimeMutation = {
  __typename: "BilledTime";
  id: string;
  minutes?: number | null;
  type?: BilledTimeType | null;
  date?: string | null;
  userId: string;
  createdAt: string;
  updatedAt: string;
  user?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateEncounterBilledTimeConnectionMutation = {
  __typename: "EncounterBilledTimeConnection";
  id?: string | null;
  accountId: string;
  encounterId: string;
  billedTimeId: string;
  createdAt: string;
  updatedAt: string;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  billedTime?: {
    __typename: "BilledTime";
    id: string;
    minutes?: number | null;
    type?: BilledTimeType | null;
    date?: string | null;
    userId: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateEncounterBilledTimeConnectionMutation = {
  __typename: "EncounterBilledTimeConnection";
  id?: string | null;
  accountId: string;
  encounterId: string;
  billedTimeId: string;
  createdAt: string;
  updatedAt: string;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  billedTime?: {
    __typename: "BilledTime";
    id: string;
    minutes?: number | null;
    type?: BilledTimeType | null;
    date?: string | null;
    userId: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteEncounterBilledTimeConnectionMutation = {
  __typename: "EncounterBilledTimeConnection";
  id?: string | null;
  accountId: string;
  encounterId: string;
  billedTimeId: string;
  createdAt: string;
  updatedAt: string;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  billedTime?: {
    __typename: "BilledTime";
    id: string;
    minutes?: number | null;
    type?: BilledTimeType | null;
    date?: string | null;
    userId: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateAssessmentBilledTimeConnectionMutation = {
  __typename: "AssessmentBilledTimeConnection";
  id: string;
  accountId: string;
  assessmentId: string;
  billedTimeId: string;
  createdAt: string;
  updatedAt: string;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  billedTime?: {
    __typename: "BilledTime";
    id: string;
    minutes?: number | null;
    type?: BilledTimeType | null;
    date?: string | null;
    userId: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  assessment?: {
    __typename: "Assessment";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    managerId: string;
    submitterId?: string | null;
    result?: string | null;
    testId?: string | null;
    testCode?: string | null;
    meta?: number | null;
    answerSheetId?: string | null;
    title?: string | null;
    type?: AssessmentType | null;
    assessed?: string | null;
    scheduledDate?: string | null;
    score?: number | null;
    scoreGauge?: ScoreGauge | null;
    status?: AssessmentStatus | null;
    assessmentEncounterId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateAssessmentBilledTimeConnectionMutation = {
  __typename: "AssessmentBilledTimeConnection";
  id: string;
  accountId: string;
  assessmentId: string;
  billedTimeId: string;
  createdAt: string;
  updatedAt: string;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  billedTime?: {
    __typename: "BilledTime";
    id: string;
    minutes?: number | null;
    type?: BilledTimeType | null;
    date?: string | null;
    userId: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  assessment?: {
    __typename: "Assessment";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    managerId: string;
    submitterId?: string | null;
    result?: string | null;
    testId?: string | null;
    testCode?: string | null;
    meta?: number | null;
    answerSheetId?: string | null;
    title?: string | null;
    type?: AssessmentType | null;
    assessed?: string | null;
    scheduledDate?: string | null;
    score?: number | null;
    scoreGauge?: ScoreGauge | null;
    status?: AssessmentStatus | null;
    assessmentEncounterId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteAssessmentBilledTimeConnectionMutation = {
  __typename: "AssessmentBilledTimeConnection";
  id: string;
  accountId: string;
  assessmentId: string;
  billedTimeId: string;
  createdAt: string;
  updatedAt: string;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  billedTime?: {
    __typename: "BilledTime";
    id: string;
    minutes?: number | null;
    type?: BilledTimeType | null;
    date?: string | null;
    userId: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  assessment?: {
    __typename: "Assessment";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    managerId: string;
    submitterId?: string | null;
    result?: string | null;
    testId?: string | null;
    testCode?: string | null;
    meta?: number | null;
    answerSheetId?: string | null;
    title?: string | null;
    type?: AssessmentType | null;
    assessed?: string | null;
    scheduledDate?: string | null;
    score?: number | null;
    scoreGauge?: ScoreGauge | null;
    status?: AssessmentStatus | null;
    assessmentEncounterId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateAssessmentMutation = {
  __typename: "Assessment";
  id: string;
  accountId: string;
  locationId: string;
  patientId: string;
  providers?: Array<string | null> | null;
  managerId: string;
  submitterId?: string | null;
  result?: string | null;
  testId?: string | null;
  testCode?: string | null;
  meta?: number | null;
  answerSheetId?: string | null;
  title?: string | null;
  type?: AssessmentType | null;
  assessed?: string | null;
  scheduledDate?: string | null;
  score?: number | null;
  scoreGauge?: ScoreGauge | null;
  status?: AssessmentStatus | null;
  assessmentEncounterId?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  manager?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  location?: {
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
    callerId?: string | null;
    testsCount?: number | null;
    usersCount?: number | null;
    physicianCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  billedTime?: {
    __typename: "BilledTime";
    id: string;
    minutes?: number | null;
    type?: BilledTimeType | null;
    date?: string | null;
    userId: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateAssessmentMutation = {
  __typename: "Assessment";
  id: string;
  accountId: string;
  locationId: string;
  patientId: string;
  providers?: Array<string | null> | null;
  managerId: string;
  submitterId?: string | null;
  result?: string | null;
  testId?: string | null;
  testCode?: string | null;
  meta?: number | null;
  answerSheetId?: string | null;
  title?: string | null;
  type?: AssessmentType | null;
  assessed?: string | null;
  scheduledDate?: string | null;
  score?: number | null;
  scoreGauge?: ScoreGauge | null;
  status?: AssessmentStatus | null;
  assessmentEncounterId?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  manager?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  location?: {
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
    callerId?: string | null;
    testsCount?: number | null;
    usersCount?: number | null;
    physicianCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  billedTime?: {
    __typename: "BilledTime";
    id: string;
    minutes?: number | null;
    type?: BilledTimeType | null;
    date?: string | null;
    userId: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteAssessmentMutation = {
  __typename: "Assessment";
  id: string;
  accountId: string;
  locationId: string;
  patientId: string;
  providers?: Array<string | null> | null;
  managerId: string;
  submitterId?: string | null;
  result?: string | null;
  testId?: string | null;
  testCode?: string | null;
  meta?: number | null;
  answerSheetId?: string | null;
  title?: string | null;
  type?: AssessmentType | null;
  assessed?: string | null;
  scheduledDate?: string | null;
  score?: number | null;
  scoreGauge?: ScoreGauge | null;
  status?: AssessmentStatus | null;
  assessmentEncounterId?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  manager?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  location?: {
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
    callerId?: string | null;
    testsCount?: number | null;
    usersCount?: number | null;
    physicianCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  billedTime?: {
    __typename: "BilledTime";
    id: string;
    minutes?: number | null;
    type?: BilledTimeType | null;
    date?: string | null;
    userId: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateTreatmentPlanMutation = {
  __typename: "TreatmentPlan";
  id: string;
  accountId: string;
  locationId: string;
  patientId: string;
  providers?: Array<string | null> | null;
  treatmentPlanManagerId?: string | null;
  created: string;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  location?: {
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
    callerId?: string | null;
    testsCount?: number | null;
    usersCount?: number | null;
    physicianCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  resourceEvents?: {
    __typename: "ModelPatientResourceScheduledEventConnectionConnection";
    nextToken?: string | null;
  } | null;
};

export type UpdateTreatmentPlanMutation = {
  __typename: "TreatmentPlan";
  id: string;
  accountId: string;
  locationId: string;
  patientId: string;
  providers?: Array<string | null> | null;
  treatmentPlanManagerId?: string | null;
  created: string;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  location?: {
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
    callerId?: string | null;
    testsCount?: number | null;
    usersCount?: number | null;
    physicianCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  resourceEvents?: {
    __typename: "ModelPatientResourceScheduledEventConnectionConnection";
    nextToken?: string | null;
  } | null;
};

export type DeleteTreatmentPlanMutation = {
  __typename: "TreatmentPlan";
  id: string;
  accountId: string;
  locationId: string;
  patientId: string;
  providers?: Array<string | null> | null;
  treatmentPlanManagerId?: string | null;
  created: string;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  location?: {
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
    callerId?: string | null;
    testsCount?: number | null;
    usersCount?: number | null;
    physicianCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  resourceEvents?: {
    __typename: "ModelPatientResourceScheduledEventConnectionConnection";
    nextToken?: string | null;
  } | null;
};

export type CreatePatientResourceScheduledEventConnectionMutation = {
  __typename: "PatientResourceScheduledEventConnection";
  id: string;
  accountId: string;
  patientId: string;
  encounterId: string;
  resourceScheduledEventId: string;
  created?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  resourceScheduledEvent?: {
    __typename: "ResourceScheduledEvent";
    id: string;
    accountId: string;
    ownerId: string;
    locationId: string;
    title: string;
    start: string;
    end: string;
    startTimezone?: string | null;
    endTimezone?: string | null;
    isAllDay?: boolean | null;
    description?: string | null;
    recurrenceRule?: string | null;
    recurrenceExceptions?: string | null;
    recurrenceId?: string | null;
    resourceId: string;
    category: ResourceCategory;
    cost?: number | null;
    ageGroup?: AgeGroup | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdatePatientResourceScheduledEventConnectionMutation = {
  __typename: "PatientResourceScheduledEventConnection";
  id: string;
  accountId: string;
  patientId: string;
  encounterId: string;
  resourceScheduledEventId: string;
  created?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  resourceScheduledEvent?: {
    __typename: "ResourceScheduledEvent";
    id: string;
    accountId: string;
    ownerId: string;
    locationId: string;
    title: string;
    start: string;
    end: string;
    startTimezone?: string | null;
    endTimezone?: string | null;
    isAllDay?: boolean | null;
    description?: string | null;
    recurrenceRule?: string | null;
    recurrenceExceptions?: string | null;
    recurrenceId?: string | null;
    resourceId: string;
    category: ResourceCategory;
    cost?: number | null;
    ageGroup?: AgeGroup | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeletePatientResourceScheduledEventConnectionMutation = {
  __typename: "PatientResourceScheduledEventConnection";
  id: string;
  accountId: string;
  patientId: string;
  encounterId: string;
  resourceScheduledEventId: string;
  created?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  resourceScheduledEvent?: {
    __typename: "ResourceScheduledEvent";
    id: string;
    accountId: string;
    ownerId: string;
    locationId: string;
    title: string;
    start: string;
    end: string;
    startTimezone?: string | null;
    endTimezone?: string | null;
    isAllDay?: boolean | null;
    description?: string | null;
    recurrenceRule?: string | null;
    recurrenceExceptions?: string | null;
    recurrenceId?: string | null;
    resourceId: string;
    category: ResourceCategory;
    cost?: number | null;
    ageGroup?: AgeGroup | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateTelehealthSessionMutation = {
  __typename: "TelehealthSession";
  id: string;
  title: string;
  accountId: string;
  participantIds: Array<string | null>;
  participants: Array<{
    __typename: "TelehealthSessionParticipant";
    name: string;
    type?: UserType | null;
    identity: string;
  } | null>;
  status?: TelehealthSessionStatus | null;
  roomType?: RoomType | null;
  startedAt?: string | null;
  endedAt?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateTelehealthSessionMutation = {
  __typename: "TelehealthSession";
  id: string;
  title: string;
  accountId: string;
  participantIds: Array<string | null>;
  participants: Array<{
    __typename: "TelehealthSessionParticipant";
    name: string;
    type?: UserType | null;
    identity: string;
  } | null>;
  status?: TelehealthSessionStatus | null;
  roomType?: RoomType | null;
  startedAt?: string | null;
  endedAt?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteTelehealthSessionMutation = {
  __typename: "TelehealthSession";
  id: string;
  title: string;
  accountId: string;
  participantIds: Array<string | null>;
  participants: Array<{
    __typename: "TelehealthSessionParticipant";
    name: string;
    type?: UserType | null;
    identity: string;
  } | null>;
  status?: TelehealthSessionStatus | null;
  roomType?: RoomType | null;
  startedAt?: string | null;
  endedAt?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateScheduledEventMutation = {
  __typename: "ScheduledEvent";
  id: string;
  accountId: string;
  ownerId: string;
  invitees: Array<{
    __typename: "ScheduledEventInvitee";
    name: string;
    type?: UserType | null;
    id?: string | null;
  } | null>;
  participantIds: Array<string | null>;
  title: string;
  start: string;
  end: string;
  type: ScheduledEventType;
  assessments?: Array<{
    __typename: "ScheduledEventAssessment";
    id?: string | null;
    name?: string | null;
  } | null> | null;
  startTimezone?: string | null;
  endTimezone?: string | null;
  isAllDay?: boolean | null;
  description?: string | null;
  recurrenceRule?: string | null;
  recurrenceExceptions?: string | null;
  recurrenceId?: string | null;
  createdAt: string;
  updatedAt: string;
  resource?: {
    __typename: "ResourceScheduledEvent";
    id: string;
    accountId: string;
    ownerId: string;
    locationId: string;
    title: string;
    start: string;
    end: string;
    startTimezone?: string | null;
    endTimezone?: string | null;
    isAllDay?: boolean | null;
    description?: string | null;
    recurrenceRule?: string | null;
    recurrenceExceptions?: string | null;
    recurrenceId?: string | null;
    resourceId: string;
    category: ResourceCategory;
    cost?: number | null;
    ageGroup?: AgeGroup | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateScheduledEventMutation = {
  __typename: "ScheduledEvent";
  id: string;
  accountId: string;
  ownerId: string;
  invitees: Array<{
    __typename: "ScheduledEventInvitee";
    name: string;
    type?: UserType | null;
    id?: string | null;
  } | null>;
  participantIds: Array<string | null>;
  title: string;
  start: string;
  end: string;
  type: ScheduledEventType;
  assessments?: Array<{
    __typename: "ScheduledEventAssessment";
    id?: string | null;
    name?: string | null;
  } | null> | null;
  startTimezone?: string | null;
  endTimezone?: string | null;
  isAllDay?: boolean | null;
  description?: string | null;
  recurrenceRule?: string | null;
  recurrenceExceptions?: string | null;
  recurrenceId?: string | null;
  createdAt: string;
  updatedAt: string;
  resource?: {
    __typename: "ResourceScheduledEvent";
    id: string;
    accountId: string;
    ownerId: string;
    locationId: string;
    title: string;
    start: string;
    end: string;
    startTimezone?: string | null;
    endTimezone?: string | null;
    isAllDay?: boolean | null;
    description?: string | null;
    recurrenceRule?: string | null;
    recurrenceExceptions?: string | null;
    recurrenceId?: string | null;
    resourceId: string;
    category: ResourceCategory;
    cost?: number | null;
    ageGroup?: AgeGroup | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteScheduledEventMutation = {
  __typename: "ScheduledEvent";
  id: string;
  accountId: string;
  ownerId: string;
  invitees: Array<{
    __typename: "ScheduledEventInvitee";
    name: string;
    type?: UserType | null;
    id?: string | null;
  } | null>;
  participantIds: Array<string | null>;
  title: string;
  start: string;
  end: string;
  type: ScheduledEventType;
  assessments?: Array<{
    __typename: "ScheduledEventAssessment";
    id?: string | null;
    name?: string | null;
  } | null> | null;
  startTimezone?: string | null;
  endTimezone?: string | null;
  isAllDay?: boolean | null;
  description?: string | null;
  recurrenceRule?: string | null;
  recurrenceExceptions?: string | null;
  recurrenceId?: string | null;
  createdAt: string;
  updatedAt: string;
  resource?: {
    __typename: "ResourceScheduledEvent";
    id: string;
    accountId: string;
    ownerId: string;
    locationId: string;
    title: string;
    start: string;
    end: string;
    startTimezone?: string | null;
    endTimezone?: string | null;
    isAllDay?: boolean | null;
    description?: string | null;
    recurrenceRule?: string | null;
    recurrenceExceptions?: string | null;
    recurrenceId?: string | null;
    resourceId: string;
    category: ResourceCategory;
    cost?: number | null;
    ageGroup?: AgeGroup | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateResourceScheduledEventMutation = {
  __typename: "ResourceScheduledEvent";
  id: string;
  accountId: string;
  ownerId: string;
  locationId: string;
  title: string;
  start: string;
  end: string;
  startTimezone?: string | null;
  endTimezone?: string | null;
  isAllDay?: boolean | null;
  description?: string | null;
  recurrenceRule?: string | null;
  recurrenceExceptions?: string | null;
  recurrenceId?: string | null;
  resourceId: string;
  category: ResourceCategory;
  cost?: number | null;
  ageGroup?: AgeGroup | null;
  createdAt: string;
  updatedAt: string;
  resource?: {
    __typename: "EventResource";
    id: string;
    ownerId?: string | null;
    accountId?: string | null;
    locationId?: string | null;
    name?: string | null;
    type?: ResourceType | null;
    classification?: EventResourceClassification | null;
    website?: string | null;
    businessHours?: string | null;
    billedTime?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateResourceScheduledEventMutation = {
  __typename: "ResourceScheduledEvent";
  id: string;
  accountId: string;
  ownerId: string;
  locationId: string;
  title: string;
  start: string;
  end: string;
  startTimezone?: string | null;
  endTimezone?: string | null;
  isAllDay?: boolean | null;
  description?: string | null;
  recurrenceRule?: string | null;
  recurrenceExceptions?: string | null;
  recurrenceId?: string | null;
  resourceId: string;
  category: ResourceCategory;
  cost?: number | null;
  ageGroup?: AgeGroup | null;
  createdAt: string;
  updatedAt: string;
  resource?: {
    __typename: "EventResource";
    id: string;
    ownerId?: string | null;
    accountId?: string | null;
    locationId?: string | null;
    name?: string | null;
    type?: ResourceType | null;
    classification?: EventResourceClassification | null;
    website?: string | null;
    businessHours?: string | null;
    billedTime?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteResourceScheduledEventMutation = {
  __typename: "ResourceScheduledEvent";
  id: string;
  accountId: string;
  ownerId: string;
  locationId: string;
  title: string;
  start: string;
  end: string;
  startTimezone?: string | null;
  endTimezone?: string | null;
  isAllDay?: boolean | null;
  description?: string | null;
  recurrenceRule?: string | null;
  recurrenceExceptions?: string | null;
  recurrenceId?: string | null;
  resourceId: string;
  category: ResourceCategory;
  cost?: number | null;
  ageGroup?: AgeGroup | null;
  createdAt: string;
  updatedAt: string;
  resource?: {
    __typename: "EventResource";
    id: string;
    ownerId?: string | null;
    accountId?: string | null;
    locationId?: string | null;
    name?: string | null;
    type?: ResourceType | null;
    classification?: EventResourceClassification | null;
    website?: string | null;
    businessHours?: string | null;
    billedTime?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateSessionRecordingMutation = {
  __typename: "SessionRecording";
  id?: string | null;
  codec?: string | null;
  container?: string | null;
  offset?: string | null;
  sourceSid?: string | null;
  twilioMediaUri?: string | null;
  roomSid?: string | null;
  eventId?: string | null;
  ownerId: string;
  sessionId: string;
  s3Url?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateSessionRecordingMutation = {
  __typename: "SessionRecording";
  id?: string | null;
  codec?: string | null;
  container?: string | null;
  offset?: string | null;
  sourceSid?: string | null;
  twilioMediaUri?: string | null;
  roomSid?: string | null;
  eventId?: string | null;
  ownerId: string;
  sessionId: string;
  s3Url?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteSessionRecordingMutation = {
  __typename: "SessionRecording";
  id?: string | null;
  codec?: string | null;
  container?: string | null;
  offset?: string | null;
  sourceSid?: string | null;
  twilioMediaUri?: string | null;
  roomSid?: string | null;
  eventId?: string | null;
  ownerId: string;
  sessionId: string;
  s3Url?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateEventResourceMutation = {
  __typename: "EventResource";
  id: string;
  ownerId?: string | null;
  accountId?: string | null;
  locationId?: string | null;
  name?: string | null;
  type?: ResourceType | null;
  classification?: EventResourceClassification | null;
  website?: string | null;
  contact?: {
    __typename: "Contact";
    id?: string | null;
    salutation?: Salutation | null;
    givenName: string;
    familyName: string;
    mobile?: string | null;
    email?: string | null;
    contactType?: ContactType | null;
  } | null;
  address?: {
    __typename: "Address";
    line1?: string | null;
    line2?: string | null;
    region?: string | null;
    postal?: string | null;
    country?: string | null;
  } | null;
  businessHours?: string | null;
  billedTime?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateEventResourceMutation = {
  __typename: "EventResource";
  id: string;
  ownerId?: string | null;
  accountId?: string | null;
  locationId?: string | null;
  name?: string | null;
  type?: ResourceType | null;
  classification?: EventResourceClassification | null;
  website?: string | null;
  contact?: {
    __typename: "Contact";
    id?: string | null;
    salutation?: Salutation | null;
    givenName: string;
    familyName: string;
    mobile?: string | null;
    email?: string | null;
    contactType?: ContactType | null;
  } | null;
  address?: {
    __typename: "Address";
    line1?: string | null;
    line2?: string | null;
    region?: string | null;
    postal?: string | null;
    country?: string | null;
  } | null;
  businessHours?: string | null;
  billedTime?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteEventResourceMutation = {
  __typename: "EventResource";
  id: string;
  ownerId?: string | null;
  accountId?: string | null;
  locationId?: string | null;
  name?: string | null;
  type?: ResourceType | null;
  classification?: EventResourceClassification | null;
  website?: string | null;
  contact?: {
    __typename: "Contact";
    id?: string | null;
    salutation?: Salutation | null;
    givenName: string;
    familyName: string;
    mobile?: string | null;
    email?: string | null;
    contactType?: ContactType | null;
  } | null;
  address?: {
    __typename: "Address";
    line1?: string | null;
    line2?: string | null;
    region?: string | null;
    postal?: string | null;
    country?: string | null;
  } | null;
  businessHours?: string | null;
  billedTime?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateQuestionnaireMutation = {
  __typename: "Questionnaire";
  id: string;
  created?: string | null;
  name?: string | null;
  title?: string | null;
  template?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateQuestionnaireMutation = {
  __typename: "Questionnaire";
  id: string;
  created?: string | null;
  name?: string | null;
  title?: string | null;
  template?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteQuestionnaireMutation = {
  __typename: "Questionnaire";
  id: string;
  created?: string | null;
  name?: string | null;
  title?: string | null;
  template?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateQuestionnaireResponseAuthorMutation = {
  __typename: "QuestionnaireResponseAuthor";
  id: string;
  accountId: string;
  responseId: string;
  authorId: string;
  createdAt: string;
  updatedAt: string;
  author?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  questionnaireResponse?: {
    __typename: "QuestionnaireResponse";
    id: string;
    accountId: string;
    patientId: string;
    encounterId: string;
    contributors?: string | null;
    created?: string | null;
    name?: string | null;
    title?: string | null;
    status?: QuestionnaireStatus | null;
    json?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateQuestionnaireResponseAuthorMutation = {
  __typename: "QuestionnaireResponseAuthor";
  id: string;
  accountId: string;
  responseId: string;
  authorId: string;
  createdAt: string;
  updatedAt: string;
  author?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  questionnaireResponse?: {
    __typename: "QuestionnaireResponse";
    id: string;
    accountId: string;
    patientId: string;
    encounterId: string;
    contributors?: string | null;
    created?: string | null;
    name?: string | null;
    title?: string | null;
    status?: QuestionnaireStatus | null;
    json?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteQuestionnaireResponseAuthorMutation = {
  __typename: "QuestionnaireResponseAuthor";
  id: string;
  accountId: string;
  responseId: string;
  authorId: string;
  createdAt: string;
  updatedAt: string;
  author?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  questionnaireResponse?: {
    __typename: "QuestionnaireResponse";
    id: string;
    accountId: string;
    patientId: string;
    encounterId: string;
    contributors?: string | null;
    created?: string | null;
    name?: string | null;
    title?: string | null;
    status?: QuestionnaireStatus | null;
    json?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateQuestionnaireResponseMutation = {
  __typename: "QuestionnaireResponse";
  id: string;
  accountId: string;
  patientId: string;
  encounterId: string;
  contributors?: string | null;
  created?: string | null;
  name?: string | null;
  title?: string | null;
  status?: QuestionnaireStatus | null;
  json?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  authors?: {
    __typename: "ModelQuestionnaireResponseAuthorConnection";
    nextToken?: string | null;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateQuestionnaireResponseMutation = {
  __typename: "QuestionnaireResponse";
  id: string;
  accountId: string;
  patientId: string;
  encounterId: string;
  contributors?: string | null;
  created?: string | null;
  name?: string | null;
  title?: string | null;
  status?: QuestionnaireStatus | null;
  json?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  authors?: {
    __typename: "ModelQuestionnaireResponseAuthorConnection";
    nextToken?: string | null;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteQuestionnaireResponseMutation = {
  __typename: "QuestionnaireResponse";
  id: string;
  accountId: string;
  patientId: string;
  encounterId: string;
  contributors?: string | null;
  created?: string | null;
  name?: string | null;
  title?: string | null;
  status?: QuestionnaireStatus | null;
  json?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  authors?: {
    __typename: "ModelQuestionnaireResponseAuthorConnection";
    nextToken?: string | null;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateClinicalImpressionMutation = {
  __typename: "ClinicalImpression";
  id?: string | null;
  accountId: string;
  patientId: string;
  encounterId: string;
  authorId?: string | null;
  created?: string | null;
  title?: string | null;
  status?: ClinicalImpressionStatus | null;
  summary?: string | null;
  name?: string | null;
  signature?: string | null;
  approved?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  questionnaireResponse?: {
    __typename: "QuestionnaireResponse";
    id: string;
    accountId: string;
    patientId: string;
    encounterId: string;
    contributors?: string | null;
    created?: string | null;
    name?: string | null;
    title?: string | null;
    status?: QuestionnaireStatus | null;
    json?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateClinicalImpressionMutation = {
  __typename: "ClinicalImpression";
  id?: string | null;
  accountId: string;
  patientId: string;
  encounterId: string;
  authorId?: string | null;
  created?: string | null;
  title?: string | null;
  status?: ClinicalImpressionStatus | null;
  summary?: string | null;
  name?: string | null;
  signature?: string | null;
  approved?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  questionnaireResponse?: {
    __typename: "QuestionnaireResponse";
    id: string;
    accountId: string;
    patientId: string;
    encounterId: string;
    contributors?: string | null;
    created?: string | null;
    name?: string | null;
    title?: string | null;
    status?: QuestionnaireStatus | null;
    json?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteClinicalImpressionMutation = {
  __typename: "ClinicalImpression";
  id?: string | null;
  accountId: string;
  patientId: string;
  encounterId: string;
  authorId?: string | null;
  created?: string | null;
  title?: string | null;
  status?: ClinicalImpressionStatus | null;
  summary?: string | null;
  name?: string | null;
  signature?: string | null;
  approved?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  questionnaireResponse?: {
    __typename: "QuestionnaireResponse";
    id: string;
    accountId: string;
    patientId: string;
    encounterId: string;
    contributors?: string | null;
    created?: string | null;
    name?: string | null;
    title?: string | null;
    status?: QuestionnaireStatus | null;
    json?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateEncounterMutation = {
  __typename: "Encounter";
  id: string;
  accountId: string;
  locationId: string;
  managerId: string;
  patientId: string;
  ownerId: string;
  status?: EncounterStatus | null;
  date: string;
  soapItems?: Array<{
    __typename: "SoapItem";
    type?: SoapItemType | null;
    text?: string | null;
    code?: string | null;
    selected?: boolean | null;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  manager?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  owner?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  location?: {
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
    callerId?: string | null;
    testsCount?: number | null;
    usersCount?: number | null;
    physicianCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  billedTimes?: {
    __typename: "ModelEncounterBilledTimeConnectionConnection";
    nextToken?: string | null;
  } | null;
  pretest?: {
    __typename: "Assessment";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    managerId: string;
    submitterId?: string | null;
    result?: string | null;
    testId?: string | null;
    testCode?: string | null;
    meta?: number | null;
    answerSheetId?: string | null;
    title?: string | null;
    type?: AssessmentType | null;
    assessed?: string | null;
    scheduledDate?: string | null;
    score?: number | null;
    scoreGauge?: ScoreGauge | null;
    status?: AssessmentStatus | null;
    assessmentEncounterId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  assessments?: {
    __typename: "ModelAssessmentConnection";
    nextToken?: string | null;
  } | null;
  questionnaireResponses?: {
    __typename: "ModelQuestionnaireResponseConnection";
    nextToken?: string | null;
  } | null;
  clinicalImpressions?: {
    __typename: "ModelClinicalImpressionConnection";
    nextToken?: string | null;
  } | null;
  providers?: {
    __typename: "ModelEncounterProviderConnectionConnection";
    nextToken?: string | null;
  } | null;
  notes?: {
    __typename: "ModelEncounterNoteConnectionConnection";
    nextToken?: string | null;
  } | null;
};

export type UpdateEncounterMutation = {
  __typename: "Encounter";
  id: string;
  accountId: string;
  locationId: string;
  managerId: string;
  patientId: string;
  ownerId: string;
  status?: EncounterStatus | null;
  date: string;
  soapItems?: Array<{
    __typename: "SoapItem";
    type?: SoapItemType | null;
    text?: string | null;
    code?: string | null;
    selected?: boolean | null;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  manager?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  owner?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  location?: {
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
    callerId?: string | null;
    testsCount?: number | null;
    usersCount?: number | null;
    physicianCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  billedTimes?: {
    __typename: "ModelEncounterBilledTimeConnectionConnection";
    nextToken?: string | null;
  } | null;
  pretest?: {
    __typename: "Assessment";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    managerId: string;
    submitterId?: string | null;
    result?: string | null;
    testId?: string | null;
    testCode?: string | null;
    meta?: number | null;
    answerSheetId?: string | null;
    title?: string | null;
    type?: AssessmentType | null;
    assessed?: string | null;
    scheduledDate?: string | null;
    score?: number | null;
    scoreGauge?: ScoreGauge | null;
    status?: AssessmentStatus | null;
    assessmentEncounterId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  assessments?: {
    __typename: "ModelAssessmentConnection";
    nextToken?: string | null;
  } | null;
  questionnaireResponses?: {
    __typename: "ModelQuestionnaireResponseConnection";
    nextToken?: string | null;
  } | null;
  clinicalImpressions?: {
    __typename: "ModelClinicalImpressionConnection";
    nextToken?: string | null;
  } | null;
  providers?: {
    __typename: "ModelEncounterProviderConnectionConnection";
    nextToken?: string | null;
  } | null;
  notes?: {
    __typename: "ModelEncounterNoteConnectionConnection";
    nextToken?: string | null;
  } | null;
};

export type DeleteEncounterMutation = {
  __typename: "Encounter";
  id: string;
  accountId: string;
  locationId: string;
  managerId: string;
  patientId: string;
  ownerId: string;
  status?: EncounterStatus | null;
  date: string;
  soapItems?: Array<{
    __typename: "SoapItem";
    type?: SoapItemType | null;
    text?: string | null;
    code?: string | null;
    selected?: boolean | null;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  manager?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  owner?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  location?: {
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
    callerId?: string | null;
    testsCount?: number | null;
    usersCount?: number | null;
    physicianCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  billedTimes?: {
    __typename: "ModelEncounterBilledTimeConnectionConnection";
    nextToken?: string | null;
  } | null;
  pretest?: {
    __typename: "Assessment";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    managerId: string;
    submitterId?: string | null;
    result?: string | null;
    testId?: string | null;
    testCode?: string | null;
    meta?: number | null;
    answerSheetId?: string | null;
    title?: string | null;
    type?: AssessmentType | null;
    assessed?: string | null;
    scheduledDate?: string | null;
    score?: number | null;
    scoreGauge?: ScoreGauge | null;
    status?: AssessmentStatus | null;
    assessmentEncounterId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  assessments?: {
    __typename: "ModelAssessmentConnection";
    nextToken?: string | null;
  } | null;
  questionnaireResponses?: {
    __typename: "ModelQuestionnaireResponseConnection";
    nextToken?: string | null;
  } | null;
  clinicalImpressions?: {
    __typename: "ModelClinicalImpressionConnection";
    nextToken?: string | null;
  } | null;
  providers?: {
    __typename: "ModelEncounterProviderConnectionConnection";
    nextToken?: string | null;
  } | null;
  notes?: {
    __typename: "ModelEncounterNoteConnectionConnection";
    nextToken?: string | null;
  } | null;
};

export type CreateEncounterProviderConnectionMutation = {
  __typename: "EncounterProviderConnection";
  id: string;
  accountId: string;
  encounterId: string;
  providerId: string;
  createdAt: string;
  updatedAt: string;
  provider?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateEncounterProviderConnectionMutation = {
  __typename: "EncounterProviderConnection";
  id: string;
  accountId: string;
  encounterId: string;
  providerId: string;
  createdAt: string;
  updatedAt: string;
  provider?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteEncounterProviderConnectionMutation = {
  __typename: "EncounterProviderConnection";
  id: string;
  accountId: string;
  encounterId: string;
  providerId: string;
  createdAt: string;
  updatedAt: string;
  provider?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateEncounterNoteConnectionMutation = {
  __typename: "EncounterNoteConnection";
  id: string;
  accountId: string;
  encounterId: string;
  noteId: string;
  createdAt: string;
  updatedAt: string;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  note?: {
    __typename: "Note";
    id: string;
    author: AuthorType;
    userId: string;
    patientId: string;
    type?: NoteType | null;
    title?: string | null;
    content?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateEncounterNoteConnectionMutation = {
  __typename: "EncounterNoteConnection";
  id: string;
  accountId: string;
  encounterId: string;
  noteId: string;
  createdAt: string;
  updatedAt: string;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  note?: {
    __typename: "Note";
    id: string;
    author: AuthorType;
    userId: string;
    patientId: string;
    type?: NoteType | null;
    title?: string | null;
    content?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteEncounterNoteConnectionMutation = {
  __typename: "EncounterNoteConnection";
  id: string;
  accountId: string;
  encounterId: string;
  noteId: string;
  createdAt: string;
  updatedAt: string;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  note?: {
    __typename: "Note";
    id: string;
    author: AuthorType;
    userId: string;
    patientId: string;
    type?: NoteType | null;
    title?: string | null;
    content?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateNoteMutation = {
  __typename: "Note";
  id: string;
  author: AuthorType;
  userId: string;
  patientId: string;
  type?: NoteType | null;
  title?: string | null;
  content?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  user?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateNoteMutation = {
  __typename: "Note";
  id: string;
  author: AuthorType;
  userId: string;
  patientId: string;
  type?: NoteType | null;
  title?: string | null;
  content?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  user?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteNoteMutation = {
  __typename: "Note";
  id: string;
  author: AuthorType;
  userId: string;
  patientId: string;
  type?: NoteType | null;
  title?: string | null;
  content?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  user?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateDiagnosticCodeMutation = {
  __typename: "DiagnosticCode";
  id: string;
  accountId: string;
  codeId: string;
  patientId: string;
  diagnosed?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  code?: {
    __typename: "Code";
    id: string;
    type: CodeType;
    name: string;
    description?: string | null;
    rate?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateDiagnosticCodeMutation = {
  __typename: "DiagnosticCode";
  id: string;
  accountId: string;
  codeId: string;
  patientId: string;
  diagnosed?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  code?: {
    __typename: "Code";
    id: string;
    type: CodeType;
    name: string;
    description?: string | null;
    rate?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteDiagnosticCodeMutation = {
  __typename: "DiagnosticCode";
  id: string;
  accountId: string;
  codeId: string;
  patientId: string;
  diagnosed?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  code?: {
    __typename: "Code";
    id: string;
    type: CodeType;
    name: string;
    description?: string | null;
    rate?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateBillingItemMutation = {
  __typename: "BillingItem";
  id: string;
  accountId: string;
  locationId?: string | null;
  patientId: string;
  codeId: string;
  amount: number;
  type?: BillingType | null;
  currency?: string | null;
  created?: string | null;
  serviced?: string | null;
  status?: BillingItemStatus | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  billedTime?: {
    __typename: "ModelBilledTimeConnection";
    nextToken?: string | null;
  } | null;
  code?: {
    __typename: "Code";
    id: string;
    type: CodeType;
    name: string;
    description?: string | null;
    rate?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdateBillingItemMutation = {
  __typename: "BillingItem";
  id: string;
  accountId: string;
  locationId?: string | null;
  patientId: string;
  codeId: string;
  amount: number;
  type?: BillingType | null;
  currency?: string | null;
  created?: string | null;
  serviced?: string | null;
  status?: BillingItemStatus | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  billedTime?: {
    __typename: "ModelBilledTimeConnection";
    nextToken?: string | null;
  } | null;
  code?: {
    __typename: "Code";
    id: string;
    type: CodeType;
    name: string;
    description?: string | null;
    rate?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeleteBillingItemMutation = {
  __typename: "BillingItem";
  id: string;
  accountId: string;
  locationId?: string | null;
  patientId: string;
  codeId: string;
  amount: number;
  type?: BillingType | null;
  currency?: string | null;
  created?: string | null;
  serviced?: string | null;
  status?: BillingItemStatus | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  billedTime?: {
    __typename: "ModelBilledTimeConnection";
    nextToken?: string | null;
  } | null;
  code?: {
    __typename: "Code";
    id: string;
    type: CodeType;
    name: string;
    description?: string | null;
    rate?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateInvoiceMutation = {
  __typename: "Invoice";
  id: string;
  accountId: string;
  locationId: string;
  dueDate: string;
  billDate: string;
  billingPeriodStartDate: string;
  billingPeriodEndDate: string;
  hours?: number | null;
  totalBill?: number | null;
  status?: InvoiceStatus | null;
  createdAt: string;
  updatedAt: string;
  billingItems?: {
    __typename: "ModelBillingItemConnection";
    nextToken?: string | null;
  } | null;
};

export type UpdateInvoiceMutation = {
  __typename: "Invoice";
  id: string;
  accountId: string;
  locationId: string;
  dueDate: string;
  billDate: string;
  billingPeriodStartDate: string;
  billingPeriodEndDate: string;
  hours?: number | null;
  totalBill?: number | null;
  status?: InvoiceStatus | null;
  createdAt: string;
  updatedAt: string;
  billingItems?: {
    __typename: "ModelBillingItemConnection";
    nextToken?: string | null;
  } | null;
};

export type DeleteInvoiceMutation = {
  __typename: "Invoice";
  id: string;
  accountId: string;
  locationId: string;
  dueDate: string;
  billDate: string;
  billingPeriodStartDate: string;
  billingPeriodEndDate: string;
  hours?: number | null;
  totalBill?: number | null;
  status?: InvoiceStatus | null;
  createdAt: string;
  updatedAt: string;
  billingItems?: {
    __typename: "ModelBillingItemConnection";
    nextToken?: string | null;
  } | null;
};

export type CreatePaymentMutation = {
  __typename: "Payment";
  id: string;
  accountId: string;
  locationId: string;
  userId: string;
  stripePaymentMethodId: string;
  paymentDate: string;
  createdAt: string;
  updatedAt: string;
  invoice?: {
    __typename: "Invoice";
    id: string;
    accountId: string;
    locationId: string;
    dueDate: string;
    billDate: string;
    billingPeriodStartDate: string;
    billingPeriodEndDate: string;
    hours?: number | null;
    totalBill?: number | null;
    status?: InvoiceStatus | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type UpdatePaymentMutation = {
  __typename: "Payment";
  id: string;
  accountId: string;
  locationId: string;
  userId: string;
  stripePaymentMethodId: string;
  paymentDate: string;
  createdAt: string;
  updatedAt: string;
  invoice?: {
    __typename: "Invoice";
    id: string;
    accountId: string;
    locationId: string;
    dueDate: string;
    billDate: string;
    billingPeriodStartDate: string;
    billingPeriodEndDate: string;
    hours?: number | null;
    totalBill?: number | null;
    status?: InvoiceStatus | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type DeletePaymentMutation = {
  __typename: "Payment";
  id: string;
  accountId: string;
  locationId: string;
  userId: string;
  stripePaymentMethodId: string;
  paymentDate: string;
  createdAt: string;
  updatedAt: string;
  invoice?: {
    __typename: "Invoice";
    id: string;
    accountId: string;
    locationId: string;
    dueDate: string;
    billDate: string;
    billingPeriodStartDate: string;
    billingPeriodEndDate: string;
    hours?: number | null;
    totalBill?: number | null;
    status?: InvoiceStatus | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type CreateCodeMutation = {
  __typename: "Code";
  id: string;
  type: CodeType;
  name: string;
  description?: string | null;
  rate?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCodeMutation = {
  __typename: "Code";
  id: string;
  type: CodeType;
  name: string;
  description?: string | null;
  rate?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteCodeMutation = {
  __typename: "Code";
  id: string;
  type: CodeType;
  name: string;
  description?: string | null;
  rate?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type ListPatientsQuery = {
  __typename: "ModelPatientConnection";
  items?: Array<{
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetPatientQuery = {
  __typename: "Patient";
  id: string;
  accountId: string;
  locationId: string;
  patientManagerId?: string | null;
  owner: string;
  salutation?: string | null;
  givenName: string;
  middleName?: string | null;
  familyName: string;
  address?: {
    __typename: "Address";
    line1?: string | null;
    line2?: string | null;
    region?: string | null;
    postal?: string | null;
    country?: string | null;
  } | null;
  ssn?: string | null;
  dob?: string | null;
  gender?: Gender | null;
  mobile?: string | null;
  email?: string | null;
  interests?: Array<string | null> | null;
  providerIds?: Array<string | null> | null;
  status?: Status | null;
  eligibleRPM?: boolean | null;
  contacts?: Array<{
    __typename: "Contact";
    id?: string | null;
    salutation?: Salutation | null;
    givenName: string;
    familyName: string;
    mobile?: string | null;
    email?: string | null;
    contactType?: ContactType | null;
  } | null> | null;
  insurance?: Array<{
    __typename: "Insurance";
    id?: string | null;
    memberId: string;
    groupId?: string | null;
    carrierId?: string | null;
    insuranceType?: InsuranceType | null;
  } | null> | null;
  qualifiedTests?: Array<string | null> | null;
  connectionId?: string | null;
  createdAt: string;
  updatedAt: string;
  providers?: {
    __typename: "ModelPatientProviderConnectionConnection";
    nextToken?: string | null;
  } | null;
  programs?: {
    __typename: "ModelPatientProgramConnectionConnection";
    nextToken?: string | null;
  } | null;
  eligibleTests?: {
    __typename: "ModelEligibilityConnectionConnection";
    nextToken?: string | null;
  } | null;
  manager?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  location?: {
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
    callerId?: string | null;
    testsCount?: number | null;
    usersCount?: number | null;
    physicianCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  lastAssessment?: {
    __typename: "Assessment";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    managerId: string;
    submitterId?: string | null;
    result?: string | null;
    testId?: string | null;
    testCode?: string | null;
    meta?: number | null;
    answerSheetId?: string | null;
    title?: string | null;
    type?: AssessmentType | null;
    assessed?: string | null;
    scheduledDate?: string | null;
    score?: number | null;
    scoreGauge?: ScoreGauge | null;
    status?: AssessmentStatus | null;
    assessmentEncounterId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  assessments?: {
    __typename: "ModelAssessmentConnection";
    nextToken?: string | null;
  } | null;
  treatmentPlan?: {
    __typename: "TreatmentPlan";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    treatmentPlanManagerId?: string | null;
    created: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  lastEncounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  diagnoses?: {
    __typename: "ModelDiagnosticCodeConnection";
    nextToken?: string | null;
  } | null;
};

export type GetPatientProviderConnectionQuery = {
  __typename: "PatientProviderConnection";
  id: string;
  accountId: string;
  patientId: string;
  providerId: string;
  type?: ProviderType | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  provider?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListPatientProviderConnectionsQuery = {
  __typename: "ModelPatientProviderConnectionConnection";
  items?: Array<{
    __typename: "PatientProviderConnection";
    id: string;
    accountId: string;
    patientId: string;
    providerId: string;
    type?: ProviderType | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetPatientProgramConnectionQuery = {
  __typename: "PatientProgramConnection";
  id?: string | null;
  accountId: string;
  patientId: string;
  programId: string;
  date?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  program?: {
    __typename: "Program";
    id: string;
    questionnaireId: string;
    name?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListPatientProgramConnectionsQuery = {
  __typename: "ModelPatientProgramConnectionConnection";
  items?: Array<{
    __typename: "PatientProgramConnection";
    id?: string | null;
    accountId: string;
    patientId: string;
    programId: string;
    date?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type ListProgramsQuery = {
  __typename: "ModelProgramConnection";
  items?: Array<{
    __typename: "Program";
    id: string;
    questionnaireId: string;
    name?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetProgramQuery = {
  __typename: "Program";
  id: string;
  questionnaireId: string;
  name?: string | null;
  createdAt: string;
  updatedAt: string;
  tests?: {
    __typename: "ModelProgramTestConnection";
    nextToken?: string | null;
  } | null;
  questionnaire?: {
    __typename: "Questionnaire";
    id: string;
    created?: string | null;
    name?: string | null;
    title?: string | null;
    template?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListProgramTestsQuery = {
  __typename: "ModelProgramTestConnection";
  items?: Array<{
    __typename: "ProgramTest";
    id: string;
    name?: string | null;
    referenceTestId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetProgramTestQuery = {
  __typename: "ProgramTest";
  id: string;
  name?: string | null;
  referenceTestId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type GetEligibilityConnectionQuery = {
  __typename: "EligibilityConnection";
  id?: string | null;
  patientId: string;
  programTestId: string;
  required?: boolean | null;
  date?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  programTest?: {
    __typename: "ProgramTest";
    id: string;
    name?: string | null;
    referenceTestId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListEligibilityConnectionsQuery = {
  __typename: "ModelEligibilityConnectionConnection";
  items?: Array<{
    __typename: "EligibilityConnection";
    id?: string | null;
    patientId: string;
    programTestId: string;
    required?: boolean | null;
    date?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type ListUsersQuery = {
  __typename: "ModelUserConnection";
  items?: Array<{
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetUserQuery = {
  __typename: "User";
  id: string;
  accountId: string;
  owner?: string | null;
  email: string;
  phone?: string | null;
  online?: boolean | null;
  firstName?: string | null;
  lastName?: string | null;
  titles?: Array<string | null> | null;
  npi?: string | null;
  image?: string | null;
  userType?: UserType | null;
  connections?: Array<{
    __typename: "WSConnection";
    connectionId?: string | null;
    startedAt?: string | null;
  } | null> | null;
  onlineStatus?: UserOnlineStatus | null;
  lastLogin?: {
    __typename: "LoginEvent";
    ip?: string | null;
    date: string;
  } | null;
  pinCode?: string | null;
  permissions?: Array<AccessPermission | null> | null;
  createdAt: string;
  updatedAt: string;
  locations?: {
    __typename: "ModelUserLocationConnectionConnection";
    nextToken?: string | null;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type SearchUsersQuery = {
  __typename: "SearchableUserConnection";
  items?: Array<{
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
  total?: number | null;
};

export type GetUserLocationConnectionQuery = {
  __typename: "UserLocationConnection";
  id: string;
  accountId: string;
  userId: string;
  locationId: string;
  createdAt: string;
  updatedAt: string;
  user?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  location?: {
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
    callerId?: string | null;
    testsCount?: number | null;
    usersCount?: number | null;
    physicianCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListUserLocationConnectionsQuery = {
  __typename: "ModelUserLocationConnectionConnection";
  items?: Array<{
    __typename: "UserLocationConnection";
    id: string;
    accountId: string;
    userId: string;
    locationId: string;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type ListAccountsQuery = {
  __typename: "ModelAccountConnection";
  items?: Array<{
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetAccountQuery = {
  __typename: "Account";
  id: string;
  name: string;
  owner?: string | null;
  stripeCustomerId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListLocationsQuery = {
  __typename: "ModelLocationConnection";
  items?: Array<{
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
    callerId?: string | null;
    testsCount?: number | null;
    usersCount?: number | null;
    physicianCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetLocationQuery = {
  __typename: "Location";
  id: string;
  owner?: string | null;
  accountId: string;
  name: string;
  address?: {
    __typename: "Address";
    line1?: string | null;
    line2?: string | null;
    region?: string | null;
    postal?: string | null;
    country?: string | null;
  } | null;
  callerId?: string | null;
  testsCount?: number | null;
  usersCount?: number | null;
  physicianCount?: number | null;
  frontDesk?: {
    __typename: "Contact";
    id?: string | null;
    salutation?: Salutation | null;
    givenName: string;
    familyName: string;
    mobile?: string | null;
    email?: string | null;
    contactType?: ContactType | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  lastLoginUser?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  users?: {
    __typename: "ModelUserLocationConnectionConnection";
    nextToken?: string | null;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  lastAssessment?: {
    __typename: "Assessment";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    managerId: string;
    submitterId?: string | null;
    result?: string | null;
    testId?: string | null;
    testCode?: string | null;
    meta?: number | null;
    answerSheetId?: string | null;
    title?: string | null;
    type?: AssessmentType | null;
    assessed?: string | null;
    scheduledDate?: string | null;
    score?: number | null;
    scoreGauge?: ScoreGauge | null;
    status?: AssessmentStatus | null;
    assessmentEncounterId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  lastPayment?: {
    __typename: "Payment";
    id: string;
    accountId: string;
    locationId: string;
    userId: string;
    stripePaymentMethodId: string;
    paymentDate: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type SearchLocationsQuery = {
  __typename: "SearchableLocationConnection";
  items?: Array<{
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
    callerId?: string | null;
    testsCount?: number | null;
    usersCount?: number | null;
    physicianCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
  total?: number | null;
};

export type GetBilledTimeQuery = {
  __typename: "BilledTime";
  id: string;
  minutes?: number | null;
  type?: BilledTimeType | null;
  date?: string | null;
  userId: string;
  createdAt: string;
  updatedAt: string;
  user?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListBilledTimesQuery = {
  __typename: "ModelBilledTimeConnection";
  items?: Array<{
    __typename: "BilledTime";
    id: string;
    minutes?: number | null;
    type?: BilledTimeType | null;
    date?: string | null;
    userId: string;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetEncounterBilledTimeConnectionQuery = {
  __typename: "EncounterBilledTimeConnection";
  id?: string | null;
  accountId: string;
  encounterId: string;
  billedTimeId: string;
  createdAt: string;
  updatedAt: string;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  billedTime?: {
    __typename: "BilledTime";
    id: string;
    minutes?: number | null;
    type?: BilledTimeType | null;
    date?: string | null;
    userId: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListEncounterBilledTimeConnectionsQuery = {
  __typename: "ModelEncounterBilledTimeConnectionConnection";
  items?: Array<{
    __typename: "EncounterBilledTimeConnection";
    id?: string | null;
    accountId: string;
    encounterId: string;
    billedTimeId: string;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetAssessmentBilledTimeConnectionQuery = {
  __typename: "AssessmentBilledTimeConnection";
  id: string;
  accountId: string;
  assessmentId: string;
  billedTimeId: string;
  createdAt: string;
  updatedAt: string;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  billedTime?: {
    __typename: "BilledTime";
    id: string;
    minutes?: number | null;
    type?: BilledTimeType | null;
    date?: string | null;
    userId: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  assessment?: {
    __typename: "Assessment";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    managerId: string;
    submitterId?: string | null;
    result?: string | null;
    testId?: string | null;
    testCode?: string | null;
    meta?: number | null;
    answerSheetId?: string | null;
    title?: string | null;
    type?: AssessmentType | null;
    assessed?: string | null;
    scheduledDate?: string | null;
    score?: number | null;
    scoreGauge?: ScoreGauge | null;
    status?: AssessmentStatus | null;
    assessmentEncounterId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListAssessmentBilledTimeConnectionsQuery = {
  __typename: "ModelAssessmentBilledTimeConnectionConnection";
  items?: Array<{
    __typename: "AssessmentBilledTimeConnection";
    id: string;
    accountId: string;
    assessmentId: string;
    billedTimeId: string;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetAssessmentQuery = {
  __typename: "Assessment";
  id: string;
  accountId: string;
  locationId: string;
  patientId: string;
  providers?: Array<string | null> | null;
  managerId: string;
  submitterId?: string | null;
  result?: string | null;
  testId?: string | null;
  testCode?: string | null;
  meta?: number | null;
  answerSheetId?: string | null;
  title?: string | null;
  type?: AssessmentType | null;
  assessed?: string | null;
  scheduledDate?: string | null;
  score?: number | null;
  scoreGauge?: ScoreGauge | null;
  status?: AssessmentStatus | null;
  assessmentEncounterId?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  manager?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  location?: {
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
    callerId?: string | null;
    testsCount?: number | null;
    usersCount?: number | null;
    physicianCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  billedTime?: {
    __typename: "BilledTime";
    id: string;
    minutes?: number | null;
    type?: BilledTimeType | null;
    date?: string | null;
    userId: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListAssessmentsQuery = {
  __typename: "ModelAssessmentConnection";
  items?: Array<{
    __typename: "Assessment";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    managerId: string;
    submitterId?: string | null;
    result?: string | null;
    testId?: string | null;
    testCode?: string | null;
    meta?: number | null;
    answerSheetId?: string | null;
    title?: string | null;
    type?: AssessmentType | null;
    assessed?: string | null;
    scheduledDate?: string | null;
    score?: number | null;
    scoreGauge?: ScoreGauge | null;
    status?: AssessmentStatus | null;
    assessmentEncounterId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type ListTreatmentPlansQuery = {
  __typename: "ModelTreatmentPlanConnection";
  items?: Array<{
    __typename: "TreatmentPlan";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    treatmentPlanManagerId?: string | null;
    created: string;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetTreatmentPlanQuery = {
  __typename: "TreatmentPlan";
  id: string;
  accountId: string;
  locationId: string;
  patientId: string;
  providers?: Array<string | null> | null;
  treatmentPlanManagerId?: string | null;
  created: string;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  location?: {
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
    callerId?: string | null;
    testsCount?: number | null;
    usersCount?: number | null;
    physicianCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  resourceEvents?: {
    __typename: "ModelPatientResourceScheduledEventConnectionConnection";
    nextToken?: string | null;
  } | null;
};

export type GetPatientResourceScheduledEventConnectionQuery = {
  __typename: "PatientResourceScheduledEventConnection";
  id: string;
  accountId: string;
  patientId: string;
  encounterId: string;
  resourceScheduledEventId: string;
  created?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  resourceScheduledEvent?: {
    __typename: "ResourceScheduledEvent";
    id: string;
    accountId: string;
    ownerId: string;
    locationId: string;
    title: string;
    start: string;
    end: string;
    startTimezone?: string | null;
    endTimezone?: string | null;
    isAllDay?: boolean | null;
    description?: string | null;
    recurrenceRule?: string | null;
    recurrenceExceptions?: string | null;
    recurrenceId?: string | null;
    resourceId: string;
    category: ResourceCategory;
    cost?: number | null;
    ageGroup?: AgeGroup | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListPatientResourceScheduledEventConnectionsQuery = {
  __typename: "ModelPatientResourceScheduledEventConnectionConnection";
  items?: Array<{
    __typename: "PatientResourceScheduledEventConnection";
    id: string;
    accountId: string;
    patientId: string;
    encounterId: string;
    resourceScheduledEventId: string;
    created?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetTelehealthSessionQuery = {
  __typename: "TelehealthSession";
  id: string;
  title: string;
  accountId: string;
  participantIds: Array<string | null>;
  participants: Array<{
    __typename: "TelehealthSessionParticipant";
    name: string;
    type?: UserType | null;
    identity: string;
  } | null>;
  status?: TelehealthSessionStatus | null;
  roomType?: RoomType | null;
  startedAt?: string | null;
  endedAt?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListTelehealthSessionsQuery = {
  __typename: "ModelTelehealthSessionConnection";
  items?: Array<{
    __typename: "TelehealthSession";
    id: string;
    title: string;
    accountId: string;
    participantIds: Array<string | null>;
    status?: TelehealthSessionStatus | null;
    roomType?: RoomType | null;
    startedAt?: string | null;
    endedAt?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetScheduledEventQuery = {
  __typename: "ScheduledEvent";
  id: string;
  accountId: string;
  ownerId: string;
  invitees: Array<{
    __typename: "ScheduledEventInvitee";
    name: string;
    type?: UserType | null;
    id?: string | null;
  } | null>;
  participantIds: Array<string | null>;
  title: string;
  start: string;
  end: string;
  type: ScheduledEventType;
  assessments?: Array<{
    __typename: "ScheduledEventAssessment";
    id?: string | null;
    name?: string | null;
  } | null> | null;
  startTimezone?: string | null;
  endTimezone?: string | null;
  isAllDay?: boolean | null;
  description?: string | null;
  recurrenceRule?: string | null;
  recurrenceExceptions?: string | null;
  recurrenceId?: string | null;
  createdAt: string;
  updatedAt: string;
  resource?: {
    __typename: "ResourceScheduledEvent";
    id: string;
    accountId: string;
    ownerId: string;
    locationId: string;
    title: string;
    start: string;
    end: string;
    startTimezone?: string | null;
    endTimezone?: string | null;
    isAllDay?: boolean | null;
    description?: string | null;
    recurrenceRule?: string | null;
    recurrenceExceptions?: string | null;
    recurrenceId?: string | null;
    resourceId: string;
    category: ResourceCategory;
    cost?: number | null;
    ageGroup?: AgeGroup | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListScheduledEventsQuery = {
  __typename: "ModelScheduledEventConnection";
  items?: Array<{
    __typename: "ScheduledEvent";
    id: string;
    accountId: string;
    ownerId: string;
    participantIds: Array<string | null>;
    title: string;
    start: string;
    end: string;
    type: ScheduledEventType;
    startTimezone?: string | null;
    endTimezone?: string | null;
    isAllDay?: boolean | null;
    description?: string | null;
    recurrenceRule?: string | null;
    recurrenceExceptions?: string | null;
    recurrenceId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type ListResourceScheduledEventsQuery = {
  __typename: "ModelResourceScheduledEventConnection";
  items?: Array<{
    __typename: "ResourceScheduledEvent";
    id: string;
    accountId: string;
    ownerId: string;
    locationId: string;
    title: string;
    start: string;
    end: string;
    startTimezone?: string | null;
    endTimezone?: string | null;
    isAllDay?: boolean | null;
    description?: string | null;
    recurrenceRule?: string | null;
    recurrenceExceptions?: string | null;
    recurrenceId?: string | null;
    resourceId: string;
    category: ResourceCategory;
    cost?: number | null;
    ageGroup?: AgeGroup | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetResourceScheduledEventQuery = {
  __typename: "ResourceScheduledEvent";
  id: string;
  accountId: string;
  ownerId: string;
  locationId: string;
  title: string;
  start: string;
  end: string;
  startTimezone?: string | null;
  endTimezone?: string | null;
  isAllDay?: boolean | null;
  description?: string | null;
  recurrenceRule?: string | null;
  recurrenceExceptions?: string | null;
  recurrenceId?: string | null;
  resourceId: string;
  category: ResourceCategory;
  cost?: number | null;
  ageGroup?: AgeGroup | null;
  createdAt: string;
  updatedAt: string;
  resource?: {
    __typename: "EventResource";
    id: string;
    ownerId?: string | null;
    accountId?: string | null;
    locationId?: string | null;
    name?: string | null;
    type?: ResourceType | null;
    classification?: EventResourceClassification | null;
    website?: string | null;
    businessHours?: string | null;
    billedTime?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type GetSessionRecordingQuery = {
  __typename: "SessionRecording";
  id?: string | null;
  codec?: string | null;
  container?: string | null;
  offset?: string | null;
  sourceSid?: string | null;
  twilioMediaUri?: string | null;
  roomSid?: string | null;
  eventId?: string | null;
  ownerId: string;
  sessionId: string;
  s3Url?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListSessionRecordingsQuery = {
  __typename: "ModelSessionRecordingConnection";
  items?: Array<{
    __typename: "SessionRecording";
    id?: string | null;
    codec?: string | null;
    container?: string | null;
    offset?: string | null;
    sourceSid?: string | null;
    twilioMediaUri?: string | null;
    roomSid?: string | null;
    eventId?: string | null;
    ownerId: string;
    sessionId: string;
    s3Url?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type ListEventResourcesQuery = {
  __typename: "ModelEventResourceConnection";
  items?: Array<{
    __typename: "EventResource";
    id: string;
    ownerId?: string | null;
    accountId?: string | null;
    locationId?: string | null;
    name?: string | null;
    type?: ResourceType | null;
    classification?: EventResourceClassification | null;
    website?: string | null;
    businessHours?: string | null;
    billedTime?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetEventResourceQuery = {
  __typename: "EventResource";
  id: string;
  ownerId?: string | null;
  accountId?: string | null;
  locationId?: string | null;
  name?: string | null;
  type?: ResourceType | null;
  classification?: EventResourceClassification | null;
  website?: string | null;
  contact?: {
    __typename: "Contact";
    id?: string | null;
    salutation?: Salutation | null;
    givenName: string;
    familyName: string;
    mobile?: string | null;
    email?: string | null;
    contactType?: ContactType | null;
  } | null;
  address?: {
    __typename: "Address";
    line1?: string | null;
    line2?: string | null;
    region?: string | null;
    postal?: string | null;
    country?: string | null;
  } | null;
  businessHours?: string | null;
  billedTime?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type ListQuestionnairesQuery = {
  __typename: "ModelQuestionnaireConnection";
  items?: Array<{
    __typename: "Questionnaire";
    id: string;
    created?: string | null;
    name?: string | null;
    title?: string | null;
    template?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetQuestionnaireQuery = {
  __typename: "Questionnaire";
  id: string;
  created?: string | null;
  name?: string | null;
  title?: string | null;
  template?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type GetQuestionnaireResponseAuthorQuery = {
  __typename: "QuestionnaireResponseAuthor";
  id: string;
  accountId: string;
  responseId: string;
  authorId: string;
  createdAt: string;
  updatedAt: string;
  author?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  questionnaireResponse?: {
    __typename: "QuestionnaireResponse";
    id: string;
    accountId: string;
    patientId: string;
    encounterId: string;
    contributors?: string | null;
    created?: string | null;
    name?: string | null;
    title?: string | null;
    status?: QuestionnaireStatus | null;
    json?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListQuestionnaireResponseAuthorsQuery = {
  __typename: "ModelQuestionnaireResponseAuthorConnection";
  items?: Array<{
    __typename: "QuestionnaireResponseAuthor";
    id: string;
    accountId: string;
    responseId: string;
    authorId: string;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetQuestionnaireResponseQuery = {
  __typename: "QuestionnaireResponse";
  id: string;
  accountId: string;
  patientId: string;
  encounterId: string;
  contributors?: string | null;
  created?: string | null;
  name?: string | null;
  title?: string | null;
  status?: QuestionnaireStatus | null;
  json?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  authors?: {
    __typename: "ModelQuestionnaireResponseAuthorConnection";
    nextToken?: string | null;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListQuestionnaireResponsesQuery = {
  __typename: "ModelQuestionnaireResponseConnection";
  items?: Array<{
    __typename: "QuestionnaireResponse";
    id: string;
    accountId: string;
    patientId: string;
    encounterId: string;
    contributors?: string | null;
    created?: string | null;
    name?: string | null;
    title?: string | null;
    status?: QuestionnaireStatus | null;
    json?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetClinicalImpressionQuery = {
  __typename: "ClinicalImpression";
  id?: string | null;
  accountId: string;
  patientId: string;
  encounterId: string;
  authorId?: string | null;
  created?: string | null;
  title?: string | null;
  status?: ClinicalImpressionStatus | null;
  summary?: string | null;
  name?: string | null;
  signature?: string | null;
  approved?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  questionnaireResponse?: {
    __typename: "QuestionnaireResponse";
    id: string;
    accountId: string;
    patientId: string;
    encounterId: string;
    contributors?: string | null;
    created?: string | null;
    name?: string | null;
    title?: string | null;
    status?: QuestionnaireStatus | null;
    json?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListClinicalImpressionsQuery = {
  __typename: "ModelClinicalImpressionConnection";
  items?: Array<{
    __typename: "ClinicalImpression";
    id?: string | null;
    accountId: string;
    patientId: string;
    encounterId: string;
    authorId?: string | null;
    created?: string | null;
    title?: string | null;
    status?: ClinicalImpressionStatus | null;
    summary?: string | null;
    name?: string | null;
    signature?: string | null;
    approved?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type ListEncountersQuery = {
  __typename: "ModelEncounterConnection";
  items?: Array<{
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetEncounterQuery = {
  __typename: "Encounter";
  id: string;
  accountId: string;
  locationId: string;
  managerId: string;
  patientId: string;
  ownerId: string;
  status?: EncounterStatus | null;
  date: string;
  soapItems?: Array<{
    __typename: "SoapItem";
    type?: SoapItemType | null;
    text?: string | null;
    code?: string | null;
    selected?: boolean | null;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  manager?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  owner?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  location?: {
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
    callerId?: string | null;
    testsCount?: number | null;
    usersCount?: number | null;
    physicianCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  billedTimes?: {
    __typename: "ModelEncounterBilledTimeConnectionConnection";
    nextToken?: string | null;
  } | null;
  pretest?: {
    __typename: "Assessment";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    managerId: string;
    submitterId?: string | null;
    result?: string | null;
    testId?: string | null;
    testCode?: string | null;
    meta?: number | null;
    answerSheetId?: string | null;
    title?: string | null;
    type?: AssessmentType | null;
    assessed?: string | null;
    scheduledDate?: string | null;
    score?: number | null;
    scoreGauge?: ScoreGauge | null;
    status?: AssessmentStatus | null;
    assessmentEncounterId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  assessments?: {
    __typename: "ModelAssessmentConnection";
    nextToken?: string | null;
  } | null;
  questionnaireResponses?: {
    __typename: "ModelQuestionnaireResponseConnection";
    nextToken?: string | null;
  } | null;
  clinicalImpressions?: {
    __typename: "ModelClinicalImpressionConnection";
    nextToken?: string | null;
  } | null;
  providers?: {
    __typename: "ModelEncounterProviderConnectionConnection";
    nextToken?: string | null;
  } | null;
  notes?: {
    __typename: "ModelEncounterNoteConnectionConnection";
    nextToken?: string | null;
  } | null;
};

export type GetEncounterProviderConnectionQuery = {
  __typename: "EncounterProviderConnection";
  id: string;
  accountId: string;
  encounterId: string;
  providerId: string;
  createdAt: string;
  updatedAt: string;
  provider?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListEncounterProviderConnectionsQuery = {
  __typename: "ModelEncounterProviderConnectionConnection";
  items?: Array<{
    __typename: "EncounterProviderConnection";
    id: string;
    accountId: string;
    encounterId: string;
    providerId: string;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetEncounterNoteConnectionQuery = {
  __typename: "EncounterNoteConnection";
  id: string;
  accountId: string;
  encounterId: string;
  noteId: string;
  createdAt: string;
  updatedAt: string;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  note?: {
    __typename: "Note";
    id: string;
    author: AuthorType;
    userId: string;
    patientId: string;
    type?: NoteType | null;
    title?: string | null;
    content?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListEncounterNoteConnectionsQuery = {
  __typename: "ModelEncounterNoteConnectionConnection";
  items?: Array<{
    __typename: "EncounterNoteConnection";
    id: string;
    accountId: string;
    encounterId: string;
    noteId: string;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type ListNotesQuery = {
  __typename: "ModelNoteConnection";
  items?: Array<{
    __typename: "Note";
    id: string;
    author: AuthorType;
    userId: string;
    patientId: string;
    type?: NoteType | null;
    title?: string | null;
    content?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetNoteQuery = {
  __typename: "Note";
  id: string;
  author: AuthorType;
  userId: string;
  patientId: string;
  type?: NoteType | null;
  title?: string | null;
  content?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  user?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type GetDiagnosticCodeQuery = {
  __typename: "DiagnosticCode";
  id: string;
  accountId: string;
  codeId: string;
  patientId: string;
  diagnosed?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  code?: {
    __typename: "Code";
    id: string;
    type: CodeType;
    name: string;
    description?: string | null;
    rate?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListDiagnosticCodesQuery = {
  __typename: "ModelDiagnosticCodeConnection";
  items?: Array<{
    __typename: "DiagnosticCode";
    id: string;
    accountId: string;
    codeId: string;
    patientId: string;
    diagnosed?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetBillingItemQuery = {
  __typename: "BillingItem";
  id: string;
  accountId: string;
  locationId?: string | null;
  patientId: string;
  codeId: string;
  amount: number;
  type?: BillingType | null;
  currency?: string | null;
  created?: string | null;
  serviced?: string | null;
  status?: BillingItemStatus | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  billedTime?: {
    __typename: "ModelBilledTimeConnection";
    nextToken?: string | null;
  } | null;
  code?: {
    __typename: "Code";
    id: string;
    type: CodeType;
    name: string;
    description?: string | null;
    rate?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListBillingItemsQuery = {
  __typename: "ModelBillingItemConnection";
  items?: Array<{
    __typename: "BillingItem";
    id: string;
    accountId: string;
    locationId?: string | null;
    patientId: string;
    codeId: string;
    amount: number;
    type?: BillingType | null;
    currency?: string | null;
    created?: string | null;
    serviced?: string | null;
    status?: BillingItemStatus | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type ListInvoicesQuery = {
  __typename: "ModelInvoiceConnection";
  items?: Array<{
    __typename: "Invoice";
    id: string;
    accountId: string;
    locationId: string;
    dueDate: string;
    billDate: string;
    billingPeriodStartDate: string;
    billingPeriodEndDate: string;
    hours?: number | null;
    totalBill?: number | null;
    status?: InvoiceStatus | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetInvoiceQuery = {
  __typename: "Invoice";
  id: string;
  accountId: string;
  locationId: string;
  dueDate: string;
  billDate: string;
  billingPeriodStartDate: string;
  billingPeriodEndDate: string;
  hours?: number | null;
  totalBill?: number | null;
  status?: InvoiceStatus | null;
  createdAt: string;
  updatedAt: string;
  billingItems?: {
    __typename: "ModelBillingItemConnection";
    nextToken?: string | null;
  } | null;
};

export type ListPaymentsQuery = {
  __typename: "ModelPaymentConnection";
  items?: Array<{
    __typename: "Payment";
    id: string;
    accountId: string;
    locationId: string;
    userId: string;
    stripePaymentMethodId: string;
    paymentDate: string;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetPaymentQuery = {
  __typename: "Payment";
  id: string;
  accountId: string;
  locationId: string;
  userId: string;
  stripePaymentMethodId: string;
  paymentDate: string;
  createdAt: string;
  updatedAt: string;
  invoice?: {
    __typename: "Invoice";
    id: string;
    accountId: string;
    locationId: string;
    dueDate: string;
    billDate: string;
    billingPeriodStartDate: string;
    billingPeriodEndDate: string;
    hours?: number | null;
    totalBill?: number | null;
    status?: InvoiceStatus | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListCodesQuery = {
  __typename: "ModelCodeConnection";
  items?: Array<{
    __typename: "Code";
    id: string;
    type: CodeType;
    name: string;
    description?: string | null;
    rate?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetCodeQuery = {
  __typename: "Code";
  id: string;
  type: CodeType;
  name: string;
  description?: string | null;
  rate?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreatePatientSubscription = {
  __typename: "Patient";
  id: string;
  accountId: string;
  locationId: string;
  patientManagerId?: string | null;
  owner: string;
  salutation?: string | null;
  givenName: string;
  middleName?: string | null;
  familyName: string;
  address?: {
    __typename: "Address";
    line1?: string | null;
    line2?: string | null;
    region?: string | null;
    postal?: string | null;
    country?: string | null;
  } | null;
  ssn?: string | null;
  dob?: string | null;
  gender?: Gender | null;
  mobile?: string | null;
  email?: string | null;
  interests?: Array<string | null> | null;
  providerIds?: Array<string | null> | null;
  status?: Status | null;
  eligibleRPM?: boolean | null;
  contacts?: Array<{
    __typename: "Contact";
    id?: string | null;
    salutation?: Salutation | null;
    givenName: string;
    familyName: string;
    mobile?: string | null;
    email?: string | null;
    contactType?: ContactType | null;
  } | null> | null;
  insurance?: Array<{
    __typename: "Insurance";
    id?: string | null;
    memberId: string;
    groupId?: string | null;
    carrierId?: string | null;
    insuranceType?: InsuranceType | null;
  } | null> | null;
  qualifiedTests?: Array<string | null> | null;
  connectionId?: string | null;
  createdAt: string;
  updatedAt: string;
  providers?: {
    __typename: "ModelPatientProviderConnectionConnection";
    nextToken?: string | null;
  } | null;
  programs?: {
    __typename: "ModelPatientProgramConnectionConnection";
    nextToken?: string | null;
  } | null;
  eligibleTests?: {
    __typename: "ModelEligibilityConnectionConnection";
    nextToken?: string | null;
  } | null;
  manager?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  location?: {
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
    callerId?: string | null;
    testsCount?: number | null;
    usersCount?: number | null;
    physicianCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  lastAssessment?: {
    __typename: "Assessment";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    managerId: string;
    submitterId?: string | null;
    result?: string | null;
    testId?: string | null;
    testCode?: string | null;
    meta?: number | null;
    answerSheetId?: string | null;
    title?: string | null;
    type?: AssessmentType | null;
    assessed?: string | null;
    scheduledDate?: string | null;
    score?: number | null;
    scoreGauge?: ScoreGauge | null;
    status?: AssessmentStatus | null;
    assessmentEncounterId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  assessments?: {
    __typename: "ModelAssessmentConnection";
    nextToken?: string | null;
  } | null;
  treatmentPlan?: {
    __typename: "TreatmentPlan";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    treatmentPlanManagerId?: string | null;
    created: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  lastEncounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  diagnoses?: {
    __typename: "ModelDiagnosticCodeConnection";
    nextToken?: string | null;
  } | null;
};

export type OnUpdatePatientSubscription = {
  __typename: "Patient";
  id: string;
  accountId: string;
  locationId: string;
  patientManagerId?: string | null;
  owner: string;
  salutation?: string | null;
  givenName: string;
  middleName?: string | null;
  familyName: string;
  address?: {
    __typename: "Address";
    line1?: string | null;
    line2?: string | null;
    region?: string | null;
    postal?: string | null;
    country?: string | null;
  } | null;
  ssn?: string | null;
  dob?: string | null;
  gender?: Gender | null;
  mobile?: string | null;
  email?: string | null;
  interests?: Array<string | null> | null;
  providerIds?: Array<string | null> | null;
  status?: Status | null;
  eligibleRPM?: boolean | null;
  contacts?: Array<{
    __typename: "Contact";
    id?: string | null;
    salutation?: Salutation | null;
    givenName: string;
    familyName: string;
    mobile?: string | null;
    email?: string | null;
    contactType?: ContactType | null;
  } | null> | null;
  insurance?: Array<{
    __typename: "Insurance";
    id?: string | null;
    memberId: string;
    groupId?: string | null;
    carrierId?: string | null;
    insuranceType?: InsuranceType | null;
  } | null> | null;
  qualifiedTests?: Array<string | null> | null;
  connectionId?: string | null;
  createdAt: string;
  updatedAt: string;
  providers?: {
    __typename: "ModelPatientProviderConnectionConnection";
    nextToken?: string | null;
  } | null;
  programs?: {
    __typename: "ModelPatientProgramConnectionConnection";
    nextToken?: string | null;
  } | null;
  eligibleTests?: {
    __typename: "ModelEligibilityConnectionConnection";
    nextToken?: string | null;
  } | null;
  manager?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  location?: {
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
    callerId?: string | null;
    testsCount?: number | null;
    usersCount?: number | null;
    physicianCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  lastAssessment?: {
    __typename: "Assessment";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    managerId: string;
    submitterId?: string | null;
    result?: string | null;
    testId?: string | null;
    testCode?: string | null;
    meta?: number | null;
    answerSheetId?: string | null;
    title?: string | null;
    type?: AssessmentType | null;
    assessed?: string | null;
    scheduledDate?: string | null;
    score?: number | null;
    scoreGauge?: ScoreGauge | null;
    status?: AssessmentStatus | null;
    assessmentEncounterId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  assessments?: {
    __typename: "ModelAssessmentConnection";
    nextToken?: string | null;
  } | null;
  treatmentPlan?: {
    __typename: "TreatmentPlan";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    treatmentPlanManagerId?: string | null;
    created: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  lastEncounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  diagnoses?: {
    __typename: "ModelDiagnosticCodeConnection";
    nextToken?: string | null;
  } | null;
};

export type OnDeletePatientSubscription = {
  __typename: "Patient";
  id: string;
  accountId: string;
  locationId: string;
  patientManagerId?: string | null;
  owner: string;
  salutation?: string | null;
  givenName: string;
  middleName?: string | null;
  familyName: string;
  address?: {
    __typename: "Address";
    line1?: string | null;
    line2?: string | null;
    region?: string | null;
    postal?: string | null;
    country?: string | null;
  } | null;
  ssn?: string | null;
  dob?: string | null;
  gender?: Gender | null;
  mobile?: string | null;
  email?: string | null;
  interests?: Array<string | null> | null;
  providerIds?: Array<string | null> | null;
  status?: Status | null;
  eligibleRPM?: boolean | null;
  contacts?: Array<{
    __typename: "Contact";
    id?: string | null;
    salutation?: Salutation | null;
    givenName: string;
    familyName: string;
    mobile?: string | null;
    email?: string | null;
    contactType?: ContactType | null;
  } | null> | null;
  insurance?: Array<{
    __typename: "Insurance";
    id?: string | null;
    memberId: string;
    groupId?: string | null;
    carrierId?: string | null;
    insuranceType?: InsuranceType | null;
  } | null> | null;
  qualifiedTests?: Array<string | null> | null;
  connectionId?: string | null;
  createdAt: string;
  updatedAt: string;
  providers?: {
    __typename: "ModelPatientProviderConnectionConnection";
    nextToken?: string | null;
  } | null;
  programs?: {
    __typename: "ModelPatientProgramConnectionConnection";
    nextToken?: string | null;
  } | null;
  eligibleTests?: {
    __typename: "ModelEligibilityConnectionConnection";
    nextToken?: string | null;
  } | null;
  manager?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  location?: {
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
    callerId?: string | null;
    testsCount?: number | null;
    usersCount?: number | null;
    physicianCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  lastAssessment?: {
    __typename: "Assessment";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    managerId: string;
    submitterId?: string | null;
    result?: string | null;
    testId?: string | null;
    testCode?: string | null;
    meta?: number | null;
    answerSheetId?: string | null;
    title?: string | null;
    type?: AssessmentType | null;
    assessed?: string | null;
    scheduledDate?: string | null;
    score?: number | null;
    scoreGauge?: ScoreGauge | null;
    status?: AssessmentStatus | null;
    assessmentEncounterId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  assessments?: {
    __typename: "ModelAssessmentConnection";
    nextToken?: string | null;
  } | null;
  treatmentPlan?: {
    __typename: "TreatmentPlan";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    treatmentPlanManagerId?: string | null;
    created: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  lastEncounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  diagnoses?: {
    __typename: "ModelDiagnosticCodeConnection";
    nextToken?: string | null;
  } | null;
};

export type OnCreatePatientProviderConnectionSubscription = {
  __typename: "PatientProviderConnection";
  id: string;
  accountId: string;
  patientId: string;
  providerId: string;
  type?: ProviderType | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  provider?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdatePatientProviderConnectionSubscription = {
  __typename: "PatientProviderConnection";
  id: string;
  accountId: string;
  patientId: string;
  providerId: string;
  type?: ProviderType | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  provider?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeletePatientProviderConnectionSubscription = {
  __typename: "PatientProviderConnection";
  id: string;
  accountId: string;
  patientId: string;
  providerId: string;
  type?: ProviderType | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  provider?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreatePatientProgramConnectionSubscription = {
  __typename: "PatientProgramConnection";
  id?: string | null;
  accountId: string;
  patientId: string;
  programId: string;
  date?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  program?: {
    __typename: "Program";
    id: string;
    questionnaireId: string;
    name?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdatePatientProgramConnectionSubscription = {
  __typename: "PatientProgramConnection";
  id?: string | null;
  accountId: string;
  patientId: string;
  programId: string;
  date?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  program?: {
    __typename: "Program";
    id: string;
    questionnaireId: string;
    name?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeletePatientProgramConnectionSubscription = {
  __typename: "PatientProgramConnection";
  id?: string | null;
  accountId: string;
  patientId: string;
  programId: string;
  date?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  program?: {
    __typename: "Program";
    id: string;
    questionnaireId: string;
    name?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateProgramSubscription = {
  __typename: "Program";
  id: string;
  questionnaireId: string;
  name?: string | null;
  createdAt: string;
  updatedAt: string;
  tests?: {
    __typename: "ModelProgramTestConnection";
    nextToken?: string | null;
  } | null;
  questionnaire?: {
    __typename: "Questionnaire";
    id: string;
    created?: string | null;
    name?: string | null;
    title?: string | null;
    template?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateProgramSubscription = {
  __typename: "Program";
  id: string;
  questionnaireId: string;
  name?: string | null;
  createdAt: string;
  updatedAt: string;
  tests?: {
    __typename: "ModelProgramTestConnection";
    nextToken?: string | null;
  } | null;
  questionnaire?: {
    __typename: "Questionnaire";
    id: string;
    created?: string | null;
    name?: string | null;
    title?: string | null;
    template?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteProgramSubscription = {
  __typename: "Program";
  id: string;
  questionnaireId: string;
  name?: string | null;
  createdAt: string;
  updatedAt: string;
  tests?: {
    __typename: "ModelProgramTestConnection";
    nextToken?: string | null;
  } | null;
  questionnaire?: {
    __typename: "Questionnaire";
    id: string;
    created?: string | null;
    name?: string | null;
    title?: string | null;
    template?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateProgramTestSubscription = {
  __typename: "ProgramTest";
  id: string;
  name?: string | null;
  referenceTestId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateProgramTestSubscription = {
  __typename: "ProgramTest";
  id: string;
  name?: string | null;
  referenceTestId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteProgramTestSubscription = {
  __typename: "ProgramTest";
  id: string;
  name?: string | null;
  referenceTestId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateEligibilityConnectionSubscription = {
  __typename: "EligibilityConnection";
  id?: string | null;
  patientId: string;
  programTestId: string;
  required?: boolean | null;
  date?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  programTest?: {
    __typename: "ProgramTest";
    id: string;
    name?: string | null;
    referenceTestId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateEligibilityConnectionSubscription = {
  __typename: "EligibilityConnection";
  id?: string | null;
  patientId: string;
  programTestId: string;
  required?: boolean | null;
  date?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  programTest?: {
    __typename: "ProgramTest";
    id: string;
    name?: string | null;
    referenceTestId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteEligibilityConnectionSubscription = {
  __typename: "EligibilityConnection";
  id?: string | null;
  patientId: string;
  programTestId: string;
  required?: boolean | null;
  date?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  programTest?: {
    __typename: "ProgramTest";
    id: string;
    name?: string | null;
    referenceTestId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateUserSubscription = {
  __typename: "User";
  id: string;
  accountId: string;
  owner?: string | null;
  email: string;
  phone?: string | null;
  online?: boolean | null;
  firstName?: string | null;
  lastName?: string | null;
  titles?: Array<string | null> | null;
  npi?: string | null;
  image?: string | null;
  userType?: UserType | null;
  connections?: Array<{
    __typename: "WSConnection";
    connectionId?: string | null;
    startedAt?: string | null;
  } | null> | null;
  onlineStatus?: UserOnlineStatus | null;
  lastLogin?: {
    __typename: "LoginEvent";
    ip?: string | null;
    date: string;
  } | null;
  pinCode?: string | null;
  permissions?: Array<AccessPermission | null> | null;
  createdAt: string;
  updatedAt: string;
  locations?: {
    __typename: "ModelUserLocationConnectionConnection";
    nextToken?: string | null;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateUserSubscription = {
  __typename: "User";
  id: string;
  accountId: string;
  owner?: string | null;
  email: string;
  phone?: string | null;
  online?: boolean | null;
  firstName?: string | null;
  lastName?: string | null;
  titles?: Array<string | null> | null;
  npi?: string | null;
  image?: string | null;
  userType?: UserType | null;
  connections?: Array<{
    __typename: "WSConnection";
    connectionId?: string | null;
    startedAt?: string | null;
  } | null> | null;
  onlineStatus?: UserOnlineStatus | null;
  lastLogin?: {
    __typename: "LoginEvent";
    ip?: string | null;
    date: string;
  } | null;
  pinCode?: string | null;
  permissions?: Array<AccessPermission | null> | null;
  createdAt: string;
  updatedAt: string;
  locations?: {
    __typename: "ModelUserLocationConnectionConnection";
    nextToken?: string | null;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteUserSubscription = {
  __typename: "User";
  id: string;
  accountId: string;
  owner?: string | null;
  email: string;
  phone?: string | null;
  online?: boolean | null;
  firstName?: string | null;
  lastName?: string | null;
  titles?: Array<string | null> | null;
  npi?: string | null;
  image?: string | null;
  userType?: UserType | null;
  connections?: Array<{
    __typename: "WSConnection";
    connectionId?: string | null;
    startedAt?: string | null;
  } | null> | null;
  onlineStatus?: UserOnlineStatus | null;
  lastLogin?: {
    __typename: "LoginEvent";
    ip?: string | null;
    date: string;
  } | null;
  pinCode?: string | null;
  permissions?: Array<AccessPermission | null> | null;
  createdAt: string;
  updatedAt: string;
  locations?: {
    __typename: "ModelUserLocationConnectionConnection";
    nextToken?: string | null;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateUserLocationConnectionSubscription = {
  __typename: "UserLocationConnection";
  id: string;
  accountId: string;
  userId: string;
  locationId: string;
  createdAt: string;
  updatedAt: string;
  user?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  location?: {
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
    callerId?: string | null;
    testsCount?: number | null;
    usersCount?: number | null;
    physicianCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateUserLocationConnectionSubscription = {
  __typename: "UserLocationConnection";
  id: string;
  accountId: string;
  userId: string;
  locationId: string;
  createdAt: string;
  updatedAt: string;
  user?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  location?: {
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
    callerId?: string | null;
    testsCount?: number | null;
    usersCount?: number | null;
    physicianCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteUserLocationConnectionSubscription = {
  __typename: "UserLocationConnection";
  id: string;
  accountId: string;
  userId: string;
  locationId: string;
  createdAt: string;
  updatedAt: string;
  user?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  location?: {
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
    callerId?: string | null;
    testsCount?: number | null;
    usersCount?: number | null;
    physicianCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateAccountSubscription = {
  __typename: "Account";
  id: string;
  name: string;
  owner?: string | null;
  stripeCustomerId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateAccountSubscription = {
  __typename: "Account";
  id: string;
  name: string;
  owner?: string | null;
  stripeCustomerId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteAccountSubscription = {
  __typename: "Account";
  id: string;
  name: string;
  owner?: string | null;
  stripeCustomerId?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateLocationSubscription = {
  __typename: "Location";
  id: string;
  owner?: string | null;
  accountId: string;
  name: string;
  address?: {
    __typename: "Address";
    line1?: string | null;
    line2?: string | null;
    region?: string | null;
    postal?: string | null;
    country?: string | null;
  } | null;
  callerId?: string | null;
  testsCount?: number | null;
  usersCount?: number | null;
  physicianCount?: number | null;
  frontDesk?: {
    __typename: "Contact";
    id?: string | null;
    salutation?: Salutation | null;
    givenName: string;
    familyName: string;
    mobile?: string | null;
    email?: string | null;
    contactType?: ContactType | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  lastLoginUser?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  users?: {
    __typename: "ModelUserLocationConnectionConnection";
    nextToken?: string | null;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  lastAssessment?: {
    __typename: "Assessment";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    managerId: string;
    submitterId?: string | null;
    result?: string | null;
    testId?: string | null;
    testCode?: string | null;
    meta?: number | null;
    answerSheetId?: string | null;
    title?: string | null;
    type?: AssessmentType | null;
    assessed?: string | null;
    scheduledDate?: string | null;
    score?: number | null;
    scoreGauge?: ScoreGauge | null;
    status?: AssessmentStatus | null;
    assessmentEncounterId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  lastPayment?: {
    __typename: "Payment";
    id: string;
    accountId: string;
    locationId: string;
    userId: string;
    stripePaymentMethodId: string;
    paymentDate: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateLocationSubscription = {
  __typename: "Location";
  id: string;
  owner?: string | null;
  accountId: string;
  name: string;
  address?: {
    __typename: "Address";
    line1?: string | null;
    line2?: string | null;
    region?: string | null;
    postal?: string | null;
    country?: string | null;
  } | null;
  callerId?: string | null;
  testsCount?: number | null;
  usersCount?: number | null;
  physicianCount?: number | null;
  frontDesk?: {
    __typename: "Contact";
    id?: string | null;
    salutation?: Salutation | null;
    givenName: string;
    familyName: string;
    mobile?: string | null;
    email?: string | null;
    contactType?: ContactType | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  lastLoginUser?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  users?: {
    __typename: "ModelUserLocationConnectionConnection";
    nextToken?: string | null;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  lastAssessment?: {
    __typename: "Assessment";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    managerId: string;
    submitterId?: string | null;
    result?: string | null;
    testId?: string | null;
    testCode?: string | null;
    meta?: number | null;
    answerSheetId?: string | null;
    title?: string | null;
    type?: AssessmentType | null;
    assessed?: string | null;
    scheduledDate?: string | null;
    score?: number | null;
    scoreGauge?: ScoreGauge | null;
    status?: AssessmentStatus | null;
    assessmentEncounterId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  lastPayment?: {
    __typename: "Payment";
    id: string;
    accountId: string;
    locationId: string;
    userId: string;
    stripePaymentMethodId: string;
    paymentDate: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteLocationSubscription = {
  __typename: "Location";
  id: string;
  owner?: string | null;
  accountId: string;
  name: string;
  address?: {
    __typename: "Address";
    line1?: string | null;
    line2?: string | null;
    region?: string | null;
    postal?: string | null;
    country?: string | null;
  } | null;
  callerId?: string | null;
  testsCount?: number | null;
  usersCount?: number | null;
  physicianCount?: number | null;
  frontDesk?: {
    __typename: "Contact";
    id?: string | null;
    salutation?: Salutation | null;
    givenName: string;
    familyName: string;
    mobile?: string | null;
    email?: string | null;
    contactType?: ContactType | null;
  } | null;
  createdAt: string;
  updatedAt: string;
  lastLoginUser?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  users?: {
    __typename: "ModelUserLocationConnectionConnection";
    nextToken?: string | null;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  lastAssessment?: {
    __typename: "Assessment";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    managerId: string;
    submitterId?: string | null;
    result?: string | null;
    testId?: string | null;
    testCode?: string | null;
    meta?: number | null;
    answerSheetId?: string | null;
    title?: string | null;
    type?: AssessmentType | null;
    assessed?: string | null;
    scheduledDate?: string | null;
    score?: number | null;
    scoreGauge?: ScoreGauge | null;
    status?: AssessmentStatus | null;
    assessmentEncounterId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  lastPayment?: {
    __typename: "Payment";
    id: string;
    accountId: string;
    locationId: string;
    userId: string;
    stripePaymentMethodId: string;
    paymentDate: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateBilledTimeSubscription = {
  __typename: "BilledTime";
  id: string;
  minutes?: number | null;
  type?: BilledTimeType | null;
  date?: string | null;
  userId: string;
  createdAt: string;
  updatedAt: string;
  user?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateBilledTimeSubscription = {
  __typename: "BilledTime";
  id: string;
  minutes?: number | null;
  type?: BilledTimeType | null;
  date?: string | null;
  userId: string;
  createdAt: string;
  updatedAt: string;
  user?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteBilledTimeSubscription = {
  __typename: "BilledTime";
  id: string;
  minutes?: number | null;
  type?: BilledTimeType | null;
  date?: string | null;
  userId: string;
  createdAt: string;
  updatedAt: string;
  user?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateEncounterBilledTimeConnectionSubscription = {
  __typename: "EncounterBilledTimeConnection";
  id?: string | null;
  accountId: string;
  encounterId: string;
  billedTimeId: string;
  createdAt: string;
  updatedAt: string;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  billedTime?: {
    __typename: "BilledTime";
    id: string;
    minutes?: number | null;
    type?: BilledTimeType | null;
    date?: string | null;
    userId: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateEncounterBilledTimeConnectionSubscription = {
  __typename: "EncounterBilledTimeConnection";
  id?: string | null;
  accountId: string;
  encounterId: string;
  billedTimeId: string;
  createdAt: string;
  updatedAt: string;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  billedTime?: {
    __typename: "BilledTime";
    id: string;
    minutes?: number | null;
    type?: BilledTimeType | null;
    date?: string | null;
    userId: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteEncounterBilledTimeConnectionSubscription = {
  __typename: "EncounterBilledTimeConnection";
  id?: string | null;
  accountId: string;
  encounterId: string;
  billedTimeId: string;
  createdAt: string;
  updatedAt: string;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  billedTime?: {
    __typename: "BilledTime";
    id: string;
    minutes?: number | null;
    type?: BilledTimeType | null;
    date?: string | null;
    userId: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateAssessmentBilledTimeConnectionSubscription = {
  __typename: "AssessmentBilledTimeConnection";
  id: string;
  accountId: string;
  assessmentId: string;
  billedTimeId: string;
  createdAt: string;
  updatedAt: string;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  billedTime?: {
    __typename: "BilledTime";
    id: string;
    minutes?: number | null;
    type?: BilledTimeType | null;
    date?: string | null;
    userId: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  assessment?: {
    __typename: "Assessment";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    managerId: string;
    submitterId?: string | null;
    result?: string | null;
    testId?: string | null;
    testCode?: string | null;
    meta?: number | null;
    answerSheetId?: string | null;
    title?: string | null;
    type?: AssessmentType | null;
    assessed?: string | null;
    scheduledDate?: string | null;
    score?: number | null;
    scoreGauge?: ScoreGauge | null;
    status?: AssessmentStatus | null;
    assessmentEncounterId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateAssessmentBilledTimeConnectionSubscription = {
  __typename: "AssessmentBilledTimeConnection";
  id: string;
  accountId: string;
  assessmentId: string;
  billedTimeId: string;
  createdAt: string;
  updatedAt: string;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  billedTime?: {
    __typename: "BilledTime";
    id: string;
    minutes?: number | null;
    type?: BilledTimeType | null;
    date?: string | null;
    userId: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  assessment?: {
    __typename: "Assessment";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    managerId: string;
    submitterId?: string | null;
    result?: string | null;
    testId?: string | null;
    testCode?: string | null;
    meta?: number | null;
    answerSheetId?: string | null;
    title?: string | null;
    type?: AssessmentType | null;
    assessed?: string | null;
    scheduledDate?: string | null;
    score?: number | null;
    scoreGauge?: ScoreGauge | null;
    status?: AssessmentStatus | null;
    assessmentEncounterId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteAssessmentBilledTimeConnectionSubscription = {
  __typename: "AssessmentBilledTimeConnection";
  id: string;
  accountId: string;
  assessmentId: string;
  billedTimeId: string;
  createdAt: string;
  updatedAt: string;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  billedTime?: {
    __typename: "BilledTime";
    id: string;
    minutes?: number | null;
    type?: BilledTimeType | null;
    date?: string | null;
    userId: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  assessment?: {
    __typename: "Assessment";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    managerId: string;
    submitterId?: string | null;
    result?: string | null;
    testId?: string | null;
    testCode?: string | null;
    meta?: number | null;
    answerSheetId?: string | null;
    title?: string | null;
    type?: AssessmentType | null;
    assessed?: string | null;
    scheduledDate?: string | null;
    score?: number | null;
    scoreGauge?: ScoreGauge | null;
    status?: AssessmentStatus | null;
    assessmentEncounterId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateAssessmentSubscription = {
  __typename: "Assessment";
  id: string;
  accountId: string;
  locationId: string;
  patientId: string;
  providers?: Array<string | null> | null;
  managerId: string;
  submitterId?: string | null;
  result?: string | null;
  testId?: string | null;
  testCode?: string | null;
  meta?: number | null;
  answerSheetId?: string | null;
  title?: string | null;
  type?: AssessmentType | null;
  assessed?: string | null;
  scheduledDate?: string | null;
  score?: number | null;
  scoreGauge?: ScoreGauge | null;
  status?: AssessmentStatus | null;
  assessmentEncounterId?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  manager?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  location?: {
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
    callerId?: string | null;
    testsCount?: number | null;
    usersCount?: number | null;
    physicianCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  billedTime?: {
    __typename: "BilledTime";
    id: string;
    minutes?: number | null;
    type?: BilledTimeType | null;
    date?: string | null;
    userId: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateAssessmentSubscription = {
  __typename: "Assessment";
  id: string;
  accountId: string;
  locationId: string;
  patientId: string;
  providers?: Array<string | null> | null;
  managerId: string;
  submitterId?: string | null;
  result?: string | null;
  testId?: string | null;
  testCode?: string | null;
  meta?: number | null;
  answerSheetId?: string | null;
  title?: string | null;
  type?: AssessmentType | null;
  assessed?: string | null;
  scheduledDate?: string | null;
  score?: number | null;
  scoreGauge?: ScoreGauge | null;
  status?: AssessmentStatus | null;
  assessmentEncounterId?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  manager?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  location?: {
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
    callerId?: string | null;
    testsCount?: number | null;
    usersCount?: number | null;
    physicianCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  billedTime?: {
    __typename: "BilledTime";
    id: string;
    minutes?: number | null;
    type?: BilledTimeType | null;
    date?: string | null;
    userId: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteAssessmentSubscription = {
  __typename: "Assessment";
  id: string;
  accountId: string;
  locationId: string;
  patientId: string;
  providers?: Array<string | null> | null;
  managerId: string;
  submitterId?: string | null;
  result?: string | null;
  testId?: string | null;
  testCode?: string | null;
  meta?: number | null;
  answerSheetId?: string | null;
  title?: string | null;
  type?: AssessmentType | null;
  assessed?: string | null;
  scheduledDate?: string | null;
  score?: number | null;
  scoreGauge?: ScoreGauge | null;
  status?: AssessmentStatus | null;
  assessmentEncounterId?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  manager?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  location?: {
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
    callerId?: string | null;
    testsCount?: number | null;
    usersCount?: number | null;
    physicianCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  billedTime?: {
    __typename: "BilledTime";
    id: string;
    minutes?: number | null;
    type?: BilledTimeType | null;
    date?: string | null;
    userId: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateTreatmentPlanSubscription = {
  __typename: "TreatmentPlan";
  id: string;
  accountId: string;
  locationId: string;
  patientId: string;
  providers?: Array<string | null> | null;
  treatmentPlanManagerId?: string | null;
  created: string;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  location?: {
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
    callerId?: string | null;
    testsCount?: number | null;
    usersCount?: number | null;
    physicianCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  resourceEvents?: {
    __typename: "ModelPatientResourceScheduledEventConnectionConnection";
    nextToken?: string | null;
  } | null;
};

export type OnUpdateTreatmentPlanSubscription = {
  __typename: "TreatmentPlan";
  id: string;
  accountId: string;
  locationId: string;
  patientId: string;
  providers?: Array<string | null> | null;
  treatmentPlanManagerId?: string | null;
  created: string;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  location?: {
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
    callerId?: string | null;
    testsCount?: number | null;
    usersCount?: number | null;
    physicianCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  resourceEvents?: {
    __typename: "ModelPatientResourceScheduledEventConnectionConnection";
    nextToken?: string | null;
  } | null;
};

export type OnDeleteTreatmentPlanSubscription = {
  __typename: "TreatmentPlan";
  id: string;
  accountId: string;
  locationId: string;
  patientId: string;
  providers?: Array<string | null> | null;
  treatmentPlanManagerId?: string | null;
  created: string;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  location?: {
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
    callerId?: string | null;
    testsCount?: number | null;
    usersCount?: number | null;
    physicianCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  resourceEvents?: {
    __typename: "ModelPatientResourceScheduledEventConnectionConnection";
    nextToken?: string | null;
  } | null;
};

export type OnCreatePatientResourceScheduledEventConnectionSubscription = {
  __typename: "PatientResourceScheduledEventConnection";
  id: string;
  accountId: string;
  patientId: string;
  encounterId: string;
  resourceScheduledEventId: string;
  created?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  resourceScheduledEvent?: {
    __typename: "ResourceScheduledEvent";
    id: string;
    accountId: string;
    ownerId: string;
    locationId: string;
    title: string;
    start: string;
    end: string;
    startTimezone?: string | null;
    endTimezone?: string | null;
    isAllDay?: boolean | null;
    description?: string | null;
    recurrenceRule?: string | null;
    recurrenceExceptions?: string | null;
    recurrenceId?: string | null;
    resourceId: string;
    category: ResourceCategory;
    cost?: number | null;
    ageGroup?: AgeGroup | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdatePatientResourceScheduledEventConnectionSubscription = {
  __typename: "PatientResourceScheduledEventConnection";
  id: string;
  accountId: string;
  patientId: string;
  encounterId: string;
  resourceScheduledEventId: string;
  created?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  resourceScheduledEvent?: {
    __typename: "ResourceScheduledEvent";
    id: string;
    accountId: string;
    ownerId: string;
    locationId: string;
    title: string;
    start: string;
    end: string;
    startTimezone?: string | null;
    endTimezone?: string | null;
    isAllDay?: boolean | null;
    description?: string | null;
    recurrenceRule?: string | null;
    recurrenceExceptions?: string | null;
    recurrenceId?: string | null;
    resourceId: string;
    category: ResourceCategory;
    cost?: number | null;
    ageGroup?: AgeGroup | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeletePatientResourceScheduledEventConnectionSubscription = {
  __typename: "PatientResourceScheduledEventConnection";
  id: string;
  accountId: string;
  patientId: string;
  encounterId: string;
  resourceScheduledEventId: string;
  created?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  resourceScheduledEvent?: {
    __typename: "ResourceScheduledEvent";
    id: string;
    accountId: string;
    ownerId: string;
    locationId: string;
    title: string;
    start: string;
    end: string;
    startTimezone?: string | null;
    endTimezone?: string | null;
    isAllDay?: boolean | null;
    description?: string | null;
    recurrenceRule?: string | null;
    recurrenceExceptions?: string | null;
    recurrenceId?: string | null;
    resourceId: string;
    category: ResourceCategory;
    cost?: number | null;
    ageGroup?: AgeGroup | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateTelehealthSessionSubscription = {
  __typename: "TelehealthSession";
  id: string;
  title: string;
  accountId: string;
  participantIds: Array<string | null>;
  participants: Array<{
    __typename: "TelehealthSessionParticipant";
    name: string;
    type?: UserType | null;
    identity: string;
  } | null>;
  status?: TelehealthSessionStatus | null;
  roomType?: RoomType | null;
  startedAt?: string | null;
  endedAt?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateTelehealthSessionSubscription = {
  __typename: "TelehealthSession";
  id: string;
  title: string;
  accountId: string;
  participantIds: Array<string | null>;
  participants: Array<{
    __typename: "TelehealthSessionParticipant";
    name: string;
    type?: UserType | null;
    identity: string;
  } | null>;
  status?: TelehealthSessionStatus | null;
  roomType?: RoomType | null;
  startedAt?: string | null;
  endedAt?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteTelehealthSessionSubscription = {
  __typename: "TelehealthSession";
  id: string;
  title: string;
  accountId: string;
  participantIds: Array<string | null>;
  participants: Array<{
    __typename: "TelehealthSessionParticipant";
    name: string;
    type?: UserType | null;
    identity: string;
  } | null>;
  status?: TelehealthSessionStatus | null;
  roomType?: RoomType | null;
  startedAt?: string | null;
  endedAt?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateScheduledEventSubscription = {
  __typename: "ScheduledEvent";
  id: string;
  accountId: string;
  ownerId: string;
  invitees: Array<{
    __typename: "ScheduledEventInvitee";
    name: string;
    type?: UserType | null;
    id?: string | null;
  } | null>;
  participantIds: Array<string | null>;
  title: string;
  start: string;
  end: string;
  type: ScheduledEventType;
  assessments?: Array<{
    __typename: "ScheduledEventAssessment";
    id?: string | null;
    name?: string | null;
  } | null> | null;
  startTimezone?: string | null;
  endTimezone?: string | null;
  isAllDay?: boolean | null;
  description?: string | null;
  recurrenceRule?: string | null;
  recurrenceExceptions?: string | null;
  recurrenceId?: string | null;
  createdAt: string;
  updatedAt: string;
  resource?: {
    __typename: "ResourceScheduledEvent";
    id: string;
    accountId: string;
    ownerId: string;
    locationId: string;
    title: string;
    start: string;
    end: string;
    startTimezone?: string | null;
    endTimezone?: string | null;
    isAllDay?: boolean | null;
    description?: string | null;
    recurrenceRule?: string | null;
    recurrenceExceptions?: string | null;
    recurrenceId?: string | null;
    resourceId: string;
    category: ResourceCategory;
    cost?: number | null;
    ageGroup?: AgeGroup | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateScheduledEventSubscription = {
  __typename: "ScheduledEvent";
  id: string;
  accountId: string;
  ownerId: string;
  invitees: Array<{
    __typename: "ScheduledEventInvitee";
    name: string;
    type?: UserType | null;
    id?: string | null;
  } | null>;
  participantIds: Array<string | null>;
  title: string;
  start: string;
  end: string;
  type: ScheduledEventType;
  assessments?: Array<{
    __typename: "ScheduledEventAssessment";
    id?: string | null;
    name?: string | null;
  } | null> | null;
  startTimezone?: string | null;
  endTimezone?: string | null;
  isAllDay?: boolean | null;
  description?: string | null;
  recurrenceRule?: string | null;
  recurrenceExceptions?: string | null;
  recurrenceId?: string | null;
  createdAt: string;
  updatedAt: string;
  resource?: {
    __typename: "ResourceScheduledEvent";
    id: string;
    accountId: string;
    ownerId: string;
    locationId: string;
    title: string;
    start: string;
    end: string;
    startTimezone?: string | null;
    endTimezone?: string | null;
    isAllDay?: boolean | null;
    description?: string | null;
    recurrenceRule?: string | null;
    recurrenceExceptions?: string | null;
    recurrenceId?: string | null;
    resourceId: string;
    category: ResourceCategory;
    cost?: number | null;
    ageGroup?: AgeGroup | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteScheduledEventSubscription = {
  __typename: "ScheduledEvent";
  id: string;
  accountId: string;
  ownerId: string;
  invitees: Array<{
    __typename: "ScheduledEventInvitee";
    name: string;
    type?: UserType | null;
    id?: string | null;
  } | null>;
  participantIds: Array<string | null>;
  title: string;
  start: string;
  end: string;
  type: ScheduledEventType;
  assessments?: Array<{
    __typename: "ScheduledEventAssessment";
    id?: string | null;
    name?: string | null;
  } | null> | null;
  startTimezone?: string | null;
  endTimezone?: string | null;
  isAllDay?: boolean | null;
  description?: string | null;
  recurrenceRule?: string | null;
  recurrenceExceptions?: string | null;
  recurrenceId?: string | null;
  createdAt: string;
  updatedAt: string;
  resource?: {
    __typename: "ResourceScheduledEvent";
    id: string;
    accountId: string;
    ownerId: string;
    locationId: string;
    title: string;
    start: string;
    end: string;
    startTimezone?: string | null;
    endTimezone?: string | null;
    isAllDay?: boolean | null;
    description?: string | null;
    recurrenceRule?: string | null;
    recurrenceExceptions?: string | null;
    recurrenceId?: string | null;
    resourceId: string;
    category: ResourceCategory;
    cost?: number | null;
    ageGroup?: AgeGroup | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateResourceScheduledEventSubscription = {
  __typename: "ResourceScheduledEvent";
  id: string;
  accountId: string;
  ownerId: string;
  locationId: string;
  title: string;
  start: string;
  end: string;
  startTimezone?: string | null;
  endTimezone?: string | null;
  isAllDay?: boolean | null;
  description?: string | null;
  recurrenceRule?: string | null;
  recurrenceExceptions?: string | null;
  recurrenceId?: string | null;
  resourceId: string;
  category: ResourceCategory;
  cost?: number | null;
  ageGroup?: AgeGroup | null;
  createdAt: string;
  updatedAt: string;
  resource?: {
    __typename: "EventResource";
    id: string;
    ownerId?: string | null;
    accountId?: string | null;
    locationId?: string | null;
    name?: string | null;
    type?: ResourceType | null;
    classification?: EventResourceClassification | null;
    website?: string | null;
    businessHours?: string | null;
    billedTime?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateResourceScheduledEventSubscription = {
  __typename: "ResourceScheduledEvent";
  id: string;
  accountId: string;
  ownerId: string;
  locationId: string;
  title: string;
  start: string;
  end: string;
  startTimezone?: string | null;
  endTimezone?: string | null;
  isAllDay?: boolean | null;
  description?: string | null;
  recurrenceRule?: string | null;
  recurrenceExceptions?: string | null;
  recurrenceId?: string | null;
  resourceId: string;
  category: ResourceCategory;
  cost?: number | null;
  ageGroup?: AgeGroup | null;
  createdAt: string;
  updatedAt: string;
  resource?: {
    __typename: "EventResource";
    id: string;
    ownerId?: string | null;
    accountId?: string | null;
    locationId?: string | null;
    name?: string | null;
    type?: ResourceType | null;
    classification?: EventResourceClassification | null;
    website?: string | null;
    businessHours?: string | null;
    billedTime?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteResourceScheduledEventSubscription = {
  __typename: "ResourceScheduledEvent";
  id: string;
  accountId: string;
  ownerId: string;
  locationId: string;
  title: string;
  start: string;
  end: string;
  startTimezone?: string | null;
  endTimezone?: string | null;
  isAllDay?: boolean | null;
  description?: string | null;
  recurrenceRule?: string | null;
  recurrenceExceptions?: string | null;
  recurrenceId?: string | null;
  resourceId: string;
  category: ResourceCategory;
  cost?: number | null;
  ageGroup?: AgeGroup | null;
  createdAt: string;
  updatedAt: string;
  resource?: {
    __typename: "EventResource";
    id: string;
    ownerId?: string | null;
    accountId?: string | null;
    locationId?: string | null;
    name?: string | null;
    type?: ResourceType | null;
    classification?: EventResourceClassification | null;
    website?: string | null;
    businessHours?: string | null;
    billedTime?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateSessionRecordingSubscription = {
  __typename: "SessionRecording";
  id?: string | null;
  codec?: string | null;
  container?: string | null;
  offset?: string | null;
  sourceSid?: string | null;
  twilioMediaUri?: string | null;
  roomSid?: string | null;
  eventId?: string | null;
  ownerId: string;
  sessionId: string;
  s3Url?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateSessionRecordingSubscription = {
  __typename: "SessionRecording";
  id?: string | null;
  codec?: string | null;
  container?: string | null;
  offset?: string | null;
  sourceSid?: string | null;
  twilioMediaUri?: string | null;
  roomSid?: string | null;
  eventId?: string | null;
  ownerId: string;
  sessionId: string;
  s3Url?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteSessionRecordingSubscription = {
  __typename: "SessionRecording";
  id?: string | null;
  codec?: string | null;
  container?: string | null;
  offset?: string | null;
  sourceSid?: string | null;
  twilioMediaUri?: string | null;
  roomSid?: string | null;
  eventId?: string | null;
  ownerId: string;
  sessionId: string;
  s3Url?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateEventResourceSubscription = {
  __typename: "EventResource";
  id: string;
  ownerId?: string | null;
  accountId?: string | null;
  locationId?: string | null;
  name?: string | null;
  type?: ResourceType | null;
  classification?: EventResourceClassification | null;
  website?: string | null;
  contact?: {
    __typename: "Contact";
    id?: string | null;
    salutation?: Salutation | null;
    givenName: string;
    familyName: string;
    mobile?: string | null;
    email?: string | null;
    contactType?: ContactType | null;
  } | null;
  address?: {
    __typename: "Address";
    line1?: string | null;
    line2?: string | null;
    region?: string | null;
    postal?: string | null;
    country?: string | null;
  } | null;
  businessHours?: string | null;
  billedTime?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateEventResourceSubscription = {
  __typename: "EventResource";
  id: string;
  ownerId?: string | null;
  accountId?: string | null;
  locationId?: string | null;
  name?: string | null;
  type?: ResourceType | null;
  classification?: EventResourceClassification | null;
  website?: string | null;
  contact?: {
    __typename: "Contact";
    id?: string | null;
    salutation?: Salutation | null;
    givenName: string;
    familyName: string;
    mobile?: string | null;
    email?: string | null;
    contactType?: ContactType | null;
  } | null;
  address?: {
    __typename: "Address";
    line1?: string | null;
    line2?: string | null;
    region?: string | null;
    postal?: string | null;
    country?: string | null;
  } | null;
  businessHours?: string | null;
  billedTime?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteEventResourceSubscription = {
  __typename: "EventResource";
  id: string;
  ownerId?: string | null;
  accountId?: string | null;
  locationId?: string | null;
  name?: string | null;
  type?: ResourceType | null;
  classification?: EventResourceClassification | null;
  website?: string | null;
  contact?: {
    __typename: "Contact";
    id?: string | null;
    salutation?: Salutation | null;
    givenName: string;
    familyName: string;
    mobile?: string | null;
    email?: string | null;
    contactType?: ContactType | null;
  } | null;
  address?: {
    __typename: "Address";
    line1?: string | null;
    line2?: string | null;
    region?: string | null;
    postal?: string | null;
    country?: string | null;
  } | null;
  businessHours?: string | null;
  billedTime?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateQuestionnaireSubscription = {
  __typename: "Questionnaire";
  id: string;
  created?: string | null;
  name?: string | null;
  title?: string | null;
  template?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateQuestionnaireSubscription = {
  __typename: "Questionnaire";
  id: string;
  created?: string | null;
  name?: string | null;
  title?: string | null;
  template?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteQuestionnaireSubscription = {
  __typename: "Questionnaire";
  id: string;
  created?: string | null;
  name?: string | null;
  title?: string | null;
  template?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateQuestionnaireResponseAuthorSubscription = {
  __typename: "QuestionnaireResponseAuthor";
  id: string;
  accountId: string;
  responseId: string;
  authorId: string;
  createdAt: string;
  updatedAt: string;
  author?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  questionnaireResponse?: {
    __typename: "QuestionnaireResponse";
    id: string;
    accountId: string;
    patientId: string;
    encounterId: string;
    contributors?: string | null;
    created?: string | null;
    name?: string | null;
    title?: string | null;
    status?: QuestionnaireStatus | null;
    json?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateQuestionnaireResponseAuthorSubscription = {
  __typename: "QuestionnaireResponseAuthor";
  id: string;
  accountId: string;
  responseId: string;
  authorId: string;
  createdAt: string;
  updatedAt: string;
  author?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  questionnaireResponse?: {
    __typename: "QuestionnaireResponse";
    id: string;
    accountId: string;
    patientId: string;
    encounterId: string;
    contributors?: string | null;
    created?: string | null;
    name?: string | null;
    title?: string | null;
    status?: QuestionnaireStatus | null;
    json?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteQuestionnaireResponseAuthorSubscription = {
  __typename: "QuestionnaireResponseAuthor";
  id: string;
  accountId: string;
  responseId: string;
  authorId: string;
  createdAt: string;
  updatedAt: string;
  author?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  questionnaireResponse?: {
    __typename: "QuestionnaireResponse";
    id: string;
    accountId: string;
    patientId: string;
    encounterId: string;
    contributors?: string | null;
    created?: string | null;
    name?: string | null;
    title?: string | null;
    status?: QuestionnaireStatus | null;
    json?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateQuestionnaireResponseSubscription = {
  __typename: "QuestionnaireResponse";
  id: string;
  accountId: string;
  patientId: string;
  encounterId: string;
  contributors?: string | null;
  created?: string | null;
  name?: string | null;
  title?: string | null;
  status?: QuestionnaireStatus | null;
  json?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  authors?: {
    __typename: "ModelQuestionnaireResponseAuthorConnection";
    nextToken?: string | null;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateQuestionnaireResponseSubscription = {
  __typename: "QuestionnaireResponse";
  id: string;
  accountId: string;
  patientId: string;
  encounterId: string;
  contributors?: string | null;
  created?: string | null;
  name?: string | null;
  title?: string | null;
  status?: QuestionnaireStatus | null;
  json?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  authors?: {
    __typename: "ModelQuestionnaireResponseAuthorConnection";
    nextToken?: string | null;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteQuestionnaireResponseSubscription = {
  __typename: "QuestionnaireResponse";
  id: string;
  accountId: string;
  patientId: string;
  encounterId: string;
  contributors?: string | null;
  created?: string | null;
  name?: string | null;
  title?: string | null;
  status?: QuestionnaireStatus | null;
  json?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  authors?: {
    __typename: "ModelQuestionnaireResponseAuthorConnection";
    nextToken?: string | null;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateClinicalImpressionSubscription = {
  __typename: "ClinicalImpression";
  id?: string | null;
  accountId: string;
  patientId: string;
  encounterId: string;
  authorId?: string | null;
  created?: string | null;
  title?: string | null;
  status?: ClinicalImpressionStatus | null;
  summary?: string | null;
  name?: string | null;
  signature?: string | null;
  approved?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  questionnaireResponse?: {
    __typename: "QuestionnaireResponse";
    id: string;
    accountId: string;
    patientId: string;
    encounterId: string;
    contributors?: string | null;
    created?: string | null;
    name?: string | null;
    title?: string | null;
    status?: QuestionnaireStatus | null;
    json?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateClinicalImpressionSubscription = {
  __typename: "ClinicalImpression";
  id?: string | null;
  accountId: string;
  patientId: string;
  encounterId: string;
  authorId?: string | null;
  created?: string | null;
  title?: string | null;
  status?: ClinicalImpressionStatus | null;
  summary?: string | null;
  name?: string | null;
  signature?: string | null;
  approved?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  questionnaireResponse?: {
    __typename: "QuestionnaireResponse";
    id: string;
    accountId: string;
    patientId: string;
    encounterId: string;
    contributors?: string | null;
    created?: string | null;
    name?: string | null;
    title?: string | null;
    status?: QuestionnaireStatus | null;
    json?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteClinicalImpressionSubscription = {
  __typename: "ClinicalImpression";
  id?: string | null;
  accountId: string;
  patientId: string;
  encounterId: string;
  authorId?: string | null;
  created?: string | null;
  title?: string | null;
  status?: ClinicalImpressionStatus | null;
  summary?: string | null;
  name?: string | null;
  signature?: string | null;
  approved?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  questionnaireResponse?: {
    __typename: "QuestionnaireResponse";
    id: string;
    accountId: string;
    patientId: string;
    encounterId: string;
    contributors?: string | null;
    created?: string | null;
    name?: string | null;
    title?: string | null;
    status?: QuestionnaireStatus | null;
    json?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateEncounterSubscription = {
  __typename: "Encounter";
  id: string;
  accountId: string;
  locationId: string;
  managerId: string;
  patientId: string;
  ownerId: string;
  status?: EncounterStatus | null;
  date: string;
  soapItems?: Array<{
    __typename: "SoapItem";
    type?: SoapItemType | null;
    text?: string | null;
    code?: string | null;
    selected?: boolean | null;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  manager?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  owner?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  location?: {
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
    callerId?: string | null;
    testsCount?: number | null;
    usersCount?: number | null;
    physicianCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  billedTimes?: {
    __typename: "ModelEncounterBilledTimeConnectionConnection";
    nextToken?: string | null;
  } | null;
  pretest?: {
    __typename: "Assessment";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    managerId: string;
    submitterId?: string | null;
    result?: string | null;
    testId?: string | null;
    testCode?: string | null;
    meta?: number | null;
    answerSheetId?: string | null;
    title?: string | null;
    type?: AssessmentType | null;
    assessed?: string | null;
    scheduledDate?: string | null;
    score?: number | null;
    scoreGauge?: ScoreGauge | null;
    status?: AssessmentStatus | null;
    assessmentEncounterId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  assessments?: {
    __typename: "ModelAssessmentConnection";
    nextToken?: string | null;
  } | null;
  questionnaireResponses?: {
    __typename: "ModelQuestionnaireResponseConnection";
    nextToken?: string | null;
  } | null;
  clinicalImpressions?: {
    __typename: "ModelClinicalImpressionConnection";
    nextToken?: string | null;
  } | null;
  providers?: {
    __typename: "ModelEncounterProviderConnectionConnection";
    nextToken?: string | null;
  } | null;
  notes?: {
    __typename: "ModelEncounterNoteConnectionConnection";
    nextToken?: string | null;
  } | null;
};

export type OnUpdateEncounterSubscription = {
  __typename: "Encounter";
  id: string;
  accountId: string;
  locationId: string;
  managerId: string;
  patientId: string;
  ownerId: string;
  status?: EncounterStatus | null;
  date: string;
  soapItems?: Array<{
    __typename: "SoapItem";
    type?: SoapItemType | null;
    text?: string | null;
    code?: string | null;
    selected?: boolean | null;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  manager?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  owner?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  location?: {
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
    callerId?: string | null;
    testsCount?: number | null;
    usersCount?: number | null;
    physicianCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  billedTimes?: {
    __typename: "ModelEncounterBilledTimeConnectionConnection";
    nextToken?: string | null;
  } | null;
  pretest?: {
    __typename: "Assessment";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    managerId: string;
    submitterId?: string | null;
    result?: string | null;
    testId?: string | null;
    testCode?: string | null;
    meta?: number | null;
    answerSheetId?: string | null;
    title?: string | null;
    type?: AssessmentType | null;
    assessed?: string | null;
    scheduledDate?: string | null;
    score?: number | null;
    scoreGauge?: ScoreGauge | null;
    status?: AssessmentStatus | null;
    assessmentEncounterId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  assessments?: {
    __typename: "ModelAssessmentConnection";
    nextToken?: string | null;
  } | null;
  questionnaireResponses?: {
    __typename: "ModelQuestionnaireResponseConnection";
    nextToken?: string | null;
  } | null;
  clinicalImpressions?: {
    __typename: "ModelClinicalImpressionConnection";
    nextToken?: string | null;
  } | null;
  providers?: {
    __typename: "ModelEncounterProviderConnectionConnection";
    nextToken?: string | null;
  } | null;
  notes?: {
    __typename: "ModelEncounterNoteConnectionConnection";
    nextToken?: string | null;
  } | null;
};

export type OnDeleteEncounterSubscription = {
  __typename: "Encounter";
  id: string;
  accountId: string;
  locationId: string;
  managerId: string;
  patientId: string;
  ownerId: string;
  status?: EncounterStatus | null;
  date: string;
  soapItems?: Array<{
    __typename: "SoapItem";
    type?: SoapItemType | null;
    text?: string | null;
    code?: string | null;
    selected?: boolean | null;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  manager?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  owner?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  location?: {
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
    callerId?: string | null;
    testsCount?: number | null;
    usersCount?: number | null;
    physicianCount?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  billedTimes?: {
    __typename: "ModelEncounterBilledTimeConnectionConnection";
    nextToken?: string | null;
  } | null;
  pretest?: {
    __typename: "Assessment";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    managerId: string;
    submitterId?: string | null;
    result?: string | null;
    testId?: string | null;
    testCode?: string | null;
    meta?: number | null;
    answerSheetId?: string | null;
    title?: string | null;
    type?: AssessmentType | null;
    assessed?: string | null;
    scheduledDate?: string | null;
    score?: number | null;
    scoreGauge?: ScoreGauge | null;
    status?: AssessmentStatus | null;
    assessmentEncounterId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  assessments?: {
    __typename: "ModelAssessmentConnection";
    nextToken?: string | null;
  } | null;
  questionnaireResponses?: {
    __typename: "ModelQuestionnaireResponseConnection";
    nextToken?: string | null;
  } | null;
  clinicalImpressions?: {
    __typename: "ModelClinicalImpressionConnection";
    nextToken?: string | null;
  } | null;
  providers?: {
    __typename: "ModelEncounterProviderConnectionConnection";
    nextToken?: string | null;
  } | null;
  notes?: {
    __typename: "ModelEncounterNoteConnectionConnection";
    nextToken?: string | null;
  } | null;
};

export type OnCreateEncounterProviderConnectionSubscription = {
  __typename: "EncounterProviderConnection";
  id: string;
  accountId: string;
  encounterId: string;
  providerId: string;
  createdAt: string;
  updatedAt: string;
  provider?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateEncounterProviderConnectionSubscription = {
  __typename: "EncounterProviderConnection";
  id: string;
  accountId: string;
  encounterId: string;
  providerId: string;
  createdAt: string;
  updatedAt: string;
  provider?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteEncounterProviderConnectionSubscription = {
  __typename: "EncounterProviderConnection";
  id: string;
  accountId: string;
  encounterId: string;
  providerId: string;
  createdAt: string;
  updatedAt: string;
  provider?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateEncounterNoteConnectionSubscription = {
  __typename: "EncounterNoteConnection";
  id: string;
  accountId: string;
  encounterId: string;
  noteId: string;
  createdAt: string;
  updatedAt: string;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  note?: {
    __typename: "Note";
    id: string;
    author: AuthorType;
    userId: string;
    patientId: string;
    type?: NoteType | null;
    title?: string | null;
    content?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateEncounterNoteConnectionSubscription = {
  __typename: "EncounterNoteConnection";
  id: string;
  accountId: string;
  encounterId: string;
  noteId: string;
  createdAt: string;
  updatedAt: string;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  note?: {
    __typename: "Note";
    id: string;
    author: AuthorType;
    userId: string;
    patientId: string;
    type?: NoteType | null;
    title?: string | null;
    content?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteEncounterNoteConnectionSubscription = {
  __typename: "EncounterNoteConnection";
  id: string;
  accountId: string;
  encounterId: string;
  noteId: string;
  createdAt: string;
  updatedAt: string;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  encounter?: {
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  note?: {
    __typename: "Note";
    id: string;
    author: AuthorType;
    userId: string;
    patientId: string;
    type?: NoteType | null;
    title?: string | null;
    content?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateNoteSubscription = {
  __typename: "Note";
  id: string;
  author: AuthorType;
  userId: string;
  patientId: string;
  type?: NoteType | null;
  title?: string | null;
  content?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  user?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateNoteSubscription = {
  __typename: "Note";
  id: string;
  author: AuthorType;
  userId: string;
  patientId: string;
  type?: NoteType | null;
  title?: string | null;
  content?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  user?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteNoteSubscription = {
  __typename: "Note";
  id: string;
  author: AuthorType;
  userId: string;
  patientId: string;
  type?: NoteType | null;
  title?: string | null;
  content?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  user?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateDiagnosticCodeSubscription = {
  __typename: "DiagnosticCode";
  id: string;
  accountId: string;
  codeId: string;
  patientId: string;
  diagnosed?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  code?: {
    __typename: "Code";
    id: string;
    type: CodeType;
    name: string;
    description?: string | null;
    rate?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateDiagnosticCodeSubscription = {
  __typename: "DiagnosticCode";
  id: string;
  accountId: string;
  codeId: string;
  patientId: string;
  diagnosed?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  code?: {
    __typename: "Code";
    id: string;
    type: CodeType;
    name: string;
    description?: string | null;
    rate?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteDiagnosticCodeSubscription = {
  __typename: "DiagnosticCode";
  id: string;
  accountId: string;
  codeId: string;
  patientId: string;
  diagnosed?: string | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    stripeCustomerId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  code?: {
    __typename: "Code";
    id: string;
    type: CodeType;
    name: string;
    description?: string | null;
    rate?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateBillingItemSubscription = {
  __typename: "BillingItem";
  id: string;
  accountId: string;
  locationId?: string | null;
  patientId: string;
  codeId: string;
  amount: number;
  type?: BillingType | null;
  currency?: string | null;
  created?: string | null;
  serviced?: string | null;
  status?: BillingItemStatus | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  billedTime?: {
    __typename: "ModelBilledTimeConnection";
    nextToken?: string | null;
  } | null;
  code?: {
    __typename: "Code";
    id: string;
    type: CodeType;
    name: string;
    description?: string | null;
    rate?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdateBillingItemSubscription = {
  __typename: "BillingItem";
  id: string;
  accountId: string;
  locationId?: string | null;
  patientId: string;
  codeId: string;
  amount: number;
  type?: BillingType | null;
  currency?: string | null;
  created?: string | null;
  serviced?: string | null;
  status?: BillingItemStatus | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  billedTime?: {
    __typename: "ModelBilledTimeConnection";
    nextToken?: string | null;
  } | null;
  code?: {
    __typename: "Code";
    id: string;
    type: CodeType;
    name: string;
    description?: string | null;
    rate?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeleteBillingItemSubscription = {
  __typename: "BillingItem";
  id: string;
  accountId: string;
  locationId?: string | null;
  patientId: string;
  codeId: string;
  amount: number;
  type?: BillingType | null;
  currency?: string | null;
  created?: string | null;
  serviced?: string | null;
  status?: BillingItemStatus | null;
  createdAt: string;
  updatedAt: string;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  billedTime?: {
    __typename: "ModelBilledTimeConnection";
    nextToken?: string | null;
  } | null;
  code?: {
    __typename: "Code";
    id: string;
    type: CodeType;
    name: string;
    description?: string | null;
    rate?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateInvoiceSubscription = {
  __typename: "Invoice";
  id: string;
  accountId: string;
  locationId: string;
  dueDate: string;
  billDate: string;
  billingPeriodStartDate: string;
  billingPeriodEndDate: string;
  hours?: number | null;
  totalBill?: number | null;
  status?: InvoiceStatus | null;
  createdAt: string;
  updatedAt: string;
  billingItems?: {
    __typename: "ModelBillingItemConnection";
    nextToken?: string | null;
  } | null;
};

export type OnUpdateInvoiceSubscription = {
  __typename: "Invoice";
  id: string;
  accountId: string;
  locationId: string;
  dueDate: string;
  billDate: string;
  billingPeriodStartDate: string;
  billingPeriodEndDate: string;
  hours?: number | null;
  totalBill?: number | null;
  status?: InvoiceStatus | null;
  createdAt: string;
  updatedAt: string;
  billingItems?: {
    __typename: "ModelBillingItemConnection";
    nextToken?: string | null;
  } | null;
};

export type OnDeleteInvoiceSubscription = {
  __typename: "Invoice";
  id: string;
  accountId: string;
  locationId: string;
  dueDate: string;
  billDate: string;
  billingPeriodStartDate: string;
  billingPeriodEndDate: string;
  hours?: number | null;
  totalBill?: number | null;
  status?: InvoiceStatus | null;
  createdAt: string;
  updatedAt: string;
  billingItems?: {
    __typename: "ModelBillingItemConnection";
    nextToken?: string | null;
  } | null;
};

export type OnCreatePaymentSubscription = {
  __typename: "Payment";
  id: string;
  accountId: string;
  locationId: string;
  userId: string;
  stripePaymentMethodId: string;
  paymentDate: string;
  createdAt: string;
  updatedAt: string;
  invoice?: {
    __typename: "Invoice";
    id: string;
    accountId: string;
    locationId: string;
    dueDate: string;
    billDate: string;
    billingPeriodStartDate: string;
    billingPeriodEndDate: string;
    hours?: number | null;
    totalBill?: number | null;
    status?: InvoiceStatus | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnUpdatePaymentSubscription = {
  __typename: "Payment";
  id: string;
  accountId: string;
  locationId: string;
  userId: string;
  stripePaymentMethodId: string;
  paymentDate: string;
  createdAt: string;
  updatedAt: string;
  invoice?: {
    __typename: "Invoice";
    id: string;
    accountId: string;
    locationId: string;
    dueDate: string;
    billDate: string;
    billingPeriodStartDate: string;
    billingPeriodEndDate: string;
    hours?: number | null;
    totalBill?: number | null;
    status?: InvoiceStatus | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnDeletePaymentSubscription = {
  __typename: "Payment";
  id: string;
  accountId: string;
  locationId: string;
  userId: string;
  stripePaymentMethodId: string;
  paymentDate: string;
  createdAt: string;
  updatedAt: string;
  invoice?: {
    __typename: "Invoice";
    id: string;
    accountId: string;
    locationId: string;
    dueDate: string;
    billDate: string;
    billingPeriodStartDate: string;
    billingPeriodEndDate: string;
    hours?: number | null;
    totalBill?: number | null;
    status?: InvoiceStatus | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type OnCreateCodeSubscription = {
  __typename: "Code";
  id: string;
  type: CodeType;
  name: string;
  description?: string | null;
  rate?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateCodeSubscription = {
  __typename: "Code";
  id: string;
  type: CodeType;
  name: string;
  description?: string | null;
  rate?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteCodeSubscription = {
  __typename: "Code";
  id: string;
  type: CodeType;
  name: string;
  description?: string | null;
  rate?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type GetPatientsListQuery = {
  __typename: "ModelPatientConnection";
  items?: Array<{
    __typename: "Patient";
    id: string;
    salutation?: string | null;
    givenName: string;
    familyName: string;
    dob?: string | null;
    gender?: Gender | null;
    email?: string | null;
    assessments?: {
      __typename: "ModelAssessmentConnection";
      nextToken?: string | null;
      items?: Array<{
        __typename: "Assessment";
        id: string;
        accountId: string;
        locationId: string;
        patientId: string;
        providers?: Array<string | null> | null;
        managerId: string;
        submitterId?: string | null;
        result?: string | null;
        testId?: string | null;
        testCode?: string | null;
        meta?: number | null;
        answerSheetId?: string | null;
        title?: string | null;
        type?: AssessmentType | null;
        assessed?: string | null;
        scheduledDate?: string | null;
        score?: number | null;
        scoreGauge?: ScoreGauge | null;
        status?: AssessmentStatus | null;
        createdAt: string;
        updatedAt: string;
        billedTime?: {
          __typename: "BilledTime";
          id: string;
          minutes?: number | null;
          type?: BilledTimeType | null;
          date?: string | null;
          userId: string;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null> | null;
    } | null;
    lastAssessment?: {
      __typename: "Assessment";
      id: string;
      testCode?: string | null;
      status?: AssessmentStatus | null;
    } | null;
    providers?: {
      __typename: "ModelPatientProviderConnectionConnection";
      items?: Array<{
        __typename: "PatientProviderConnection";
        type?: ProviderType | null;
        provider?: {
          __typename: "User";
          id: string;
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      } | null> | null;
    } | null;
    manager?: {
      __typename: "User";
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
  } | null> | null;
};

export type GetLocationsListQuery = {
  __typename: "ModelLocationConnection";
  items?: Array<{
    __typename: "Location";
    id: string;
    owner?: string | null;
    accountId: string;
    name: string;
  } | null> | null;
};

export type GetLoggedInUserQuery = {
  __typename: "User";
  id: string;
  accountId: string;
  owner?: string | null;
  email: string;
  phone?: string | null;
  online?: boolean | null;
  firstName?: string | null;
  lastName?: string | null;
  titles?: Array<string | null> | null;
  npi?: string | null;
  image?: string | null;
  userType?: UserType | null;
  connections?: Array<{
    __typename: "WSConnection";
    connectionId?: string | null;
    startedAt?: string | null;
  } | null> | null;
  onlineStatus?: UserOnlineStatus | null;
  lastLogin?: {
    __typename: "LoginEvent";
    ip?: string | null;
    date: string;
  } | null;
  pinCode?: string | null;
  permissions?: Array<AccessPermission | null> | null;
  createdAt: string;
  updatedAt: string;
  locations?: {
    __typename: "ModelUserLocationConnectionConnection";
    nextToken?: string | null;
    items?: Array<{
      __typename: "UserLocationConnection";
      id: string;
      location?: {
        __typename: "Location";
        id: string;
        name: string;
      } | null;
    } | null> | null;
  } | null;
  account?: {
    __typename: "Account";
    id: string;
    name: string;
    owner?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
};

export type ListResourceScheduledEventsExtendedQuery = {
  __typename: "ModelResourceScheduledEventConnection";
  items?: Array<{
    __typename: "ResourceScheduledEvent";
    id: string;
    accountId: string;
    ownerId: string;
    locationId: string;
    title: string;
    start: string;
    end: string;
    startTimezone?: string | null;
    endTimezone?: string | null;
    isAllDay?: boolean | null;
    description?: string | null;
    recurrenceRule?: string | null;
    recurrenceExceptions?: string | null;
    recurrenceId?: string | null;
    resourceId: string;
    resource?: {
      __typename: "EventResource";
      id: string;
      ownerId?: string | null;
      accountId?: string | null;
      locationId?: string | null;
      name?: string | null;
      type?: ResourceType | null;
      classification?: EventResourceClassification | null;
      website?: string | null;
      businessHours?: string | null;
      billedTime?: number | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    category: ResourceCategory;
    cost?: number | null;
    ageGroup?: AgeGroup | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetEncounterExtendedQuery = {
  __typename: "Encounter";
  id: string;
  accountId: string;
  locationId: string;
  managerId: string;
  patientId: string;
  ownerId: string;
  status?: EncounterStatus | null;
  date: string;
  soapItems?: Array<{
    __typename: "SoapItem";
    type?: SoapItemType | null;
    text?: string | null;
    code?: string | null;
    selected?: boolean | null;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
  location?: {
    __typename: "Location";
    accountId: string;
    callerId?: string | null;
    createdAt: string;
    id: string;
    name: string;
    owner?: string | null;
    physicianCount?: number | null;
    testsCount?: number | null;
    updatedAt: string;
    usersCount?: number | null;
  } | null;
  patient?: {
    __typename: "Patient";
    id: string;
    accountId: string;
    locationId: string;
    patientManagerId?: string | null;
    owner: string;
    salutation?: string | null;
    givenName: string;
    middleName?: string | null;
    familyName: string;
    ssn?: string | null;
    dob?: string | null;
    gender?: Gender | null;
    mobile?: string | null;
    email?: string | null;
    interests?: Array<string | null> | null;
    providerIds?: Array<string | null> | null;
    status?: Status | null;
    eligibleRPM?: boolean | null;
    qualifiedTests?: Array<string | null> | null;
    connectionId?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  manager?: {
    __typename: "User";
    id: string;
    accountId: string;
    owner?: string | null;
    email: string;
    phone?: string | null;
    online?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
    titles?: Array<string | null> | null;
    npi?: string | null;
    image?: string | null;
    userType?: UserType | null;
    onlineStatus?: UserOnlineStatus | null;
    pinCode?: string | null;
    permissions?: Array<AccessPermission | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  billedTimes?: {
    __typename: "ModelEncounterBilledTimeConnectionConnection";
    nextToken?: string | null;
    items?: Array<{
      __typename: "EncounterBilledTimeConnection";
      id?: string | null;
      billedTime?: {
        __typename: "BilledTime";
        id: string;
        minutes?: number | null;
        type?: BilledTimeType | null;
        date?: string | null;
        userId: string;
      } | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
  } | null;
  pretest?: {
    __typename: "Assessment";
    id: string;
    accountId: string;
    locationId: string;
    patientId: string;
    providers?: Array<string | null> | null;
    managerId: string;
    submitterId?: string | null;
    result?: string | null;
    testId?: string | null;
    testCode?: string | null;
    meta?: number | null;
    answerSheetId?: string | null;
    title?: string | null;
    type?: AssessmentType | null;
    assessed?: string | null;
    scheduledDate?: string | null;
    score?: number | null;
    scoreGauge?: ScoreGauge | null;
    status?: AssessmentStatus | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  assessments?: {
    __typename: "ModelAssessmentConnection";
    nextToken?: string | null;
    items?: Array<{
      __typename: "Assessment";
      id: string;
      accountId: string;
      locationId: string;
      patientId: string;
      providers?: Array<string | null> | null;
      managerId: string;
      submitterId?: string | null;
      result?: string | null;
      testId?: string | null;
      testCode?: string | null;
      meta?: number | null;
      answerSheetId?: string | null;
      title?: string | null;
      type?: AssessmentType | null;
      assessed?: string | null;
      scheduledDate?: string | null;
      score?: number | null;
      scoreGauge?: ScoreGauge | null;
      status?: AssessmentStatus | null;
      createdAt: string;
      updatedAt: string;
      billedTime?: {
        __typename: "BilledTime";
        id: string;
        minutes?: number | null;
        type?: BilledTimeType | null;
        date?: string | null;
        userId: string;
        createdAt: string;
        updatedAt: string;
      } | null;
    } | null> | null;
  } | null;
  questionnaireResponses?: {
    __typename: "ModelQuestionnaireResponseConnection";
    nextToken?: string | null;
    items?: Array<{
      __typename: "QuestionnaireResponse";
      id: string;
      accountId: string;
      patientId: string;
      encounterId: string;
      contributors?: string | null;
      created?: string | null;
      name?: string | null;
      title?: string | null;
      status?: QuestionnaireStatus | null;
      json?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
  } | null;
  clinicalImpressions?: {
    __typename: "ModelClinicalImpressionConnection";
    nextToken?: string | null;
    items?: Array<{
      __typename: "ClinicalImpression";
      id?: string | null;
      accountId: string;
      patientId: string;
      encounterId: string;
      authorId?: string | null;
      created?: string | null;
      title?: string | null;
      status?: ClinicalImpressionStatus | null;
      summary?: string | null;
      name?: string | null;
      signature?: string | null;
      approved?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
  } | null;
  providers?: {
    __typename: "ModelEncounterProviderConnectionConnection";
    nextToken?: string | null;
  } | null;
  notes?: {
    __typename: "ModelEncounterNoteConnectionConnection";
    nextToken?: string | null;
  } | null;
};

export type ListUserLocationExtendedConnectionsQuery = {
  __typename: "ModelUserLocationConnectionConnection";
  items?: Array<{
    __typename: "UserLocationConnection";
    id: string;
    accountId: string;
    userId: string;
    locationId: string;
    createdAt: string;
    updatedAt: string;
    location?: {
      __typename: "Location";
      id: string;
      name: string;
    } | null;
    user?: {
      __typename: "User";
      id: string;
      accountId: string;
      owner?: string | null;
      email: string;
      phone?: string | null;
      online?: boolean | null;
      firstName?: string | null;
      lastName?: string | null;
      titles?: Array<string | null> | null;
      npi?: string | null;
      image?: string | null;
      userType?: UserType | null;
      onlineStatus?: UserOnlineStatus | null;
      pinCode?: string | null;
      permissions?: Array<AccessPermission | null> | null;
      createdAt: string;
      updatedAt: string;
    } | null;
  } | null> | null;
  nextToken?: string | null;
};

export type ListEncountersExtendedQuery = {
  __typename: "ModelEncounterConnection";
  items?: Array<{
    __typename: "Encounter";
    id: string;
    accountId: string;
    locationId: string;
    managerId: string;
    patientId: string;
    ownerId: string;
    status?: EncounterStatus | null;
    date: string;
    soapItems?: Array<{
      __typename: "SoapItem";
      type?: SoapItemType | null;
      text?: string | null;
      code?: string | null;
      selected?: boolean | null;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
    location?: {
      __typename: "Location";
      accountId: string;
      callerId?: string | null;
      createdAt: string;
      id: string;
      name: string;
      owner?: string | null;
      physicianCount?: number | null;
      testsCount?: number | null;
      updatedAt: string;
      usersCount?: number | null;
    } | null;
    patient?: {
      __typename: "Patient";
      id: string;
      accountId: string;
      locationId: string;
      patientManagerId?: string | null;
      owner: string;
      salutation?: string | null;
      givenName: string;
      middleName?: string | null;
      familyName: string;
      ssn?: string | null;
      dob?: string | null;
      gender?: Gender | null;
      mobile?: string | null;
      email?: string | null;
      interests?: Array<string | null> | null;
      providerIds?: Array<string | null> | null;
      status?: Status | null;
      eligibleRPM?: boolean | null;
      qualifiedTests?: Array<string | null> | null;
      connectionId?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    manager?: {
      __typename: "User";
      id: string;
      accountId: string;
      owner?: string | null;
      email: string;
      phone?: string | null;
      online?: boolean | null;
      firstName?: string | null;
      lastName?: string | null;
      titles?: Array<string | null> | null;
      npi?: string | null;
      image?: string | null;
      userType?: UserType | null;
      onlineStatus?: UserOnlineStatus | null;
      pinCode?: string | null;
      permissions?: Array<AccessPermission | null> | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    billedTimes?: {
      __typename: "ModelEncounterBilledTimeConnectionConnection";
      nextToken?: string | null;
      items?: Array<{
        __typename: "EncounterBilledTimeConnection";
        id?: string | null;
        billedTime?: {
          __typename: "BilledTime";
          id: string;
          minutes?: number | null;
          type?: BilledTimeType | null;
          date?: string | null;
          userId: string;
        } | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
    } | null;
    pretest?: {
      __typename: "Assessment";
      id: string;
      accountId: string;
      locationId: string;
      patientId: string;
      providers?: Array<string | null> | null;
      managerId: string;
      submitterId?: string | null;
      result?: string | null;
      testId?: string | null;
      testCode?: string | null;
      meta?: number | null;
      answerSheetId?: string | null;
      title?: string | null;
      type?: AssessmentType | null;
      assessed?: string | null;
      scheduledDate?: string | null;
      score?: number | null;
      scoreGauge?: ScoreGauge | null;
      status?: AssessmentStatus | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    assessments?: {
      __typename: "ModelAssessmentConnection";
      nextToken?: string | null;
      items?: Array<{
        __typename: "Assessment";
        id: string;
        accountId: string;
        locationId: string;
        patientId: string;
        providers?: Array<string | null> | null;
        managerId: string;
        submitterId?: string | null;
        result?: string | null;
        testId?: string | null;
        testCode?: string | null;
        meta?: number | null;
        answerSheetId?: string | null;
        title?: string | null;
        type?: AssessmentType | null;
        assessed?: string | null;
        scheduledDate?: string | null;
        score?: number | null;
        scoreGauge?: ScoreGauge | null;
        status?: AssessmentStatus | null;
        createdAt: string;
        updatedAt: string;
        billedTime?: {
          __typename: "BilledTime";
          id: string;
          minutes?: number | null;
          type?: BilledTimeType | null;
          date?: string | null;
          userId: string;
          createdAt: string;
          updatedAt: string;
        } | null;
      } | null> | null;
    } | null;
    questionnaireResponses?: {
      __typename: "ModelQuestionnaireResponseConnection";
      nextToken?: string | null;
      items?: Array<{
        __typename: "QuestionnaireResponse";
        id: string;
        accountId: string;
        patientId: string;
        encounterId: string;
        contributors?: string | null;
        created?: string | null;
        name?: string | null;
        title?: string | null;
        status?: QuestionnaireStatus | null;
        json?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
    } | null;
    clinicalImpressions?: {
      __typename: "ModelClinicalImpressionConnection";
      nextToken?: string | null;
      items?: Array<{
        __typename: "ClinicalImpression";
        id?: string | null;
        accountId: string;
        patientId: string;
        encounterId: string;
        authorId?: string | null;
        created?: string | null;
        title?: string | null;
        status?: ClinicalImpressionStatus | null;
        summary?: string | null;
        name?: string | null;
        signature?: string | null;
        approved?: string | null;
        createdAt: string;
        updatedAt: string;
      } | null> | null;
    } | null;
    providers?: {
      __typename: "ModelEncounterProviderConnectionConnection";
      nextToken?: string | null;
    } | null;
    notes?: {
      __typename: "ModelEncounterNoteConnectionConnection";
      nextToken?: string | null;
    } | null;
  } | null> | null;
  nextToken?: string | null;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async AddUserAccount(
    input: AddUserAccountInput
  ): Promise<AddUserAccountMutation> {
    const statement = `mutation AddUserAccount($input: AddUserAccountInput!) {
        addUserAccount(input: $input) {
          __typename
          id
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AddUserAccountMutation>response.data.addUserAccount;
  }
  async AddPatientAccount(
    input: AddPatientAccountInput
  ): Promise<AddPatientAccountMutation> {
    const statement = `mutation AddPatientAccount($input: AddPatientAccountInput!) {
        addPatientAccount(input: $input) {
          __typename
          id
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AddPatientAccountMutation>response.data.addPatientAccount;
  }
  async RequestTelehealthAccess(
    input: RequestTelehealthAccessInput
  ): Promise<RequestTelehealthAccessMutation> {
    const statement = `mutation RequestTelehealthAccess($input: RequestTelehealthAccessInput!) {
        requestTelehealthAccess(input: $input) {
          __typename
          accessToken
          roomSid
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RequestTelehealthAccessMutation>(
      response.data.requestTelehealthAccess
    );
  }
  async InitTwilioRoom(
    input: InitTwilioRoomInput
  ): Promise<InitTwilioRoomMutation> {
    const statement = `mutation InitTwilioRoom($input: InitTwilioRoomInput!) {
        initTwilioRoom(input: $input) {
          __typename
          roomSid
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <InitTwilioRoomMutation>response.data.initTwilioRoom;
  }
  async DestroyTwilioRoom(
    input: DestroyTwilioRoomInput
  ): Promise<DestroyTwilioRoomMutation> {
    const statement = `mutation DestroyTwilioRoom($input: DestroyTwilioRoomInput!) {
        destroyTwilioRoom(input: $input) {
          __typename
          roomSid
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DestroyTwilioRoomMutation>response.data.destroyTwilioRoom;
  }
  async RequestChatAccess(
    input: RequestChatAccessInput
  ): Promise<RequestChatAccessMutation> {
    const statement = `mutation RequestChatAccess($input: RequestChatAccessInput!) {
        requestChatAccess(input: $input) {
          __typename
          accessToken
          identity
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RequestChatAccessMutation>response.data.requestChatAccess;
  }
  async RequestVoiceAccess(
    input?: RequestVoiceAccessInput
  ): Promise<RequestVoiceAccessMutation> {
    const statement = `mutation RequestVoiceAccess($input: RequestVoiceAccessInput) {
        requestVoiceAccess(input: $input) {
          __typename
          accessToken
          identity
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <RequestVoiceAccessMutation>response.data.requestVoiceAccess;
  }
  async GetAssessmentEligibility(
    input: GetAssessmentEligibilityInput
  ): Promise<GetAssessmentEligibilityMutation> {
    const statement = `mutation GetAssessmentEligibility($input: GetAssessmentEligibilityInput!) {
        getAssessmentEligibility(input: $input) {
          __typename
          eligibleTests {
            __typename
            title
            testId
            code
            recommended
            required
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAssessmentEligibilityMutation>(
      response.data.getAssessmentEligibility
    );
  }
  async ProcessPretestApproval(
    input: ProcessPretestApprovalInput
  ): Promise<ProcessPretestApprovalMutation> {
    const statement = `mutation ProcessPretestApproval($input: ProcessPretestApprovalInput!) {
        processPretestApproval(input: $input) {
          __typename
          encounterId
          pretestAssessmentId
          assessmentIds
          wasApproved
          comment
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ProcessPretestApprovalMutation>response.data.processPretestApproval;
  }
  async AddTest(input: JSONInput): Promise<AddTestMutation> {
    const statement = `mutation AddTest($input: JSONInput!) {
        addTest(input: $input) {
          __typename
          data
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AddTestMutation>response.data.addTest;
  }
  async GetTest(input: JSONInput): Promise<GetTestMutation> {
    const statement = `mutation GetTest($input: JSONInput!) {
        getTest(input: $input) {
          __typename
          data
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTestMutation>response.data.getTest;
  }
  async ListTests(input: JSONInput): Promise<ListTestsMutation> {
    const statement = `mutation ListTests($input: JSONInput!) {
        listTests(input: $input) {
          __typename
          data
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTestsMutation>response.data.listTests;
  }
  async UpdateTest(input: JSONInput): Promise<UpdateTestMutation> {
    const statement = `mutation UpdateTest($input: JSONInput!) {
        updateTest(input: $input) {
          __typename
          data
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTestMutation>response.data.updateTest;
  }
  async DeleteTest(input: JSONInput): Promise<DeleteTestMutation> {
    const statement = `mutation DeleteTest($input: JSONInput!) {
        deleteTest(input: $input) {
          __typename
          data
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTestMutation>response.data.deleteTest;
  }
  async AddAnswerSheet(input: JSONInput): Promise<AddAnswerSheetMutation> {
    const statement = `mutation AddAnswerSheet($input: JSONInput!) {
        addAnswerSheet(input: $input) {
          __typename
          data
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AddAnswerSheetMutation>response.data.addAnswerSheet;
  }
  async GetAnswerSheet(input: JSONInput): Promise<GetAnswerSheetMutation> {
    const statement = `mutation GetAnswerSheet($input: JSONInput!) {
        getAnswerSheet(input: $input) {
          __typename
          data
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAnswerSheetMutation>response.data.getAnswerSheet;
  }
  async ListAnswerSheets(input: JSONInput): Promise<ListAnswerSheetsMutation> {
    const statement = `mutation ListAnswerSheets($input: JSONInput!) {
        listAnswerSheets(input: $input) {
          __typename
          data
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAnswerSheetsMutation>response.data.listAnswerSheets;
  }
  async UpdateAnswerSheet(
    input: JSONInput
  ): Promise<UpdateAnswerSheetMutation> {
    const statement = `mutation UpdateAnswerSheet($input: JSONInput!) {
        updateAnswerSheet(input: $input) {
          __typename
          data
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAnswerSheetMutation>response.data.updateAnswerSheet;
  }
  async DeleteAnswerSheet(
    input: JSONInput
  ): Promise<DeleteAnswerSheetMutation> {
    const statement = `mutation DeleteAnswerSheet($input: JSONInput!) {
        deleteAnswerSheet(input: $input) {
          __typename
          data
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAnswerSheetMutation>response.data.deleteAnswerSheet;
  }
  async GenerateEncounterQuestionnaires(
    encounterId?: string
  ): Promise<GenerateEncounterQuestionnairesMutation> {
    const statement = `mutation GenerateEncounterQuestionnaires($encounterId: String) {
        generateEncounterQuestionnaires(encounterId: $encounterId) {
          __typename
          ids
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (encounterId) {
      gqlAPIServiceArguments.encounterId = encounterId;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GenerateEncounterQuestionnairesMutation>(
      response.data.generateEncounterQuestionnaires
    );
  }
  async GenerateEncounterImpressions(
    encounterId?: string
  ): Promise<GenerateEncounterImpressionsMutation> {
    const statement = `mutation GenerateEncounterImpressions($encounterId: String) {
        generateEncounterImpressions(encounterId: $encounterId) {
          __typename
          ids
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (encounterId) {
      gqlAPIServiceArguments.encounterId = encounterId;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GenerateEncounterImpressionsMutation>(
      response.data.generateEncounterImpressions
    );
  }
  async GetPatientTestingProfile(
    input: GetPatientTestingProfileInput
  ): Promise<GetPatientTestingProfileMutation> {
    const statement = `mutation GetPatientTestingProfile($input: GetPatientTestingProfileInput!) {
        getPatientTestingProfile(input: $input) {
          __typename
          patientId
          groupQualifier
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetPatientTestingProfileMutation>(
      response.data.getPatientTestingProfile
    );
  }
  async StripePaymentMethod(
    input: StripePaymentMethodInput
  ): Promise<StripePaymentMethodMutation> {
    const statement = `mutation StripePaymentMethod($input: StripePaymentMethodInput!) {
        stripePaymentMethod(input: $input) {
          __typename
          error
          items {
            __typename
            paymentMethodId
            expiryMonth
            expiryYear
            brand
            last4Digits
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <StripePaymentMethodMutation>response.data.stripePaymentMethod;
  }
  async GetRecentEligibleAssessments(
    input: GetRecentEligibleAssessmentsInput
  ): Promise<GetRecentEligibleAssessmentsMutation> {
    const statement = `mutation GetRecentEligibleAssessments($input: GetRecentEligibleAssessmentsInput!) {
        getRecentEligibleAssessments(input: $input) {
          __typename
          patientId
          recentAssessments {
            __typename
            id
            testCode
            assessed
            scoreGauge
            score
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetRecentEligibleAssessmentsMutation>(
      response.data.getRecentEligibleAssessments
    );
  }
  async CreatePatient(
    input: CreatePatientInput,
    condition?: ModelPatientConditionInput
  ): Promise<CreatePatientMutation> {
    const statement = `mutation CreatePatient($input: CreatePatientInput!, $condition: ModelPatientConditionInput) {
        createPatient(input: $input, condition: $condition) {
          __typename
          id
          accountId
          locationId
          patientManagerId
          owner
          salutation
          givenName
          middleName
          familyName
          address {
            __typename
            line1
            line2
            region
            postal
            country
          }
          ssn
          dob
          gender
          mobile
          email
          interests
          providerIds
          status
          eligibleRPM
          contacts {
            __typename
            id
            salutation
            givenName
            familyName
            mobile
            email
            contactType
          }
          insurance {
            __typename
            id
            memberId
            groupId
            carrierId
            insuranceType
          }
          qualifiedTests
          connectionId
          createdAt
          updatedAt
          providers {
            __typename
            nextToken
          }
          programs {
            __typename
            nextToken
          }
          eligibleTests {
            __typename
            nextToken
          }
          manager {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          location {
            __typename
            id
            owner
            accountId
            name
            callerId
            testsCount
            usersCount
            physicianCount
            createdAt
            updatedAt
          }
          lastAssessment {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            managerId
            submitterId
            result
            testId
            testCode
            meta
            answerSheetId
            title
            type
            assessed
            scheduledDate
            score
            scoreGauge
            status
            assessmentEncounterId
            createdAt
            updatedAt
          }
          assessments {
            __typename
            nextToken
          }
          treatmentPlan {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            treatmentPlanManagerId
            created
            createdAt
            updatedAt
          }
          lastEncounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
          diagnoses {
            __typename
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreatePatientMutation>response.data.createPatient;
  }
  async UpdatePatient(
    input: UpdatePatientInput,
    condition?: ModelPatientConditionInput
  ): Promise<UpdatePatientMutation> {
    const statement = `mutation UpdatePatient($input: UpdatePatientInput!, $condition: ModelPatientConditionInput) {
        updatePatient(input: $input, condition: $condition) {
          __typename
          id
          accountId
          locationId
          patientManagerId
          owner
          salutation
          givenName
          middleName
          familyName
          address {
            __typename
            line1
            line2
            region
            postal
            country
          }
          ssn
          dob
          gender
          mobile
          email
          interests
          providerIds
          status
          eligibleRPM
          contacts {
            __typename
            id
            salutation
            givenName
            familyName
            mobile
            email
            contactType
          }
          insurance {
            __typename
            id
            memberId
            groupId
            carrierId
            insuranceType
          }
          qualifiedTests
          connectionId
          createdAt
          updatedAt
          providers {
            __typename
            nextToken
          }
          programs {
            __typename
            nextToken
          }
          eligibleTests {
            __typename
            nextToken
          }
          manager {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          location {
            __typename
            id
            owner
            accountId
            name
            callerId
            testsCount
            usersCount
            physicianCount
            createdAt
            updatedAt
          }
          lastAssessment {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            managerId
            submitterId
            result
            testId
            testCode
            meta
            answerSheetId
            title
            type
            assessed
            scheduledDate
            score
            scoreGauge
            status
            assessmentEncounterId
            createdAt
            updatedAt
          }
          assessments {
            __typename
            nextToken
          }
          treatmentPlan {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            treatmentPlanManagerId
            created
            createdAt
            updatedAt
          }
          lastEncounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
          diagnoses {
            __typename
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdatePatientMutation>response.data.updatePatient;
  }
  async DeletePatient(
    input: DeletePatientInput,
    condition?: ModelPatientConditionInput
  ): Promise<DeletePatientMutation> {
    const statement = `mutation DeletePatient($input: DeletePatientInput!, $condition: ModelPatientConditionInput) {
        deletePatient(input: $input, condition: $condition) {
          __typename
          id
          accountId
          locationId
          patientManagerId
          owner
          salutation
          givenName
          middleName
          familyName
          address {
            __typename
            line1
            line2
            region
            postal
            country
          }
          ssn
          dob
          gender
          mobile
          email
          interests
          providerIds
          status
          eligibleRPM
          contacts {
            __typename
            id
            salutation
            givenName
            familyName
            mobile
            email
            contactType
          }
          insurance {
            __typename
            id
            memberId
            groupId
            carrierId
            insuranceType
          }
          qualifiedTests
          connectionId
          createdAt
          updatedAt
          providers {
            __typename
            nextToken
          }
          programs {
            __typename
            nextToken
          }
          eligibleTests {
            __typename
            nextToken
          }
          manager {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          location {
            __typename
            id
            owner
            accountId
            name
            callerId
            testsCount
            usersCount
            physicianCount
            createdAt
            updatedAt
          }
          lastAssessment {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            managerId
            submitterId
            result
            testId
            testCode
            meta
            answerSheetId
            title
            type
            assessed
            scheduledDate
            score
            scoreGauge
            status
            assessmentEncounterId
            createdAt
            updatedAt
          }
          assessments {
            __typename
            nextToken
          }
          treatmentPlan {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            treatmentPlanManagerId
            created
            createdAt
            updatedAt
          }
          lastEncounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
          diagnoses {
            __typename
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeletePatientMutation>response.data.deletePatient;
  }
  async CreatePatientProviderConnection(
    input: CreatePatientProviderConnectionInput,
    condition?: ModelPatientProviderConnectionConditionInput
  ): Promise<CreatePatientProviderConnectionMutation> {
    const statement = `mutation CreatePatientProviderConnection($input: CreatePatientProviderConnectionInput!, $condition: ModelPatientProviderConnectionConditionInput) {
        createPatientProviderConnection(input: $input, condition: $condition) {
          __typename
          id
          accountId
          patientId
          providerId
          type
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          provider {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreatePatientProviderConnectionMutation>(
      response.data.createPatientProviderConnection
    );
  }
  async UpdatePatientProviderConnection(
    input: UpdatePatientProviderConnectionInput,
    condition?: ModelPatientProviderConnectionConditionInput
  ): Promise<UpdatePatientProviderConnectionMutation> {
    const statement = `mutation UpdatePatientProviderConnection($input: UpdatePatientProviderConnectionInput!, $condition: ModelPatientProviderConnectionConditionInput) {
        updatePatientProviderConnection(input: $input, condition: $condition) {
          __typename
          id
          accountId
          patientId
          providerId
          type
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          provider {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdatePatientProviderConnectionMutation>(
      response.data.updatePatientProviderConnection
    );
  }
  async DeletePatientProviderConnection(
    input: DeletePatientProviderConnectionInput,
    condition?: ModelPatientProviderConnectionConditionInput
  ): Promise<DeletePatientProviderConnectionMutation> {
    const statement = `mutation DeletePatientProviderConnection($input: DeletePatientProviderConnectionInput!, $condition: ModelPatientProviderConnectionConditionInput) {
        deletePatientProviderConnection(input: $input, condition: $condition) {
          __typename
          id
          accountId
          patientId
          providerId
          type
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          provider {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeletePatientProviderConnectionMutation>(
      response.data.deletePatientProviderConnection
    );
  }
  async CreatePatientProgramConnection(
    input: CreatePatientProgramConnectionInput,
    condition?: ModelPatientProgramConnectionConditionInput
  ): Promise<CreatePatientProgramConnectionMutation> {
    const statement = `mutation CreatePatientProgramConnection($input: CreatePatientProgramConnectionInput!, $condition: ModelPatientProgramConnectionConditionInput) {
        createPatientProgramConnection(input: $input, condition: $condition) {
          __typename
          id
          accountId
          patientId
          programId
          date
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          program {
            __typename
            id
            questionnaireId
            name
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreatePatientProgramConnectionMutation>(
      response.data.createPatientProgramConnection
    );
  }
  async UpdatePatientProgramConnection(
    input: UpdatePatientProgramConnectionInput,
    condition?: ModelPatientProgramConnectionConditionInput
  ): Promise<UpdatePatientProgramConnectionMutation> {
    const statement = `mutation UpdatePatientProgramConnection($input: UpdatePatientProgramConnectionInput!, $condition: ModelPatientProgramConnectionConditionInput) {
        updatePatientProgramConnection(input: $input, condition: $condition) {
          __typename
          id
          accountId
          patientId
          programId
          date
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          program {
            __typename
            id
            questionnaireId
            name
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdatePatientProgramConnectionMutation>(
      response.data.updatePatientProgramConnection
    );
  }
  async DeletePatientProgramConnection(
    input: DeletePatientProgramConnectionInput,
    condition?: ModelPatientProgramConnectionConditionInput
  ): Promise<DeletePatientProgramConnectionMutation> {
    const statement = `mutation DeletePatientProgramConnection($input: DeletePatientProgramConnectionInput!, $condition: ModelPatientProgramConnectionConditionInput) {
        deletePatientProgramConnection(input: $input, condition: $condition) {
          __typename
          id
          accountId
          patientId
          programId
          date
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          program {
            __typename
            id
            questionnaireId
            name
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeletePatientProgramConnectionMutation>(
      response.data.deletePatientProgramConnection
    );
  }
  async CreateProgram(
    input: CreateProgramInput,
    condition?: ModelProgramConditionInput
  ): Promise<CreateProgramMutation> {
    const statement = `mutation CreateProgram($input: CreateProgramInput!, $condition: ModelProgramConditionInput) {
        createProgram(input: $input, condition: $condition) {
          __typename
          id
          questionnaireId
          name
          createdAt
          updatedAt
          tests {
            __typename
            nextToken
          }
          questionnaire {
            __typename
            id
            created
            name
            title
            template
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateProgramMutation>response.data.createProgram;
  }
  async UpdateProgram(
    input: UpdateProgramInput,
    condition?: ModelProgramConditionInput
  ): Promise<UpdateProgramMutation> {
    const statement = `mutation UpdateProgram($input: UpdateProgramInput!, $condition: ModelProgramConditionInput) {
        updateProgram(input: $input, condition: $condition) {
          __typename
          id
          questionnaireId
          name
          createdAt
          updatedAt
          tests {
            __typename
            nextToken
          }
          questionnaire {
            __typename
            id
            created
            name
            title
            template
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateProgramMutation>response.data.updateProgram;
  }
  async DeleteProgram(
    input: DeleteProgramInput,
    condition?: ModelProgramConditionInput
  ): Promise<DeleteProgramMutation> {
    const statement = `mutation DeleteProgram($input: DeleteProgramInput!, $condition: ModelProgramConditionInput) {
        deleteProgram(input: $input, condition: $condition) {
          __typename
          id
          questionnaireId
          name
          createdAt
          updatedAt
          tests {
            __typename
            nextToken
          }
          questionnaire {
            __typename
            id
            created
            name
            title
            template
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteProgramMutation>response.data.deleteProgram;
  }
  async CreateProgramTest(
    input: CreateProgramTestInput,
    condition?: ModelProgramTestConditionInput
  ): Promise<CreateProgramTestMutation> {
    const statement = `mutation CreateProgramTest($input: CreateProgramTestInput!, $condition: ModelProgramTestConditionInput) {
        createProgramTest(input: $input, condition: $condition) {
          __typename
          id
          name
          referenceTestId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateProgramTestMutation>response.data.createProgramTest;
  }
  async UpdateProgramTest(
    input: UpdateProgramTestInput,
    condition?: ModelProgramTestConditionInput
  ): Promise<UpdateProgramTestMutation> {
    const statement = `mutation UpdateProgramTest($input: UpdateProgramTestInput!, $condition: ModelProgramTestConditionInput) {
        updateProgramTest(input: $input, condition: $condition) {
          __typename
          id
          name
          referenceTestId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateProgramTestMutation>response.data.updateProgramTest;
  }
  async DeleteProgramTest(
    input: DeleteProgramTestInput,
    condition?: ModelProgramTestConditionInput
  ): Promise<DeleteProgramTestMutation> {
    const statement = `mutation DeleteProgramTest($input: DeleteProgramTestInput!, $condition: ModelProgramTestConditionInput) {
        deleteProgramTest(input: $input, condition: $condition) {
          __typename
          id
          name
          referenceTestId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteProgramTestMutation>response.data.deleteProgramTest;
  }
  async CreateEligibilityConnection(
    input: CreateEligibilityConnectionInput,
    condition?: ModelEligibilityConnectionConditionInput
  ): Promise<CreateEligibilityConnectionMutation> {
    const statement = `mutation CreateEligibilityConnection($input: CreateEligibilityConnectionInput!, $condition: ModelEligibilityConnectionConditionInput) {
        createEligibilityConnection(input: $input, condition: $condition) {
          __typename
          id
          patientId
          programTestId
          required
          date
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          programTest {
            __typename
            id
            name
            referenceTestId
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateEligibilityConnectionMutation>(
      response.data.createEligibilityConnection
    );
  }
  async UpdateEligibilityConnection(
    input: UpdateEligibilityConnectionInput,
    condition?: ModelEligibilityConnectionConditionInput
  ): Promise<UpdateEligibilityConnectionMutation> {
    const statement = `mutation UpdateEligibilityConnection($input: UpdateEligibilityConnectionInput!, $condition: ModelEligibilityConnectionConditionInput) {
        updateEligibilityConnection(input: $input, condition: $condition) {
          __typename
          id
          patientId
          programTestId
          required
          date
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          programTest {
            __typename
            id
            name
            referenceTestId
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateEligibilityConnectionMutation>(
      response.data.updateEligibilityConnection
    );
  }
  async DeleteEligibilityConnection(
    input: DeleteEligibilityConnectionInput,
    condition?: ModelEligibilityConnectionConditionInput
  ): Promise<DeleteEligibilityConnectionMutation> {
    const statement = `mutation DeleteEligibilityConnection($input: DeleteEligibilityConnectionInput!, $condition: ModelEligibilityConnectionConditionInput) {
        deleteEligibilityConnection(input: $input, condition: $condition) {
          __typename
          id
          patientId
          programTestId
          required
          date
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          programTest {
            __typename
            id
            name
            referenceTestId
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteEligibilityConnectionMutation>(
      response.data.deleteEligibilityConnection
    );
  }
  async CreateUser(
    input: CreateUserInput,
    condition?: ModelUserConditionInput
  ): Promise<CreateUserMutation> {
    const statement = `mutation CreateUser($input: CreateUserInput!, $condition: ModelUserConditionInput) {
        createUser(input: $input, condition: $condition) {
          __typename
          id
          accountId
          owner
          email
          phone
          online
          firstName
          lastName
          titles
          npi
          image
          userType
          connections {
            __typename
            connectionId
            startedAt
          }
          onlineStatus
          lastLogin {
            __typename
            ip
            date
          }
          pinCode
          permissions
          createdAt
          updatedAt
          locations {
            __typename
            nextToken
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateUserMutation>response.data.createUser;
  }
  async UpdateUser(
    input: UpdateUserInput,
    condition?: ModelUserConditionInput
  ): Promise<UpdateUserMutation> {
    const statement = `mutation UpdateUser($input: UpdateUserInput!, $condition: ModelUserConditionInput) {
        updateUser(input: $input, condition: $condition) {
          __typename
          id
          accountId
          owner
          email
          phone
          online
          firstName
          lastName
          titles
          npi
          image
          userType
          connections {
            __typename
            connectionId
            startedAt
          }
          onlineStatus
          lastLogin {
            __typename
            ip
            date
          }
          pinCode
          permissions
          createdAt
          updatedAt
          locations {
            __typename
            nextToken
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateUserMutation>response.data.updateUser;
  }
  async DeleteUser(
    input: DeleteUserInput,
    condition?: ModelUserConditionInput
  ): Promise<DeleteUserMutation> {
    const statement = `mutation DeleteUser($input: DeleteUserInput!, $condition: ModelUserConditionInput) {
        deleteUser(input: $input, condition: $condition) {
          __typename
          id
          accountId
          owner
          email
          phone
          online
          firstName
          lastName
          titles
          npi
          image
          userType
          connections {
            __typename
            connectionId
            startedAt
          }
          onlineStatus
          lastLogin {
            __typename
            ip
            date
          }
          pinCode
          permissions
          createdAt
          updatedAt
          locations {
            __typename
            nextToken
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteUserMutation>response.data.deleteUser;
  }
  async CreateUserLocationConnection(
    input: CreateUserLocationConnectionInput,
    condition?: ModelUserLocationConnectionConditionInput
  ): Promise<CreateUserLocationConnectionMutation> {
    const statement = `mutation CreateUserLocationConnection($input: CreateUserLocationConnectionInput!, $condition: ModelUserLocationConnectionConditionInput) {
        createUserLocationConnection(input: $input, condition: $condition) {
          __typename
          id
          accountId
          userId
          locationId
          createdAt
          updatedAt
          user {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          location {
            __typename
            id
            owner
            accountId
            name
            callerId
            testsCount
            usersCount
            physicianCount
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateUserLocationConnectionMutation>(
      response.data.createUserLocationConnection
    );
  }
  async UpdateUserLocationConnection(
    input: UpdateUserLocationConnectionInput,
    condition?: ModelUserLocationConnectionConditionInput
  ): Promise<UpdateUserLocationConnectionMutation> {
    const statement = `mutation UpdateUserLocationConnection($input: UpdateUserLocationConnectionInput!, $condition: ModelUserLocationConnectionConditionInput) {
        updateUserLocationConnection(input: $input, condition: $condition) {
          __typename
          id
          accountId
          userId
          locationId
          createdAt
          updatedAt
          user {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          location {
            __typename
            id
            owner
            accountId
            name
            callerId
            testsCount
            usersCount
            physicianCount
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateUserLocationConnectionMutation>(
      response.data.updateUserLocationConnection
    );
  }
  async DeleteUserLocationConnection(
    input: DeleteUserLocationConnectionInput,
    condition?: ModelUserLocationConnectionConditionInput
  ): Promise<DeleteUserLocationConnectionMutation> {
    const statement = `mutation DeleteUserLocationConnection($input: DeleteUserLocationConnectionInput!, $condition: ModelUserLocationConnectionConditionInput) {
        deleteUserLocationConnection(input: $input, condition: $condition) {
          __typename
          id
          accountId
          userId
          locationId
          createdAt
          updatedAt
          user {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          location {
            __typename
            id
            owner
            accountId
            name
            callerId
            testsCount
            usersCount
            physicianCount
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteUserLocationConnectionMutation>(
      response.data.deleteUserLocationConnection
    );
  }
  async CreateAccount(
    input: CreateAccountInput,
    condition?: ModelAccountConditionInput
  ): Promise<CreateAccountMutation> {
    const statement = `mutation CreateAccount($input: CreateAccountInput!, $condition: ModelAccountConditionInput) {
        createAccount(input: $input, condition: $condition) {
          __typename
          id
          name
          owner
          stripeCustomerId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAccountMutation>response.data.createAccount;
  }
  async UpdateAccount(
    input: UpdateAccountInput,
    condition?: ModelAccountConditionInput
  ): Promise<UpdateAccountMutation> {
    const statement = `mutation UpdateAccount($input: UpdateAccountInput!, $condition: ModelAccountConditionInput) {
        updateAccount(input: $input, condition: $condition) {
          __typename
          id
          name
          owner
          stripeCustomerId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAccountMutation>response.data.updateAccount;
  }
  async DeleteAccount(
    input: DeleteAccountInput,
    condition?: ModelAccountConditionInput
  ): Promise<DeleteAccountMutation> {
    const statement = `mutation DeleteAccount($input: DeleteAccountInput!, $condition: ModelAccountConditionInput) {
        deleteAccount(input: $input, condition: $condition) {
          __typename
          id
          name
          owner
          stripeCustomerId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAccountMutation>response.data.deleteAccount;
  }
  async CreateLocation(
    input: CreateLocationInput,
    condition?: ModelLocationConditionInput
  ): Promise<CreateLocationMutation> {
    const statement = `mutation CreateLocation($input: CreateLocationInput!, $condition: ModelLocationConditionInput) {
        createLocation(input: $input, condition: $condition) {
          __typename
          id
          owner
          accountId
          name
          address {
            __typename
            line1
            line2
            region
            postal
            country
          }
          callerId
          testsCount
          usersCount
          physicianCount
          frontDesk {
            __typename
            id
            salutation
            givenName
            familyName
            mobile
            email
            contactType
          }
          createdAt
          updatedAt
          lastLoginUser {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          users {
            __typename
            nextToken
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          lastAssessment {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            managerId
            submitterId
            result
            testId
            testCode
            meta
            answerSheetId
            title
            type
            assessed
            scheduledDate
            score
            scoreGauge
            status
            assessmentEncounterId
            createdAt
            updatedAt
          }
          lastPayment {
            __typename
            id
            accountId
            locationId
            userId
            stripePaymentMethodId
            paymentDate
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateLocationMutation>response.data.createLocation;
  }
  async UpdateLocation(
    input: UpdateLocationInput,
    condition?: ModelLocationConditionInput
  ): Promise<UpdateLocationMutation> {
    const statement = `mutation UpdateLocation($input: UpdateLocationInput!, $condition: ModelLocationConditionInput) {
        updateLocation(input: $input, condition: $condition) {
          __typename
          id
          owner
          accountId
          name
          address {
            __typename
            line1
            line2
            region
            postal
            country
          }
          callerId
          testsCount
          usersCount
          physicianCount
          frontDesk {
            __typename
            id
            salutation
            givenName
            familyName
            mobile
            email
            contactType
          }
          createdAt
          updatedAt
          lastLoginUser {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          users {
            __typename
            nextToken
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          lastAssessment {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            managerId
            submitterId
            result
            testId
            testCode
            meta
            answerSheetId
            title
            type
            assessed
            scheduledDate
            score
            scoreGauge
            status
            assessmentEncounterId
            createdAt
            updatedAt
          }
          lastPayment {
            __typename
            id
            accountId
            locationId
            userId
            stripePaymentMethodId
            paymentDate
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateLocationMutation>response.data.updateLocation;
  }
  async DeleteLocation(
    input: DeleteLocationInput,
    condition?: ModelLocationConditionInput
  ): Promise<DeleteLocationMutation> {
    const statement = `mutation DeleteLocation($input: DeleteLocationInput!, $condition: ModelLocationConditionInput) {
        deleteLocation(input: $input, condition: $condition) {
          __typename
          id
          owner
          accountId
          name
          address {
            __typename
            line1
            line2
            region
            postal
            country
          }
          callerId
          testsCount
          usersCount
          physicianCount
          frontDesk {
            __typename
            id
            salutation
            givenName
            familyName
            mobile
            email
            contactType
          }
          createdAt
          updatedAt
          lastLoginUser {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          users {
            __typename
            nextToken
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          lastAssessment {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            managerId
            submitterId
            result
            testId
            testCode
            meta
            answerSheetId
            title
            type
            assessed
            scheduledDate
            score
            scoreGauge
            status
            assessmentEncounterId
            createdAt
            updatedAt
          }
          lastPayment {
            __typename
            id
            accountId
            locationId
            userId
            stripePaymentMethodId
            paymentDate
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteLocationMutation>response.data.deleteLocation;
  }
  async CreateBilledTime(
    input: CreateBilledTimeInput,
    condition?: ModelBilledTimeConditionInput
  ): Promise<CreateBilledTimeMutation> {
    const statement = `mutation CreateBilledTime($input: CreateBilledTimeInput!, $condition: ModelBilledTimeConditionInput) {
        createBilledTime(input: $input, condition: $condition) {
          __typename
          id
          minutes
          type
          date
          userId
          createdAt
          updatedAt
          user {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateBilledTimeMutation>response.data.createBilledTime;
  }
  async UpdateBilledTime(
    input: UpdateBilledTimeInput,
    condition?: ModelBilledTimeConditionInput
  ): Promise<UpdateBilledTimeMutation> {
    const statement = `mutation UpdateBilledTime($input: UpdateBilledTimeInput!, $condition: ModelBilledTimeConditionInput) {
        updateBilledTime(input: $input, condition: $condition) {
          __typename
          id
          minutes
          type
          date
          userId
          createdAt
          updatedAt
          user {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateBilledTimeMutation>response.data.updateBilledTime;
  }
  async DeleteBilledTime(
    input: DeleteBilledTimeInput,
    condition?: ModelBilledTimeConditionInput
  ): Promise<DeleteBilledTimeMutation> {
    const statement = `mutation DeleteBilledTime($input: DeleteBilledTimeInput!, $condition: ModelBilledTimeConditionInput) {
        deleteBilledTime(input: $input, condition: $condition) {
          __typename
          id
          minutes
          type
          date
          userId
          createdAt
          updatedAt
          user {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteBilledTimeMutation>response.data.deleteBilledTime;
  }
  async CreateEncounterBilledTimeConnection(
    input: CreateEncounterBilledTimeConnectionInput,
    condition?: ModelEncounterBilledTimeConnectionConditionInput
  ): Promise<CreateEncounterBilledTimeConnectionMutation> {
    const statement = `mutation CreateEncounterBilledTimeConnection($input: CreateEncounterBilledTimeConnectionInput!, $condition: ModelEncounterBilledTimeConnectionConditionInput) {
        createEncounterBilledTimeConnection(input: $input, condition: $condition) {
          __typename
          id
          accountId
          encounterId
          billedTimeId
          createdAt
          updatedAt
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          billedTime {
            __typename
            id
            minutes
            type
            date
            userId
            createdAt
            updatedAt
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateEncounterBilledTimeConnectionMutation>(
      response.data.createEncounterBilledTimeConnection
    );
  }
  async UpdateEncounterBilledTimeConnection(
    input: UpdateEncounterBilledTimeConnectionInput,
    condition?: ModelEncounterBilledTimeConnectionConditionInput
  ): Promise<UpdateEncounterBilledTimeConnectionMutation> {
    const statement = `mutation UpdateEncounterBilledTimeConnection($input: UpdateEncounterBilledTimeConnectionInput!, $condition: ModelEncounterBilledTimeConnectionConditionInput) {
        updateEncounterBilledTimeConnection(input: $input, condition: $condition) {
          __typename
          id
          accountId
          encounterId
          billedTimeId
          createdAt
          updatedAt
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          billedTime {
            __typename
            id
            minutes
            type
            date
            userId
            createdAt
            updatedAt
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateEncounterBilledTimeConnectionMutation>(
      response.data.updateEncounterBilledTimeConnection
    );
  }
  async DeleteEncounterBilledTimeConnection(
    input: DeleteEncounterBilledTimeConnectionInput,
    condition?: ModelEncounterBilledTimeConnectionConditionInput
  ): Promise<DeleteEncounterBilledTimeConnectionMutation> {
    const statement = `mutation DeleteEncounterBilledTimeConnection($input: DeleteEncounterBilledTimeConnectionInput!, $condition: ModelEncounterBilledTimeConnectionConditionInput) {
        deleteEncounterBilledTimeConnection(input: $input, condition: $condition) {
          __typename
          id
          accountId
          encounterId
          billedTimeId
          createdAt
          updatedAt
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          billedTime {
            __typename
            id
            minutes
            type
            date
            userId
            createdAt
            updatedAt
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteEncounterBilledTimeConnectionMutation>(
      response.data.deleteEncounterBilledTimeConnection
    );
  }
  async CreateAssessmentBilledTimeConnection(
    input: CreateAssessmentBilledTimeConnectionInput,
    condition?: ModelAssessmentBilledTimeConnectionConditionInput
  ): Promise<CreateAssessmentBilledTimeConnectionMutation> {
    const statement = `mutation CreateAssessmentBilledTimeConnection($input: CreateAssessmentBilledTimeConnectionInput!, $condition: ModelAssessmentBilledTimeConnectionConditionInput) {
        createAssessmentBilledTimeConnection(input: $input, condition: $condition) {
          __typename
          id
          accountId
          assessmentId
          billedTimeId
          createdAt
          updatedAt
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          billedTime {
            __typename
            id
            minutes
            type
            date
            userId
            createdAt
            updatedAt
          }
          assessment {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            managerId
            submitterId
            result
            testId
            testCode
            meta
            answerSheetId
            title
            type
            assessed
            scheduledDate
            score
            scoreGauge
            status
            assessmentEncounterId
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAssessmentBilledTimeConnectionMutation>(
      response.data.createAssessmentBilledTimeConnection
    );
  }
  async UpdateAssessmentBilledTimeConnection(
    input: UpdateAssessmentBilledTimeConnectionInput,
    condition?: ModelAssessmentBilledTimeConnectionConditionInput
  ): Promise<UpdateAssessmentBilledTimeConnectionMutation> {
    const statement = `mutation UpdateAssessmentBilledTimeConnection($input: UpdateAssessmentBilledTimeConnectionInput!, $condition: ModelAssessmentBilledTimeConnectionConditionInput) {
        updateAssessmentBilledTimeConnection(input: $input, condition: $condition) {
          __typename
          id
          accountId
          assessmentId
          billedTimeId
          createdAt
          updatedAt
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          billedTime {
            __typename
            id
            minutes
            type
            date
            userId
            createdAt
            updatedAt
          }
          assessment {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            managerId
            submitterId
            result
            testId
            testCode
            meta
            answerSheetId
            title
            type
            assessed
            scheduledDate
            score
            scoreGauge
            status
            assessmentEncounterId
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAssessmentBilledTimeConnectionMutation>(
      response.data.updateAssessmentBilledTimeConnection
    );
  }
  async DeleteAssessmentBilledTimeConnection(
    input: DeleteAssessmentBilledTimeConnectionInput,
    condition?: ModelAssessmentBilledTimeConnectionConditionInput
  ): Promise<DeleteAssessmentBilledTimeConnectionMutation> {
    const statement = `mutation DeleteAssessmentBilledTimeConnection($input: DeleteAssessmentBilledTimeConnectionInput!, $condition: ModelAssessmentBilledTimeConnectionConditionInput) {
        deleteAssessmentBilledTimeConnection(input: $input, condition: $condition) {
          __typename
          id
          accountId
          assessmentId
          billedTimeId
          createdAt
          updatedAt
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          billedTime {
            __typename
            id
            minutes
            type
            date
            userId
            createdAt
            updatedAt
          }
          assessment {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            managerId
            submitterId
            result
            testId
            testCode
            meta
            answerSheetId
            title
            type
            assessed
            scheduledDate
            score
            scoreGauge
            status
            assessmentEncounterId
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAssessmentBilledTimeConnectionMutation>(
      response.data.deleteAssessmentBilledTimeConnection
    );
  }
  async CreateAssessment(
    input: CreateAssessmentInput,
    condition?: ModelAssessmentConditionInput
  ): Promise<CreateAssessmentMutation> {
    const statement = `mutation CreateAssessment($input: CreateAssessmentInput!, $condition: ModelAssessmentConditionInput) {
        createAssessment(input: $input, condition: $condition) {
          __typename
          id
          accountId
          locationId
          patientId
          providers
          managerId
          submitterId
          result
          testId
          testCode
          meta
          answerSheetId
          title
          type
          assessed
          scheduledDate
          score
          scoreGauge
          status
          assessmentEncounterId
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          manager {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          location {
            __typename
            id
            owner
            accountId
            name
            callerId
            testsCount
            usersCount
            physicianCount
            createdAt
            updatedAt
          }
          billedTime {
            __typename
            id
            minutes
            type
            date
            userId
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAssessmentMutation>response.data.createAssessment;
  }
  async UpdateAssessment(
    input: UpdateAssessmentInput,
    condition?: ModelAssessmentConditionInput
  ): Promise<UpdateAssessmentMutation> {
    const statement = `mutation UpdateAssessment($input: UpdateAssessmentInput!, $condition: ModelAssessmentConditionInput) {
        updateAssessment(input: $input, condition: $condition) {
          __typename
          id
          accountId
          locationId
          patientId
          providers
          managerId
          submitterId
          result
          testId
          testCode
          meta
          answerSheetId
          title
          type
          assessed
          scheduledDate
          score
          scoreGauge
          status
          assessmentEncounterId
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          manager {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          location {
            __typename
            id
            owner
            accountId
            name
            callerId
            testsCount
            usersCount
            physicianCount
            createdAt
            updatedAt
          }
          billedTime {
            __typename
            id
            minutes
            type
            date
            userId
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAssessmentMutation>response.data.updateAssessment;
  }
  async DeleteAssessment(
    input: DeleteAssessmentInput,
    condition?: ModelAssessmentConditionInput
  ): Promise<DeleteAssessmentMutation> {
    const statement = `mutation DeleteAssessment($input: DeleteAssessmentInput!, $condition: ModelAssessmentConditionInput) {
        deleteAssessment(input: $input, condition: $condition) {
          __typename
          id
          accountId
          locationId
          patientId
          providers
          managerId
          submitterId
          result
          testId
          testCode
          meta
          answerSheetId
          title
          type
          assessed
          scheduledDate
          score
          scoreGauge
          status
          assessmentEncounterId
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          manager {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          location {
            __typename
            id
            owner
            accountId
            name
            callerId
            testsCount
            usersCount
            physicianCount
            createdAt
            updatedAt
          }
          billedTime {
            __typename
            id
            minutes
            type
            date
            userId
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAssessmentMutation>response.data.deleteAssessment;
  }
  async CreateTreatmentPlan(
    input: CreateTreatmentPlanInput,
    condition?: ModelTreatmentPlanConditionInput
  ): Promise<CreateTreatmentPlanMutation> {
    const statement = `mutation CreateTreatmentPlan($input: CreateTreatmentPlanInput!, $condition: ModelTreatmentPlanConditionInput) {
        createTreatmentPlan(input: $input, condition: $condition) {
          __typename
          id
          accountId
          locationId
          patientId
          providers
          treatmentPlanManagerId
          created
          createdAt
          updatedAt
          patient {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          location {
            __typename
            id
            owner
            accountId
            name
            callerId
            testsCount
            usersCount
            physicianCount
            createdAt
            updatedAt
          }
          resourceEvents {
            __typename
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTreatmentPlanMutation>response.data.createTreatmentPlan;
  }
  async UpdateTreatmentPlan(
    input: UpdateTreatmentPlanInput,
    condition?: ModelTreatmentPlanConditionInput
  ): Promise<UpdateTreatmentPlanMutation> {
    const statement = `mutation UpdateTreatmentPlan($input: UpdateTreatmentPlanInput!, $condition: ModelTreatmentPlanConditionInput) {
        updateTreatmentPlan(input: $input, condition: $condition) {
          __typename
          id
          accountId
          locationId
          patientId
          providers
          treatmentPlanManagerId
          created
          createdAt
          updatedAt
          patient {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          location {
            __typename
            id
            owner
            accountId
            name
            callerId
            testsCount
            usersCount
            physicianCount
            createdAt
            updatedAt
          }
          resourceEvents {
            __typename
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTreatmentPlanMutation>response.data.updateTreatmentPlan;
  }
  async DeleteTreatmentPlan(
    input: DeleteTreatmentPlanInput,
    condition?: ModelTreatmentPlanConditionInput
  ): Promise<DeleteTreatmentPlanMutation> {
    const statement = `mutation DeleteTreatmentPlan($input: DeleteTreatmentPlanInput!, $condition: ModelTreatmentPlanConditionInput) {
        deleteTreatmentPlan(input: $input, condition: $condition) {
          __typename
          id
          accountId
          locationId
          patientId
          providers
          treatmentPlanManagerId
          created
          createdAt
          updatedAt
          patient {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          location {
            __typename
            id
            owner
            accountId
            name
            callerId
            testsCount
            usersCount
            physicianCount
            createdAt
            updatedAt
          }
          resourceEvents {
            __typename
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTreatmentPlanMutation>response.data.deleteTreatmentPlan;
  }
  async CreatePatientResourceScheduledEventConnection(
    input: CreatePatientResourceScheduledEventConnectionInput,
    condition?: ModelPatientResourceScheduledEventConnectionConditionInput
  ): Promise<CreatePatientResourceScheduledEventConnectionMutation> {
    const statement = `mutation CreatePatientResourceScheduledEventConnection($input: CreatePatientResourceScheduledEventConnectionInput!, $condition: ModelPatientResourceScheduledEventConnectionConditionInput) {
        createPatientResourceScheduledEventConnection(input: $input, condition: $condition) {
          __typename
          id
          accountId
          patientId
          encounterId
          resourceScheduledEventId
          created
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          resourceScheduledEvent {
            __typename
            id
            accountId
            ownerId
            locationId
            title
            start
            end
            startTimezone
            endTimezone
            isAllDay
            description
            recurrenceRule
            recurrenceExceptions
            recurrenceId
            resourceId
            category
            cost
            ageGroup
            createdAt
            updatedAt
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreatePatientResourceScheduledEventConnectionMutation>(
      response.data.createPatientResourceScheduledEventConnection
    );
  }
  async UpdatePatientResourceScheduledEventConnection(
    input: UpdatePatientResourceScheduledEventConnectionInput,
    condition?: ModelPatientResourceScheduledEventConnectionConditionInput
  ): Promise<UpdatePatientResourceScheduledEventConnectionMutation> {
    const statement = `mutation UpdatePatientResourceScheduledEventConnection($input: UpdatePatientResourceScheduledEventConnectionInput!, $condition: ModelPatientResourceScheduledEventConnectionConditionInput) {
        updatePatientResourceScheduledEventConnection(input: $input, condition: $condition) {
          __typename
          id
          accountId
          patientId
          encounterId
          resourceScheduledEventId
          created
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          resourceScheduledEvent {
            __typename
            id
            accountId
            ownerId
            locationId
            title
            start
            end
            startTimezone
            endTimezone
            isAllDay
            description
            recurrenceRule
            recurrenceExceptions
            recurrenceId
            resourceId
            category
            cost
            ageGroup
            createdAt
            updatedAt
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdatePatientResourceScheduledEventConnectionMutation>(
      response.data.updatePatientResourceScheduledEventConnection
    );
  }
  async DeletePatientResourceScheduledEventConnection(
    input: DeletePatientResourceScheduledEventConnectionInput,
    condition?: ModelPatientResourceScheduledEventConnectionConditionInput
  ): Promise<DeletePatientResourceScheduledEventConnectionMutation> {
    const statement = `mutation DeletePatientResourceScheduledEventConnection($input: DeletePatientResourceScheduledEventConnectionInput!, $condition: ModelPatientResourceScheduledEventConnectionConditionInput) {
        deletePatientResourceScheduledEventConnection(input: $input, condition: $condition) {
          __typename
          id
          accountId
          patientId
          encounterId
          resourceScheduledEventId
          created
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          resourceScheduledEvent {
            __typename
            id
            accountId
            ownerId
            locationId
            title
            start
            end
            startTimezone
            endTimezone
            isAllDay
            description
            recurrenceRule
            recurrenceExceptions
            recurrenceId
            resourceId
            category
            cost
            ageGroup
            createdAt
            updatedAt
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeletePatientResourceScheduledEventConnectionMutation>(
      response.data.deletePatientResourceScheduledEventConnection
    );
  }
  async CreateTelehealthSession(
    input: CreateTelehealthSessionInput,
    condition?: ModelTelehealthSessionConditionInput
  ): Promise<CreateTelehealthSessionMutation> {
    const statement = `mutation CreateTelehealthSession($input: CreateTelehealthSessionInput!, $condition: ModelTelehealthSessionConditionInput) {
        createTelehealthSession(input: $input, condition: $condition) {
          __typename
          id
          title
          accountId
          participantIds
          participants {
            __typename
            name
            type
            identity
          }
          status
          roomType
          startedAt
          endedAt
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTelehealthSessionMutation>(
      response.data.createTelehealthSession
    );
  }
  async UpdateTelehealthSession(
    input: UpdateTelehealthSessionInput,
    condition?: ModelTelehealthSessionConditionInput
  ): Promise<UpdateTelehealthSessionMutation> {
    const statement = `mutation UpdateTelehealthSession($input: UpdateTelehealthSessionInput!, $condition: ModelTelehealthSessionConditionInput) {
        updateTelehealthSession(input: $input, condition: $condition) {
          __typename
          id
          title
          accountId
          participantIds
          participants {
            __typename
            name
            type
            identity
          }
          status
          roomType
          startedAt
          endedAt
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTelehealthSessionMutation>(
      response.data.updateTelehealthSession
    );
  }
  async DeleteTelehealthSession(
    input: DeleteTelehealthSessionInput,
    condition?: ModelTelehealthSessionConditionInput
  ): Promise<DeleteTelehealthSessionMutation> {
    const statement = `mutation DeleteTelehealthSession($input: DeleteTelehealthSessionInput!, $condition: ModelTelehealthSessionConditionInput) {
        deleteTelehealthSession(input: $input, condition: $condition) {
          __typename
          id
          title
          accountId
          participantIds
          participants {
            __typename
            name
            type
            identity
          }
          status
          roomType
          startedAt
          endedAt
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTelehealthSessionMutation>(
      response.data.deleteTelehealthSession
    );
  }
  async CreateScheduledEvent(
    input: CreateScheduledEventInput,
    condition?: ModelScheduledEventConditionInput
  ): Promise<CreateScheduledEventMutation> {
    const statement = `mutation CreateScheduledEvent($input: CreateScheduledEventInput!, $condition: ModelScheduledEventConditionInput) {
        createScheduledEvent(input: $input, condition: $condition) {
          __typename
          id
          accountId
          ownerId
          invitees {
            __typename
            name
            type
            id
          }
          participantIds
          title
          start
          end
          type
          assessments {
            __typename
            id
            name
          }
          startTimezone
          endTimezone
          isAllDay
          description
          recurrenceRule
          recurrenceExceptions
          recurrenceId
          createdAt
          updatedAt
          resource {
            __typename
            id
            accountId
            ownerId
            locationId
            title
            start
            end
            startTimezone
            endTimezone
            isAllDay
            description
            recurrenceRule
            recurrenceExceptions
            recurrenceId
            resourceId
            category
            cost
            ageGroup
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateScheduledEventMutation>response.data.createScheduledEvent;
  }
  async UpdateScheduledEvent(
    input: UpdateScheduledEventInput,
    condition?: ModelScheduledEventConditionInput
  ): Promise<UpdateScheduledEventMutation> {
    const statement = `mutation UpdateScheduledEvent($input: UpdateScheduledEventInput!, $condition: ModelScheduledEventConditionInput) {
        updateScheduledEvent(input: $input, condition: $condition) {
          __typename
          id
          accountId
          ownerId
          invitees {
            __typename
            name
            type
            id
          }
          participantIds
          title
          start
          end
          type
          assessments {
            __typename
            id
            name
          }
          startTimezone
          endTimezone
          isAllDay
          description
          recurrenceRule
          recurrenceExceptions
          recurrenceId
          createdAt
          updatedAt
          resource {
            __typename
            id
            accountId
            ownerId
            locationId
            title
            start
            end
            startTimezone
            endTimezone
            isAllDay
            description
            recurrenceRule
            recurrenceExceptions
            recurrenceId
            resourceId
            category
            cost
            ageGroup
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateScheduledEventMutation>response.data.updateScheduledEvent;
  }
  async DeleteScheduledEvent(
    input: DeleteScheduledEventInput,
    condition?: ModelScheduledEventConditionInput
  ): Promise<DeleteScheduledEventMutation> {
    const statement = `mutation DeleteScheduledEvent($input: DeleteScheduledEventInput!, $condition: ModelScheduledEventConditionInput) {
        deleteScheduledEvent(input: $input, condition: $condition) {
          __typename
          id
          accountId
          ownerId
          invitees {
            __typename
            name
            type
            id
          }
          participantIds
          title
          start
          end
          type
          assessments {
            __typename
            id
            name
          }
          startTimezone
          endTimezone
          isAllDay
          description
          recurrenceRule
          recurrenceExceptions
          recurrenceId
          createdAt
          updatedAt
          resource {
            __typename
            id
            accountId
            ownerId
            locationId
            title
            start
            end
            startTimezone
            endTimezone
            isAllDay
            description
            recurrenceRule
            recurrenceExceptions
            recurrenceId
            resourceId
            category
            cost
            ageGroup
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteScheduledEventMutation>response.data.deleteScheduledEvent;
  }
  async CreateResourceScheduledEvent(
    input: CreateResourceScheduledEventInput,
    condition?: ModelResourceScheduledEventConditionInput
  ): Promise<CreateResourceScheduledEventMutation> {
    const statement = `mutation CreateResourceScheduledEvent($input: CreateResourceScheduledEventInput!, $condition: ModelResourceScheduledEventConditionInput) {
        createResourceScheduledEvent(input: $input, condition: $condition) {
          __typename
          id
          accountId
          ownerId
          locationId
          title
          start
          end
          startTimezone
          endTimezone
          isAllDay
          description
          recurrenceRule
          recurrenceExceptions
          recurrenceId
          resourceId
          category
          cost
          ageGroup
          createdAt
          updatedAt
          resource {
            __typename
            id
            ownerId
            accountId
            locationId
            name
            type
            classification
            website
            businessHours
            billedTime
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateResourceScheduledEventMutation>(
      response.data.createResourceScheduledEvent
    );
  }
  async UpdateResourceScheduledEvent(
    input: UpdateResourceScheduledEventInput,
    condition?: ModelResourceScheduledEventConditionInput
  ): Promise<UpdateResourceScheduledEventMutation> {
    const statement = `mutation UpdateResourceScheduledEvent($input: UpdateResourceScheduledEventInput!, $condition: ModelResourceScheduledEventConditionInput) {
        updateResourceScheduledEvent(input: $input, condition: $condition) {
          __typename
          id
          accountId
          ownerId
          locationId
          title
          start
          end
          startTimezone
          endTimezone
          isAllDay
          description
          recurrenceRule
          recurrenceExceptions
          recurrenceId
          resourceId
          category
          cost
          ageGroup
          createdAt
          updatedAt
          resource {
            __typename
            id
            ownerId
            accountId
            locationId
            name
            type
            classification
            website
            businessHours
            billedTime
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateResourceScheduledEventMutation>(
      response.data.updateResourceScheduledEvent
    );
  }
  async DeleteResourceScheduledEvent(
    input: DeleteResourceScheduledEventInput,
    condition?: ModelResourceScheduledEventConditionInput
  ): Promise<DeleteResourceScheduledEventMutation> {
    const statement = `mutation DeleteResourceScheduledEvent($input: DeleteResourceScheduledEventInput!, $condition: ModelResourceScheduledEventConditionInput) {
        deleteResourceScheduledEvent(input: $input, condition: $condition) {
          __typename
          id
          accountId
          ownerId
          locationId
          title
          start
          end
          startTimezone
          endTimezone
          isAllDay
          description
          recurrenceRule
          recurrenceExceptions
          recurrenceId
          resourceId
          category
          cost
          ageGroup
          createdAt
          updatedAt
          resource {
            __typename
            id
            ownerId
            accountId
            locationId
            name
            type
            classification
            website
            businessHours
            billedTime
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteResourceScheduledEventMutation>(
      response.data.deleteResourceScheduledEvent
    );
  }
  async CreateSessionRecording(
    input: CreateSessionRecordingInput,
    condition?: ModelSessionRecordingConditionInput
  ): Promise<CreateSessionRecordingMutation> {
    const statement = `mutation CreateSessionRecording($input: CreateSessionRecordingInput!, $condition: ModelSessionRecordingConditionInput) {
        createSessionRecording(input: $input, condition: $condition) {
          __typename
          id
          codec
          container
          offset
          sourceSid
          twilioMediaUri
          roomSid
          eventId
          ownerId
          sessionId
          s3Url
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSessionRecordingMutation>response.data.createSessionRecording;
  }
  async UpdateSessionRecording(
    input: UpdateSessionRecordingInput,
    condition?: ModelSessionRecordingConditionInput
  ): Promise<UpdateSessionRecordingMutation> {
    const statement = `mutation UpdateSessionRecording($input: UpdateSessionRecordingInput!, $condition: ModelSessionRecordingConditionInput) {
        updateSessionRecording(input: $input, condition: $condition) {
          __typename
          id
          codec
          container
          offset
          sourceSid
          twilioMediaUri
          roomSid
          eventId
          ownerId
          sessionId
          s3Url
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSessionRecordingMutation>response.data.updateSessionRecording;
  }
  async DeleteSessionRecording(
    input: DeleteSessionRecordingInput,
    condition?: ModelSessionRecordingConditionInput
  ): Promise<DeleteSessionRecordingMutation> {
    const statement = `mutation DeleteSessionRecording($input: DeleteSessionRecordingInput!, $condition: ModelSessionRecordingConditionInput) {
        deleteSessionRecording(input: $input, condition: $condition) {
          __typename
          id
          codec
          container
          offset
          sourceSid
          twilioMediaUri
          roomSid
          eventId
          ownerId
          sessionId
          s3Url
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSessionRecordingMutation>response.data.deleteSessionRecording;
  }
  async CreateEventResource(
    input: CreateEventResourceInput,
    condition?: ModelEventResourceConditionInput
  ): Promise<CreateEventResourceMutation> {
    const statement = `mutation CreateEventResource($input: CreateEventResourceInput!, $condition: ModelEventResourceConditionInput) {
        createEventResource(input: $input, condition: $condition) {
          __typename
          id
          ownerId
          accountId
          locationId
          name
          type
          classification
          website
          contact {
            __typename
            id
            salutation
            givenName
            familyName
            mobile
            email
            contactType
          }
          address {
            __typename
            line1
            line2
            region
            postal
            country
          }
          businessHours
          billedTime
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateEventResourceMutation>response.data.createEventResource;
  }
  async UpdateEventResource(
    input: UpdateEventResourceInput,
    condition?: ModelEventResourceConditionInput
  ): Promise<UpdateEventResourceMutation> {
    const statement = `mutation UpdateEventResource($input: UpdateEventResourceInput!, $condition: ModelEventResourceConditionInput) {
        updateEventResource(input: $input, condition: $condition) {
          __typename
          id
          ownerId
          accountId
          locationId
          name
          type
          classification
          website
          contact {
            __typename
            id
            salutation
            givenName
            familyName
            mobile
            email
            contactType
          }
          address {
            __typename
            line1
            line2
            region
            postal
            country
          }
          businessHours
          billedTime
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateEventResourceMutation>response.data.updateEventResource;
  }
  async DeleteEventResource(
    input: DeleteEventResourceInput,
    condition?: ModelEventResourceConditionInput
  ): Promise<DeleteEventResourceMutation> {
    const statement = `mutation DeleteEventResource($input: DeleteEventResourceInput!, $condition: ModelEventResourceConditionInput) {
        deleteEventResource(input: $input, condition: $condition) {
          __typename
          id
          ownerId
          accountId
          locationId
          name
          type
          classification
          website
          contact {
            __typename
            id
            salutation
            givenName
            familyName
            mobile
            email
            contactType
          }
          address {
            __typename
            line1
            line2
            region
            postal
            country
          }
          businessHours
          billedTime
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteEventResourceMutation>response.data.deleteEventResource;
  }
  async CreateQuestionnaire(
    input: CreateQuestionnaireInput,
    condition?: ModelQuestionnaireConditionInput
  ): Promise<CreateQuestionnaireMutation> {
    const statement = `mutation CreateQuestionnaire($input: CreateQuestionnaireInput!, $condition: ModelQuestionnaireConditionInput) {
        createQuestionnaire(input: $input, condition: $condition) {
          __typename
          id
          created
          name
          title
          template
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateQuestionnaireMutation>response.data.createQuestionnaire;
  }
  async UpdateQuestionnaire(
    input: UpdateQuestionnaireInput,
    condition?: ModelQuestionnaireConditionInput
  ): Promise<UpdateQuestionnaireMutation> {
    const statement = `mutation UpdateQuestionnaire($input: UpdateQuestionnaireInput!, $condition: ModelQuestionnaireConditionInput) {
        updateQuestionnaire(input: $input, condition: $condition) {
          __typename
          id
          created
          name
          title
          template
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateQuestionnaireMutation>response.data.updateQuestionnaire;
  }
  async DeleteQuestionnaire(
    input: DeleteQuestionnaireInput,
    condition?: ModelQuestionnaireConditionInput
  ): Promise<DeleteQuestionnaireMutation> {
    const statement = `mutation DeleteQuestionnaire($input: DeleteQuestionnaireInput!, $condition: ModelQuestionnaireConditionInput) {
        deleteQuestionnaire(input: $input, condition: $condition) {
          __typename
          id
          created
          name
          title
          template
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteQuestionnaireMutation>response.data.deleteQuestionnaire;
  }
  async CreateQuestionnaireResponseAuthor(
    input: CreateQuestionnaireResponseAuthorInput,
    condition?: ModelQuestionnaireResponseAuthorConditionInput
  ): Promise<CreateQuestionnaireResponseAuthorMutation> {
    const statement = `mutation CreateQuestionnaireResponseAuthor($input: CreateQuestionnaireResponseAuthorInput!, $condition: ModelQuestionnaireResponseAuthorConditionInput) {
        createQuestionnaireResponseAuthor(input: $input, condition: $condition) {
          __typename
          id
          accountId
          responseId
          authorId
          createdAt
          updatedAt
          author {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          questionnaireResponse {
            __typename
            id
            accountId
            patientId
            encounterId
            contributors
            created
            name
            title
            status
            json
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateQuestionnaireResponseAuthorMutation>(
      response.data.createQuestionnaireResponseAuthor
    );
  }
  async UpdateQuestionnaireResponseAuthor(
    input: UpdateQuestionnaireResponseAuthorInput,
    condition?: ModelQuestionnaireResponseAuthorConditionInput
  ): Promise<UpdateQuestionnaireResponseAuthorMutation> {
    const statement = `mutation UpdateQuestionnaireResponseAuthor($input: UpdateQuestionnaireResponseAuthorInput!, $condition: ModelQuestionnaireResponseAuthorConditionInput) {
        updateQuestionnaireResponseAuthor(input: $input, condition: $condition) {
          __typename
          id
          accountId
          responseId
          authorId
          createdAt
          updatedAt
          author {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          questionnaireResponse {
            __typename
            id
            accountId
            patientId
            encounterId
            contributors
            created
            name
            title
            status
            json
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateQuestionnaireResponseAuthorMutation>(
      response.data.updateQuestionnaireResponseAuthor
    );
  }
  async DeleteQuestionnaireResponseAuthor(
    input: DeleteQuestionnaireResponseAuthorInput,
    condition?: ModelQuestionnaireResponseAuthorConditionInput
  ): Promise<DeleteQuestionnaireResponseAuthorMutation> {
    const statement = `mutation DeleteQuestionnaireResponseAuthor($input: DeleteQuestionnaireResponseAuthorInput!, $condition: ModelQuestionnaireResponseAuthorConditionInput) {
        deleteQuestionnaireResponseAuthor(input: $input, condition: $condition) {
          __typename
          id
          accountId
          responseId
          authorId
          createdAt
          updatedAt
          author {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          questionnaireResponse {
            __typename
            id
            accountId
            patientId
            encounterId
            contributors
            created
            name
            title
            status
            json
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteQuestionnaireResponseAuthorMutation>(
      response.data.deleteQuestionnaireResponseAuthor
    );
  }
  async CreateQuestionnaireResponse(
    input: CreateQuestionnaireResponseInput,
    condition?: ModelQuestionnaireResponseConditionInput
  ): Promise<CreateQuestionnaireResponseMutation> {
    const statement = `mutation CreateQuestionnaireResponse($input: CreateQuestionnaireResponseInput!, $condition: ModelQuestionnaireResponseConditionInput) {
        createQuestionnaireResponse(input: $input, condition: $condition) {
          __typename
          id
          accountId
          patientId
          encounterId
          contributors
          created
          name
          title
          status
          json
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          authors {
            __typename
            nextToken
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateQuestionnaireResponseMutation>(
      response.data.createQuestionnaireResponse
    );
  }
  async UpdateQuestionnaireResponse(
    input: UpdateQuestionnaireResponseInput,
    condition?: ModelQuestionnaireResponseConditionInput
  ): Promise<UpdateQuestionnaireResponseMutation> {
    const statement = `mutation UpdateQuestionnaireResponse($input: UpdateQuestionnaireResponseInput!, $condition: ModelQuestionnaireResponseConditionInput) {
        updateQuestionnaireResponse(input: $input, condition: $condition) {
          __typename
          id
          accountId
          patientId
          encounterId
          contributors
          created
          name
          title
          status
          json
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          authors {
            __typename
            nextToken
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateQuestionnaireResponseMutation>(
      response.data.updateQuestionnaireResponse
    );
  }
  async DeleteQuestionnaireResponse(
    input: DeleteQuestionnaireResponseInput,
    condition?: ModelQuestionnaireResponseConditionInput
  ): Promise<DeleteQuestionnaireResponseMutation> {
    const statement = `mutation DeleteQuestionnaireResponse($input: DeleteQuestionnaireResponseInput!, $condition: ModelQuestionnaireResponseConditionInput) {
        deleteQuestionnaireResponse(input: $input, condition: $condition) {
          __typename
          id
          accountId
          patientId
          encounterId
          contributors
          created
          name
          title
          status
          json
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          authors {
            __typename
            nextToken
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteQuestionnaireResponseMutation>(
      response.data.deleteQuestionnaireResponse
    );
  }
  async CreateClinicalImpression(
    input: CreateClinicalImpressionInput,
    condition?: ModelClinicalImpressionConditionInput
  ): Promise<CreateClinicalImpressionMutation> {
    const statement = `mutation CreateClinicalImpression($input: CreateClinicalImpressionInput!, $condition: ModelClinicalImpressionConditionInput) {
        createClinicalImpression(input: $input, condition: $condition) {
          __typename
          id
          accountId
          patientId
          encounterId
          authorId
          created
          title
          status
          summary
          name
          signature
          approved
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          questionnaireResponse {
            __typename
            id
            accountId
            patientId
            encounterId
            contributors
            created
            name
            title
            status
            json
            createdAt
            updatedAt
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateClinicalImpressionMutation>(
      response.data.createClinicalImpression
    );
  }
  async UpdateClinicalImpression(
    input: UpdateClinicalImpressionInput,
    condition?: ModelClinicalImpressionConditionInput
  ): Promise<UpdateClinicalImpressionMutation> {
    const statement = `mutation UpdateClinicalImpression($input: UpdateClinicalImpressionInput!, $condition: ModelClinicalImpressionConditionInput) {
        updateClinicalImpression(input: $input, condition: $condition) {
          __typename
          id
          accountId
          patientId
          encounterId
          authorId
          created
          title
          status
          summary
          name
          signature
          approved
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          questionnaireResponse {
            __typename
            id
            accountId
            patientId
            encounterId
            contributors
            created
            name
            title
            status
            json
            createdAt
            updatedAt
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateClinicalImpressionMutation>(
      response.data.updateClinicalImpression
    );
  }
  async DeleteClinicalImpression(
    input: DeleteClinicalImpressionInput,
    condition?: ModelClinicalImpressionConditionInput
  ): Promise<DeleteClinicalImpressionMutation> {
    const statement = `mutation DeleteClinicalImpression($input: DeleteClinicalImpressionInput!, $condition: ModelClinicalImpressionConditionInput) {
        deleteClinicalImpression(input: $input, condition: $condition) {
          __typename
          id
          accountId
          patientId
          encounterId
          authorId
          created
          title
          status
          summary
          name
          signature
          approved
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          questionnaireResponse {
            __typename
            id
            accountId
            patientId
            encounterId
            contributors
            created
            name
            title
            status
            json
            createdAt
            updatedAt
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteClinicalImpressionMutation>(
      response.data.deleteClinicalImpression
    );
  }
  async CreateEncounter(
    input: CreateEncounterInput,
    condition?: ModelEncounterConditionInput
  ): Promise<CreateEncounterMutation> {
    const statement = `mutation CreateEncounter($input: CreateEncounterInput!, $condition: ModelEncounterConditionInput) {
        createEncounter(input: $input, condition: $condition) {
          __typename
          id
          accountId
          locationId
          managerId
          patientId
          ownerId
          status
          date
          soapItems {
            __typename
            type
            text
            code
            selected
          }
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          manager {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          owner {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          location {
            __typename
            id
            owner
            accountId
            name
            callerId
            testsCount
            usersCount
            physicianCount
            createdAt
            updatedAt
          }
          billedTimes {
            __typename
            nextToken
          }
          pretest {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            managerId
            submitterId
            result
            testId
            testCode
            meta
            answerSheetId
            title
            type
            assessed
            scheduledDate
            score
            scoreGauge
            status
            assessmentEncounterId
            createdAt
            updatedAt
          }
          assessments {
            __typename
            nextToken
          }
          questionnaireResponses {
            __typename
            nextToken
          }
          clinicalImpressions {
            __typename
            nextToken
          }
          providers {
            __typename
            nextToken
          }
          notes {
            __typename
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateEncounterMutation>response.data.createEncounter;
  }
  async UpdateEncounter(
    input: UpdateEncounterInput,
    condition?: ModelEncounterConditionInput
  ): Promise<UpdateEncounterMutation> {
    const statement = `mutation UpdateEncounter($input: UpdateEncounterInput!, $condition: ModelEncounterConditionInput) {
        updateEncounter(input: $input, condition: $condition) {
          __typename
          id
          accountId
          locationId
          managerId
          patientId
          ownerId
          status
          date
          soapItems {
            __typename
            type
            text
            code
            selected
          }
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          manager {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          owner {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          location {
            __typename
            id
            owner
            accountId
            name
            callerId
            testsCount
            usersCount
            physicianCount
            createdAt
            updatedAt
          }
          billedTimes {
            __typename
            nextToken
          }
          pretest {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            managerId
            submitterId
            result
            testId
            testCode
            meta
            answerSheetId
            title
            type
            assessed
            scheduledDate
            score
            scoreGauge
            status
            assessmentEncounterId
            createdAt
            updatedAt
          }
          assessments {
            __typename
            nextToken
          }
          questionnaireResponses {
            __typename
            nextToken
          }
          clinicalImpressions {
            __typename
            nextToken
          }
          providers {
            __typename
            nextToken
          }
          notes {
            __typename
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateEncounterMutation>response.data.updateEncounter;
  }
  async DeleteEncounter(
    input: DeleteEncounterInput,
    condition?: ModelEncounterConditionInput
  ): Promise<DeleteEncounterMutation> {
    const statement = `mutation DeleteEncounter($input: DeleteEncounterInput!, $condition: ModelEncounterConditionInput) {
        deleteEncounter(input: $input, condition: $condition) {
          __typename
          id
          accountId
          locationId
          managerId
          patientId
          ownerId
          status
          date
          soapItems {
            __typename
            type
            text
            code
            selected
          }
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          manager {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          owner {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          location {
            __typename
            id
            owner
            accountId
            name
            callerId
            testsCount
            usersCount
            physicianCount
            createdAt
            updatedAt
          }
          billedTimes {
            __typename
            nextToken
          }
          pretest {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            managerId
            submitterId
            result
            testId
            testCode
            meta
            answerSheetId
            title
            type
            assessed
            scheduledDate
            score
            scoreGauge
            status
            assessmentEncounterId
            createdAt
            updatedAt
          }
          assessments {
            __typename
            nextToken
          }
          questionnaireResponses {
            __typename
            nextToken
          }
          clinicalImpressions {
            __typename
            nextToken
          }
          providers {
            __typename
            nextToken
          }
          notes {
            __typename
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteEncounterMutation>response.data.deleteEncounter;
  }
  async CreateEncounterProviderConnection(
    input: CreateEncounterProviderConnectionInput,
    condition?: ModelEncounterProviderConnectionConditionInput
  ): Promise<CreateEncounterProviderConnectionMutation> {
    const statement = `mutation CreateEncounterProviderConnection($input: CreateEncounterProviderConnectionInput!, $condition: ModelEncounterProviderConnectionConditionInput) {
        createEncounterProviderConnection(input: $input, condition: $condition) {
          __typename
          id
          accountId
          encounterId
          providerId
          createdAt
          updatedAt
          provider {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateEncounterProviderConnectionMutation>(
      response.data.createEncounterProviderConnection
    );
  }
  async UpdateEncounterProviderConnection(
    input: UpdateEncounterProviderConnectionInput,
    condition?: ModelEncounterProviderConnectionConditionInput
  ): Promise<UpdateEncounterProviderConnectionMutation> {
    const statement = `mutation UpdateEncounterProviderConnection($input: UpdateEncounterProviderConnectionInput!, $condition: ModelEncounterProviderConnectionConditionInput) {
        updateEncounterProviderConnection(input: $input, condition: $condition) {
          __typename
          id
          accountId
          encounterId
          providerId
          createdAt
          updatedAt
          provider {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateEncounterProviderConnectionMutation>(
      response.data.updateEncounterProviderConnection
    );
  }
  async DeleteEncounterProviderConnection(
    input: DeleteEncounterProviderConnectionInput,
    condition?: ModelEncounterProviderConnectionConditionInput
  ): Promise<DeleteEncounterProviderConnectionMutation> {
    const statement = `mutation DeleteEncounterProviderConnection($input: DeleteEncounterProviderConnectionInput!, $condition: ModelEncounterProviderConnectionConditionInput) {
        deleteEncounterProviderConnection(input: $input, condition: $condition) {
          __typename
          id
          accountId
          encounterId
          providerId
          createdAt
          updatedAt
          provider {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteEncounterProviderConnectionMutation>(
      response.data.deleteEncounterProviderConnection
    );
  }
  async CreateEncounterNoteConnection(
    input: CreateEncounterNoteConnectionInput,
    condition?: ModelEncounterNoteConnectionConditionInput
  ): Promise<CreateEncounterNoteConnectionMutation> {
    const statement = `mutation CreateEncounterNoteConnection($input: CreateEncounterNoteConnectionInput!, $condition: ModelEncounterNoteConnectionConditionInput) {
        createEncounterNoteConnection(input: $input, condition: $condition) {
          __typename
          id
          accountId
          encounterId
          noteId
          createdAt
          updatedAt
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
          note {
            __typename
            id
            author
            userId
            patientId
            type
            title
            content
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateEncounterNoteConnectionMutation>(
      response.data.createEncounterNoteConnection
    );
  }
  async UpdateEncounterNoteConnection(
    input: UpdateEncounterNoteConnectionInput,
    condition?: ModelEncounterNoteConnectionConditionInput
  ): Promise<UpdateEncounterNoteConnectionMutation> {
    const statement = `mutation UpdateEncounterNoteConnection($input: UpdateEncounterNoteConnectionInput!, $condition: ModelEncounterNoteConnectionConditionInput) {
        updateEncounterNoteConnection(input: $input, condition: $condition) {
          __typename
          id
          accountId
          encounterId
          noteId
          createdAt
          updatedAt
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
          note {
            __typename
            id
            author
            userId
            patientId
            type
            title
            content
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateEncounterNoteConnectionMutation>(
      response.data.updateEncounterNoteConnection
    );
  }
  async DeleteEncounterNoteConnection(
    input: DeleteEncounterNoteConnectionInput,
    condition?: ModelEncounterNoteConnectionConditionInput
  ): Promise<DeleteEncounterNoteConnectionMutation> {
    const statement = `mutation DeleteEncounterNoteConnection($input: DeleteEncounterNoteConnectionInput!, $condition: ModelEncounterNoteConnectionConditionInput) {
        deleteEncounterNoteConnection(input: $input, condition: $condition) {
          __typename
          id
          accountId
          encounterId
          noteId
          createdAt
          updatedAt
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
          note {
            __typename
            id
            author
            userId
            patientId
            type
            title
            content
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteEncounterNoteConnectionMutation>(
      response.data.deleteEncounterNoteConnection
    );
  }
  async CreateNote(
    input: CreateNoteInput,
    condition?: ModelNoteConditionInput
  ): Promise<CreateNoteMutation> {
    const statement = `mutation CreateNote($input: CreateNoteInput!, $condition: ModelNoteConditionInput) {
        createNote(input: $input, condition: $condition) {
          __typename
          id
          author
          userId
          patientId
          type
          title
          content
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          user {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateNoteMutation>response.data.createNote;
  }
  async UpdateNote(
    input: UpdateNoteInput,
    condition?: ModelNoteConditionInput
  ): Promise<UpdateNoteMutation> {
    const statement = `mutation UpdateNote($input: UpdateNoteInput!, $condition: ModelNoteConditionInput) {
        updateNote(input: $input, condition: $condition) {
          __typename
          id
          author
          userId
          patientId
          type
          title
          content
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          user {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateNoteMutation>response.data.updateNote;
  }
  async DeleteNote(
    input: DeleteNoteInput,
    condition?: ModelNoteConditionInput
  ): Promise<DeleteNoteMutation> {
    const statement = `mutation DeleteNote($input: DeleteNoteInput!, $condition: ModelNoteConditionInput) {
        deleteNote(input: $input, condition: $condition) {
          __typename
          id
          author
          userId
          patientId
          type
          title
          content
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          user {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteNoteMutation>response.data.deleteNote;
  }
  async CreateDiagnosticCode(
    input: CreateDiagnosticCodeInput,
    condition?: ModelDiagnosticCodeConditionInput
  ): Promise<CreateDiagnosticCodeMutation> {
    const statement = `mutation CreateDiagnosticCode($input: CreateDiagnosticCodeInput!, $condition: ModelDiagnosticCodeConditionInput) {
        createDiagnosticCode(input: $input, condition: $condition) {
          __typename
          id
          accountId
          codeId
          patientId
          diagnosed
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          code {
            __typename
            id
            type
            name
            description
            rate
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateDiagnosticCodeMutation>response.data.createDiagnosticCode;
  }
  async UpdateDiagnosticCode(
    input: UpdateDiagnosticCodeInput,
    condition?: ModelDiagnosticCodeConditionInput
  ): Promise<UpdateDiagnosticCodeMutation> {
    const statement = `mutation UpdateDiagnosticCode($input: UpdateDiagnosticCodeInput!, $condition: ModelDiagnosticCodeConditionInput) {
        updateDiagnosticCode(input: $input, condition: $condition) {
          __typename
          id
          accountId
          codeId
          patientId
          diagnosed
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          code {
            __typename
            id
            type
            name
            description
            rate
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateDiagnosticCodeMutation>response.data.updateDiagnosticCode;
  }
  async DeleteDiagnosticCode(
    input: DeleteDiagnosticCodeInput,
    condition?: ModelDiagnosticCodeConditionInput
  ): Promise<DeleteDiagnosticCodeMutation> {
    const statement = `mutation DeleteDiagnosticCode($input: DeleteDiagnosticCodeInput!, $condition: ModelDiagnosticCodeConditionInput) {
        deleteDiagnosticCode(input: $input, condition: $condition) {
          __typename
          id
          accountId
          codeId
          patientId
          diagnosed
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          code {
            __typename
            id
            type
            name
            description
            rate
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteDiagnosticCodeMutation>response.data.deleteDiagnosticCode;
  }
  async CreateBillingItem(
    input: CreateBillingItemInput,
    condition?: ModelBillingItemConditionInput
  ): Promise<CreateBillingItemMutation> {
    const statement = `mutation CreateBillingItem($input: CreateBillingItemInput!, $condition: ModelBillingItemConditionInput) {
        createBillingItem(input: $input, condition: $condition) {
          __typename
          id
          accountId
          locationId
          patientId
          codeId
          amount
          type
          currency
          created
          serviced
          status
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          billedTime {
            __typename
            nextToken
          }
          code {
            __typename
            id
            type
            name
            description
            rate
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateBillingItemMutation>response.data.createBillingItem;
  }
  async UpdateBillingItem(
    input: UpdateBillingItemInput,
    condition?: ModelBillingItemConditionInput
  ): Promise<UpdateBillingItemMutation> {
    const statement = `mutation UpdateBillingItem($input: UpdateBillingItemInput!, $condition: ModelBillingItemConditionInput) {
        updateBillingItem(input: $input, condition: $condition) {
          __typename
          id
          accountId
          locationId
          patientId
          codeId
          amount
          type
          currency
          created
          serviced
          status
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          billedTime {
            __typename
            nextToken
          }
          code {
            __typename
            id
            type
            name
            description
            rate
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateBillingItemMutation>response.data.updateBillingItem;
  }
  async DeleteBillingItem(
    input: DeleteBillingItemInput,
    condition?: ModelBillingItemConditionInput
  ): Promise<DeleteBillingItemMutation> {
    const statement = `mutation DeleteBillingItem($input: DeleteBillingItemInput!, $condition: ModelBillingItemConditionInput) {
        deleteBillingItem(input: $input, condition: $condition) {
          __typename
          id
          accountId
          locationId
          patientId
          codeId
          amount
          type
          currency
          created
          serviced
          status
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          billedTime {
            __typename
            nextToken
          }
          code {
            __typename
            id
            type
            name
            description
            rate
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteBillingItemMutation>response.data.deleteBillingItem;
  }
  async CreateInvoice(
    input: CreateInvoiceInput,
    condition?: ModelInvoiceConditionInput
  ): Promise<CreateInvoiceMutation> {
    const statement = `mutation CreateInvoice($input: CreateInvoiceInput!, $condition: ModelInvoiceConditionInput) {
        createInvoice(input: $input, condition: $condition) {
          __typename
          id
          accountId
          locationId
          dueDate
          billDate
          billingPeriodStartDate
          billingPeriodEndDate
          hours
          totalBill
          status
          createdAt
          updatedAt
          billingItems {
            __typename
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateInvoiceMutation>response.data.createInvoice;
  }
  async UpdateInvoice(
    input: UpdateInvoiceInput,
    condition?: ModelInvoiceConditionInput
  ): Promise<UpdateInvoiceMutation> {
    const statement = `mutation UpdateInvoice($input: UpdateInvoiceInput!, $condition: ModelInvoiceConditionInput) {
        updateInvoice(input: $input, condition: $condition) {
          __typename
          id
          accountId
          locationId
          dueDate
          billDate
          billingPeriodStartDate
          billingPeriodEndDate
          hours
          totalBill
          status
          createdAt
          updatedAt
          billingItems {
            __typename
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateInvoiceMutation>response.data.updateInvoice;
  }
  async DeleteInvoice(
    input: DeleteInvoiceInput,
    condition?: ModelInvoiceConditionInput
  ): Promise<DeleteInvoiceMutation> {
    const statement = `mutation DeleteInvoice($input: DeleteInvoiceInput!, $condition: ModelInvoiceConditionInput) {
        deleteInvoice(input: $input, condition: $condition) {
          __typename
          id
          accountId
          locationId
          dueDate
          billDate
          billingPeriodStartDate
          billingPeriodEndDate
          hours
          totalBill
          status
          createdAt
          updatedAt
          billingItems {
            __typename
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteInvoiceMutation>response.data.deleteInvoice;
  }
  async CreatePayment(
    input: CreatePaymentInput,
    condition?: ModelPaymentConditionInput
  ): Promise<CreatePaymentMutation> {
    const statement = `mutation CreatePayment($input: CreatePaymentInput!, $condition: ModelPaymentConditionInput) {
        createPayment(input: $input, condition: $condition) {
          __typename
          id
          accountId
          locationId
          userId
          stripePaymentMethodId
          paymentDate
          createdAt
          updatedAt
          invoice {
            __typename
            id
            accountId
            locationId
            dueDate
            billDate
            billingPeriodStartDate
            billingPeriodEndDate
            hours
            totalBill
            status
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreatePaymentMutation>response.data.createPayment;
  }
  async UpdatePayment(
    input: UpdatePaymentInput,
    condition?: ModelPaymentConditionInput
  ): Promise<UpdatePaymentMutation> {
    const statement = `mutation UpdatePayment($input: UpdatePaymentInput!, $condition: ModelPaymentConditionInput) {
        updatePayment(input: $input, condition: $condition) {
          __typename
          id
          accountId
          locationId
          userId
          stripePaymentMethodId
          paymentDate
          createdAt
          updatedAt
          invoice {
            __typename
            id
            accountId
            locationId
            dueDate
            billDate
            billingPeriodStartDate
            billingPeriodEndDate
            hours
            totalBill
            status
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdatePaymentMutation>response.data.updatePayment;
  }
  async DeletePayment(
    input: DeletePaymentInput,
    condition?: ModelPaymentConditionInput
  ): Promise<DeletePaymentMutation> {
    const statement = `mutation DeletePayment($input: DeletePaymentInput!, $condition: ModelPaymentConditionInput) {
        deletePayment(input: $input, condition: $condition) {
          __typename
          id
          accountId
          locationId
          userId
          stripePaymentMethodId
          paymentDate
          createdAt
          updatedAt
          invoice {
            __typename
            id
            accountId
            locationId
            dueDate
            billDate
            billingPeriodStartDate
            billingPeriodEndDate
            hours
            totalBill
            status
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeletePaymentMutation>response.data.deletePayment;
  }
  async CreateCode(
    input: CreateCodeInput,
    condition?: ModelCodeConditionInput
  ): Promise<CreateCodeMutation> {
    const statement = `mutation CreateCode($input: CreateCodeInput!, $condition: ModelCodeConditionInput) {
        createCode(input: $input, condition: $condition) {
          __typename
          id
          type
          name
          description
          rate
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCodeMutation>response.data.createCode;
  }
  async UpdateCode(
    input: UpdateCodeInput,
    condition?: ModelCodeConditionInput
  ): Promise<UpdateCodeMutation> {
    const statement = `mutation UpdateCode($input: UpdateCodeInput!, $condition: ModelCodeConditionInput) {
        updateCode(input: $input, condition: $condition) {
          __typename
          id
          type
          name
          description
          rate
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCodeMutation>response.data.updateCode;
  }
  async DeleteCode(
    input: DeleteCodeInput,
    condition?: ModelCodeConditionInput
  ): Promise<DeleteCodeMutation> {
    const statement = `mutation DeleteCode($input: DeleteCodeInput!, $condition: ModelCodeConditionInput) {
        deleteCode(input: $input, condition: $condition) {
          __typename
          id
          type
          name
          description
          rate
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCodeMutation>response.data.deleteCode;
  }
  async ListPatients(
    filter?: ModelPatientFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListPatientsQuery> {
    const statement = `query ListPatients($filter: ModelPatientFilterInput, $limit: Int, $nextToken: String) {
        listPatients(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListPatientsQuery>response.data.listPatients;
  }
  async GetPatient(id: string): Promise<GetPatientQuery> {
    const statement = `query GetPatient($id: ID!) {
        getPatient(id: $id) {
          __typename
          id
          accountId
          locationId
          patientManagerId
          owner
          salutation
          givenName
          middleName
          familyName
          address {
            __typename
            line1
            line2
            region
            postal
            country
          }
          ssn
          dob
          gender
          mobile
          email
          interests
          providerIds
          status
          eligibleRPM
          contacts {
            __typename
            id
            salutation
            givenName
            familyName
            mobile
            email
            contactType
          }
          insurance {
            __typename
            id
            memberId
            groupId
            carrierId
            insuranceType
          }
          qualifiedTests
          connectionId
          createdAt
          updatedAt
          providers {
            __typename
            nextToken
          }
          programs {
            __typename
            nextToken
          }
          eligibleTests {
            __typename
            nextToken
          }
          manager {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          location {
            __typename
            id
            owner
            accountId
            name
            callerId
            testsCount
            usersCount
            physicianCount
            createdAt
            updatedAt
          }
          lastAssessment {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            managerId
            submitterId
            result
            testId
            testCode
            meta
            answerSheetId
            title
            type
            assessed
            scheduledDate
            score
            scoreGauge
            status
            assessmentEncounterId
            createdAt
            updatedAt
          }
          assessments {
            __typename
            nextToken
          }
          treatmentPlan {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            treatmentPlanManagerId
            created
            createdAt
            updatedAt
          }
          lastEncounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
          diagnoses {
            __typename
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetPatientQuery>response.data.getPatient;
  }
  async GetPatientProviderConnection(
    id: string
  ): Promise<GetPatientProviderConnectionQuery> {
    const statement = `query GetPatientProviderConnection($id: ID!) {
        getPatientProviderConnection(id: $id) {
          __typename
          id
          accountId
          patientId
          providerId
          type
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          provider {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetPatientProviderConnectionQuery>(
      response.data.getPatientProviderConnection
    );
  }
  async ListPatientProviderConnections(
    filter?: ModelPatientProviderConnectionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListPatientProviderConnectionsQuery> {
    const statement = `query ListPatientProviderConnections($filter: ModelPatientProviderConnectionFilterInput, $limit: Int, $nextToken: String) {
        listPatientProviderConnections(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            accountId
            patientId
            providerId
            type
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListPatientProviderConnectionsQuery>(
      response.data.listPatientProviderConnections
    );
  }
  async GetPatientProgramConnection(
    id: string
  ): Promise<GetPatientProgramConnectionQuery> {
    const statement = `query GetPatientProgramConnection($id: ID!) {
        getPatientProgramConnection(id: $id) {
          __typename
          id
          accountId
          patientId
          programId
          date
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          program {
            __typename
            id
            questionnaireId
            name
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetPatientProgramConnectionQuery>(
      response.data.getPatientProgramConnection
    );
  }
  async ListPatientProgramConnections(
    filter?: ModelPatientProgramConnectionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListPatientProgramConnectionsQuery> {
    const statement = `query ListPatientProgramConnections($filter: ModelPatientProgramConnectionFilterInput, $limit: Int, $nextToken: String) {
        listPatientProgramConnections(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            accountId
            patientId
            programId
            date
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListPatientProgramConnectionsQuery>(
      response.data.listPatientProgramConnections
    );
  }
  async ListPrograms(
    filter?: ModelProgramFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListProgramsQuery> {
    const statement = `query ListPrograms($filter: ModelProgramFilterInput, $limit: Int, $nextToken: String) {
        listPrograms(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            questionnaireId
            name
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListProgramsQuery>response.data.listPrograms;
  }
  async GetProgram(id: string): Promise<GetProgramQuery> {
    const statement = `query GetProgram($id: ID!) {
        getProgram(id: $id) {
          __typename
          id
          questionnaireId
          name
          createdAt
          updatedAt
          tests {
            __typename
            nextToken
          }
          questionnaire {
            __typename
            id
            created
            name
            title
            template
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetProgramQuery>response.data.getProgram;
  }
  async ListProgramTests(
    filter?: ModelProgramTestFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListProgramTestsQuery> {
    const statement = `query ListProgramTests($filter: ModelProgramTestFilterInput, $limit: Int, $nextToken: String) {
        listProgramTests(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            referenceTestId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListProgramTestsQuery>response.data.listProgramTests;
  }
  async GetProgramTest(id: string): Promise<GetProgramTestQuery> {
    const statement = `query GetProgramTest($id: ID!) {
        getProgramTest(id: $id) {
          __typename
          id
          name
          referenceTestId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetProgramTestQuery>response.data.getProgramTest;
  }
  async GetEligibilityConnection(
    id: string
  ): Promise<GetEligibilityConnectionQuery> {
    const statement = `query GetEligibilityConnection($id: ID!) {
        getEligibilityConnection(id: $id) {
          __typename
          id
          patientId
          programTestId
          required
          date
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          programTest {
            __typename
            id
            name
            referenceTestId
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetEligibilityConnectionQuery>(
      response.data.getEligibilityConnection
    );
  }
  async ListEligibilityConnections(
    filter?: ModelEligibilityConnectionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListEligibilityConnectionsQuery> {
    const statement = `query ListEligibilityConnections($filter: ModelEligibilityConnectionFilterInput, $limit: Int, $nextToken: String) {
        listEligibilityConnections(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            patientId
            programTestId
            required
            date
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEligibilityConnectionsQuery>(
      response.data.listEligibilityConnections
    );
  }
  async ListUsers(
    filter?: ModelUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListUsersQuery> {
    const statement = `query ListUsers($filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
        listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListUsersQuery>response.data.listUsers;
  }
  async GetUser(id: string): Promise<GetUserQuery> {
    const statement = `query GetUser($id: ID!) {
        getUser(id: $id) {
          __typename
          id
          accountId
          owner
          email
          phone
          online
          firstName
          lastName
          titles
          npi
          image
          userType
          connections {
            __typename
            connectionId
            startedAt
          }
          onlineStatus
          lastLogin {
            __typename
            ip
            date
          }
          pinCode
          permissions
          createdAt
          updatedAt
          locations {
            __typename
            nextToken
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUserQuery>response.data.getUser;
  }
  async SearchUsers(
    filter?: SearchableUserFilterInput,
    sort?: SearchableUserSortInput,
    limit?: number,
    nextToken?: string,
    from?: number
  ): Promise<SearchUsersQuery> {
    const statement = `query SearchUsers($filter: SearchableUserFilterInput, $sort: SearchableUserSortInput, $limit: Int, $nextToken: String, $from: Int) {
        searchUsers(filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken, from: $from) {
          __typename
          items {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          nextToken
          total
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (sort) {
      gqlAPIServiceArguments.sort = sort;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (from) {
      gqlAPIServiceArguments.from = from;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SearchUsersQuery>response.data.searchUsers;
  }
  async GetUserLocationConnection(
    id: string
  ): Promise<GetUserLocationConnectionQuery> {
    const statement = `query GetUserLocationConnection($id: ID!) {
        getUserLocationConnection(id: $id) {
          __typename
          id
          accountId
          userId
          locationId
          createdAt
          updatedAt
          user {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          location {
            __typename
            id
            owner
            accountId
            name
            callerId
            testsCount
            usersCount
            physicianCount
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUserLocationConnectionQuery>(
      response.data.getUserLocationConnection
    );
  }
  async ListUserLocationConnections(
    filter?: ModelUserLocationConnectionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListUserLocationConnectionsQuery> {
    const statement = `query ListUserLocationConnections($filter: ModelUserLocationConnectionFilterInput, $limit: Int, $nextToken: String) {
        listUserLocationConnections(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            accountId
            userId
            locationId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListUserLocationConnectionsQuery>(
      response.data.listUserLocationConnections
    );
  }
  async ListAccounts(
    filter?: ModelAccountFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAccountsQuery> {
    const statement = `query ListAccounts($filter: ModelAccountFilterInput, $limit: Int, $nextToken: String) {
        listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAccountsQuery>response.data.listAccounts;
  }
  async GetAccount(id: string): Promise<GetAccountQuery> {
    const statement = `query GetAccount($id: ID!) {
        getAccount(id: $id) {
          __typename
          id
          name
          owner
          stripeCustomerId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAccountQuery>response.data.getAccount;
  }
  async ListLocations(
    filter?: ModelLocationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListLocationsQuery> {
    const statement = `query ListLocations($filter: ModelLocationFilterInput, $limit: Int, $nextToken: String) {
        listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            owner
            accountId
            name
            callerId
            testsCount
            usersCount
            physicianCount
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLocationsQuery>response.data.listLocations;
  }
  async GetLocation(id: string): Promise<GetLocationQuery> {
    const statement = `query GetLocation($id: ID!) {
        getLocation(id: $id) {
          __typename
          id
          owner
          accountId
          name
          address {
            __typename
            line1
            line2
            region
            postal
            country
          }
          callerId
          testsCount
          usersCount
          physicianCount
          frontDesk {
            __typename
            id
            salutation
            givenName
            familyName
            mobile
            email
            contactType
          }
          createdAt
          updatedAt
          lastLoginUser {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          users {
            __typename
            nextToken
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          lastAssessment {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            managerId
            submitterId
            result
            testId
            testCode
            meta
            answerSheetId
            title
            type
            assessed
            scheduledDate
            score
            scoreGauge
            status
            assessmentEncounterId
            createdAt
            updatedAt
          }
          lastPayment {
            __typename
            id
            accountId
            locationId
            userId
            stripePaymentMethodId
            paymentDate
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetLocationQuery>response.data.getLocation;
  }
  async SearchLocations(
    filter?: SearchableLocationFilterInput,
    sort?: SearchableLocationSortInput,
    limit?: number,
    nextToken?: string,
    from?: number
  ): Promise<SearchLocationsQuery> {
    const statement = `query SearchLocations($filter: SearchableLocationFilterInput, $sort: SearchableLocationSortInput, $limit: Int, $nextToken: String, $from: Int) {
        searchLocations(filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken, from: $from) {
          __typename
          items {
            __typename
            id
            owner
            accountId
            name
            callerId
            testsCount
            usersCount
            physicianCount
            createdAt
            updatedAt
          }
          nextToken
          total
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (sort) {
      gqlAPIServiceArguments.sort = sort;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    if (from) {
      gqlAPIServiceArguments.from = from;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SearchLocationsQuery>response.data.searchLocations;
  }
  async GetBilledTime(id: string): Promise<GetBilledTimeQuery> {
    const statement = `query GetBilledTime($id: ID!) {
        getBilledTime(id: $id) {
          __typename
          id
          minutes
          type
          date
          userId
          createdAt
          updatedAt
          user {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetBilledTimeQuery>response.data.getBilledTime;
  }
  async ListBilledTimes(
    filter?: ModelBilledTimeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListBilledTimesQuery> {
    const statement = `query ListBilledTimes($filter: ModelBilledTimeFilterInput, $limit: Int, $nextToken: String) {
        listBilledTimes(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            minutes
            type
            date
            userId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListBilledTimesQuery>response.data.listBilledTimes;
  }
  async GetEncounterBilledTimeConnection(
    id: string
  ): Promise<GetEncounterBilledTimeConnectionQuery> {
    const statement = `query GetEncounterBilledTimeConnection($id: ID!) {
        getEncounterBilledTimeConnection(id: $id) {
          __typename
          id
          accountId
          encounterId
          billedTimeId
          createdAt
          updatedAt
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          billedTime {
            __typename
            id
            minutes
            type
            date
            userId
            createdAt
            updatedAt
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetEncounterBilledTimeConnectionQuery>(
      response.data.getEncounterBilledTimeConnection
    );
  }
  async ListEncounterBilledTimeConnections(
    filter?: ModelEncounterBilledTimeConnectionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListEncounterBilledTimeConnectionsQuery> {
    const statement = `query ListEncounterBilledTimeConnections($filter: ModelEncounterBilledTimeConnectionFilterInput, $limit: Int, $nextToken: String) {
        listEncounterBilledTimeConnections(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            accountId
            encounterId
            billedTimeId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEncounterBilledTimeConnectionsQuery>(
      response.data.listEncounterBilledTimeConnections
    );
  }
  async GetAssessmentBilledTimeConnection(
    id: string
  ): Promise<GetAssessmentBilledTimeConnectionQuery> {
    const statement = `query GetAssessmentBilledTimeConnection($id: ID!) {
        getAssessmentBilledTimeConnection(id: $id) {
          __typename
          id
          accountId
          assessmentId
          billedTimeId
          createdAt
          updatedAt
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          billedTime {
            __typename
            id
            minutes
            type
            date
            userId
            createdAt
            updatedAt
          }
          assessment {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            managerId
            submitterId
            result
            testId
            testCode
            meta
            answerSheetId
            title
            type
            assessed
            scheduledDate
            score
            scoreGauge
            status
            assessmentEncounterId
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAssessmentBilledTimeConnectionQuery>(
      response.data.getAssessmentBilledTimeConnection
    );
  }
  async ListAssessmentBilledTimeConnections(
    filter?: ModelAssessmentBilledTimeConnectionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAssessmentBilledTimeConnectionsQuery> {
    const statement = `query ListAssessmentBilledTimeConnections($filter: ModelAssessmentBilledTimeConnectionFilterInput, $limit: Int, $nextToken: String) {
        listAssessmentBilledTimeConnections(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            accountId
            assessmentId
            billedTimeId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAssessmentBilledTimeConnectionsQuery>(
      response.data.listAssessmentBilledTimeConnections
    );
  }
  async GetAssessment(id: string): Promise<GetAssessmentQuery> {
    const statement = `query GetAssessment($id: ID!) {
        getAssessment(id: $id) {
          __typename
          id
          accountId
          locationId
          patientId
          providers
          managerId
          submitterId
          result
          testId
          testCode
          meta
          answerSheetId
          title
          type
          assessed
          scheduledDate
          score
          scoreGauge
          status
          assessmentEncounterId
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          manager {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          location {
            __typename
            id
            owner
            accountId
            name
            callerId
            testsCount
            usersCount
            physicianCount
            createdAt
            updatedAt
          }
          billedTime {
            __typename
            id
            minutes
            type
            date
            userId
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAssessmentQuery>response.data.getAssessment;
  }
  async ListAssessments(
    filter?: ModelAssessmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAssessmentsQuery> {
    const statement = `query ListAssessments($filter: ModelAssessmentFilterInput, $limit: Int, $nextToken: String) {
        listAssessments(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            managerId
            submitterId
            result
            testId
            testCode
            meta
            answerSheetId
            title
            type
            assessed
            scheduledDate
            score
            scoreGauge
            status
            assessmentEncounterId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAssessmentsQuery>response.data.listAssessments;
  }
  async ListTreatmentPlans(
    filter?: ModelTreatmentPlanFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTreatmentPlansQuery> {
    const statement = `query ListTreatmentPlans($filter: ModelTreatmentPlanFilterInput, $limit: Int, $nextToken: String) {
        listTreatmentPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            treatmentPlanManagerId
            created
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTreatmentPlansQuery>response.data.listTreatmentPlans;
  }
  async GetTreatmentPlan(id: string): Promise<GetTreatmentPlanQuery> {
    const statement = `query GetTreatmentPlan($id: ID!) {
        getTreatmentPlan(id: $id) {
          __typename
          id
          accountId
          locationId
          patientId
          providers
          treatmentPlanManagerId
          created
          createdAt
          updatedAt
          patient {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          location {
            __typename
            id
            owner
            accountId
            name
            callerId
            testsCount
            usersCount
            physicianCount
            createdAt
            updatedAt
          }
          resourceEvents {
            __typename
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTreatmentPlanQuery>response.data.getTreatmentPlan;
  }
  async GetPatientResourceScheduledEventConnection(
    id: string
  ): Promise<GetPatientResourceScheduledEventConnectionQuery> {
    const statement = `query GetPatientResourceScheduledEventConnection($id: ID!) {
        getPatientResourceScheduledEventConnection(id: $id) {
          __typename
          id
          accountId
          patientId
          encounterId
          resourceScheduledEventId
          created
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          resourceScheduledEvent {
            __typename
            id
            accountId
            ownerId
            locationId
            title
            start
            end
            startTimezone
            endTimezone
            isAllDay
            description
            recurrenceRule
            recurrenceExceptions
            recurrenceId
            resourceId
            category
            cost
            ageGroup
            createdAt
            updatedAt
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetPatientResourceScheduledEventConnectionQuery>(
      response.data.getPatientResourceScheduledEventConnection
    );
  }
  async ListPatientResourceScheduledEventConnections(
    filter?: ModelPatientResourceScheduledEventConnectionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListPatientResourceScheduledEventConnectionsQuery> {
    const statement = `query ListPatientResourceScheduledEventConnections($filter: ModelPatientResourceScheduledEventConnectionFilterInput, $limit: Int, $nextToken: String) {
        listPatientResourceScheduledEventConnections(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            accountId
            patientId
            encounterId
            resourceScheduledEventId
            created
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListPatientResourceScheduledEventConnectionsQuery>(
      response.data.listPatientResourceScheduledEventConnections
    );
  }
  async GetTelehealthSession(id: string): Promise<GetTelehealthSessionQuery> {
    const statement = `query GetTelehealthSession($id: ID!) {
        getTelehealthSession(id: $id) {
          __typename
          id
          title
          accountId
          participantIds
          participants {
            __typename
            name
            type
            identity
          }
          status
          roomType
          startedAt
          endedAt
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTelehealthSessionQuery>response.data.getTelehealthSession;
  }
  async ListTelehealthSessions(
    filter?: ModelTelehealthSessionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTelehealthSessionsQuery> {
    const statement = `query ListTelehealthSessions($filter: ModelTelehealthSessionFilterInput, $limit: Int, $nextToken: String) {
        listTelehealthSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            title
            accountId
            participantIds
            status
            roomType
            startedAt
            endedAt
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTelehealthSessionsQuery>response.data.listTelehealthSessions;
  }
  async GetScheduledEvent(id: string): Promise<GetScheduledEventQuery> {
    const statement = `query GetScheduledEvent($id: ID!) {
        getScheduledEvent(id: $id) {
          __typename
          id
          accountId
          ownerId
          invitees {
            __typename
            name
            type
            id
          }
          participantIds
          title
          start
          end
          type
          assessments {
            __typename
            id
            name
          }
          startTimezone
          endTimezone
          isAllDay
          description
          recurrenceRule
          recurrenceExceptions
          recurrenceId
          createdAt
          updatedAt
          resource {
            __typename
            id
            accountId
            ownerId
            locationId
            title
            start
            end
            startTimezone
            endTimezone
            isAllDay
            description
            recurrenceRule
            recurrenceExceptions
            recurrenceId
            resourceId
            category
            cost
            ageGroup
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetScheduledEventQuery>response.data.getScheduledEvent;
  }
  async ListScheduledEvents(
    filter?: ModelScheduledEventFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListScheduledEventsQuery> {
    const statement = `query ListScheduledEvents($filter: ModelScheduledEventFilterInput, $limit: Int, $nextToken: String) {
        listScheduledEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            accountId
            ownerId
            participantIds
            title
            start
            end
            type
            startTimezone
            endTimezone
            isAllDay
            description
            recurrenceRule
            recurrenceExceptions
            recurrenceId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListScheduledEventsQuery>response.data.listScheduledEvents;
  }
  async ListResourceScheduledEvents(
    filter?: ModelResourceScheduledEventFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListResourceScheduledEventsQuery> {
    const statement = `query ListResourceScheduledEvents($filter: ModelResourceScheduledEventFilterInput, $limit: Int, $nextToken: String) {
        listResourceScheduledEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            accountId
            ownerId
            locationId
            title
            start
            end
            startTimezone
            endTimezone
            isAllDay
            description
            recurrenceRule
            recurrenceExceptions
            recurrenceId
            resourceId
            category
            cost
            ageGroup
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListResourceScheduledEventsQuery>(
      response.data.listResourceScheduledEvents
    );
  }
  async GetResourceScheduledEvent(
    id: string
  ): Promise<GetResourceScheduledEventQuery> {
    const statement = `query GetResourceScheduledEvent($id: ID!) {
        getResourceScheduledEvent(id: $id) {
          __typename
          id
          accountId
          ownerId
          locationId
          title
          start
          end
          startTimezone
          endTimezone
          isAllDay
          description
          recurrenceRule
          recurrenceExceptions
          recurrenceId
          resourceId
          category
          cost
          ageGroup
          createdAt
          updatedAt
          resource {
            __typename
            id
            ownerId
            accountId
            locationId
            name
            type
            classification
            website
            businessHours
            billedTime
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetResourceScheduledEventQuery>(
      response.data.getResourceScheduledEvent
    );
  }
  async GetSessionRecording(id: string): Promise<GetSessionRecordingQuery> {
    const statement = `query GetSessionRecording($id: ID!) {
        getSessionRecording(id: $id) {
          __typename
          id
          codec
          container
          offset
          sourceSid
          twilioMediaUri
          roomSid
          eventId
          ownerId
          sessionId
          s3Url
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSessionRecordingQuery>response.data.getSessionRecording;
  }
  async ListSessionRecordings(
    filter?: ModelSessionRecordingFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSessionRecordingsQuery> {
    const statement = `query ListSessionRecordings($filter: ModelSessionRecordingFilterInput, $limit: Int, $nextToken: String) {
        listSessionRecordings(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            codec
            container
            offset
            sourceSid
            twilioMediaUri
            roomSid
            eventId
            ownerId
            sessionId
            s3Url
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListSessionRecordingsQuery>response.data.listSessionRecordings;
  }
  async ListEventResources(
    filter?: ModelEventResourceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListEventResourcesQuery> {
    const statement = `query ListEventResources($filter: ModelEventResourceFilterInput, $limit: Int, $nextToken: String) {
        listEventResources(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            ownerId
            accountId
            locationId
            name
            type
            classification
            website
            businessHours
            billedTime
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEventResourcesQuery>response.data.listEventResources;
  }
  async GetEventResource(id: string): Promise<GetEventResourceQuery> {
    const statement = `query GetEventResource($id: ID!) {
        getEventResource(id: $id) {
          __typename
          id
          ownerId
          accountId
          locationId
          name
          type
          classification
          website
          contact {
            __typename
            id
            salutation
            givenName
            familyName
            mobile
            email
            contactType
          }
          address {
            __typename
            line1
            line2
            region
            postal
            country
          }
          businessHours
          billedTime
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetEventResourceQuery>response.data.getEventResource;
  }
  async ListQuestionnaires(
    filter?: ModelQuestionnaireFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListQuestionnairesQuery> {
    const statement = `query ListQuestionnaires($filter: ModelQuestionnaireFilterInput, $limit: Int, $nextToken: String) {
        listQuestionnaires(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            created
            name
            title
            template
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListQuestionnairesQuery>response.data.listQuestionnaires;
  }
  async GetQuestionnaire(id: string): Promise<GetQuestionnaireQuery> {
    const statement = `query GetQuestionnaire($id: ID!) {
        getQuestionnaire(id: $id) {
          __typename
          id
          created
          name
          title
          template
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetQuestionnaireQuery>response.data.getQuestionnaire;
  }
  async GetQuestionnaireResponseAuthor(
    id: string
  ): Promise<GetQuestionnaireResponseAuthorQuery> {
    const statement = `query GetQuestionnaireResponseAuthor($id: ID!) {
        getQuestionnaireResponseAuthor(id: $id) {
          __typename
          id
          accountId
          responseId
          authorId
          createdAt
          updatedAt
          author {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          questionnaireResponse {
            __typename
            id
            accountId
            patientId
            encounterId
            contributors
            created
            name
            title
            status
            json
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetQuestionnaireResponseAuthorQuery>(
      response.data.getQuestionnaireResponseAuthor
    );
  }
  async ListQuestionnaireResponseAuthors(
    filter?: ModelQuestionnaireResponseAuthorFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListQuestionnaireResponseAuthorsQuery> {
    const statement = `query ListQuestionnaireResponseAuthors($filter: ModelQuestionnaireResponseAuthorFilterInput, $limit: Int, $nextToken: String) {
        listQuestionnaireResponseAuthors(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            accountId
            responseId
            authorId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListQuestionnaireResponseAuthorsQuery>(
      response.data.listQuestionnaireResponseAuthors
    );
  }
  async GetQuestionnaireResponse(
    id: string
  ): Promise<GetQuestionnaireResponseQuery> {
    const statement = `query GetQuestionnaireResponse($id: ID!) {
        getQuestionnaireResponse(id: $id) {
          __typename
          id
          accountId
          patientId
          encounterId
          contributors
          created
          name
          title
          status
          json
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          authors {
            __typename
            nextToken
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetQuestionnaireResponseQuery>(
      response.data.getQuestionnaireResponse
    );
  }
  async ListQuestionnaireResponses(
    filter?: ModelQuestionnaireResponseFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListQuestionnaireResponsesQuery> {
    const statement = `query ListQuestionnaireResponses($filter: ModelQuestionnaireResponseFilterInput, $limit: Int, $nextToken: String) {
        listQuestionnaireResponses(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            accountId
            patientId
            encounterId
            contributors
            created
            name
            title
            status
            json
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListQuestionnaireResponsesQuery>(
      response.data.listQuestionnaireResponses
    );
  }
  async GetClinicalImpression(id: string): Promise<GetClinicalImpressionQuery> {
    const statement = `query GetClinicalImpression($id: ID!) {
        getClinicalImpression(id: $id) {
          __typename
          id
          accountId
          patientId
          encounterId
          authorId
          created
          title
          status
          summary
          name
          signature
          approved
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          questionnaireResponse {
            __typename
            id
            accountId
            patientId
            encounterId
            contributors
            created
            name
            title
            status
            json
            createdAt
            updatedAt
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetClinicalImpressionQuery>response.data.getClinicalImpression;
  }
  async ListClinicalImpressions(
    filter?: ModelClinicalImpressionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListClinicalImpressionsQuery> {
    const statement = `query ListClinicalImpressions($filter: ModelClinicalImpressionFilterInput, $limit: Int, $nextToken: String) {
        listClinicalImpressions(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            accountId
            patientId
            encounterId
            authorId
            created
            title
            status
            summary
            name
            signature
            approved
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListClinicalImpressionsQuery>response.data.listClinicalImpressions;
  }
  async ListEncounters(
    filter?: ModelEncounterFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListEncountersQuery> {
    const statement = `query ListEncounters($filter: ModelEncounterFilterInput, $limit: Int, $nextToken: String) {
        listEncounters(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEncountersQuery>response.data.listEncounters;
  }
  async GetEncounter(id: string): Promise<GetEncounterQuery> {
    const statement = `query GetEncounter($id: ID!) {
        getEncounter(id: $id) {
          __typename
          id
          accountId
          locationId
          managerId
          patientId
          ownerId
          status
          date
          soapItems {
            __typename
            type
            text
            code
            selected
          }
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          manager {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          owner {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          location {
            __typename
            id
            owner
            accountId
            name
            callerId
            testsCount
            usersCount
            physicianCount
            createdAt
            updatedAt
          }
          billedTimes {
            __typename
            nextToken
          }
          pretest {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            managerId
            submitterId
            result
            testId
            testCode
            meta
            answerSheetId
            title
            type
            assessed
            scheduledDate
            score
            scoreGauge
            status
            assessmentEncounterId
            createdAt
            updatedAt
          }
          assessments {
            __typename
            nextToken
          }
          questionnaireResponses {
            __typename
            nextToken
          }
          clinicalImpressions {
            __typename
            nextToken
          }
          providers {
            __typename
            nextToken
          }
          notes {
            __typename
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetEncounterQuery>response.data.getEncounter;
  }
  async GetEncounterProviderConnection(
    id: string
  ): Promise<GetEncounterProviderConnectionQuery> {
    const statement = `query GetEncounterProviderConnection($id: ID!) {
        getEncounterProviderConnection(id: $id) {
          __typename
          id
          accountId
          encounterId
          providerId
          createdAt
          updatedAt
          provider {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetEncounterProviderConnectionQuery>(
      response.data.getEncounterProviderConnection
    );
  }
  async ListEncounterProviderConnections(
    filter?: ModelEncounterProviderConnectionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListEncounterProviderConnectionsQuery> {
    const statement = `query ListEncounterProviderConnections($filter: ModelEncounterProviderConnectionFilterInput, $limit: Int, $nextToken: String) {
        listEncounterProviderConnections(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            accountId
            encounterId
            providerId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEncounterProviderConnectionsQuery>(
      response.data.listEncounterProviderConnections
    );
  }
  async GetEncounterNoteConnection(
    id: string
  ): Promise<GetEncounterNoteConnectionQuery> {
    const statement = `query GetEncounterNoteConnection($id: ID!) {
        getEncounterNoteConnection(id: $id) {
          __typename
          id
          accountId
          encounterId
          noteId
          createdAt
          updatedAt
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
          note {
            __typename
            id
            author
            userId
            patientId
            type
            title
            content
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetEncounterNoteConnectionQuery>(
      response.data.getEncounterNoteConnection
    );
  }
  async ListEncounterNoteConnections(
    filter?: ModelEncounterNoteConnectionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListEncounterNoteConnectionsQuery> {
    const statement = `query ListEncounterNoteConnections($filter: ModelEncounterNoteConnectionFilterInput, $limit: Int, $nextToken: String) {
        listEncounterNoteConnections(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            accountId
            encounterId
            noteId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEncounterNoteConnectionsQuery>(
      response.data.listEncounterNoteConnections
    );
  }
  async ListNotes(
    filter?: ModelNoteFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListNotesQuery> {
    const statement = `query ListNotes($filter: ModelNoteFilterInput, $limit: Int, $nextToken: String) {
        listNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            author
            userId
            patientId
            type
            title
            content
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListNotesQuery>response.data.listNotes;
  }
  async GetNote(id: string): Promise<GetNoteQuery> {
    const statement = `query GetNote($id: ID!) {
        getNote(id: $id) {
          __typename
          id
          author
          userId
          patientId
          type
          title
          content
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          user {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetNoteQuery>response.data.getNote;
  }
  async GetDiagnosticCode(id: string): Promise<GetDiagnosticCodeQuery> {
    const statement = `query GetDiagnosticCode($id: ID!) {
        getDiagnosticCode(id: $id) {
          __typename
          id
          accountId
          codeId
          patientId
          diagnosed
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          code {
            __typename
            id
            type
            name
            description
            rate
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetDiagnosticCodeQuery>response.data.getDiagnosticCode;
  }
  async ListDiagnosticCodes(
    filter?: ModelDiagnosticCodeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListDiagnosticCodesQuery> {
    const statement = `query ListDiagnosticCodes($filter: ModelDiagnosticCodeFilterInput, $limit: Int, $nextToken: String) {
        listDiagnosticCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            accountId
            codeId
            patientId
            diagnosed
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListDiagnosticCodesQuery>response.data.listDiagnosticCodes;
  }
  async GetBillingItem(id: string): Promise<GetBillingItemQuery> {
    const statement = `query GetBillingItem($id: ID!) {
        getBillingItem(id: $id) {
          __typename
          id
          accountId
          locationId
          patientId
          codeId
          amount
          type
          currency
          created
          serviced
          status
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          billedTime {
            __typename
            nextToken
          }
          code {
            __typename
            id
            type
            name
            description
            rate
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetBillingItemQuery>response.data.getBillingItem;
  }
  async ListBillingItems(
    filter?: ModelBillingItemFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListBillingItemsQuery> {
    const statement = `query ListBillingItems($filter: ModelBillingItemFilterInput, $limit: Int, $nextToken: String) {
        listBillingItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            accountId
            locationId
            patientId
            codeId
            amount
            type
            currency
            created
            serviced
            status
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListBillingItemsQuery>response.data.listBillingItems;
  }
  async ListInvoices(
    filter?: ModelInvoiceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListInvoicesQuery> {
    const statement = `query ListInvoices($filter: ModelInvoiceFilterInput, $limit: Int, $nextToken: String) {
        listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            accountId
            locationId
            dueDate
            billDate
            billingPeriodStartDate
            billingPeriodEndDate
            hours
            totalBill
            status
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListInvoicesQuery>response.data.listInvoices;
  }
  async GetInvoice(id: string): Promise<GetInvoiceQuery> {
    const statement = `query GetInvoice($id: ID!) {
        getInvoice(id: $id) {
          __typename
          id
          accountId
          locationId
          dueDate
          billDate
          billingPeriodStartDate
          billingPeriodEndDate
          hours
          totalBill
          status
          createdAt
          updatedAt
          billingItems {
            __typename
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetInvoiceQuery>response.data.getInvoice;
  }
  async ListPayments(
    filter?: ModelPaymentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListPaymentsQuery> {
    const statement = `query ListPayments($filter: ModelPaymentFilterInput, $limit: Int, $nextToken: String) {
        listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            accountId
            locationId
            userId
            stripePaymentMethodId
            paymentDate
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListPaymentsQuery>response.data.listPayments;
  }
  async GetPayment(id: string): Promise<GetPaymentQuery> {
    const statement = `query GetPayment($id: ID!) {
        getPayment(id: $id) {
          __typename
          id
          accountId
          locationId
          userId
          stripePaymentMethodId
          paymentDate
          createdAt
          updatedAt
          invoice {
            __typename
            id
            accountId
            locationId
            dueDate
            billDate
            billingPeriodStartDate
            billingPeriodEndDate
            hours
            totalBill
            status
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetPaymentQuery>response.data.getPayment;
  }
  async ListCodes(
    filter?: ModelCodeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCodesQuery> {
    const statement = `query ListCodes($filter: ModelCodeFilterInput, $limit: Int, $nextToken: String) {
        listCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            type
            name
            description
            rate
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCodesQuery>response.data.listCodes;
  }
  async GetCode(id: string): Promise<GetCodeQuery> {
    const statement = `query GetCode($id: ID!) {
        getCode(id: $id) {
          __typename
          id
          type
          name
          description
          rate
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCodeQuery>response.data.getCode;
  }
  OnCreatePatientListener: Observable<
    SubscriptionResponse<OnCreatePatientSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreatePatient($owner: String, $manager: String, $providerIds: String) {
        onCreatePatient(owner: $owner, manager: $manager, providerIds: $providerIds) {
          __typename
          id
          accountId
          locationId
          patientManagerId
          owner
          salutation
          givenName
          middleName
          familyName
          address {
            __typename
            line1
            line2
            region
            postal
            country
          }
          ssn
          dob
          gender
          mobile
          email
          interests
          providerIds
          status
          eligibleRPM
          contacts {
            __typename
            id
            salutation
            givenName
            familyName
            mobile
            email
            contactType
          }
          insurance {
            __typename
            id
            memberId
            groupId
            carrierId
            insuranceType
          }
          qualifiedTests
          connectionId
          createdAt
          updatedAt
          providers {
            __typename
            nextToken
          }
          programs {
            __typename
            nextToken
          }
          eligibleTests {
            __typename
            nextToken
          }
          manager {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          location {
            __typename
            id
            owner
            accountId
            name
            callerId
            testsCount
            usersCount
            physicianCount
            createdAt
            updatedAt
          }
          lastAssessment {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            managerId
            submitterId
            result
            testId
            testCode
            meta
            answerSheetId
            title
            type
            assessed
            scheduledDate
            score
            scoreGauge
            status
            assessmentEncounterId
            createdAt
            updatedAt
          }
          assessments {
            __typename
            nextToken
          }
          treatmentPlan {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            treatmentPlanManagerId
            created
            createdAt
            updatedAt
          }
          lastEncounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
          diagnoses {
            __typename
            nextToken
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreatePatientSubscription>>;

  OnUpdatePatientListener: Observable<
    SubscriptionResponse<OnUpdatePatientSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdatePatient($owner: String, $manager: String, $providerIds: String) {
        onUpdatePatient(owner: $owner, manager: $manager, providerIds: $providerIds) {
          __typename
          id
          accountId
          locationId
          patientManagerId
          owner
          salutation
          givenName
          middleName
          familyName
          address {
            __typename
            line1
            line2
            region
            postal
            country
          }
          ssn
          dob
          gender
          mobile
          email
          interests
          providerIds
          status
          eligibleRPM
          contacts {
            __typename
            id
            salutation
            givenName
            familyName
            mobile
            email
            contactType
          }
          insurance {
            __typename
            id
            memberId
            groupId
            carrierId
            insuranceType
          }
          qualifiedTests
          connectionId
          createdAt
          updatedAt
          providers {
            __typename
            nextToken
          }
          programs {
            __typename
            nextToken
          }
          eligibleTests {
            __typename
            nextToken
          }
          manager {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          location {
            __typename
            id
            owner
            accountId
            name
            callerId
            testsCount
            usersCount
            physicianCount
            createdAt
            updatedAt
          }
          lastAssessment {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            managerId
            submitterId
            result
            testId
            testCode
            meta
            answerSheetId
            title
            type
            assessed
            scheduledDate
            score
            scoreGauge
            status
            assessmentEncounterId
            createdAt
            updatedAt
          }
          assessments {
            __typename
            nextToken
          }
          treatmentPlan {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            treatmentPlanManagerId
            created
            createdAt
            updatedAt
          }
          lastEncounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
          diagnoses {
            __typename
            nextToken
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdatePatientSubscription>>;

  OnDeletePatientListener: Observable<
    SubscriptionResponse<OnDeletePatientSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeletePatient($owner: String, $manager: String, $providerIds: String) {
        onDeletePatient(owner: $owner, manager: $manager, providerIds: $providerIds) {
          __typename
          id
          accountId
          locationId
          patientManagerId
          owner
          salutation
          givenName
          middleName
          familyName
          address {
            __typename
            line1
            line2
            region
            postal
            country
          }
          ssn
          dob
          gender
          mobile
          email
          interests
          providerIds
          status
          eligibleRPM
          contacts {
            __typename
            id
            salutation
            givenName
            familyName
            mobile
            email
            contactType
          }
          insurance {
            __typename
            id
            memberId
            groupId
            carrierId
            insuranceType
          }
          qualifiedTests
          connectionId
          createdAt
          updatedAt
          providers {
            __typename
            nextToken
          }
          programs {
            __typename
            nextToken
          }
          eligibleTests {
            __typename
            nextToken
          }
          manager {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          location {
            __typename
            id
            owner
            accountId
            name
            callerId
            testsCount
            usersCount
            physicianCount
            createdAt
            updatedAt
          }
          lastAssessment {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            managerId
            submitterId
            result
            testId
            testCode
            meta
            answerSheetId
            title
            type
            assessed
            scheduledDate
            score
            scoreGauge
            status
            assessmentEncounterId
            createdAt
            updatedAt
          }
          assessments {
            __typename
            nextToken
          }
          treatmentPlan {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            treatmentPlanManagerId
            created
            createdAt
            updatedAt
          }
          lastEncounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
          diagnoses {
            __typename
            nextToken
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeletePatientSubscription>>;

  OnCreatePatientProviderConnectionListener: Observable<
    SubscriptionResponse<OnCreatePatientProviderConnectionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreatePatientProviderConnection {
        onCreatePatientProviderConnection {
          __typename
          id
          accountId
          patientId
          providerId
          type
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          provider {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnCreatePatientProviderConnectionSubscription>
  >;

  OnUpdatePatientProviderConnectionListener: Observable<
    SubscriptionResponse<OnUpdatePatientProviderConnectionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdatePatientProviderConnection {
        onUpdatePatientProviderConnection {
          __typename
          id
          accountId
          patientId
          providerId
          type
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          provider {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnUpdatePatientProviderConnectionSubscription>
  >;

  OnDeletePatientProviderConnectionListener: Observable<
    SubscriptionResponse<OnDeletePatientProviderConnectionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeletePatientProviderConnection {
        onDeletePatientProviderConnection {
          __typename
          id
          accountId
          patientId
          providerId
          type
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          provider {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnDeletePatientProviderConnectionSubscription>
  >;

  OnCreatePatientProgramConnectionListener: Observable<
    SubscriptionResponse<OnCreatePatientProgramConnectionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreatePatientProgramConnection {
        onCreatePatientProgramConnection {
          __typename
          id
          accountId
          patientId
          programId
          date
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          program {
            __typename
            id
            questionnaireId
            name
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnCreatePatientProgramConnectionSubscription>
  >;

  OnUpdatePatientProgramConnectionListener: Observable<
    SubscriptionResponse<OnUpdatePatientProgramConnectionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdatePatientProgramConnection {
        onUpdatePatientProgramConnection {
          __typename
          id
          accountId
          patientId
          programId
          date
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          program {
            __typename
            id
            questionnaireId
            name
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnUpdatePatientProgramConnectionSubscription>
  >;

  OnDeletePatientProgramConnectionListener: Observable<
    SubscriptionResponse<OnDeletePatientProgramConnectionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeletePatientProgramConnection {
        onDeletePatientProgramConnection {
          __typename
          id
          accountId
          patientId
          programId
          date
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          program {
            __typename
            id
            questionnaireId
            name
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnDeletePatientProgramConnectionSubscription>
  >;

  OnCreateProgramListener: Observable<
    SubscriptionResponse<OnCreateProgramSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateProgram {
        onCreateProgram {
          __typename
          id
          questionnaireId
          name
          createdAt
          updatedAt
          tests {
            __typename
            nextToken
          }
          questionnaire {
            __typename
            id
            created
            name
            title
            template
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateProgramSubscription>>;

  OnUpdateProgramListener: Observable<
    SubscriptionResponse<OnUpdateProgramSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateProgram {
        onUpdateProgram {
          __typename
          id
          questionnaireId
          name
          createdAt
          updatedAt
          tests {
            __typename
            nextToken
          }
          questionnaire {
            __typename
            id
            created
            name
            title
            template
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateProgramSubscription>>;

  OnDeleteProgramListener: Observable<
    SubscriptionResponse<OnDeleteProgramSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteProgram {
        onDeleteProgram {
          __typename
          id
          questionnaireId
          name
          createdAt
          updatedAt
          tests {
            __typename
            nextToken
          }
          questionnaire {
            __typename
            id
            created
            name
            title
            template
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteProgramSubscription>>;

  OnCreateProgramTestListener: Observable<
    SubscriptionResponse<OnCreateProgramTestSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateProgramTest {
        onCreateProgramTest {
          __typename
          id
          name
          referenceTestId
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateProgramTestSubscription>>;

  OnUpdateProgramTestListener: Observable<
    SubscriptionResponse<OnUpdateProgramTestSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateProgramTest {
        onUpdateProgramTest {
          __typename
          id
          name
          referenceTestId
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateProgramTestSubscription>>;

  OnDeleteProgramTestListener: Observable<
    SubscriptionResponse<OnDeleteProgramTestSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteProgramTest {
        onDeleteProgramTest {
          __typename
          id
          name
          referenceTestId
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteProgramTestSubscription>>;

  OnCreateEligibilityConnectionListener: Observable<
    SubscriptionResponse<OnCreateEligibilityConnectionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateEligibilityConnection {
        onCreateEligibilityConnection {
          __typename
          id
          patientId
          programTestId
          required
          date
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          programTest {
            __typename
            id
            name
            referenceTestId
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnCreateEligibilityConnectionSubscription>
  >;

  OnUpdateEligibilityConnectionListener: Observable<
    SubscriptionResponse<OnUpdateEligibilityConnectionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateEligibilityConnection {
        onUpdateEligibilityConnection {
          __typename
          id
          patientId
          programTestId
          required
          date
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          programTest {
            __typename
            id
            name
            referenceTestId
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnUpdateEligibilityConnectionSubscription>
  >;

  OnDeleteEligibilityConnectionListener: Observable<
    SubscriptionResponse<OnDeleteEligibilityConnectionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteEligibilityConnection {
        onDeleteEligibilityConnection {
          __typename
          id
          patientId
          programTestId
          required
          date
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          programTest {
            __typename
            id
            name
            referenceTestId
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnDeleteEligibilityConnectionSubscription>
  >;

  OnCreateUserListener: Observable<
    SubscriptionResponse<OnCreateUserSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateUser($owner: String) {
        onCreateUser(owner: $owner) {
          __typename
          id
          accountId
          owner
          email
          phone
          online
          firstName
          lastName
          titles
          npi
          image
          userType
          connections {
            __typename
            connectionId
            startedAt
          }
          onlineStatus
          lastLogin {
            __typename
            ip
            date
          }
          pinCode
          permissions
          createdAt
          updatedAt
          locations {
            __typename
            nextToken
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateUserSubscription>>;

  OnUpdateUserListener: Observable<
    SubscriptionResponse<OnUpdateUserSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateUser($owner: String) {
        onUpdateUser(owner: $owner) {
          __typename
          id
          accountId
          owner
          email
          phone
          online
          firstName
          lastName
          titles
          npi
          image
          userType
          connections {
            __typename
            connectionId
            startedAt
          }
          onlineStatus
          lastLogin {
            __typename
            ip
            date
          }
          pinCode
          permissions
          createdAt
          updatedAt
          locations {
            __typename
            nextToken
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateUserSubscription>>;

  OnDeleteUserListener: Observable<
    SubscriptionResponse<OnDeleteUserSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteUser($owner: String) {
        onDeleteUser(owner: $owner) {
          __typename
          id
          accountId
          owner
          email
          phone
          online
          firstName
          lastName
          titles
          npi
          image
          userType
          connections {
            __typename
            connectionId
            startedAt
          }
          onlineStatus
          lastLogin {
            __typename
            ip
            date
          }
          pinCode
          permissions
          createdAt
          updatedAt
          locations {
            __typename
            nextToken
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteUserSubscription>>;

  OnCreateUserLocationConnectionListener: Observable<
    SubscriptionResponse<OnCreateUserLocationConnectionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateUserLocationConnection($userId: String) {
        onCreateUserLocationConnection(userId: $userId) {
          __typename
          id
          accountId
          userId
          locationId
          createdAt
          updatedAt
          user {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          location {
            __typename
            id
            owner
            accountId
            name
            callerId
            testsCount
            usersCount
            physicianCount
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnCreateUserLocationConnectionSubscription>
  >;

  OnUpdateUserLocationConnectionListener: Observable<
    SubscriptionResponse<OnUpdateUserLocationConnectionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateUserLocationConnection($userId: String) {
        onUpdateUserLocationConnection(userId: $userId) {
          __typename
          id
          accountId
          userId
          locationId
          createdAt
          updatedAt
          user {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          location {
            __typename
            id
            owner
            accountId
            name
            callerId
            testsCount
            usersCount
            physicianCount
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnUpdateUserLocationConnectionSubscription>
  >;

  OnDeleteUserLocationConnectionListener: Observable<
    SubscriptionResponse<OnDeleteUserLocationConnectionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteUserLocationConnection($userId: String) {
        onDeleteUserLocationConnection(userId: $userId) {
          __typename
          id
          accountId
          userId
          locationId
          createdAt
          updatedAt
          user {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          location {
            __typename
            id
            owner
            accountId
            name
            callerId
            testsCount
            usersCount
            physicianCount
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnDeleteUserLocationConnectionSubscription>
  >;

  OnCreateAccountListener: Observable<
    SubscriptionResponse<OnCreateAccountSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateAccount($owner: String) {
        onCreateAccount(owner: $owner) {
          __typename
          id
          name
          owner
          stripeCustomerId
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateAccountSubscription>>;

  OnUpdateAccountListener: Observable<
    SubscriptionResponse<OnUpdateAccountSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateAccount($owner: String) {
        onUpdateAccount(owner: $owner) {
          __typename
          id
          name
          owner
          stripeCustomerId
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateAccountSubscription>>;

  OnDeleteAccountListener: Observable<
    SubscriptionResponse<OnDeleteAccountSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteAccount($owner: String) {
        onDeleteAccount(owner: $owner) {
          __typename
          id
          name
          owner
          stripeCustomerId
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteAccountSubscription>>;

  OnCreateLocationListener: Observable<
    SubscriptionResponse<OnCreateLocationSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateLocation($owner: String) {
        onCreateLocation(owner: $owner) {
          __typename
          id
          owner
          accountId
          name
          address {
            __typename
            line1
            line2
            region
            postal
            country
          }
          callerId
          testsCount
          usersCount
          physicianCount
          frontDesk {
            __typename
            id
            salutation
            givenName
            familyName
            mobile
            email
            contactType
          }
          createdAt
          updatedAt
          lastLoginUser {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          users {
            __typename
            nextToken
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          lastAssessment {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            managerId
            submitterId
            result
            testId
            testCode
            meta
            answerSheetId
            title
            type
            assessed
            scheduledDate
            score
            scoreGauge
            status
            assessmentEncounterId
            createdAt
            updatedAt
          }
          lastPayment {
            __typename
            id
            accountId
            locationId
            userId
            stripePaymentMethodId
            paymentDate
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateLocationSubscription>>;

  OnUpdateLocationListener: Observable<
    SubscriptionResponse<OnUpdateLocationSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateLocation($owner: String) {
        onUpdateLocation(owner: $owner) {
          __typename
          id
          owner
          accountId
          name
          address {
            __typename
            line1
            line2
            region
            postal
            country
          }
          callerId
          testsCount
          usersCount
          physicianCount
          frontDesk {
            __typename
            id
            salutation
            givenName
            familyName
            mobile
            email
            contactType
          }
          createdAt
          updatedAt
          lastLoginUser {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          users {
            __typename
            nextToken
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          lastAssessment {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            managerId
            submitterId
            result
            testId
            testCode
            meta
            answerSheetId
            title
            type
            assessed
            scheduledDate
            score
            scoreGauge
            status
            assessmentEncounterId
            createdAt
            updatedAt
          }
          lastPayment {
            __typename
            id
            accountId
            locationId
            userId
            stripePaymentMethodId
            paymentDate
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateLocationSubscription>>;

  OnDeleteLocationListener: Observable<
    SubscriptionResponse<OnDeleteLocationSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteLocation($owner: String) {
        onDeleteLocation(owner: $owner) {
          __typename
          id
          owner
          accountId
          name
          address {
            __typename
            line1
            line2
            region
            postal
            country
          }
          callerId
          testsCount
          usersCount
          physicianCount
          frontDesk {
            __typename
            id
            salutation
            givenName
            familyName
            mobile
            email
            contactType
          }
          createdAt
          updatedAt
          lastLoginUser {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          users {
            __typename
            nextToken
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          lastAssessment {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            managerId
            submitterId
            result
            testId
            testCode
            meta
            answerSheetId
            title
            type
            assessed
            scheduledDate
            score
            scoreGauge
            status
            assessmentEncounterId
            createdAt
            updatedAt
          }
          lastPayment {
            __typename
            id
            accountId
            locationId
            userId
            stripePaymentMethodId
            paymentDate
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteLocationSubscription>>;

  OnCreateBilledTimeListener: Observable<
    SubscriptionResponse<OnCreateBilledTimeSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateBilledTime($owner: String) {
        onCreateBilledTime(owner: $owner) {
          __typename
          id
          minutes
          type
          date
          userId
          createdAt
          updatedAt
          user {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateBilledTimeSubscription>>;

  OnUpdateBilledTimeListener: Observable<
    SubscriptionResponse<OnUpdateBilledTimeSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateBilledTime($owner: String) {
        onUpdateBilledTime(owner: $owner) {
          __typename
          id
          minutes
          type
          date
          userId
          createdAt
          updatedAt
          user {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateBilledTimeSubscription>>;

  OnDeleteBilledTimeListener: Observable<
    SubscriptionResponse<OnDeleteBilledTimeSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteBilledTime($owner: String) {
        onDeleteBilledTime(owner: $owner) {
          __typename
          id
          minutes
          type
          date
          userId
          createdAt
          updatedAt
          user {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteBilledTimeSubscription>>;

  OnCreateEncounterBilledTimeConnectionListener: Observable<
    SubscriptionResponse<OnCreateEncounterBilledTimeConnectionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateEncounterBilledTimeConnection {
        onCreateEncounterBilledTimeConnection {
          __typename
          id
          accountId
          encounterId
          billedTimeId
          createdAt
          updatedAt
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          billedTime {
            __typename
            id
            minutes
            type
            date
            userId
            createdAt
            updatedAt
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnCreateEncounterBilledTimeConnectionSubscription>
  >;

  OnUpdateEncounterBilledTimeConnectionListener: Observable<
    SubscriptionResponse<OnUpdateEncounterBilledTimeConnectionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateEncounterBilledTimeConnection {
        onUpdateEncounterBilledTimeConnection {
          __typename
          id
          accountId
          encounterId
          billedTimeId
          createdAt
          updatedAt
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          billedTime {
            __typename
            id
            minutes
            type
            date
            userId
            createdAt
            updatedAt
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnUpdateEncounterBilledTimeConnectionSubscription>
  >;

  OnDeleteEncounterBilledTimeConnectionListener: Observable<
    SubscriptionResponse<OnDeleteEncounterBilledTimeConnectionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteEncounterBilledTimeConnection {
        onDeleteEncounterBilledTimeConnection {
          __typename
          id
          accountId
          encounterId
          billedTimeId
          createdAt
          updatedAt
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          billedTime {
            __typename
            id
            minutes
            type
            date
            userId
            createdAt
            updatedAt
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnDeleteEncounterBilledTimeConnectionSubscription>
  >;

  OnCreateAssessmentBilledTimeConnectionListener: Observable<
    SubscriptionResponse<OnCreateAssessmentBilledTimeConnectionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateAssessmentBilledTimeConnection {
        onCreateAssessmentBilledTimeConnection {
          __typename
          id
          accountId
          assessmentId
          billedTimeId
          createdAt
          updatedAt
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          billedTime {
            __typename
            id
            minutes
            type
            date
            userId
            createdAt
            updatedAt
          }
          assessment {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            managerId
            submitterId
            result
            testId
            testCode
            meta
            answerSheetId
            title
            type
            assessed
            scheduledDate
            score
            scoreGauge
            status
            assessmentEncounterId
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnCreateAssessmentBilledTimeConnectionSubscription>
  >;

  OnUpdateAssessmentBilledTimeConnectionListener: Observable<
    SubscriptionResponse<OnUpdateAssessmentBilledTimeConnectionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateAssessmentBilledTimeConnection {
        onUpdateAssessmentBilledTimeConnection {
          __typename
          id
          accountId
          assessmentId
          billedTimeId
          createdAt
          updatedAt
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          billedTime {
            __typename
            id
            minutes
            type
            date
            userId
            createdAt
            updatedAt
          }
          assessment {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            managerId
            submitterId
            result
            testId
            testCode
            meta
            answerSheetId
            title
            type
            assessed
            scheduledDate
            score
            scoreGauge
            status
            assessmentEncounterId
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnUpdateAssessmentBilledTimeConnectionSubscription>
  >;

  OnDeleteAssessmentBilledTimeConnectionListener: Observable<
    SubscriptionResponse<OnDeleteAssessmentBilledTimeConnectionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteAssessmentBilledTimeConnection {
        onDeleteAssessmentBilledTimeConnection {
          __typename
          id
          accountId
          assessmentId
          billedTimeId
          createdAt
          updatedAt
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          billedTime {
            __typename
            id
            minutes
            type
            date
            userId
            createdAt
            updatedAt
          }
          assessment {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            managerId
            submitterId
            result
            testId
            testCode
            meta
            answerSheetId
            title
            type
            assessed
            scheduledDate
            score
            scoreGauge
            status
            assessmentEncounterId
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnDeleteAssessmentBilledTimeConnectionSubscription>
  >;

  OnCreateAssessmentListener: Observable<
    SubscriptionResponse<OnCreateAssessmentSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateAssessment($patientId: String, $manager: String, $providers: String) {
        onCreateAssessment(patientId: $patientId, manager: $manager, providers: $providers) {
          __typename
          id
          accountId
          locationId
          patientId
          providers
          managerId
          submitterId
          result
          testId
          testCode
          meta
          answerSheetId
          title
          type
          assessed
          scheduledDate
          score
          scoreGauge
          status
          assessmentEncounterId
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          manager {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          location {
            __typename
            id
            owner
            accountId
            name
            callerId
            testsCount
            usersCount
            physicianCount
            createdAt
            updatedAt
          }
          billedTime {
            __typename
            id
            minutes
            type
            date
            userId
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateAssessmentSubscription>>;

  OnUpdateAssessmentListener: Observable<
    SubscriptionResponse<OnUpdateAssessmentSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateAssessment($patientId: String, $manager: String, $providers: String) {
        onUpdateAssessment(patientId: $patientId, manager: $manager, providers: $providers) {
          __typename
          id
          accountId
          locationId
          patientId
          providers
          managerId
          submitterId
          result
          testId
          testCode
          meta
          answerSheetId
          title
          type
          assessed
          scheduledDate
          score
          scoreGauge
          status
          assessmentEncounterId
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          manager {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          location {
            __typename
            id
            owner
            accountId
            name
            callerId
            testsCount
            usersCount
            physicianCount
            createdAt
            updatedAt
          }
          billedTime {
            __typename
            id
            minutes
            type
            date
            userId
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateAssessmentSubscription>>;

  OnDeleteAssessmentListener: Observable<
    SubscriptionResponse<OnDeleteAssessmentSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteAssessment($patientId: String, $manager: String, $providers: String) {
        onDeleteAssessment(patientId: $patientId, manager: $manager, providers: $providers) {
          __typename
          id
          accountId
          locationId
          patientId
          providers
          managerId
          submitterId
          result
          testId
          testCode
          meta
          answerSheetId
          title
          type
          assessed
          scheduledDate
          score
          scoreGauge
          status
          assessmentEncounterId
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          manager {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          location {
            __typename
            id
            owner
            accountId
            name
            callerId
            testsCount
            usersCount
            physicianCount
            createdAt
            updatedAt
          }
          billedTime {
            __typename
            id
            minutes
            type
            date
            userId
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteAssessmentSubscription>>;

  OnCreateTreatmentPlanListener: Observable<
    SubscriptionResponse<OnCreateTreatmentPlanSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateTreatmentPlan($patientId: String, $manager: String, $providers: String) {
        onCreateTreatmentPlan(patientId: $patientId, manager: $manager, providers: $providers) {
          __typename
          id
          accountId
          locationId
          patientId
          providers
          treatmentPlanManagerId
          created
          createdAt
          updatedAt
          patient {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          location {
            __typename
            id
            owner
            accountId
            name
            callerId
            testsCount
            usersCount
            physicianCount
            createdAt
            updatedAt
          }
          resourceEvents {
            __typename
            nextToken
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateTreatmentPlanSubscription>>;

  OnUpdateTreatmentPlanListener: Observable<
    SubscriptionResponse<OnUpdateTreatmentPlanSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateTreatmentPlan($patientId: String, $manager: String, $providers: String) {
        onUpdateTreatmentPlan(patientId: $patientId, manager: $manager, providers: $providers) {
          __typename
          id
          accountId
          locationId
          patientId
          providers
          treatmentPlanManagerId
          created
          createdAt
          updatedAt
          patient {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          location {
            __typename
            id
            owner
            accountId
            name
            callerId
            testsCount
            usersCount
            physicianCount
            createdAt
            updatedAt
          }
          resourceEvents {
            __typename
            nextToken
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateTreatmentPlanSubscription>>;

  OnDeleteTreatmentPlanListener: Observable<
    SubscriptionResponse<OnDeleteTreatmentPlanSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteTreatmentPlan($patientId: String, $manager: String, $providers: String) {
        onDeleteTreatmentPlan(patientId: $patientId, manager: $manager, providers: $providers) {
          __typename
          id
          accountId
          locationId
          patientId
          providers
          treatmentPlanManagerId
          created
          createdAt
          updatedAt
          patient {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          location {
            __typename
            id
            owner
            accountId
            name
            callerId
            testsCount
            usersCount
            physicianCount
            createdAt
            updatedAt
          }
          resourceEvents {
            __typename
            nextToken
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteTreatmentPlanSubscription>>;

  OnCreatePatientResourceScheduledEventConnectionListener: Observable<
    SubscriptionResponse<
      OnCreatePatientResourceScheduledEventConnectionSubscription
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreatePatientResourceScheduledEventConnection {
        onCreatePatientResourceScheduledEventConnection {
          __typename
          id
          accountId
          patientId
          encounterId
          resourceScheduledEventId
          created
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          resourceScheduledEvent {
            __typename
            id
            accountId
            ownerId
            locationId
            title
            start
            end
            startTimezone
            endTimezone
            isAllDay
            description
            recurrenceRule
            recurrenceExceptions
            recurrenceId
            resourceId
            category
            cost
            ageGroup
            createdAt
            updatedAt
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      OnCreatePatientResourceScheduledEventConnectionSubscription
    >
  >;

  OnUpdatePatientResourceScheduledEventConnectionListener: Observable<
    SubscriptionResponse<
      OnUpdatePatientResourceScheduledEventConnectionSubscription
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdatePatientResourceScheduledEventConnection {
        onUpdatePatientResourceScheduledEventConnection {
          __typename
          id
          accountId
          patientId
          encounterId
          resourceScheduledEventId
          created
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          resourceScheduledEvent {
            __typename
            id
            accountId
            ownerId
            locationId
            title
            start
            end
            startTimezone
            endTimezone
            isAllDay
            description
            recurrenceRule
            recurrenceExceptions
            recurrenceId
            resourceId
            category
            cost
            ageGroup
            createdAt
            updatedAt
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      OnUpdatePatientResourceScheduledEventConnectionSubscription
    >
  >;

  OnDeletePatientResourceScheduledEventConnectionListener: Observable<
    SubscriptionResponse<
      OnDeletePatientResourceScheduledEventConnectionSubscription
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeletePatientResourceScheduledEventConnection {
        onDeletePatientResourceScheduledEventConnection {
          __typename
          id
          accountId
          patientId
          encounterId
          resourceScheduledEventId
          created
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          resourceScheduledEvent {
            __typename
            id
            accountId
            ownerId
            locationId
            title
            start
            end
            startTimezone
            endTimezone
            isAllDay
            description
            recurrenceRule
            recurrenceExceptions
            recurrenceId
            resourceId
            category
            cost
            ageGroup
            createdAt
            updatedAt
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      OnDeletePatientResourceScheduledEventConnectionSubscription
    >
  >;

  OnCreateTelehealthSessionListener: Observable<
    SubscriptionResponse<OnCreateTelehealthSessionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateTelehealthSession($ownerId: String, $participantIds: String) {
        onCreateTelehealthSession(ownerId: $ownerId, participantIds: $participantIds) {
          __typename
          id
          title
          accountId
          participantIds
          participants {
            __typename
            name
            type
            identity
          }
          status
          roomType
          startedAt
          endedAt
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateTelehealthSessionSubscription>>;

  OnUpdateTelehealthSessionListener: Observable<
    SubscriptionResponse<OnUpdateTelehealthSessionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateTelehealthSession($ownerId: String, $participantIds: String) {
        onUpdateTelehealthSession(ownerId: $ownerId, participantIds: $participantIds) {
          __typename
          id
          title
          accountId
          participantIds
          participants {
            __typename
            name
            type
            identity
          }
          status
          roomType
          startedAt
          endedAt
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateTelehealthSessionSubscription>>;

  OnDeleteTelehealthSessionListener: Observable<
    SubscriptionResponse<OnDeleteTelehealthSessionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteTelehealthSession($ownerId: String, $participantIds: String) {
        onDeleteTelehealthSession(ownerId: $ownerId, participantIds: $participantIds) {
          __typename
          id
          title
          accountId
          participantIds
          participants {
            __typename
            name
            type
            identity
          }
          status
          roomType
          startedAt
          endedAt
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteTelehealthSessionSubscription>>;

  OnCreateScheduledEventListener: Observable<
    SubscriptionResponse<OnCreateScheduledEventSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateScheduledEvent($ownerId: String, $participantIds: String) {
        onCreateScheduledEvent(ownerId: $ownerId, participantIds: $participantIds) {
          __typename
          id
          accountId
          ownerId
          invitees {
            __typename
            name
            type
            id
          }
          participantIds
          title
          start
          end
          type
          assessments {
            __typename
            id
            name
          }
          startTimezone
          endTimezone
          isAllDay
          description
          recurrenceRule
          recurrenceExceptions
          recurrenceId
          createdAt
          updatedAt
          resource {
            __typename
            id
            accountId
            ownerId
            locationId
            title
            start
            end
            startTimezone
            endTimezone
            isAllDay
            description
            recurrenceRule
            recurrenceExceptions
            recurrenceId
            resourceId
            category
            cost
            ageGroup
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateScheduledEventSubscription>>;

  OnUpdateScheduledEventListener: Observable<
    SubscriptionResponse<OnUpdateScheduledEventSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateScheduledEvent($ownerId: String, $participantIds: String) {
        onUpdateScheduledEvent(ownerId: $ownerId, participantIds: $participantIds) {
          __typename
          id
          accountId
          ownerId
          invitees {
            __typename
            name
            type
            id
          }
          participantIds
          title
          start
          end
          type
          assessments {
            __typename
            id
            name
          }
          startTimezone
          endTimezone
          isAllDay
          description
          recurrenceRule
          recurrenceExceptions
          recurrenceId
          createdAt
          updatedAt
          resource {
            __typename
            id
            accountId
            ownerId
            locationId
            title
            start
            end
            startTimezone
            endTimezone
            isAllDay
            description
            recurrenceRule
            recurrenceExceptions
            recurrenceId
            resourceId
            category
            cost
            ageGroup
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateScheduledEventSubscription>>;

  OnDeleteScheduledEventListener: Observable<
    SubscriptionResponse<OnDeleteScheduledEventSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteScheduledEvent($ownerId: String, $participantIds: String) {
        onDeleteScheduledEvent(ownerId: $ownerId, participantIds: $participantIds) {
          __typename
          id
          accountId
          ownerId
          invitees {
            __typename
            name
            type
            id
          }
          participantIds
          title
          start
          end
          type
          assessments {
            __typename
            id
            name
          }
          startTimezone
          endTimezone
          isAllDay
          description
          recurrenceRule
          recurrenceExceptions
          recurrenceId
          createdAt
          updatedAt
          resource {
            __typename
            id
            accountId
            ownerId
            locationId
            title
            start
            end
            startTimezone
            endTimezone
            isAllDay
            description
            recurrenceRule
            recurrenceExceptions
            recurrenceId
            resourceId
            category
            cost
            ageGroup
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteScheduledEventSubscription>>;

  OnCreateResourceScheduledEventListener: Observable<
    SubscriptionResponse<OnCreateResourceScheduledEventSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateResourceScheduledEvent($ownerId: String) {
        onCreateResourceScheduledEvent(ownerId: $ownerId) {
          __typename
          id
          accountId
          ownerId
          locationId
          title
          start
          end
          startTimezone
          endTimezone
          isAllDay
          description
          recurrenceRule
          recurrenceExceptions
          recurrenceId
          resourceId
          category
          cost
          ageGroup
          createdAt
          updatedAt
          resource {
            __typename
            id
            ownerId
            accountId
            locationId
            name
            type
            classification
            website
            businessHours
            billedTime
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnCreateResourceScheduledEventSubscription>
  >;

  OnUpdateResourceScheduledEventListener: Observable<
    SubscriptionResponse<OnUpdateResourceScheduledEventSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateResourceScheduledEvent($ownerId: String) {
        onUpdateResourceScheduledEvent(ownerId: $ownerId) {
          __typename
          id
          accountId
          ownerId
          locationId
          title
          start
          end
          startTimezone
          endTimezone
          isAllDay
          description
          recurrenceRule
          recurrenceExceptions
          recurrenceId
          resourceId
          category
          cost
          ageGroup
          createdAt
          updatedAt
          resource {
            __typename
            id
            ownerId
            accountId
            locationId
            name
            type
            classification
            website
            businessHours
            billedTime
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnUpdateResourceScheduledEventSubscription>
  >;

  OnDeleteResourceScheduledEventListener: Observable<
    SubscriptionResponse<OnDeleteResourceScheduledEventSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteResourceScheduledEvent($ownerId: String) {
        onDeleteResourceScheduledEvent(ownerId: $ownerId) {
          __typename
          id
          accountId
          ownerId
          locationId
          title
          start
          end
          startTimezone
          endTimezone
          isAllDay
          description
          recurrenceRule
          recurrenceExceptions
          recurrenceId
          resourceId
          category
          cost
          ageGroup
          createdAt
          updatedAt
          resource {
            __typename
            id
            ownerId
            accountId
            locationId
            name
            type
            classification
            website
            businessHours
            billedTime
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnDeleteResourceScheduledEventSubscription>
  >;

  OnCreateSessionRecordingListener: Observable<
    SubscriptionResponse<OnCreateSessionRecordingSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateSessionRecording($ownerId: String) {
        onCreateSessionRecording(ownerId: $ownerId) {
          __typename
          id
          codec
          container
          offset
          sourceSid
          twilioMediaUri
          roomSid
          eventId
          ownerId
          sessionId
          s3Url
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateSessionRecordingSubscription>>;

  OnUpdateSessionRecordingListener: Observable<
    SubscriptionResponse<OnUpdateSessionRecordingSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateSessionRecording($ownerId: String) {
        onUpdateSessionRecording(ownerId: $ownerId) {
          __typename
          id
          codec
          container
          offset
          sourceSid
          twilioMediaUri
          roomSid
          eventId
          ownerId
          sessionId
          s3Url
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateSessionRecordingSubscription>>;

  OnDeleteSessionRecordingListener: Observable<
    SubscriptionResponse<OnDeleteSessionRecordingSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteSessionRecording($ownerId: String) {
        onDeleteSessionRecording(ownerId: $ownerId) {
          __typename
          id
          codec
          container
          offset
          sourceSid
          twilioMediaUri
          roomSid
          eventId
          ownerId
          sessionId
          s3Url
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteSessionRecordingSubscription>>;

  OnCreateEventResourceListener: Observable<
    SubscriptionResponse<OnCreateEventResourceSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateEventResource($ownerId: String) {
        onCreateEventResource(ownerId: $ownerId) {
          __typename
          id
          ownerId
          accountId
          locationId
          name
          type
          classification
          website
          contact {
            __typename
            id
            salutation
            givenName
            familyName
            mobile
            email
            contactType
          }
          address {
            __typename
            line1
            line2
            region
            postal
            country
          }
          businessHours
          billedTime
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateEventResourceSubscription>>;

  OnUpdateEventResourceListener: Observable<
    SubscriptionResponse<OnUpdateEventResourceSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateEventResource($ownerId: String) {
        onUpdateEventResource(ownerId: $ownerId) {
          __typename
          id
          ownerId
          accountId
          locationId
          name
          type
          classification
          website
          contact {
            __typename
            id
            salutation
            givenName
            familyName
            mobile
            email
            contactType
          }
          address {
            __typename
            line1
            line2
            region
            postal
            country
          }
          businessHours
          billedTime
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateEventResourceSubscription>>;

  OnDeleteEventResourceListener: Observable<
    SubscriptionResponse<OnDeleteEventResourceSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteEventResource($ownerId: String) {
        onDeleteEventResource(ownerId: $ownerId) {
          __typename
          id
          ownerId
          accountId
          locationId
          name
          type
          classification
          website
          contact {
            __typename
            id
            salutation
            givenName
            familyName
            mobile
            email
            contactType
          }
          address {
            __typename
            line1
            line2
            region
            postal
            country
          }
          businessHours
          billedTime
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteEventResourceSubscription>>;

  OnCreateQuestionnaireListener: Observable<
    SubscriptionResponse<OnCreateQuestionnaireSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateQuestionnaire {
        onCreateQuestionnaire {
          __typename
          id
          created
          name
          title
          template
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateQuestionnaireSubscription>>;

  OnUpdateQuestionnaireListener: Observable<
    SubscriptionResponse<OnUpdateQuestionnaireSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateQuestionnaire {
        onUpdateQuestionnaire {
          __typename
          id
          created
          name
          title
          template
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateQuestionnaireSubscription>>;

  OnDeleteQuestionnaireListener: Observable<
    SubscriptionResponse<OnDeleteQuestionnaireSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteQuestionnaire {
        onDeleteQuestionnaire {
          __typename
          id
          created
          name
          title
          template
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteQuestionnaireSubscription>>;

  OnCreateQuestionnaireResponseAuthorListener: Observable<
    SubscriptionResponse<OnCreateQuestionnaireResponseAuthorSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateQuestionnaireResponseAuthor($authorId: String) {
        onCreateQuestionnaireResponseAuthor(authorId: $authorId) {
          __typename
          id
          accountId
          responseId
          authorId
          createdAt
          updatedAt
          author {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          questionnaireResponse {
            __typename
            id
            accountId
            patientId
            encounterId
            contributors
            created
            name
            title
            status
            json
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnCreateQuestionnaireResponseAuthorSubscription>
  >;

  OnUpdateQuestionnaireResponseAuthorListener: Observable<
    SubscriptionResponse<OnUpdateQuestionnaireResponseAuthorSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateQuestionnaireResponseAuthor($authorId: String) {
        onUpdateQuestionnaireResponseAuthor(authorId: $authorId) {
          __typename
          id
          accountId
          responseId
          authorId
          createdAt
          updatedAt
          author {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          questionnaireResponse {
            __typename
            id
            accountId
            patientId
            encounterId
            contributors
            created
            name
            title
            status
            json
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnUpdateQuestionnaireResponseAuthorSubscription>
  >;

  OnDeleteQuestionnaireResponseAuthorListener: Observable<
    SubscriptionResponse<OnDeleteQuestionnaireResponseAuthorSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteQuestionnaireResponseAuthor($authorId: String) {
        onDeleteQuestionnaireResponseAuthor(authorId: $authorId) {
          __typename
          id
          accountId
          responseId
          authorId
          createdAt
          updatedAt
          author {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          questionnaireResponse {
            __typename
            id
            accountId
            patientId
            encounterId
            contributors
            created
            name
            title
            status
            json
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnDeleteQuestionnaireResponseAuthorSubscription>
  >;

  OnCreateQuestionnaireResponseListener: Observable<
    SubscriptionResponse<OnCreateQuestionnaireResponseSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateQuestionnaireResponse($contributors: String) {
        onCreateQuestionnaireResponse(contributors: $contributors) {
          __typename
          id
          accountId
          patientId
          encounterId
          contributors
          created
          name
          title
          status
          json
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          authors {
            __typename
            nextToken
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnCreateQuestionnaireResponseSubscription>
  >;

  OnUpdateQuestionnaireResponseListener: Observable<
    SubscriptionResponse<OnUpdateQuestionnaireResponseSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateQuestionnaireResponse($contributors: String) {
        onUpdateQuestionnaireResponse(contributors: $contributors) {
          __typename
          id
          accountId
          patientId
          encounterId
          contributors
          created
          name
          title
          status
          json
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          authors {
            __typename
            nextToken
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnUpdateQuestionnaireResponseSubscription>
  >;

  OnDeleteQuestionnaireResponseListener: Observable<
    SubscriptionResponse<OnDeleteQuestionnaireResponseSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteQuestionnaireResponse($contributors: String) {
        onDeleteQuestionnaireResponse(contributors: $contributors) {
          __typename
          id
          accountId
          patientId
          encounterId
          contributors
          created
          name
          title
          status
          json
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          authors {
            __typename
            nextToken
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnDeleteQuestionnaireResponseSubscription>
  >;

  OnCreateClinicalImpressionListener: Observable<
    SubscriptionResponse<OnCreateClinicalImpressionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateClinicalImpression($authorId: String) {
        onCreateClinicalImpression(authorId: $authorId) {
          __typename
          id
          accountId
          patientId
          encounterId
          authorId
          created
          title
          status
          summary
          name
          signature
          approved
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          questionnaireResponse {
            __typename
            id
            accountId
            patientId
            encounterId
            contributors
            created
            name
            title
            status
            json
            createdAt
            updatedAt
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateClinicalImpressionSubscription>>;

  OnUpdateClinicalImpressionListener: Observable<
    SubscriptionResponse<OnUpdateClinicalImpressionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateClinicalImpression($authorId: String) {
        onUpdateClinicalImpression(authorId: $authorId) {
          __typename
          id
          accountId
          patientId
          encounterId
          authorId
          created
          title
          status
          summary
          name
          signature
          approved
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          questionnaireResponse {
            __typename
            id
            accountId
            patientId
            encounterId
            contributors
            created
            name
            title
            status
            json
            createdAt
            updatedAt
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateClinicalImpressionSubscription>>;

  OnDeleteClinicalImpressionListener: Observable<
    SubscriptionResponse<OnDeleteClinicalImpressionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteClinicalImpression($authorId: String) {
        onDeleteClinicalImpression(authorId: $authorId) {
          __typename
          id
          accountId
          patientId
          encounterId
          authorId
          created
          title
          status
          summary
          name
          signature
          approved
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          questionnaireResponse {
            __typename
            id
            accountId
            patientId
            encounterId
            contributors
            created
            name
            title
            status
            json
            createdAt
            updatedAt
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteClinicalImpressionSubscription>>;

  OnCreateEncounterListener: Observable<
    SubscriptionResponse<OnCreateEncounterSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateEncounter($ownerId: String, $manager: String, $providers: String) {
        onCreateEncounter(ownerId: $ownerId, manager: $manager, providers: $providers) {
          __typename
          id
          accountId
          locationId
          managerId
          patientId
          ownerId
          status
          date
          soapItems {
            __typename
            type
            text
            code
            selected
          }
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          manager {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          owner {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          location {
            __typename
            id
            owner
            accountId
            name
            callerId
            testsCount
            usersCount
            physicianCount
            createdAt
            updatedAt
          }
          billedTimes {
            __typename
            nextToken
          }
          pretest {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            managerId
            submitterId
            result
            testId
            testCode
            meta
            answerSheetId
            title
            type
            assessed
            scheduledDate
            score
            scoreGauge
            status
            assessmentEncounterId
            createdAt
            updatedAt
          }
          assessments {
            __typename
            nextToken
          }
          questionnaireResponses {
            __typename
            nextToken
          }
          clinicalImpressions {
            __typename
            nextToken
          }
          providers {
            __typename
            nextToken
          }
          notes {
            __typename
            nextToken
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateEncounterSubscription>>;

  OnUpdateEncounterListener: Observable<
    SubscriptionResponse<OnUpdateEncounterSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateEncounter($ownerId: String, $manager: String, $providers: String) {
        onUpdateEncounter(ownerId: $ownerId, manager: $manager, providers: $providers) {
          __typename
          id
          accountId
          locationId
          managerId
          patientId
          ownerId
          status
          date
          soapItems {
            __typename
            type
            text
            code
            selected
          }
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          manager {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          owner {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          location {
            __typename
            id
            owner
            accountId
            name
            callerId
            testsCount
            usersCount
            physicianCount
            createdAt
            updatedAt
          }
          billedTimes {
            __typename
            nextToken
          }
          pretest {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            managerId
            submitterId
            result
            testId
            testCode
            meta
            answerSheetId
            title
            type
            assessed
            scheduledDate
            score
            scoreGauge
            status
            assessmentEncounterId
            createdAt
            updatedAt
          }
          assessments {
            __typename
            nextToken
          }
          questionnaireResponses {
            __typename
            nextToken
          }
          clinicalImpressions {
            __typename
            nextToken
          }
          providers {
            __typename
            nextToken
          }
          notes {
            __typename
            nextToken
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateEncounterSubscription>>;

  OnDeleteEncounterListener: Observable<
    SubscriptionResponse<OnDeleteEncounterSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteEncounter($ownerId: String, $manager: String, $providers: String) {
        onDeleteEncounter(ownerId: $ownerId, manager: $manager, providers: $providers) {
          __typename
          id
          accountId
          locationId
          managerId
          patientId
          ownerId
          status
          date
          soapItems {
            __typename
            type
            text
            code
            selected
          }
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          manager {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          owner {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          location {
            __typename
            id
            owner
            accountId
            name
            callerId
            testsCount
            usersCount
            physicianCount
            createdAt
            updatedAt
          }
          billedTimes {
            __typename
            nextToken
          }
          pretest {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            managerId
            submitterId
            result
            testId
            testCode
            meta
            answerSheetId
            title
            type
            assessed
            scheduledDate
            score
            scoreGauge
            status
            assessmentEncounterId
            createdAt
            updatedAt
          }
          assessments {
            __typename
            nextToken
          }
          questionnaireResponses {
            __typename
            nextToken
          }
          clinicalImpressions {
            __typename
            nextToken
          }
          providers {
            __typename
            nextToken
          }
          notes {
            __typename
            nextToken
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteEncounterSubscription>>;

  OnCreateEncounterProviderConnectionListener: Observable<
    SubscriptionResponse<OnCreateEncounterProviderConnectionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateEncounterProviderConnection {
        onCreateEncounterProviderConnection {
          __typename
          id
          accountId
          encounterId
          providerId
          createdAt
          updatedAt
          provider {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnCreateEncounterProviderConnectionSubscription>
  >;

  OnUpdateEncounterProviderConnectionListener: Observable<
    SubscriptionResponse<OnUpdateEncounterProviderConnectionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateEncounterProviderConnection {
        onUpdateEncounterProviderConnection {
          __typename
          id
          accountId
          encounterId
          providerId
          createdAt
          updatedAt
          provider {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnUpdateEncounterProviderConnectionSubscription>
  >;

  OnDeleteEncounterProviderConnectionListener: Observable<
    SubscriptionResponse<OnDeleteEncounterProviderConnectionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteEncounterProviderConnection {
        onDeleteEncounterProviderConnection {
          __typename
          id
          accountId
          encounterId
          providerId
          createdAt
          updatedAt
          provider {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnDeleteEncounterProviderConnectionSubscription>
  >;

  OnCreateEncounterNoteConnectionListener: Observable<
    SubscriptionResponse<OnCreateEncounterNoteConnectionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateEncounterNoteConnection {
        onCreateEncounterNoteConnection {
          __typename
          id
          accountId
          encounterId
          noteId
          createdAt
          updatedAt
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
          note {
            __typename
            id
            author
            userId
            patientId
            type
            title
            content
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnCreateEncounterNoteConnectionSubscription>
  >;

  OnUpdateEncounterNoteConnectionListener: Observable<
    SubscriptionResponse<OnUpdateEncounterNoteConnectionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateEncounterNoteConnection {
        onUpdateEncounterNoteConnection {
          __typename
          id
          accountId
          encounterId
          noteId
          createdAt
          updatedAt
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
          note {
            __typename
            id
            author
            userId
            patientId
            type
            title
            content
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnUpdateEncounterNoteConnectionSubscription>
  >;

  OnDeleteEncounterNoteConnectionListener: Observable<
    SubscriptionResponse<OnDeleteEncounterNoteConnectionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteEncounterNoteConnection {
        onDeleteEncounterNoteConnection {
          __typename
          id
          accountId
          encounterId
          noteId
          createdAt
          updatedAt
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          encounter {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            createdAt
            updatedAt
          }
          note {
            __typename
            id
            author
            userId
            patientId
            type
            title
            content
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnDeleteEncounterNoteConnectionSubscription>
  >;

  OnCreateNoteListener: Observable<
    SubscriptionResponse<OnCreateNoteSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateNote {
        onCreateNote {
          __typename
          id
          author
          userId
          patientId
          type
          title
          content
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          user {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateNoteSubscription>>;

  OnUpdateNoteListener: Observable<
    SubscriptionResponse<OnUpdateNoteSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateNote {
        onUpdateNote {
          __typename
          id
          author
          userId
          patientId
          type
          title
          content
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          user {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateNoteSubscription>>;

  OnDeleteNoteListener: Observable<
    SubscriptionResponse<OnDeleteNoteSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteNote {
        onDeleteNote {
          __typename
          id
          author
          userId
          patientId
          type
          title
          content
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          user {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteNoteSubscription>>;

  OnCreateDiagnosticCodeListener: Observable<
    SubscriptionResponse<OnCreateDiagnosticCodeSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateDiagnosticCode {
        onCreateDiagnosticCode {
          __typename
          id
          accountId
          codeId
          patientId
          diagnosed
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          code {
            __typename
            id
            type
            name
            description
            rate
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateDiagnosticCodeSubscription>>;

  OnUpdateDiagnosticCodeListener: Observable<
    SubscriptionResponse<OnUpdateDiagnosticCodeSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateDiagnosticCode {
        onUpdateDiagnosticCode {
          __typename
          id
          accountId
          codeId
          patientId
          diagnosed
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          code {
            __typename
            id
            type
            name
            description
            rate
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateDiagnosticCodeSubscription>>;

  OnDeleteDiagnosticCodeListener: Observable<
    SubscriptionResponse<OnDeleteDiagnosticCodeSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteDiagnosticCode {
        onDeleteDiagnosticCode {
          __typename
          id
          accountId
          codeId
          patientId
          diagnosed
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          account {
            __typename
            id
            name
            owner
            stripeCustomerId
            createdAt
            updatedAt
          }
          code {
            __typename
            id
            type
            name
            description
            rate
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteDiagnosticCodeSubscription>>;

  OnCreateBillingItemListener: Observable<
    SubscriptionResponse<OnCreateBillingItemSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateBillingItem {
        onCreateBillingItem {
          __typename
          id
          accountId
          locationId
          patientId
          codeId
          amount
          type
          currency
          created
          serviced
          status
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          billedTime {
            __typename
            nextToken
          }
          code {
            __typename
            id
            type
            name
            description
            rate
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateBillingItemSubscription>>;

  OnUpdateBillingItemListener: Observable<
    SubscriptionResponse<OnUpdateBillingItemSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateBillingItem {
        onUpdateBillingItem {
          __typename
          id
          accountId
          locationId
          patientId
          codeId
          amount
          type
          currency
          created
          serviced
          status
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          billedTime {
            __typename
            nextToken
          }
          code {
            __typename
            id
            type
            name
            description
            rate
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateBillingItemSubscription>>;

  OnDeleteBillingItemListener: Observable<
    SubscriptionResponse<OnDeleteBillingItemSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteBillingItem {
        onDeleteBillingItem {
          __typename
          id
          accountId
          locationId
          patientId
          codeId
          amount
          type
          currency
          created
          serviced
          status
          createdAt
          updatedAt
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          billedTime {
            __typename
            nextToken
          }
          code {
            __typename
            id
            type
            name
            description
            rate
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteBillingItemSubscription>>;

  OnCreateInvoiceListener: Observable<
    SubscriptionResponse<OnCreateInvoiceSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateInvoice {
        onCreateInvoice {
          __typename
          id
          accountId
          locationId
          dueDate
          billDate
          billingPeriodStartDate
          billingPeriodEndDate
          hours
          totalBill
          status
          createdAt
          updatedAt
          billingItems {
            __typename
            nextToken
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateInvoiceSubscription>>;

  OnUpdateInvoiceListener: Observable<
    SubscriptionResponse<OnUpdateInvoiceSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateInvoice {
        onUpdateInvoice {
          __typename
          id
          accountId
          locationId
          dueDate
          billDate
          billingPeriodStartDate
          billingPeriodEndDate
          hours
          totalBill
          status
          createdAt
          updatedAt
          billingItems {
            __typename
            nextToken
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateInvoiceSubscription>>;

  OnDeleteInvoiceListener: Observable<
    SubscriptionResponse<OnDeleteInvoiceSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteInvoice {
        onDeleteInvoice {
          __typename
          id
          accountId
          locationId
          dueDate
          billDate
          billingPeriodStartDate
          billingPeriodEndDate
          hours
          totalBill
          status
          createdAt
          updatedAt
          billingItems {
            __typename
            nextToken
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteInvoiceSubscription>>;

  OnCreatePaymentListener: Observable<
    SubscriptionResponse<OnCreatePaymentSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreatePayment {
        onCreatePayment {
          __typename
          id
          accountId
          locationId
          userId
          stripePaymentMethodId
          paymentDate
          createdAt
          updatedAt
          invoice {
            __typename
            id
            accountId
            locationId
            dueDate
            billDate
            billingPeriodStartDate
            billingPeriodEndDate
            hours
            totalBill
            status
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreatePaymentSubscription>>;

  OnUpdatePaymentListener: Observable<
    SubscriptionResponse<OnUpdatePaymentSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdatePayment {
        onUpdatePayment {
          __typename
          id
          accountId
          locationId
          userId
          stripePaymentMethodId
          paymentDate
          createdAt
          updatedAt
          invoice {
            __typename
            id
            accountId
            locationId
            dueDate
            billDate
            billingPeriodStartDate
            billingPeriodEndDate
            hours
            totalBill
            status
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdatePaymentSubscription>>;

  OnDeletePaymentListener: Observable<
    SubscriptionResponse<OnDeletePaymentSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeletePayment {
        onDeletePayment {
          __typename
          id
          accountId
          locationId
          userId
          stripePaymentMethodId
          paymentDate
          createdAt
          updatedAt
          invoice {
            __typename
            id
            accountId
            locationId
            dueDate
            billDate
            billingPeriodStartDate
            billingPeriodEndDate
            hours
            totalBill
            status
            createdAt
            updatedAt
          }
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeletePaymentSubscription>>;

  OnCreateCodeListener: Observable<
    SubscriptionResponse<OnCreateCodeSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCode {
        onCreateCode {
          __typename
          id
          type
          name
          description
          rate
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateCodeSubscription>>;

  OnUpdateCodeListener: Observable<
    SubscriptionResponse<OnUpdateCodeSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCode {
        onUpdateCode {
          __typename
          id
          type
          name
          description
          rate
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateCodeSubscription>>;

  OnDeleteCodeListener: Observable<
    SubscriptionResponse<OnDeleteCodeSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCode {
        onDeleteCode {
          __typename
          id
          type
          name
          description
          rate
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteCodeSubscription>>;

  async GetPatientsList(
    filter?: ModelPatientFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<GetPatientsListQuery> {
    const statement = `query GetPatientsList($filter: ModelPatientFilterInput, $limit: Int, $nextToken: String) {
        listPatients(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            salutation
            givenName
            familyName
            dob
            gender
            email
            assessments {
              __typename
              nextToken
              items {
                __typename
                id
                accountId
                locationId
                patientId
                providers
                managerId
                submitterId
                result
                testId
                testCode
                meta
                answerSheetId
                title
                type
                assessed
                scheduledDate
                score
                scoreGauge
                status
                createdAt
                updatedAt
                billedTime {
                  __typename
                  id
                  minutes
                  type
                  date
                  userId
                  createdAt
                  updatedAt
                }
              }
            }
            lastAssessment {
              __typename
              id
              testCode
              status
            }
            providers {
              __typename
              items {
                __typename
                type
                provider {
                  __typename
                  id
                  firstName
                  lastName
                }
              }
            }
            manager {
              __typename
              id
              firstName
              lastName
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetPatientsListQuery>response.data.listPatients;
  }
  async GetLocationsList(
    filter?: ModelLocationFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<GetLocationsListQuery> {
    const statement = `query GetLocationsList($filter: ModelLocationFilterInput, $limit: Int, $nextToken: String) {
        listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            owner
            accountId
            name
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetLocationsListQuery>response.data.listLocations;
  }
  async GetLoggedInUser(id: string): Promise<GetLoggedInUserQuery> {
    const statement = `query GetLoggedInUser($id: ID!) {
        getUser(id: $id) {
          __typename
          id
          accountId
          owner
          email
          phone
          online
          firstName
          lastName
          titles
          npi
          image
          userType
          connections {
            __typename
            connectionId
            startedAt
          }
          onlineStatus
          lastLogin {
            __typename
            ip
            date
          }
          pinCode
          permissions
          createdAt
          updatedAt
          locations {
            __typename
            nextToken
            items {
              __typename
              id
              location {
                __typename
                id
                name
              }
            }
          }
          account {
            __typename
            id
            name
            owner
            createdAt
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetLoggedInUserQuery>response.data.getUser;
  }
  async ListResourceScheduledEventsExtended(
    filter?: ModelResourceScheduledEventFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListResourceScheduledEventsExtendedQuery> {
    const statement = `query ListResourceScheduledEventsExtended($filter: ModelResourceScheduledEventFilterInput, $limit: Int, $nextToken: String) {
        listResourceScheduledEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            accountId
            ownerId
            locationId
            title
            start
            end
            startTimezone
            endTimezone
            isAllDay
            description
            recurrenceRule
            recurrenceExceptions
            recurrenceId
            resourceId
            resource {
              __typename
              id
              ownerId
              accountId
              locationId
              name
              type
              classification
              website
              businessHours
              billedTime
              createdAt
              updatedAt
            }
            category
            cost
            ageGroup
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListResourceScheduledEventsExtendedQuery>(
      response.data.listResourceScheduledEvents
    );
  }
  async GetEncounterExtended(id: string): Promise<GetEncounterExtendedQuery> {
    const statement = `query GetEncounterExtended($id: ID!) {
        getEncounter(id: $id) {
          __typename
          id
          accountId
          locationId
          managerId
          patientId
          ownerId
          status
          date
          soapItems {
            __typename
            type
            text
            code
            selected
          }
          createdAt
          updatedAt
          location {
            __typename
            accountId
            callerId
            createdAt
            id
            name
            owner
            physicianCount
            testsCount
            updatedAt
            usersCount
          }
          patient {
            __typename
            id
            accountId
            locationId
            patientManagerId
            owner
            salutation
            givenName
            middleName
            familyName
            ssn
            dob
            gender
            mobile
            email
            interests
            providerIds
            status
            eligibleRPM
            qualifiedTests
            connectionId
            createdAt
            updatedAt
          }
          manager {
            __typename
            id
            accountId
            owner
            email
            phone
            online
            firstName
            lastName
            titles
            npi
            image
            userType
            onlineStatus
            pinCode
            permissions
            createdAt
            updatedAt
          }
          billedTimes {
            __typename
            nextToken
            items {
              __typename
              id
              billedTime {
                __typename
                id
                minutes
                type
                date
                userId
              }
              createdAt
              updatedAt
            }
          }
          pretest {
            __typename
            id
            accountId
            locationId
            patientId
            providers
            managerId
            submitterId
            result
            testId
            testCode
            meta
            answerSheetId
            title
            type
            assessed
            scheduledDate
            score
            scoreGauge
            status
            createdAt
            updatedAt
          }
          assessments {
            __typename
            nextToken
            items {
              __typename
              id
              accountId
              locationId
              patientId
              providers
              managerId
              submitterId
              result
              testId
              testCode
              meta
              answerSheetId
              title
              type
              assessed
              scheduledDate
              score
              scoreGauge
              status
              createdAt
              updatedAt
              billedTime {
                __typename
                id
                minutes
                type
                date
                userId
                createdAt
                updatedAt
              }
            }
          }
          questionnaireResponses {
            __typename
            nextToken
            items {
              __typename
              id
              accountId
              patientId
              encounterId
              contributors
              created
              name
              title
              status
              json
              createdAt
              updatedAt
            }
          }
          clinicalImpressions {
            __typename
            nextToken
            items {
              __typename
              id
              accountId
              patientId
              encounterId
              authorId
              created
              title
              status
              summary
              name
              signature
              approved
              createdAt
              updatedAt
            }
          }
          providers {
            __typename
            nextToken
          }
          notes {
            __typename
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetEncounterExtendedQuery>response.data.getEncounter;
  }
  async ListUserLocationExtendedConnections(
    filter?: ModelUserLocationConnectionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListUserLocationExtendedConnectionsQuery> {
    const statement = `query ListUserLocationExtendedConnections($filter: ModelUserLocationConnectionFilterInput, $limit: Int, $nextToken: String) {
        listUserLocationConnections(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            accountId
            userId
            locationId
            createdAt
            updatedAt
            location {
              __typename
              id
              name
            }
            user {
              __typename
              id
              accountId
              owner
              email
              phone
              online
              firstName
              lastName
              titles
              npi
              image
              userType
              onlineStatus
              pinCode
              permissions
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListUserLocationExtendedConnectionsQuery>(
      response.data.listUserLocationConnections
    );
  }
  async ListEncountersExtended(
    filter?: ModelEncounterFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListEncountersExtendedQuery> {
    const statement = `query ListEncountersExtended($filter: ModelEncounterFilterInput, $limit: Int, $nextToken: String) {
        listEncounters(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            accountId
            locationId
            managerId
            patientId
            ownerId
            status
            date
            soapItems {
              __typename
              type
              text
              code
              selected
            }
            createdAt
            updatedAt
            location {
              __typename
              accountId
              callerId
              createdAt
              id
              name
              owner
              physicianCount
              testsCount
              updatedAt
              usersCount
            }
            patient {
              __typename
              id
              accountId
              locationId
              patientManagerId
              owner
              salutation
              givenName
              middleName
              familyName
              ssn
              dob
              gender
              mobile
              email
              interests
              providerIds
              status
              eligibleRPM
              qualifiedTests
              connectionId
              createdAt
              updatedAt
            }
            manager {
              __typename
              id
              accountId
              owner
              email
              phone
              online
              firstName
              lastName
              titles
              npi
              image
              userType
              onlineStatus
              pinCode
              permissions
              createdAt
              updatedAt
            }
            billedTimes {
              __typename
              nextToken
              items {
                __typename
                id
                billedTime {
                  __typename
                  id
                  minutes
                  type
                  date
                  userId
                }
                createdAt
                updatedAt
              }
            }
            pretest {
              __typename
              id
              accountId
              locationId
              patientId
              providers
              managerId
              submitterId
              result
              testId
              testCode
              meta
              answerSheetId
              title
              type
              assessed
              scheduledDate
              score
              scoreGauge
              status
              createdAt
              updatedAt
            }
            assessments {
              __typename
              nextToken
              items {
                __typename
                id
                accountId
                locationId
                patientId
                providers
                managerId
                submitterId
                result
                testId
                testCode
                meta
                answerSheetId
                title
                type
                assessed
                scheduledDate
                score
                scoreGauge
                status
                createdAt
                updatedAt
                billedTime {
                  __typename
                  id
                  minutes
                  type
                  date
                  userId
                  createdAt
                  updatedAt
                }
              }
            }
            questionnaireResponses {
              __typename
              nextToken
              items {
                __typename
                id
                accountId
                patientId
                encounterId
                contributors
                created
                name
                title
                status
                json
                createdAt
                updatedAt
              }
            }
            clinicalImpressions {
              __typename
              nextToken
              items {
                __typename
                id
                accountId
                patientId
                encounterId
                authorId
                created
                title
                status
                summary
                name
                signature
                approved
                createdAt
                updatedAt
              }
            }
            providers {
              __typename
              nextToken
            }
            notes {
              __typename
              nextToken
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEncountersExtendedQuery>response.data.listEncounters;
  }
}
