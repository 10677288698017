import { Injectable, OnDestroy, OnInit } from '@angular/core'
import { webSocket, WebSocketSubject } from 'rxjs/webSocket'
import { BehaviorSubject, Observable, Subscription, timer, zip } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class WsConnectionService implements OnDestroy {
  public connection$: WebSocketSubject<any>
  public message$: BehaviorSubject<any>
  private _messageSubscription: Subscription
  constructor() {
    this.message$ = new BehaviorSubject<any>(null)
  }

  /**
   * Connect to the websocket service via accessToken provided by the API
   * @param accessToken
   */
  connect(accessToken: string): WebSocketSubject<any> {
    console.log('Connecting to WS')
    if (this.connection$) {
      console.log('Connection already existing, disconnecting first')
      this.disconnect()
    }

    // TODO: Put URL in a deployment variable
    this.connection$ = webSocket(
      `wss://eqqfoe1k8c.execute-api.us-east-1.amazonaws.com/staging?accessToken=${accessToken}`
    )

    this._messageSubscription = this.connection$.subscribe(message => {
      console.log('Received Message', message)
      this.message$.next(message)
    })

    return this.connection$
  }

  /**
   * Send a message to a target (userId)
   * @param target - userId of the user target to receive the message
   * @param action - action identifier so that it gets handled by known handlers
   * @param message - message or data
   */
  send(target: string, action: string, message: any) {
    if (this.connection$) {
      const data = {
        target,
        action,
        message
      }
      this.connection$.next(data)
    } else {
      console.log('Cannot send message, no connection yet')
    }
  }

  /**
   * Disonnect and stop listening for messages
   */
  disconnect() {
    if (this.connection$) {
      console.log('Disconnecting from WS')
      this.connection$.complete()
      this.connection$ = null
      this._messageSubscription.unsubscribe()
      this._messageSubscription = null
    } else {
      console.log('Cannot disconnect, no connection yet')
    }
  }

  // Cleanup the service
  ngOnDestroy() {
    this.disconnect()
  }
}
