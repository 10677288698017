import { Component, Input, OnInit } from '@angular/core'
import { Question, QuestionMeta, Section } from '../../../../../types'

@Component({
  selector: 'app-instruction-question',
  templateUrl: './instruction-question.component.html',
  styleUrls: ['./instruction-question.component.scss']
})
export class InstructionQuestionComponent implements OnInit {
  /**
   * The Section object
   */
  @Input('section') section: Section
  /**
   * Question Meta that is 'selected' for this Question
   */
  @Input('questionMeta') questionMeta: QuestionMeta
  /**
   * The Question object
   */
  @Input('question') question: Question
  constructor() {}

  ngOnInit(): void {}
}
