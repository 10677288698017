import { Component, NgZone, OnDestroy, OnInit } from '@angular/core'
import { onAuthUIStateChange } from '@aws-amplify/ui-components'
import { NavigationEnd, Router } from '@angular/router'
import { AuthService } from './services/auth.service'
import { SessionService } from './services/session.service'
import { Observable, Subscription } from 'rxjs'
import { BnNgIdleService } from 'bn-ng-idle'
import { SeedService } from './services/seed.service'
import { WsConnectionService } from './services/ws-connection.service'
import { WsMessagingService } from './services/ws-messaging.service'
import { PatientSessionService } from './testing/services/patient-session.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'breconsole'
  loading = true
  loadedSessionSub: Subscription
  bnIdleSub: Subscription
  routeSub: Subscription
  seedSub: Subscription
  jwtSub: Subscription
  messageSub: Subscription

  constructor(
    private router: Router,
    private sessionService: SessionService,
    private ngZone: NgZone,
    private bnIdle: BnNgIdleService,
    private seedService: SeedService,
    private wsConnectionService: WsConnectionService,
    private wsMessagingService: WsMessagingService,
    private patientSessionService: PatientSessionService
  ) {}

  ngOnInit() {
    this.loadedSessionSub = this.sessionService.loadedSession$.subscribe(
      loaded => {
        this.loading = !loaded
      }
    )

    this.seedSub = this.sessionService.currentUser$.subscribe(user => {
      if (user != null) {
        console.log('CURRENTUSER', user)
        this.seedService.up()
      }
    })

    this.routeSub = this.router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        this.sessionService.currentRoute$.next(evt.urlAfterRedirects)
      }
    })

    // TODO: Need to triple check the cleanup when switching between users
    // Start listening to wsConnections once jwtToken becomes accessible from the session (means logged in)
    this.jwtSub = this.sessionService.jwtToken$.subscribe(token => {
      if (token != null) {
        this.patientSessionService.initialize()

        // Before establishing a NEW connection, make sure to disconnect first
        this.wsConnectionService.disconnect()
        // And turn off any existing subscriptions
        if (this.messageSub) this.messageSub.unsubscribe()

        console.log('Connecting to socket server')

        // Connect to the service
        this.wsConnectionService.connect(token)

        // Listen for messages
        this.messageSub = this.wsConnectionService.message$.subscribe(
          message => {
            this.wsMessagingService.processMessage(message)
          }
        )
      }
    })

    // This controls the Authenticator UI.
    onAuthUIStateChange((authState, authData) => {
      this.ngZone.run(() => {
        if (authState === 'signedin') {
          // When the UI changes to has successfully signedin, try routing to dashboard
          this.router.navigate(['/'])
        } else if (authState === 'signin' || authState === 'signedout') {
          // When the UI has signedout, try routing to login page
          this.router.navigate(['/login'])
        }
        // Whenever there is a change in auth, try loading session
        this.sessionService.loadSession()
      })
    })

    // TODO: Enable this when used
    this.bnIdleSub = this.bnIdle.startWatching(300).subscribe(isTimedOut => {
      if (isTimedOut) {
        // this.authService.logout().then(() => {
        //   return this.router.navigate(['/login'])
        // })
      }
    })
  }

  ngOnDestroy() {
    this.seedService.down()

    if (this.seedSub) this.seedSub.unsubscribe()
    if (this.loadedSessionSub) this.loadedSessionSub.unsubscribe()
    if (this.bnIdleSub) this.bnIdleSub.unsubscribe()
    if (this.messageSub) this.messageSub.unsubscribe()
    if (this.wsConnectionService) this.wsConnectionService.disconnect()
    return onAuthUIStateChange
  }
}
