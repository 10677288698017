import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot
} from '@angular/router'
import { APIService } from '../API.service'
import { SessionService } from './session.service'
import { LocalStorageService } from './local-storage.service'
import { isBoolean } from 'lodash'

@Injectable({
  providedIn: 'root'
})
export class PageLockService {
  public lockMode: boolean
  public patientId: string

  public lastNavigationPaths: Array<string>
  public lastQueryParams: { [key: string]: string }

  constructor(
    private apiService: APIService,
    private sessionService: SessionService,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {
    const lockMode = this.getLockMode()
    if (isBoolean(lockMode)) {
      this.lockMode = lockMode
    } else {
      // only set to false if you are absolutely
      // sure there is no existing lock
      this.lockMode = false
    }
  }

  public getLockMode() {
    return this.localStorageService.get<boolean>('lockMode')
  }

  public setLockMode() {
    this.localStorageService.put<boolean>('lockMode', this.lockMode)
  }

  public initializeOnPatientTest(patientId: string) {
    this.patientId = patientId
    this.lockMode = true
    this.setLockMode()
  }

  public async routeAllowed(
    snapshot: ActivatedRouteSnapshot
  ): Promise<boolean> {
    // Always allow if lock mode is disabled
    if (!this.lockMode) return true

    const path = snapshot.url.map(u => u.path).join('/')

    if (path === 'test') {
      const assessmentId = snapshot.queryParams.assessmentId
      if (assessmentId) {
        // Is checking an assessment
        const assessment = await this.apiService.GetAssessment(assessmentId)
        if (assessment) {
          return assessment.patientId === this.patientId
        }
      }
    } else {
    }

    return false
  }

  public unlockWithCode(code) {
    const currentUser = this.sessionService.currentUser$.getValue()
    let newLockMode
    if (!currentUser) {
      // Lock it if there is no user logged in
      newLockMode = false
    } else if (!currentUser.pinCode) {
      // Don't lock if there is no pincode anyway
      newLockMode = false
    } else {
      newLockMode = currentUser.pinCode !== code
    }

    console.log('New Lockmode Value is ', this.lockMode)
    this.lockMode = newLockMode
    this.setLockMode()

    if (!this.lockMode) {
      // If it is in unlocked mode
      // Clear all data
      this.patientId = null
    }
  }

  public async applyLastNavigation() {
    console.log(
      'Navigating to ' + this.lastNavigationPaths,
      this.lastQueryParams
    )

    if (!this.lastNavigationPaths || this.lastNavigationPaths?.length === 0) {
      this.lastNavigationPaths = ['']
    }

    await this.router.navigate(this.lastNavigationPaths, {
      queryParams: this.lastQueryParams
    })
  }

  public saveLastNavigation(snapshot: ActivatedRouteSnapshot) {
    const paths = snapshot.url.map(url => {
      return url.path
    })
    this.lastNavigationPaths = paths
    this.lastQueryParams = snapshot.queryParams

    console.log('SAVING Last Navigation', {
      lastNavigationPaths: this.lastNavigationPaths,
      lastQueryParams: this.lastQueryParams
    })
  }
}
