import { Component, Input, OnInit } from '@angular/core'
import { AnswerSheetItem } from '../../../../../types'
import { FormControl, FormGroup } from '@angular/forms'
import { TestService } from '../../../../../services/test.service'

@Component({
  selector: 'app-score-single-choice',
  templateUrl: './score-single-choice.component.html',
  styleUrls: ['./score-single-choice.component.scss']
})
export class ScoreSingleChoiceComponent implements OnInit {
  /**
   * The Section object
   */
  @Input('answer') answer: AnswerSheetItem
  /**
   * This is the primary interface to store/get field data
   */
  public formGroup: FormGroup
  constructor(private testService: TestService) {}

  ngOnInit(): void {
    // Load the answer if it exists
    const value = this.answer.answerValue || {}
    // Instantiate the form group and control
    this.formGroup = new FormGroup({
      [this.answer.questionRef.id]: new FormControl({ value, disabled: true })
    })
  }
}
