import { QuestionMeta } from './question-meta'

export type Question = {
  /************************* REQUIRED FIELDS *******************************/
  /**
   * Identifier for this question for reporting
   */
  id: string
  /**
   * Main subtype of this object
   */
  type: QuestionType
  /**
   * Refers to the different variations (aka versions)
   */
  meta: QuestionMeta[]
  /************************* OPTIONAL FIELDS *******************************/
  /**
   * Description of this question
   */
  description?: string
  /**
   * Contains the options specific to the question's type, different settings
   */
  scoring?: QuestionScoring
  /**
   * Contains information when this question should be displayed/hidden/required
   */
  logic?: QuestionLogic
  /**
   * Contains the options specific to the question's type, different settings
   */
  options?: QuestionOptions
}

export enum QuestionType {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
  COMPLETION = 'COMPLETION',
  COPY_SENTENCE = 'COPY_SENTENCE',
  DRAWING = 'DRAWING',
  ENUMERATION = 'ENUMERATION',
  LABELING = 'LABELING',
  SHORT_ESSAY = 'SHORT_ESSAY',
  INSTRUCTION = 'INSTRUCTION',
  YES_NO = 'YES_NO'
}

export type QuestionScoring = {
  isAutomatic?: boolean
  maximumScore: number
  guide?: string
}

export type QuestionLogic = {
  groupQualifiers?: Array<string>
  gender?: 'Male' | 'Female'
} & any

export type QuestionOptions = {
  // Used by Drawing Component
  strokeWidth?: number
  strokeAlpha?: number
  imageAlpha?: number
  editable?: boolean
}
