import { Component, Input, OnInit } from '@angular/core'
import { AnswerSheetItem } from '../../../../../types'

@Component({
  selector: 'app-score-labeling',
  templateUrl: './score-labeling.component.html',
  styleUrls: ['./score-labeling.component.scss']
})
export class ScoreLabelingComponent implements OnInit {
  /**
   * The Section object
   */
  @Input('answer') answer: AnswerSheetItem
  /**
   * Refers to the numbering of the field inputs
   */
  public blanks: number[]

  /**
   * Refers to the inputs model to store the field values
   */
  public inputs: { [key: string]: string }
  constructor() {}

  ngOnInit(): void {
    // Load the answer value if it exists
    const inputs = this.answer.answerValue

    // FIll for the numbering
    this.blanks = Array(this.answer.questionRef.items.length)
      .fill(0)
      .map((x, i) => i + 1)

    // Then setup the input fields (so that they are non null)
    this.inputs = this.blanks.reduce((acc, cur) => {
      if (!acc[cur]) {
        acc[cur] = ''
      }
      return acc
    }, inputs || {})
  }
}
