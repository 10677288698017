import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core'
import { Question, QuestionMeta, Section } from '../../../../../types'
import { TestService } from '../../../../../services/test.service'

@Component({
  selector: 'app-copy-sentence-question',
  templateUrl: './copy-sentence-question.component.html',
  styleUrls: ['./copy-sentence-question.component.scss']
})
export class CopySentenceQuestionComponent implements OnInit, AfterViewInit {
  /**
   * The Section object
   */
  @Input('section') section: Section
  /**
   * Question Meta that is 'selected' for this Question
   */
  @Input('questionMeta') questionMeta: QuestionMeta
  /**
   * The Question object
   */
  @Input('question') question: Question
  /**
   * Emitter that tells whether this question type component has reached an answered state
   */
  @Output() answered = new EventEmitter<boolean>()
  /**
   * This is the canvas element where the text is rendered so that user cannot 'hack' and copy paste
   */
  @ViewChild('canvasElement') canvas: ElementRef<HTMLCanvasElement>

  /**
   * Context object used by canvas to render the sentence to copy
   */
  public context: CanvasRenderingContext2D

  /**
   * Refers to the copy sentence field model
   */
  public input: string

  constructor(private testService: TestService) {}

  ngOnInit(): void {
    // Load answer if it is available
    this.input = this.testService.getAnswerValue(this.question.id) || ''
  }

  ngAfterViewInit(): void {
    // Render the question upon startup
    this.context = this.canvas.nativeElement.getContext('2d')
    this.context.font = '24px Arial'
    this.context.textAlign = 'left'
    this.context.fillText(
      this.questionMeta.text,
      0, //this.canvas.nativeElement.width / 2,
      this.canvas.nativeElement.height / 2
    )
    // Always check upon init
    this.checkIfAnswered()
  }

  onChange(value) {
    // Update the value upon changing
    this.testService.updateAnswerValue(
      this.section,
      this.question,
      this.questionMeta,
      value
    )
    this.checkIfAnswered()
  }

  private checkIfAnswered() {
    const value = this.input || ''
    this.answered.emit(value.length > 0)
  }
}
