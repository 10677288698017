export enum DisplaySegment {
  // Has not started the test but is already reading the test intro
  INTRO = 'INTRO',
  // Within the main test
  MAIN = 'MAIN',
  // Waiting Pretest Approval
  WAITING_PRETEST = 'WAITING_PRETEST',
  // Waiting Next Assessment
  WAITING = 'WAITING',
  // Assessment is in completed state
  COMPLETED = 'COMPLETED',
  // It is in loading state
  LOADING = 'LOADING',
  // Pretest was in a Unqualified state
  UNQUALIFIED = 'UNQUALIFIED'
}

export type StepperStep = {
  label: string
}
