import { Component, Input, OnInit } from '@angular/core'
import { StepperStep } from '../types'
import { Subscription } from 'rxjs'
import { TestService } from '../services/test.service'
import { ActivatedRoute } from '@angular/router'
import { ScoreService } from '../services/score.service'
import { SessionService } from '../../services/session.service'
import { APIService } from '../../API.service'

@Component({
  selector: 'app-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.scss']
})
export class ScoreComponent implements OnInit {
  /**
   * AnswerSheetId to be scored
   */
  @Input('answerSheetId') answerSheetId: string
  /**
   * Current part of the test
   */
  stepperSteps: StepperStep[]
  /**
   * Current Section Index as marker
   */
  currentSectionIndex: number
  /**
   * Subscriptions so that it can be unsubscribed in component lifecycle
   */
  private sectionSub: Subscription
  private sectionIndexSub: Subscription
  /**
   * Name of the patient that is answering the test
   * @private
   */
  private patientName: string

  constructor(
    public scoreService: ScoreService,
    private activatedRoute: ActivatedRoute,
    private sessionService: SessionService,
    private apiService: APIService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.initialize(params.answerSheetId || this.answerSheetId)
    })
  }

  initialize(answerSheetId: string) {
    const currentUser = this.sessionService.currentUser$.getValue()

    // Load the answer sheet
    this.scoreService.initRating(answerSheetId, currentUser['id']).then(() => {
      const answerSheet = this.scoreService.answerSheet$.getValue()
      // Fetch the answer sheet object
      // and derive the patient name
      this.apiService
        .GetPatient(answerSheet.patientId)
        .then(({ givenName, familyName }) => {
          this.patientName = `${givenName} ${familyName}`
        })

      this.scoreService.startRating()
    })

    // Load the stepper steps so that the test is navigatable
    this.sectionSub = this.scoreService.sections$.subscribe(sections => {
      this.stepperSteps = sections.map(
        (section): StepperStep => {
          return { label: section.sectionRef.title }
        }
      )
    })

    // Listen for currentSection index
    this.sectionIndexSub = this.scoreService.currentSectionIndex$.subscribe(
      index => {
        this.currentSectionIndex = index
      }
    )

    // TODO: this should triggered from INTRO page
    this.startRating()
  }

  ngOnDestroy(): void {
    // To avoid memory leak, unsubscribe
    this.sectionSub.unsubscribe()
    this.sectionIndexSub.unsubscribe()
  }

  startRating() {
    this.scoreService.startRating()
  }

  nextSection() {
    this.scoreService.nextSection()
  }

  previousSection() {
    this.scoreService.previousSection()
  }

  async submitScores() {
    await this.scoreService.submitScores()
  }
}
