import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  constructor() {}

  public get<T>(key: string): T {
    const data = localStorage.getItem(key)
    let object: T = data ? JSON.parse(data) : null
    return object
  }

  public put<T>(key: string, object: T): void {
    const data = JSON.stringify(object)
    localStorage.setItem(key, data)
  }

  public has(key: string): boolean {
    const data = localStorage.getItem(key)
    return !!data
  }

  public remove(key: string): void {
    localStorage.removeItem(key)
  }
}
