import { Component, Input, OnInit } from '@angular/core'
import { AnswerSheetItem, Section } from '../../../../../types'

@Component({
  selector: 'app-score-completion',
  templateUrl: './score-completion.component.html',
  styleUrls: ['./score-completion.component.scss']
})
export class ScoreCompletionComponent implements OnInit {
  /**
   * The Section object
   */
  @Input('answer') answer: AnswerSheetItem
  /**
   * These are the parts of a completion question, they
   * are rendered as text or input fields
   */
  public parts: CompletionRatingPart[]
  /**
   * These are the input fields for this component
   */
  public inputs: { [key: string]: string } = {}

  constructor() {
    // Start with empty
    this.parts = []
  }

  ngOnInit(): void {
    const inputs = this.answer.answerValue

    // Upon initialization, load the dynamic template, parse it into parts
    const parts = this.parse(this.answer.questionRef.text)

    // Save the parts to this component
    this.parts = parts

    // Each part will determine the inputs model
    this.inputs = parts.reduce((acc, cur) => {
      if (cur.type === 'input' && !acc[cur.data]) {
        acc[cur.data] = ''
      }
      return acc
    }, inputs || {})
  }

  /**
   * This is the main porser for the dynamic templates
   * @param text
   */
  parse(text: string): CompletionRatingPart[] {
    let parts: CompletionRatingPart[] = []
    let temp = ''

    // Process each character
    for (let i = 0; i < text.length; i++) {
      let ch = text.charAt(i)

      if (ch === '\n') {
        // When it is a newline
        if (temp.trim().length) {
          // if the temp var has contents, save it as text
          parts.push({ type: 'text', data: temp.trim() })
          temp = ''
        }
        parts.push({ type: 'break' })
      } else if (ch === '{') {
        // When it is an opening {
        if (temp.trim().length) {
          // if the temp var has contents, save it as text
          parts.push({ type: 'text', data: temp.trim() })
          temp = ''
        }
      } else if (ch === '}') {
        if (temp.trim().length) {
          const inputToken = temp.trim()
          let [data, placeholder, width] = inputToken
            .split(':')
            .map(t => t.trim())
          if (!width) width = '100px'
          if (!placeholder) placeholder = data
          // if the temp var has contents, probably an input token
          parts.push({ type: 'input', data, width, placeholder })
          temp = ''
        }
      } else if (i === text.length - 1) {
        // When it is the last character
        // Just add it to temp, and save it as text regardless
        temp += ch
        parts.push({ type: 'text', data: temp.trim() })
        temp = ''
      } else {
        // In most cases, its just a string so just add it to temp
        temp += ch
      }
    }
    return parts
  }
}

export type CompletionRatingPart = {
  type: 'text' | 'input' | 'break'
  data?: string
  width?: string
  placeholder?: string
}
