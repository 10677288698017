/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://6rqtiylq4rbbhc5kmojkkfc3gi.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_content_delivery_bucket": "bretest-20201229111720-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d1oot12r5wt99b.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-east-1:d936a42b-9304-4a3e-b45e-61554dece293",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_XXGe6SFvU",
    "aws_user_pools_web_client_id": "6b4kmdgru1oqaa8acj9fglhfvk",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://bjbkdd259d.execute-api.us-east-1.amazonaws.com/staging",
            "region": "us-east-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "ClinicalImpression-7dyr2uxaandylbsjibobcvxtxm-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "ClinicalImpression-7dyr2uxaandylbsjibobcvxtxm-dev",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
