import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core'
import { AnswerSheetItem } from '../../../../../types'

@Component({
  selector: 'app-score-copy-sentence',
  templateUrl: './score-copy-sentence.component.html',
  styleUrls: ['./score-copy-sentence.component.scss']
})
export class ScoreCopySentenceComponent implements OnInit, AfterViewInit {
  /**
   * The Section object
   */
  @Input('answer') answer: AnswerSheetItem
  /**
   * This is the canvas element where the text is rendered so that user cannot 'hack' and copy paste
   */
  @ViewChild('canvasElement') canvas: ElementRef<HTMLCanvasElement>

  /**
   * Context object used by canvas to render the sentence to copy
   */
  public context: CanvasRenderingContext2D

  /**
   * Refers to the copy sentence field model
   */
  public input: string

  constructor() {}

  ngOnInit(): void {
    // Load answer if it is available
    this.input = this.answer.answerValue || ''
  }

  ngAfterViewInit(): void {
    // Render the question upon startup
    this.context = this.canvas.nativeElement.getContext('2d')
    this.context.font = '24px Arial'
    this.context.textAlign = 'left'
    this.context.fillText(
      this.answer.questionRef.text,
      0, //this.canvas.nativeElement.width / 2,
      this.canvas.nativeElement.height / 2
    )
  }
}
