import { Component, Input, OnInit } from '@angular/core'
import { TestService } from '../../../services/test.service'
import {
  QuestionType,
  AnswerSheetItem,
  AnswerSheetSection,
  AnswerSheet
} from '../../../types'
import { ScoreService } from '../../../services/score.service'

@Component({
  selector: 'app-score-answer',
  templateUrl: './score-answer.component.html',
  styleUrls: ['./score-answer.component.scss']
})
export class ScoreAnswerComponent implements OnInit {
  /**
   * The Section object that is forwarded
   */
  @Input('section') section: AnswerSheetSection
  /**
   * The Question object that is forwarded from Section
   */
  @Input('answer') answer: AnswerSheetItem
  /**
   * Expose QuestionType enum for the template
   */
  public QuestionType = QuestionType

  public sectionScore: number
  public sectionMaxScore: number

  constructor(private scoreService: ScoreService) {}

  ngOnInit(): void {
    // Fetch the meta number and load it as questionMeta stream
    if (this.answer.score === undefined || this.answer.score === null) {
      this.answer.score = 0
    }

    this.sectionScore = 0
    this.sectionMaxScore = this.section.sectionRef.scoring?.maximumScore || 0
  }

  /**
   * Awards a point to question score
   */
  addPoint() {
    const max = this.answer.questionRef.questionScoring?.maximumScore || 0
    if (this.answer.score < max) {
      this.answer.score++
      if (this.answer.isAutomaticScored) {
        this.answer.isAutomaticScored = false
      }
      this.scoreService.updateTotalScore()
    }
  }

  /**
   * Awards a point to question score
   */
  minusPoint() {
    if (this.answer.score > 0) this.answer.score--
    if (this.answer.isAutomaticScored) {
      this.answer.isAutomaticScored = false
    }
    this.scoreService.updateTotalScore()
  }
}
