import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { PageNotFoundComponent } from './page-not-found/page-not-found.component'
import { LoginComponent } from './login/login.component'
import { LoginGuard } from './shared/guards/login.guard'
import { DashboardComponent } from './dashboard/dashboard.component'
import { AuthGuard } from './shared/guards/auth.guard'
import { TestComponent, ScoreComponent } from './testing'
import { TestGuard } from './shared/guards/test.guard'
import { ScoreGuard } from './shared/guards/score.guard'
import { AnswerSheetsComponent } from './answer-sheets/answer-sheets.component'
import { PageLockComponent } from './page-lock/page-lock.component'
import { PageLockGuard } from './shared/guards/page-lock.guard'

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'test',
    component: TestComponent,
    canActivate: [AuthGuard, TestGuard, PageLockGuard]
  },
  {
    path: 'score',
    component: ScoreComponent,
    canActivate: [AuthGuard, ScoreGuard, PageLockGuard]
  },
  {
    path: 'answer-sheets',
    component: AnswerSheetsComponent,
    canActivate: [AuthGuard, PageLockGuard]
  },
  {
    path: 'page-lock',
    component: PageLockComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard, PageLockGuard]
  },
  // System Pages
  { path: '404', component: PageNotFoundComponent }, // page is not found
  // Redirects
  { path: '**', redirectTo: '/404', pathMatch: 'full' }
]

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        //enableTracing: true,
        useHash: true
      } // <-- debugging purposes only
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
