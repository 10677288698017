import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Question, QuestionMeta, Section } from '../../../../../types'
import { FormControl, FormGroup } from '@angular/forms'
import { TestService } from '../../../../../services/test.service'

@Component({
  selector: 'app-single-choice-question',
  templateUrl: './single-choice-question.component.html',
  styleUrls: ['./single-choice-question.component.scss']
})
export class SingleChoiceQuestionComponent implements OnInit {
  /**
   * The Section object
   */
  @Input('section') section: Section
  /**
   * Question Meta that is 'selected' for this Question
   */
  @Input('questionMeta') questionMeta: QuestionMeta
  /**
   * The Question object
   */
  @Input('question') question: Question
  /**
   * Emitter that tells whether this question type component has reached an answered state
   */
  @Output() answered = new EventEmitter<boolean>()
  /**
   * This is the primary interface to store/get field data
   */
  public formGroup: FormGroup
  constructor(private testService: TestService) {}

  ngOnInit(): void {
    // Load the answer if it exists
    const value = this.testService.getAnswerValue(this.question.id) || ''
    // Instantiate the form group and control
    this.formGroup = new FormGroup({
      [this.question.id]: new FormControl(value)
    })
    // Always check upon init
    this.checkIfAllAnswered()
  }

  onChange(value) {
    // Update the answer upon changes, as text
    this.testService.updateAnswerValue(
      this.section,
      this.question,
      this.questionMeta,
      value
    )
    this.checkIfAllAnswered()
  }

  private checkIfAllAnswered() {
    const value = this.formGroup.get(this.question.id).value || ''
    this.answered.emit(value.length > 0)
  }
}
