import { Injectable } from '@angular/core'
import { LocalStorageService } from '../../services/local-storage.service'
import { APIService, AssessmentStatus, AssessmentType } from '../../API.service'
import { Router } from '@angular/router'
import { identity, pickBy } from 'lodash'
import { SessionService } from '../../services/session.service'
import { TestService } from '../index'

/**
 * Patient Session Service
 *
 * Keeps track of the current patient session (or encounter).
 * The current assessments that need to be made by a patient will be stored
 */
@Injectable({
  providedIn: 'root'
})
export class PatientSessionService {
  session: PatientSession
  constructor(
    private lsService: LocalStorageService,
    private router: Router,
    private apiService: APIService,
    private sessionService: SessionService
  ) {}

  public initialize(
    patientId: string = null,
    pretestId: string = null,
    assessmentIds: Array<string> = [],
    isInPretest: boolean = false
  ) {
    // Load session when it is not initialized from else where
    if (patientId === null) {
      this.loadSessionFromLocal()
    } else if (this.session && this.session.patientId !== patientId) {
      // If we initialize the service for another patient
      // We need to invalidate the data
      this.invalidateSession()

      this.session = {
        patientId: patientId,
        assessmentIds: assessmentIds,
        pretestAssessmentId: pretestId,
        isInPretest: isInPretest
      }
    } else {
      this.session = {
        patientId: patientId,
        assessmentIds: assessmentIds,
        pretestAssessmentId: pretestId,
        isInPretest: isInPretest
      }
    }

    console.log('Initialized Patient Session', this.session)
  }

  /**
   * This fetches all assessments of the patient
   * @param patientId
   */
  public async loadPatientSessionFromRemote(patientId: string) {
    const currentUser = this.sessionService.currentUser$.getValue()
    if (currentUser) {
      // TODO: Maybe better filter by date as well?
      const { items } = await this.apiService.ListAssessments({
        status: { eq: AssessmentStatus.SCHEDULED },
        patientId: { eq: patientId }
      })
      const pretestAssessment = items.find(a => {
        return a.type === AssessmentType.PRETEST
      })
      const otherAssessments = items.filter(a => {
        return a.type === AssessmentType.ASSESSMENT
      })

      console.log('Loading Patient Session from Remote', {
        pretestAssessment,
        otherAssessments
      })

      this.session = {
        pretestAssessmentId: pretestAssessment ? pretestAssessment.id : null,
        isInPretest: !!pretestAssessment,
        assessmentIds: otherAssessments.map(a => a.id),
        patientId: patientId,
        encounterId: null
      }
      this.saveSessionToLocal()
    }
  }

  public loadSessionFromLocal() {
    console.log('Loading Patient Session!!!', this.session)
    this.session = this.lsService.get<PatientSession>('PatientSession')
  }

  public saveSessionToLocal() {
    console.log('Saving Patient Session!!!', this.session)
    this.lsService.put<PatientSession>('PatientSession', this.session)
  }

  public invalidateSession() {
    console.log('Invalidation Patient Session!!!', this.session)
    this.lsService.remove('PatientSession')
    this.session = null
  }

  public async navigateToCurrentAssessment() {
    console.log(
      'Navigating to next assessment from Patient Session!!!',
      this.session
    )
    // Load the session from local if it is not yet set
    if (!this.session) {
      this.loadSessionFromLocal()
    }
    // If there is an existing session
    if (this.session) {
      let assessmentId = null
      // Check if the patient should be doing a pretest first;
      if (this.session.isInPretest) {
        // If so use that
        assessmentId = this.session.pretestAssessmentId
      } else {
        // otherwise, select the correct index among the assessmentIds
        assessmentId = this.session.assessmentIds[0]
      }

      await this.router.navigate(['test'], {
        queryParams: {
          assessmentId: assessmentId
        }
      })
    }
  }

  public assessmentsLeft(): number {
    if (this.session) {
      return (
        this.session.assessmentIds.length + (this.session.isInPretest ? 1 : 0)
      )
    }
    return 0
  }

  public updateSession(session: {
    pretestAssessmentId?
    assessmentIds?
    currentAssessmentIndex?
    patientId?
    encounterId?
    isInPretest?
  }) {
    console.log('Updating session with', this.session, session)
    const cleanSession = pickBy(session, identity)
    if (!this.session) {
      this.session = {
        pretestAssessmentId: null,
        assessmentIds: [],
        patientId: null,
        encounterId: null,
        isInPretest: false
      }
    }
    this.session = Object.assign({}, this.session, cleanSession)
    this.saveSessionToLocal()
    console.log('Patient Session Updated', this.session)
  }

  public assessmentDone(assessmentId) {
    if (
      this.session &&
      this.session.isInPretest &&
      assessmentId === this.session?.pretestAssessmentId
    ) {
      // If the session is still in pretest, and the assessment was marked done;
      this.session.isInPretest = false
    } else if (this.session) {
      // Otherwise, this might just be a normal assessment;
      this.session.assessmentIds = this.session.assessmentIds.filter(
        id => id !== assessmentId
      )
    }
    this.saveSessionToLocal()
  }
}

export type PatientSession = {
  patientId: string
  /**
   * If an encounter has been created, store it in session
   */
  encounterId?: string
  /**
   * If a pretest has been made, store the pretestAssessmentId
   */
  pretestAssessmentId: string
  /**
   * If assessments were already known, store the ids here
   */
  assessmentIds: Array<string>
  /**
   * If the patient session is in pretest mode
   */
  isInPretest: boolean
}
