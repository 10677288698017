import { Component, OnInit } from '@angular/core'
import { PageLockService } from '../services/page-lock.service'
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-page-lock',
  templateUrl: './page-lock.component.html',
  styleUrls: ['./page-lock.component.scss']
})
export class PageLockComponent implements OnInit {
  constructor(private pageLockService: PageLockService) {}

  ngOnInit(): void {}

  onCodeChanged(code: string) {}

  // this called only if user entered full code
  async onCodeCompleted(code: string) {
    this.pageLockService.unlockWithCode(code)
    await this.pageLockService.applyLastNavigation()
  }
}
