import { Injectable, OnDestroy } from '@angular/core'
import {
  AnswerSheet,
  AnswerSheetItem,
  AnswerSheetSection,
  AnswerSheetStatus,
  DisplaySegment,
  Question,
  QuestionMeta,
  QuestionType,
  Section,
  Test
} from '../types'
import {
  BehaviorSubject,
  combineLatest,
  interval,
  Observable,
  Subscription
} from 'rxjs'
import { map, take } from 'rxjs/operators'
import { isObject } from 'lodash'
import { AnswerSheetApiService } from './answer-sheet-api.service'
import { TestApiService } from './test-api.service'
import {
  APIService,
  AssessmentStatus,
  AssessmentType,
  GetAssessmentQuery,
  ScoreGauge,
  UpdateAssessmentMutation,
  GetPatientTestingProfileMutation
} from '../../API.service'
import { SessionService } from '../../services/session.service'
import { WsConnectionService } from '../../services/ws-connection.service'
import { WsMessagingService } from '../../services/ws-messaging.service'
import { WsActions } from '../../shared/types/ws-actions'
import { PatientSessionService } from './patient-session.service'
import { modGPCOGTest } from '../types/test-examples/mod-gpcog.test'
import { cesdrTest } from '../types/test-examples/cesdr.test'
import { phq9Test } from '../types/test-examples/phq9.test'
import { harmTest } from '../types/test-examples/harm.test'

@Injectable({
  providedIn: 'root'
})
export class TestService implements OnDestroy {
  /**
   * An interval that will always run
   * @private
   */
  private intervalCounter: Observable<number>
  /**
   * Current timer for the section before automatically moving to the next section
   */
  public timerCount$: Observable<number>

  /**
   * Tells if there is a next section available
   */
  public hasNextSection$: Observable<boolean>

  /**
   * Current assessment that is loaded
   */
  public assessment$: BehaviorSubject<GetAssessmentQuery>

  /**
   * Current assessment that is loaded
   */
  public testingProfile$: BehaviorSubject<GetPatientTestingProfileMutation>

  /**
   * Current test to display
   */
  public test$: BehaviorSubject<Test>

  /**
   * Current set of sections active when a test is ongoing
   */
  public sections$: Observable<Section[]>

  /**
   * Current Display Segment - This tells which part of the test flow it is in
   *
   */
  public displaySegment$: BehaviorSubject<DisplaySegment>
  public currentSection$: BehaviorSubject<Section>
  public questionsInSection$: Observable<Question[]>
  public currentSectionIndex$: BehaviorSubject<number>
  public meta$: BehaviorSubject<number>
  public isSubmittingAnswers$: BehaviorSubject<boolean>

  public answerSheet: AnswerSheet

  private startTimestamp: number

  private jwtSub: Subscription
  private messageSub: Subscription
  private timerSub: Subscription
  private currentSectionSub: Subscription

  constructor(
    private testApiService: TestApiService,
    private answerSheetApiService: AnswerSheetApiService,
    private apiService: APIService,
    private sessionService: SessionService,
    private wsConnectionService: WsConnectionService,
    private wsMessagingService: WsMessagingService,
    private patientSessionService: PatientSessionService
  ) {
    this.intervalCounter = interval(1000)
    this.test$ = new BehaviorSubject<Test>(null)
    this.displaySegment$ = new BehaviorSubject<DisplaySegment>(null)
    this.currentSectionIndex$ = new BehaviorSubject<number>(0)
    this.currentSection$ = new BehaviorSubject<Section>(null)
    this.meta$ = new BehaviorSubject<number>(null)
    this.timerCount$ = new BehaviorSubject(null)
    this.isSubmittingAnswers$ = new BehaviorSubject(false)
    this.assessment$ = new BehaviorSubject(null)
    this.testingProfile$ = new BehaviorSubject(null)

    this.sections$ = this.test$.pipe(
      map(test => {
        if (test) return test.items
        return []
      })
    )

    this.hasNextSection$ = combineLatest([
      this.currentSectionIndex$,
      this.sections$
    ]).pipe(
      map(([index, sections]) => {
        return index < sections.length - 1
      })
    )

    // When a currentSection changes in value, run a timer that will use the
    // section timer options, to automatically move to the next section
    this.currentSectionSub = this.currentSection$.subscribe(section => {
      if (section) {
        // At the start of any section, save the start timestamp
        this.startTimestamp = Date.now()
        this.startTimerToNextSection(section.options?.timeToAnswer || 0)
      }
    })

    // Handle pretest approvals
    this.wsMessagingService.handle(
      WsActions.PretestApproval,
      async ({ message }) => {
        console.log('AssessmentApproved!!!!', message)

        // Sample message content:
        // {
        //    pretestAssessmentId: "bffc16cd-8969-4d72-a4a5-42b5e4c0dedb"
        //    selectedAssessments: ["7c4f6c50-4b20-4eb6-a895-109f41ec9a1a"]
        //    wasApproved: true
        // }

        // The approval WS Message will contain the details:
        // The assessment that was approved (must check if it is relevant to us)
        const pretestAssessmentId = message['pretestAssessmentId']
        // What are the currently selected assessments; array of ids
        const selectedAssessments = message['selectedAssessments']
        // If the pretest was approved
        const wasApproved = message['wasApproved']
        // What the current assessment is -- if it is the same on, we must update the state
        const currentAssessment = this.assessment$.getValue()

        console.log(
          'Processing the pretest approval message',
          message,
          currentAssessment
        )

        if (pretestAssessmentId === currentAssessment.id) {
          if (wasApproved) {
            // SHow the loading dialog
            this.displaySegment$.next(DisplaySegment.LOADING)

            // Update the patient session with the correct details
            this.patientSessionService.updateSession({
              patientId: currentAssessment.patientId,
              pretestAssessmentId: pretestAssessmentId,
              currentAssessmentIndex: 0,
              isInPretest: false,
              assessmentIds: selectedAssessments
            })

            await this.patientSessionService.navigateToCurrentAssessment()
          } else {
            this.displaySegment$.next(DisplaySegment.UNQUALIFIED)
          }
        }
      }
    )
  }

  async initTest(assessment: GetAssessmentQuery) {
    this.assessment$.next(assessment)

    const testingProfile = await this.apiService.GetPatientTestingProfile({
      patientId: assessment.patientId
    })
    this.testingProfile$.next(testingProfile)

    console.log('InitTest', assessment.status)
    // TODO: What happens when an admin opens up a test that is supposed to be done only in the future?
    switch (assessment.status) {
      /**
       * A scheduled test means that it is meant to be started
       */
      case AssessmentStatus.SCHEDULED:
        // Need to do this in order for the number to be hidden at the beginning
        this.timerCount$ = undefined
        this.assessment$.next(assessment)
        // TODO: fix this later
        let t: Test = await this.testApiService.getTest(assessment.testId)

        // Apply the showLogic right here; this way it will only be shown when there is
        t.items = t.items.reduce((sections, section): Section[] => {
          // Filter questions in section based on if it should be shown
          section.items = section.items.filter(question => {
            return this.shouldQuestionShow(question)
          })

          if (section.items.length) {
            sections.push(section)
          }

          return sections
        }, [])

        this.test$.next(t)
        this.displaySegment$.next(DisplaySegment.INTRO)
        this.meta$.next(assessment.meta)
        this.initializeAnswerSheet(t, assessment.meta, assessment.patientId)
        break
      /**
       * A pending test means it is waiting for an admin's action
       * Such as scoring or perhaps if its a pretest, it will
       * have to wait for the approval
       */
      case AssessmentStatus.PENDING:
        if (assessment.type === AssessmentType.PRETEST) {
          this.displaySegment$.next(DisplaySegment.WAITING_PRETEST)
        } else {
          this.displaySegment$.next(DisplaySegment.WAITING)
        }
        break
      /**
       * The three states will show the same Display Segment
       * which says that the test has been "completed"
       */
      case AssessmentStatus.COMPLETED:
      case AssessmentStatus.QUALIFIED:
      case AssessmentStatus.UNQUALIFIED:
        this.displaySegment$.next(DisplaySegment.COMPLETED)
        break
    }
  }

  /**
   * Starts a timer that will make it so to automatically move to the next
   * section when the timer runs out
   * @param seconds
   */
  startTimerToNextSection(seconds: number) {
    // Check if we have a timerSub already watching the counter
    // If it exists, unsubscribe
    if (this.timerSub) this.timerSub.unsubscribe()

    if (seconds > 0) {
      // Create a new subscription to watch the timer
      this.timerCount$ = this.intervalCounter.pipe(
        take(seconds),
        map(countdown => {
          return seconds - countdown - 1
        })
      )

      this.timerSub = this.timerCount$.subscribe(value => {
        if (value === 0) {
          // countdown reached, trigger to move to next section
          const moved = this.nextSection()
          if (!moved) {
            // Probably end of the test now
            this.submitAnswerSheetAndUpdateAssessment()
          }
        }
      })
    }
  }

  /**
   * Initialize an answersheet from a test
   * @param test
   * @param meta
   * @param patientId
   */
  initializeAnswerSheet(test: Test, meta: number, patientId: string) {
    const answerSheetSections: Array<AnswerSheetSection> = test.items.map(
      (section): AnswerSheetSection => {
        return {
          timeElapsed: 0,
          sectionRef: {
            title: section.title,
            type: section.type,
            instructions: section.instructions,
            options: section.options,
            scoring: section.scoring
          },
          items: section.items.map(
            (question): AnswerSheetItem => {
              // Instruction types are NOT answerable
              const answerable = question.type !== QuestionType.INSTRUCTION
              const select = meta % question.meta.length
              const qMeta = question.meta[select]
              return {
                questionRef: {
                  id: question.id,
                  type: question.type,
                  text: qMeta.text,
                  items: qMeta.items,
                  data: qMeta.data,
                  options: qMeta.options,
                  questionScoring: question.scoring,
                  questionMetaScoring: qMeta.scoring
                },
                // TODO: Change back to null
                answerValue: false,
                score: null,
                isAutomaticScored: !!question.scoring?.isAutomatic,
                // The fields below are not stored in backend
                isAnswered: !answerable
              }
            }
          )
        }
      }
    )

    // Initialize the answer sheet
    this.answerSheet = {
      startedAt: null,
      endedAt: null,
      accountId: test.accountId,
      status: AnswerSheetStatus.UNANSWERED,
      items: answerSheetSections,
      meta: meta,
      patientId: patientId,
      testRef: {
        id: test.id,
        code: test.code,
        title: test.title,
        type: test.type,
        description: test.description,
        options: test.options,
        scoring: test.scoring
      }
    }
  }

  shouldQuestionShow(question: Question) {
    const testingProfile = this.testingProfile$.getValue()
    if (question.logic) {
      if (question.logic.groupQualifiers) {
        if (
          question.logic.groupQualifiers.includes(testingProfile.groupQualifier)
        ) {
          return true
        } else {
          return false
        }
      }
    }
    return true
  }

  startTest() {
    const currentTest = this.test$.getValue()
    const currentMeta = this.meta$.getValue()

    if (!currentTest) return null

    this.answerSheet.startedAt = Date.now()

    // TODO: Change to index 0 so it starts on the first page
    const index = 0
    this.displaySegment$.next(DisplaySegment.MAIN)
    this.currentSectionIndex$.next(index)
    const section = currentTest.items[index]
    if (this.questionsInSection$) {
      this.questionsInSection$ = null
    }

    // Create a new subscription
    this.questionsInSection$ = this.currentSection$.pipe(
      map(sec => {
        if (sec) {
          return sec.items
        } else {
          return []
        }
      })
    )

    this.currentSection$.next(section)
    console.log('Loaded test-section', this.currentSection$.getValue())
  }

  nextSection(): boolean {
    if (
      this.displaySegment$.getValue() !== DisplaySegment.MAIN ||
      this.isSubmittingAnswers$.getValue() === true
    ) {
      return false
    }
    const currentTest = this.test$.getValue()
    if (!currentTest) return null

    let timeElapsed = null
    if (this.startTimestamp) {
      timeElapsed = (Date.now() - this.startTimestamp) / 1000
      const lastSectionIndex = this.currentSectionIndex$.getValue()
      this.answerSheet.items[lastSectionIndex].timeElapsed = timeElapsed
      console.log('Updated', this.answerSheet.items[lastSectionIndex])
    }

    const sections = currentTest.items
    let index = this.currentSectionIndex$.getValue()

    if (index < sections.length - 1) {
      index += 1
      const section = sections[index]
      this.currentSection$.next(section)
      this.currentSectionIndex$.next(index)
      console.log('Moving to next test-section', section)
      return true
    } else {
      // Return false to notify it can't move to next section
      return false
    }
  }

  async submitAnswerSheetAndUpdateAssessment(): Promise<UpdateAssessmentMutation> {
    // We need to send the submitter ID because Websockets will have to notify the user back
    const submitterId = this.sessionService.userId$.getValue()
    // Process the answer sheet without respect to the assessment first
    this.isSubmittingAnswers$.next(true)
    // Update our endedAt value
    this.answerSheet.endedAt = Date.now()
    this.answerSheet.status = AnswerSheetStatus.UNSCORED
    console.log('Submitting Answers..', this.answerSheet)
    const addedSheet = await this.answerSheetApiService.addAnswerSheet(
      this.answerSheet
    )
    console.log('Added Answer sheet', addedSheet)
    this.isSubmittingAnswers$.next(false)

    const assessment = this.assessment$.getValue()

    const test = this.test$.getValue()
    // Update the assessment and put it in PENDING state
    const updatedAssessment = await this.apiService.UpdateAssessment({
      id: assessment.id,
      status: AssessmentStatus.PENDING,
      answerSheetId: addedSheet.id,
      submitterId: submitterId,
      testCode: test.code,
      score: addedSheet.totalScore,
      scoreGauge: ScoreGauge.UNSCORED,
      assessed: new Date().toISOString()
    })

    if (assessment.type === AssessmentType.PRETEST) {
      // If the assessment is a pretest
      // Put it in a waiting state. In doing so,
      // have the system wait for the next assessment
      this.displaySegment$.next(DisplaySegment.WAITING_PRETEST)

      this.wsConnectionService.send(
        assessment.managerId,
        WsActions.PretestSubmission,
        {
          assessmentId: assessment.id,
          text:
            assessment.patient?.givenName +
            ' ' +
            assessment.patient?.familyName +
            ' has completed the Pretest and is awaiting approval'
        }
      )
    } else {
      // Assessments on the other hand would have a bunch of
      this.displaySegment$.next(DisplaySegment.WAITING)
    }

    this.patientSessionService.assessmentDone(updatedAssessment.id)
    // TODO: Start waiting for next assessment to be available

    // Return the updated assessment for further processing
    return updatedAssessment
  }

  updateAnswerValue(
    section: Section,
    question: Question,
    questionMeta: QuestionMeta,
    value: any
  ) {
    console.log('Updating Answer sheet with', {
      question,
      questionMeta,
      value,
      answerSheet: this.answerSheet
    })
    const answerSheetItem = this.findAnswerSheetItemByQuestionId(question.id)
    if (answerSheetItem.answerValue === null) {
      // Need to initialize answer if it is not set
      answerSheetItem.answerValue = value
    }

    // When both are maps
    if (answerSheetItem.answerValue != null && isObject(value)) {
      const previousValue = answerSheetItem.answerValue
      // if answer value is an object Or a Mapping
      answerSheetItem.answerValue = Object.assign({}, previousValue, value)

      console.log('Dealing with maps, so merged', {
        value,
        previousValue,
        merged: answerSheetItem.answerValue
      })
    } else {
      answerSheetItem.answerValue = value
    }

    console.log('AnswerSheetItem', answerSheetItem)
  }

  private findAnswerSheetItemByQuestionId(questionId: string): AnswerSheetItem {
    const sections = this.answerSheet.items
    for (let i = 0; i < sections.length; i++) {
      const section = sections[i]
      const questions = section.items
      for (let j = 0; j < questions.length; j++) {
        const question = questions[j]
        if (question.questionRef?.id === questionId) {
          return question
        }
      }
    }
    return null
  }

  getAnswerValue(questionId: string): any {
    console.log('Getting Answer sheet', this.answerSheet)
    if (this.answerSheet) {
      return this.findAnswerSheetItemByQuestionId(questionId)?.answerValue
    }
    return null
  }

  hasCompletedCurrentSection(): boolean {
    // This checks if all questions in the current section are in the 'answered' state
    const currentSection = this.currentSection$.getValue()
    if (!currentSection) return false

    // using the question ids
    return currentSection.items
      .map(q => q.id)
      .reduce((acc, cur) => {
        // check if the answersheet item corresponding to it
        // is in answered state
        const answerSheetItem = this.findAnswerSheetItemByQuestionId(cur)
        // ALL questions must be in answered state for it to be "complete"
        return acc && answerSheetItem.isAnswered
      }, true)
  }

  /**
   *
   * @param questionId
   * @param answeredState
   */
  updateAnsweredState(questionId: string, answeredState: boolean) {
    const answerSheetItem = this.findAnswerSheetItemByQuestionId(questionId)
    console.log('Found answersheetItem', answerSheetItem)
    if (answerSheetItem) {
      answerSheetItem.isAnswered = answeredState
    }
  }

  /**
   * Reinitialize will
   */
  reinitialize() {
    this.test$.next(null)
    this.displaySegment$.next(null)
    this.currentSectionIndex$.next(0)
    this.currentSection$.next(null)
    this.meta$.next(null)
    this.isSubmittingAnswers$.next(false)
  }

  /**
   * Cleanup all subscriptions of this component
   */
  ngOnDestroy(): void {
    this.jwtSub.unsubscribe()
    this.currentSectionSub.unsubscribe()
    this.messageSub.unsubscribe()
    this.timerSub.unsubscribe()
  }

  setDoneState(): void {
    const isPretest =
      this.assessment$.getValue()?.type === AssessmentType.PRETEST
    this.reinitialize()
    if (isPretest) this.displaySegment$.next(DisplaySegment.WAITING_PRETEST)
    else this.displaySegment$.next(DisplaySegment.COMPLETED)
  }
}
