import { Injectable } from '@angular/core'
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router'
import { Observable } from 'rxjs'
import { AuthService } from '../../services/auth.service'
import { PageLockService } from '../../services/page-lock.service'

@Injectable({
  providedIn: 'root'
})
export class PageLockGuard implements CanActivate {
  constructor(
    private pageLockService: PageLockService,
    private router: Router
  ) {}
  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<any> {
    /**
     * This guard is supposed to assert that page lock is in the correct state
     * and matches the correct target route
     */
    const allowed = await this.pageLockService.routeAllowed(next)

    console.log('PageLock State', {
      allowed,
      next,
      state
    })

    if (!allowed) {
      this.pageLockService.saveLastNavigation(next)
      await this.router.navigate(['/page-lock'])
    }
    return true
  }
}
