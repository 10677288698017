import { Component, Input, OnInit } from '@angular/core'
import { AnswerSheetItem } from '../../../../../types'

@Component({
  selector: 'app-score-instruction',
  templateUrl: './score-instruction.component.html',
  styleUrls: ['./score-instruction.component.scss']
})
export class ScoreInstructionComponent implements OnInit {
  /**
   * The Section object
   */
  @Input('answer') answer: AnswerSheetItem
  constructor() {}

  ngOnInit(): void {}
}
