import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Question, QuestionMeta, Section } from '../../../../../types'
import { FormControl, FormGroup } from '@angular/forms'
import { TestService } from '../../../../../services'
import { isBoolean } from 'lodash'
@Component({
  selector: 'app-yes-no-question',
  templateUrl: './yes-no-question.component.html',
  styleUrls: ['./yes-no-question.component.scss']
})
export class YesNoQuestionComponent implements OnInit {
  /**
   * The Section object
   */
  @Input('section') section: Section
  /**
   * Question Meta that is 'selected' for this Question
   */
  @Input('questionMeta') questionMeta: QuestionMeta
  /**
   * The Question object
   */
  @Input('question') question: Question
  /**
   * Emitter that tells whether this question type component has reached an answered state
   */
  @Output() answered = new EventEmitter<boolean>()
  /**
   * This is the primary interface to store/get field data
   */
  public formGroup: FormGroup

  value: boolean

  constructor(private testService: TestService) {}

  ngOnInit(): void {
    // Load the answer if it exists
    const val = this.testService.getAnswerValue(this.question.id)
    this.value = isBoolean(val) ? val : null
    // Instantiate the form group and control
    this.formGroup = new FormGroup({
      [this.question.id]: new FormControl(this.value)
    })

    this.checkIfAllAnswered()
  }

  onChange(value) {
    this.value = value
    // Update the answer upon changes, as text
    this.testService.updateAnswerValue(
      this.section,
      this.question,
      this.questionMeta,
      value
    )
    this.checkIfAllAnswered()
  }

  private checkIfAllAnswered() {
    this.answered.emit(isBoolean(this.value))
  }
}
