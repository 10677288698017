import { Component, OnInit } from '@angular/core'
import { TestService } from '../../services/test.service'

@Component({
  selector: 'app-test-section',
  templateUrl: './test-section.component.html',
  styleUrls: ['./test-section.component.scss']
})
export class TestSectionComponent implements OnInit {
  constructor(public testService: TestService) {}

  ngOnInit(): void {}
}
