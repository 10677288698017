import { AnswerSheetItem } from './answer-sheet-item'
import { AnswerSheetSection } from './answer-sheet-section'
import { TestScoring } from '../test'
import { TestReference } from './test-reference'

export type AnswerSheet = {
  /**
   * Answer ID will be a uuid generated
   */
  id?: string
  /**
   * Timestamp the answer sheet started at
   */
  startedAt: number
  /**
   * Timestamp the answer sheet ended at
   */
  endedAt: number
  /**
   * Account this answer sheet belongs to; should copy patient's
   */
  accountId: string
  /**
   * Current variant selected
   */
  meta: number
  /**
   * Patient ID answering the test
   */
  patientId: string
  /**
   * Status of the AnswerSheet as it goes through the flow
   */
  status: AnswerSheetStatus
  /**
   * AnswerSheet Sections
   */
  items: AnswerSheetSection[]
  /**
   * Total score based on the
   */
  totalScore?: number
  /**
   * ScorerID or the CCM's ID that rated this answersheet
   */
  scorerId?: string
  /**
   * Reference of the test object
   */
  testRef: TestReference
}

export enum AnswerSheetStatus {
  UNANSWERED = 'UNANSWERED',
  UNSCORED = 'UNSCORED',
  SCORED = 'SCORED',
  ARCHIVED = 'ARCHIVED'
}
