import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter
} from '@angular/core'

import { TestService } from '../services'
import { AnswerSheet, DisplaySegment, StepperStep } from '../types'
import { Subscription } from 'rxjs'
import { ActivatedRoute, Router } from '@angular/router'
import {
  APIService,
  GetAssessmentQuery,
  UpdateAssessmentMutation
} from '../../API.service'
import { PatientSessionService } from '../services/patient-session.service'

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit, OnDestroy {
  /**
   * Test Component initializes with assessmentId
   */
  @Input('assessmentId') assessmentId?: string
  /**
   * Test Component initializes with meta
   */
  @Input('meta') meta?: number
  /**
   *
   */
  @Output()
  finished: EventEmitter<GetAssessmentQuery> = new EventEmitter<GetAssessmentQuery>()
  /**
   * Current part of the test
   */
  stepperSteps: StepperStep[]
  /**
   * Current Section Index as marker
   */
  currentSectionIndex: number

  /**
   * Subscriptions so that it can be unsubscribed in component lifecycle
   */
  private sectionSub: Subscription
  private sectionIndexSub: Subscription

  displaySegments = DisplaySegment

  /**
   * Name of the patient that is answering the test
   * @private
   */
  private patientName: string

  constructor(
    public testService: TestService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private apiService: APIService,
    private patientSessionService: PatientSessionService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.initialize(params.assessmentId || this.assessmentId)
    })
  }

  async initialize(assessmentId: string): Promise<void> {
    const assessment: GetAssessmentQuery = await this.apiService.GetAssessment(
      assessmentId
    )
    const patient = assessment.patient

    // Load the test and its sections
    await this.testService.initTest(assessment)

    this.patientName = `${patient.givenName} ${patient.familyName}`

    // Load the stepper steps so that the test is navigatable
    this.sectionSub = this.testService.sections$.subscribe(sections => {
      this.stepperSteps = sections.map(
        (section): StepperStep => {
          return { label: section.title }
        }
      )
    })

    // Listen for currentSection index
    this.sectionIndexSub = this.testService.currentSectionIndex$.subscribe(
      index => {
        this.currentSectionIndex = index
      }
    )
  }

  ngOnDestroy(): void {
    // To avoid memory leak, unsubscribe
    this.sectionSub.unsubscribe()
    this.sectionIndexSub.unsubscribe()
  }

  startTest() {
    this.testService.startTest()
  }

  nextSection() {
    this.testService.nextSection()
  }

  async submitAnswers() {
    const updated: UpdateAssessmentMutation = await this.testService.submitAnswerSheetAndUpdateAssessment()
    // Clear the component of leftover data
    this.testService.reinitialize()
    // check if there is a next assessment
    const count = this.patientSessionService.assessmentsLeft()
    if (count > 0) {
      // The current assessment will now be the next in line
      await this.patientSessionService.navigateToCurrentAssessment()
    } else {
      await this.testService.setDoneState()
    }
    this.finished.emit(updated)
  }

  hasCompletedCurrentSection(): boolean {
    return this.testService.hasCompletedCurrentSection()
  }

  async close() {
    this.testService.displaySegment$.next(null)
    await this.router.navigate([''])
  }
}
