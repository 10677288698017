import { Component, Input, OnInit } from '@angular/core'
import { Question, QuestionType, QuestionMeta, Section } from '../../../types'
import { TestService } from '../../../services/test.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit {
  /**
   * The Section object that is forwarded
   */
  @Input('section') section: Section
  /**
   * The Question object that is forwarded from Section
   */
  @Input('question') question: Question
  /**
   * The questionMeta that is currently selected
   */
  public questionMeta$: Observable<QuestionMeta>
  /**
   * Expose QuestionType enum for the template
   */
  public QuestionType = QuestionType

  constructor(public testService: TestService) {}

  ngOnInit(): void {
    // Fetch the meta number and load it as questionMeta stream
    this.questionMeta$ = this.testService.meta$.pipe(
      map(meta => {
        // Using the meta, map it to the questionMeta using modulo operation
        const index = meta % this.question.meta.length
        return this.question.meta[index]
      })
    )
  }

  onAnsweredChanged(answered: boolean) {
    // When an the answer value changes, we double check from the question type
    // if it switches to a answered/unanswered state and must update the answerSheet as such
    this.testService.updateAnsweredState(this.question.id, answered)
  }
}
