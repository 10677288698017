import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core'
import { AnswerSheetItem, QuestionOptions } from '../../../../../types'

@Component({
  selector: 'app-score-drawing',
  templateUrl: './score-drawing.component.html',
  styleUrls: ['./score-drawing.component.scss']
})
export class ScoreDrawingComponent implements OnInit, AfterViewInit {
  /**
   * The Section object
   */
  @Input('answer') answer: AnswerSheetItem
  /**
   * The canvas where the drawing is rendered and user can draw
   */
  @ViewChild('canvasElement') canvas: ElementRef<HTMLCanvasElement>

  /**
   * Context2D to render stuff in canvas
   */
  public canvasContext: CanvasRenderingContext2D

  /**
   * Question options that was merged with defaults
   */
  private options: QuestionOptions

  constructor() {}

  ngOnInit(): void {
    const options = this.answer.questionRef.options
    // Load default options
    this.options = Object.assign(
      {},
      {
        // Assign defaults
        strokeWidth: 30,
        strokeAlpha: 0.17,
        imageAlpha: 1
      },
      options
    )
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    // Need to load these in AfterView because that is the only valid time
    // for canvas to be reachable
    const canvas = this.canvas.nativeElement
    const context = canvas.getContext('2d')
    this.canvasContext = context

    const data = this.answer.answerValue
    this.drawAnswerImage(data)
  }

  /**
   * Draws the base image
   */
  drawAnswerImage(data): void {
    const canvas = this.canvas.nativeElement
    const img = new Image()
    const ctx = this.canvasContext
    img.onload = function () {
      // Load imageAlpha which comes from the options object
      ctx.globalAlpha = 1
      ctx.drawImage(
        img,
        0,
        0,
        img.width,
        img.height,
        0,
        0,
        canvas.width,
        canvas.height
      ) // Or at whate
    }

    // Image URL is loaded from meta.data
    img.src = data
  }
}
